export const INITIAL_VALUES = {
  total: 100000,
  risk: 1,
  riskType: 'dynamic',
  maxAmount: 30
};

export const TABS = {
  demo: 'demo',
  active: 'active',
};
