import React, { useCallback } from 'react';
import _ from 'lodash';
import propTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import DialogForm from 'components/DialogForm';
import { Field, Form, useField } from 'react-final-form';
import { Input } from 'components/Fields';
import { InputAdornment } from '@material-ui/core';
import * as validators from 'helpers/validators';
import './styles.scss';
import { CustomSwitch } from 'components/Controls';
import classNames from 'classnames';

const Fields = ({ symbol, name, disabled }) => (
  <>
    <p>{symbol}</p>
    <Field
      isSmallField
      size="small"
      variand="standart"
      name={name}
      disabled={disabled}
      component={Input}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            Pts
          </InputAdornment>
        )
      }}
    />
  </>
);

Fields.propTypes = {
  name: propTypes.string,
  symbol: propTypes.string,
  disabled: propTypes.bool,
}

const Range = ({ name }) => {
  const { input: { value: global } } = useField('value');
  const { input: { value }, meta: { error } } = useField(name, {
    validate: ({ value, available_range: range, enabled }) => {
      if (value instanceof Object) {
        return (enabled ? (
          validators.validateWithRange(range, true)(value.min_value) ||
          validators.validateWithRange(range, true)(value.max_value) ||
          validators.validateMinMax(value)
        ) : null)
      }
      return enabled ? validators.validateWithRange(range, true)(value) || validators.validateWithoutSpace(value) : null;
    }
  });

  return (
    <div className={classNames('row', { disabled: !value.enabled })} >
      <span>
        <Field
          type="checkbox"
          name={`${name}.enabled`}
          render={({ input }) => (
            <CustomSwitch
              edge="start"
              input={input}
              disabled={input.checked && (global.above_line.enabled ^ global.below_line.enabled)}
            />
          )}
        />
        <FormattedMessage id={`entry_system.conditions.${value.kind}`} tagName="p" />
      </span>
      <span>
        {value.value instanceof Object ? (
          <>
            <Fields symbol="&ge;" disabled={!value.enabled} name={`${name}.value.min_value`} />
            <Fields symbol="&le;" disabled={!value.enabled} name={`${name}.value.max_value`} />
          </>
        ) : (
          <Fields symbol="&le;" disabled={!value.enabled} name={`${name}.value`} />
        )}
      </span>
      {error && <p className="error">{error}</p>}
    </div>
  );
};

Range.propTypes = {
  name: propTypes.string,
};

const TripleConditionForm = ({ open, obj, handleClose, handleSave }) => {
  const title = (
    <div className="condition-form-header title2 bold">
      <FormattedMessage id={`entry_system.conditions.${obj.kind}.header`} />
    </div>
  );

  const controls = (invalid, handleSubmit) => (
    <div className="custom-dialog__actions">
      <button
        disabled={invalid}
        onClick={() => { handleSubmit(); handleClose() }}
        className="primary-btn main-btn form-action-btn bold"
      >
        <FormattedMessage id="modules.buttons.apply" />
      </button>
    </div>
  );

  const content = useCallback(values => (
    <form className='triple-tolerance-form'>
      <div className='triple-tolerance-form__content'>
        <Range name="value.above_line" />
        <Range name="value.below_line" />
      </div>
    </form>
  ), []);

  if (_.isEmpty(obj)) {
    return null
  };

  return (
    <>
      <Form
        onSubmit={handleSave}
        initialValues={obj}
        render={({ values, handleSubmit, invalid }) => {
          return (
            <DialogForm
              open={open}
              title={title}
              content={content(values, handleSubmit)}
              controls={controls(invalid, handleSubmit)}
              handleClose={handleClose}
              customClass='condition-form-popup'
            />
          )
        }}
      />
    </>
  );
};

TripleConditionForm.propTypes = {
  open: propTypes.bool,
  obj: propTypes.object,
  handleClose: propTypes.func,
  handleSave: propTypes.func
};

export default TripleConditionForm;
