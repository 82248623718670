import React, { useState, useCallback } from 'react';
import propTypes from 'prop-types';
import { ClickAwayListener, IconButton, InputAdornment } from '@material-ui/core';
import { Input } from 'components/Fields';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import EditIcon from '@material-ui/icons/Edit';
import { useNotify } from 'contexts/popup';
import { validateTitle } from 'helpers/validators';

const Title = ({ title, changeTitle, id, isEditingAvailable }) => {
  const [editing, setEditing] = useState(false);
  const notify = useNotify();

  const handleSubmit = useCallback(event => {
    event.preventDefault();
    const newTitle = event.target.title.value.trim();
    if (newTitle && newTitle !== title) {
      const error = validateTitle(50)(newTitle);
      if (error) {
        notify.error({ title: 'Error', description: error });
        return;
      }
      changeTitle({ id, title: newTitle });
    }
    setEditing(false);
  }, [id, setEditing, changeTitle]);

  return (
    <ClickAwayListener
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
      onClickAway={() => { setEditing(false) }}
    >
      <div className="title">
        {editing ? (
          <form onSubmit={handleSubmit}>
            <Input
              isSmallField
              size="small"
              name="title"
              variand="standart"
              defaultValue={title}
              onKeyDown={e => { e.key === 'Escape' && setEditing(false) }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton color="inherit" type="submit" edge="end">
                      <CheckCircleIcon />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </form>
        ) : (
          <div className="edit-button">
            <span>{title}</span>
            {isEditingAvailable && (
              <button className="single-icon-btn" onClick={() => { setEditing(true) }}>
                <EditIcon />
              </button>
            )}
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
};

Title.propTypes = {
  isEditingAvailable: propTypes.bool,
  id: propTypes.number,
  title: propTypes.string,
  changeTitle: propTypes.func
};

Title.defaultProps = {
  isEditingAvailable: true,
}

export default Title;
