import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';

import './styles.scss';
import TradeHistoryRow from './TradeHistoryRow';

const TradeHistoryTable = ({ data, headerColumns, rowColumns, totalColumns, handlers, isStickyHeader }) => {
  return (
    <div className={cn('trade-history', {
      'trade-history--sticky': isStickyHeader
    })}>
      <table className="app-table">
        <thead className="app-table__head">
          <tr>
            {headerColumns.map(({ attribute, classes }, index) => (
              <th key={index} className={classes}>
                <FormattedMessage id={`trade.history.${attribute}`} defaultMessage=" " />
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="app-table__content">
          {data?.logs?.map((row, index) => (
            <TradeHistoryRow row={row} key={row.id} rowColumns={rowColumns} handlers={handlers} />
          ))}
          {data?.total && totalColumns && (
            <TradeHistoryRow row={data.total} rowColumns={totalColumns} />
          )}
        </tbody>
      </table>
    </div>
  );
};

TradeHistoryTable.propTypes = {
  data: PropTypes.object,
  headerColumns: PropTypes.array,
  rowColumns: PropTypes.array,
  totalColumns: PropTypes.array,
  handlers: PropTypes.object,
  isStickyHeader: PropTypes.bool,
};

export default TradeHistoryTable;
