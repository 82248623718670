import React, { useCallback } from 'react';
import propTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useSwitcher } from 'contexts/pageSwitcher';
import './styles.scss';

const CandlePatternEntry = ({ prefix }) => {
  const switcher = useSwitcher();
  const onClick = useCallback((type, position) => () => {
    switcher.moveTo(type === 'candlePatterns' ? 1 : 2, {
      type: position,
      isLogicEditor: true,
      fieldName: `${prefix}.${position}`
    });
  }, [switcher, prefix]);

  return (
    <div className="candle_pattern_entry">
      <div className="title3 bold">
        <FormattedMessage id={`logicEditor.${prefix}_menu`} />
      </div>
      {['candlePatterns', 'candleClose'].map(type => (
        <div className="candle_pattern_entry__item" key={type}>
          <FormattedMessage id={`logicEditor.${type}`} tagName="p" />
          <span>
            <button
              onClick={onClick(type, 'long')}
              className="secondary-btn main-btn"
            >
              long
            </button>
            <button
              onClick={onClick(type, 'short')}
              className="secondary-btn main-btn"
            >
              short
            </button>
          </span>
        </div>
      ))}
    </div>
  );
};

CandlePatternEntry.propTypes = {
  prefix: propTypes.string.isRequired,
  onClick: propTypes.func
};

/**
 * A div menu to select short/long default pattern editor
 */
const DefaultPatternsMenu = () => {
  return (
    <div className="default-patterns-form">
      <div className="form__card">
        <div className="form__card-content">
          <h2 className="title2 bold">
            <FormattedMessage id="logicEditor.menuTitle" />
          </h2>
          <CandlePatternEntry prefix="triple_close" />
          <CandlePatternEntry prefix="triple_tolerance" />
        </div>
      </div>
    </div>
  );
};

export default DefaultPatternsMenu;
