import { useEffect, useMemo } from 'react';
import _ from 'lodash';

import { useStore } from 'hooks';
import { useChartSync } from 'contexts/chart';
import { boundNum } from 'helpers';

export function useArmingLinesButtons (chart) {
  const { chartStore: { tfGrouper, armingLine, rsi } } = useStore();
  const { observers } = useChartSync();
  const dataZoomIndex = tfGrouper.getIndexDataZoom(1);

  _useCheckingButtonsLock({ chart, dataZoomIndex });

  useEffect(() => observers.armingLine.subscribe(button => {
    if (!armingLine.lines.length) return;
    const { startValue, endValue } = chart.getOption().dataZoom[dataZoomIndex];
    const middleChartIndex = Math.round((startValue + endValue) / 2);

    // function wrapped for laziness
    const armingLineTo = {
      prev: () => _.last(armingLine.lines.filter(line => line.middle.index < middleChartIndex)),
      next: () => _.first(armingLine.lines.filter(line => line.middle.index > middleChartIndex)),
      last: () => _.last(armingLine.lines)
    }[button]();

    // strict (-startValue ≤ shiftIndex ≤ rsi.length - endValue - 1) to not cross boundaries
    const shiftIndex = boundNum(-startValue, armingLineTo.middle.index - middleChartIndex, rsi.allData.length - endValue - 1);

    chart.dispatchAction({
      type: 'dataZoom',
      dataZoomIndex,
      startValue: startValue + shiftIndex,
      endValue: endValue + shiftIndex,
      source: 'tal-button',
    });
  }), [armingLine.lines]);
}

function _useCheckingButtonsLock ({ chart, dataZoomIndex }) {
  const { chartStore: { armingLine: { buttonAvailability, lines } } } = useStore();

  const checkIsArmingLinesButtonsDisabled = useMemo(() => _.debounce(() => {
    const { start, end, startValue, endValue } = chart.getOption().dataZoom[dataZoomIndex];
    if (!lines.length || (start === 0 && end === 0)) {
      buttonAvailability.set(false);
      return;
    }
    const middleChartIndex = Math.round((startValue + endValue) / 2);
    const isChartEnd = _.last(lines).middle.index <= middleChartIndex || end === 100;
    const isChartStart = _.first(lines).middle.index >= middleChartIndex || start === 0;

    buttonAvailability.set(isChartEnd, 'last');
    buttonAvailability.set(isChartEnd, 'next');
    buttonAvailability.set(isChartStart, 'prev');
  }, 150), [lines]);

  useEffect(() => {
    if (!chart) return;
    checkIsArmingLinesButtonsDisabled();
    chart.on('datazoom', checkIsArmingLinesButtonsDisabled);
    return () => { chart.off('datazoom', checkIsArmingLinesButtonsDisabled) };
  }, [checkIsArmingLinesButtonsDisabled]);
}

export function useListenerArmingLines (chart) {
  const { chartStore } = useStore();
  useEffect(() => {
    if (!chart) return;
    const setTALValueThrottled = _.throttle(v => chartStore.armingLine.value.set(v), 100);

    chart.on('mousemove', 'series', (event) => {
      if (event.seriesIndex < 3) return;
      setTALValueThrottled(event?.value ? event : null);
    });

    chart.on('mouseout', 'series', (event) => {
      if (event.seriesIndex < 3) return;
      setTALValueThrottled(null)
    });

    return () => {
      chart.off('mousemove');
      chart.off('mouseout');
    };
  }, [chart]);
}
