import React, { useEffect, useMemo, useState } from 'react';
import propTypes from 'prop-types';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import { compose } from 'helpers';
import { toRow } from 'containers/ModulesListContainer/utils';
import BacktestStore from 'store/backtestStore';
import ModuleStore from 'store/moduleStore';
import TradeHistoryStore from 'store/tradeHistory';
import BackTestModal from 'components/BackTestModal';
import BackTestList from 'components/BackTestList';
import DialogBackTestCopy from 'components/DialogBackTestCopy';
import Loader from 'components/Loader';
import { useStore } from 'hooks';

import './styles.scss';

const BackTestModules = ({ store, moduleStore, tradeHistoryStore }) => {
  const { instrumentsStore } = useStore();
  const [isDialogCopyOpen, setIsDialogCopyOpen] = useState(false);
  const [isRunBacktestOpen, setIsRunBacktestOpen] = useState(false);

  const instruments = useMemo(() => {
    return instrumentsStore.instruments
  }, [instrumentsStore.instruments.length]);

  useEffect(() => {
    store.list();
  }, [])

  const deleteHandler = (id) => {
    store.delete(id)
  };

  const copyHandler = async backtest => {
    setIsDialogCopyOpen(true);
    store.copyBacktest = backtest;
    const data = await moduleStore.getModuleListForRuntime({
      modules_ids: _.map(backtest.runtimeItems, 'clientModuleId'),
      key: 'list_with_selected',
    });
    store.selectedModuleList = _.filter(data, ['selected', true]);
  };

  const createHandler = (backtest) => {
    try {
      return store.create(backtest)
    } catch {
    } finally {
      setIsDialogCopyOpen(false);
    }
  }

  const runBackTestModal = () => {
    setIsRunBacktestOpen(true);
  }

  return (
    <div className="form">
      <div className="div form__card">
        <div className="back-test-modules">
          <h2 className="title2 bold">
            <FormattedMessage id="backtest.header" />
          </h2>
          {(isDialogCopyOpen && /* store.isLoading */ moduleStore.isLoading)
            ? <Loader />
            : (
              <>
                <BackTestList
                  rows={store.backtestList}
                  isLoading={/* store.isLoading */ moduleStore.isLoading}
                  handlers={{
                    delete: deleteHandler,
                    copy: copyHandler,
                  }}
                />
                <DialogBackTestCopy
                  open={isDialogCopyOpen}
                  title={store?.copyBacktest?.name}
                  rows={moduleStore.currentModuleList.map(toRow(instruments)) /* store.backtestModuleList.map(toRow) */}
                  selectedModuleList={store.selectedModuleList}
                  isDisabledButton={!store.selectedModuleList.length || /* store.isLoading */ moduleStore.isLoading}
                  handlers={{
                    onClose: () => setIsDialogCopyOpen(false),
                    onSelectModule: store.setSelectedModuleList,
                    clearSelectedModuleList: store.clearSelectedModuleList,
                    runBackTestModal
                  }}
                />
              </>
            )
          }

          <BackTestModal
            open={isRunBacktestOpen}
            selectedModuleList={store.selectedModuleList}
            copyBacktest={store.copyBacktest}
            handlers={{
              handleClose: () => setIsRunBacktestOpen(false),
              clearSelectedModuleList: store.clearSelectedModuleList,
              create: createHandler
            }}
          />
        </div>
      </div>
    </div>
  );
};

BackTestModules.propTypes = {
  store: propTypes.instanceOf(BacktestStore),
  moduleStore: propTypes.instanceOf(ModuleStore),
  tradeHistoryStore: propTypes.instanceOf(TradeHistoryStore),
};

const decorator = compose(
  observer,
  inject(stores => ({
    store: stores.backtestStore,
    moduleStore: stores.moduleStore,
    tradeHistoryStore: stores.tradeHistoryStore,
  })),
);

export default decorator(BackTestModules);
