import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import ConditionList from './ConditionList';

const StochConditionList = ({ prefix, fieldName, type }) => {
  return (
    <Accordion>
      <div className="main-btn">
        <AccordionSummary className="title3 bold">
          <FormattedMessage id={`modules.indicator.stoch.${type}`} />
        </AccordionSummary>
      </div>
      <AccordionDetails>
        <div className="form__card-content">
          <ConditionList prefix={prefix} fieldName={`${fieldName}.${type}`} />
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

StochConditionList.propTypes = {
  prefix: PropTypes.element,
  fieldName: PropTypes.string,
  type: PropTypes.string
};

export default StochConditionList;
