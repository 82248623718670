import React, { useCallback } from 'react';
import propTypes from 'prop-types';
import { useStore } from 'hooks';
import { Slider as MUISlider } from '@mui/material';
import { Interval } from 'luxon';

import { DATE_HISTORY_FORMAT } from 'globalConstants';
import { useSlider } from '../hooks';
import { useChartSync } from 'contexts/chart';

export default function Slider ({ chart }) {
  const slider = useSlider(chart);
  const { observers } = useChartSync()
  const { chartStore: { candle } } = useStore();

  const getSliderDate = useCallback((percentage) => {
    const { startPeriod, endPeriod } = candle.dateRange;
    if (!startPeriod || !endPeriod) return;

    const daysBetween = Interval.fromDateTimes(startPeriod, endPeriod).length('days');
    // Calculate the number of days for each percentage point
    const daysPerPercentage = daysBetween / 100;
    // Calculate the date for the given percentage
    const daysToAdd = daysPerPercentage * percentage;

    return startPeriod.plus({ days: daysToAdd });
  }, [candle.dateRange]);

  const formatSliderLabelValue = useCallback((percentage) => {
    const date = getSliderDate(percentage);
    if (!date) return;
    return date.toFormat(DATE_HISTORY_FORMAT)
  }, [getSliderDate]);

  const onChangeSlider = useCallback((e, percentage) => {
    if (Array.isArray(percentage)) percentage = percentage[0];
    const date = getSliderDate(percentage);

    if (percentage === 0 || percentage === 100 || !date) {
      slider.onChange(percentage);
    } else {
      observers.selectedDate.publish(date.toSeconds());
    }
  }, [getSliderDate]);

  return (
    <MUISlider
      min={0}
      step={1}
      max={100}
      defaultValue={100}
      color="secondary"
      className="time-slider"
      disabled={slider.disabled}
      onChangeCommitted={onChangeSlider}
      valueLabelFormat={formatSliderLabelValue}
      valueLabelDisplay={candle.dateRange.startPeriod ? 'auto' : 'off'}
    />
  )
}

Slider.propTypes = {
  chart: propTypes.any,
}
