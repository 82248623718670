import * as api from 'api/utils';
import { FUND_URL, ACTIVE_FUND_URL } from './consts';

export default class FundManagementService {
  constructor (type) {
    this.url = {
      active: ACTIVE_FUND_URL,
      demo: FUND_URL
    }[type];
  }

  get () {
    return api.get('tradeManagement', this.url, true);
  }

  patch (params) {
    return api.patch('tradeManagement', this.url, true, params);
  }
}
