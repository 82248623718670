// import _ from 'lodash';
// import moment from 'moment';
import { DateTime } from 'luxon';

// const minDate = moment().startOf('day');
// const maxDate = _.cloneDeep(minDate).add(1, 'minutes');
export const initialValues = {
  title: 'Exclusion time',
  instruments: ['gbp_usd'],
  timeCycle: 'all',
  startTime: DateTime.now().setZone('utc').startOf('day').toFormat('HH:mm'),
  finishTime: DateTime.now().setZone('utc').startOf('day').plus({ minutes: 1 }).toFormat('HH:mm'),
  timeframeStart: '',
  timeframeEnd: '',
  frequencyPeriod: 'daily',
  frequencyDay: '',
  frequencySequence: '',
  action: 'stop_opening_orders',
};
export const timeCycles = [
  {
    value: 'All',
    id: 'all'
  },
  {
    value: 'Minute',
    id: 'minute'
  },
  {
    value: 'Hourly',
    id: 'hourly'
  },
  {
    value: 'Daily',
    id: 'daily'
  },
];

export const frequency = [
  {
    value: 'Daily',
    id: 'daily'
  },
  {
    value: 'Weekly',
    id: 'weekly'
  },
  {
    value: 'Monthly',
    id: 'monthly'
  },
];

export const days = [
  {
    value: 'Monday',
    id: 'monday'
  },
  {
    value: 'Tuesday',
    id: 'tuesday'
  },
  {
    value: 'Wednesday',
    id: 'wednesday'
  },
  {
    value: 'Thursday',
    id: 'thursday'
  },
  {
    value: 'Friday',
    id: 'friday'
  },
  {
    value: 'Saturday',
    id: 'saturday'
  },
  {
    value: 'Sunday',
    id: 'sunday'
  },
];

export const sequences = [
  {
    value: 'First',
    id: 'first'
  },
  {
    value: 'Second',
    id: 'second'
  },
  {
    value: 'Third',
    id: 'third'
  },
  {
    value: 'Fourth',
    id: 'fourth'
  },
  {
    value: 'Last',
    id: 'last'
  },
];

export const actions = [
  {
    id: 'stop_opening_orders'
  },
  {
    id: 'close_open_orders'
  }
];
