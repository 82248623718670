import React, { useCallback, useState, useEffect } from 'react';
import _ from 'lodash';
import propTypes from 'prop-types';
import { DateTime } from 'luxon';
import Controls from './Controls';
import { inject, observer } from 'mobx-react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { compose } from 'helpers';
import Loader from 'components/Loader';
import ChartSync from './ChartSync';
import ModuleStore from 'store/moduleStore';
import BacktestStore from 'store/backtestStore';
import ChartSyncProvider from 'contexts/chart';
import { preformatModule } from './utils';
import './styles.scss';

const RunModule = ({ moduleStore, backtestStore }) => {
  const history = useHistory();
  const { id, itemId } = useParams();
  const location = useLocation();
  const [module, setModule] = useState();
  const [controls, _setControls] = useState({});

  const setControls = useCallback(cb => {
    _setControls(old => {
      const _new = _.isFunction(cb) ? cb(old) : { ...old, ...cb };
      // tie candle pattern timeframe with RSI in candle close case
      if (module?.clientModuleParams?.patternKind === 'candle_close') {
        _new.candlePeriod = _new.rsiPeriod;
      }
      return _new;
    });
  }, [module?.clientModuleParams?.patternKind]);

  useEffect(() => {
    const retrieveModule = async () => {
      try {
        let retrieved;
        if (id && itemId) {
          // retrieved = await moduleStore.getModule(id, true);
          retrieved = await backtestStore.getItem(id, itemId);
        } else {
          retrieved = await backtestStore.getDemoModule(id);
        }
        const module = preformatModule(retrieved);
        setModule(module);
      } catch {
        // history.push('/not_fount');
      }
    }
    retrieveModule();
  }, [id, itemId, history, moduleStore]);

  useEffect(() => {
    if (module) {
      const rsiPeriod = module.clientModuleParams.rsiTimeframes.find(({ type }) => type.includes('rsi'))?.value;
      const candlePeriod = module.clientModuleParams.candlePatternTimeframe.find(({ enabled }) => enabled)?.value;
      setControls({
        dPeriod: module.clientModuleParams.dPeriod,
        kPeriod: module.clientModuleParams.kPeriod,
        slowingPeriod: module.clientModuleParams.slowingPeriod,
        rsiPeriodValue: module.clientModuleParams.rsiPeriodValue,
        rsiPeriod: location.state?.candleTimeframe ?? rsiPeriod,
        stochPeriod: module.clientModuleParams.stochTimeframes[0].value,
        instrument: module.clientModuleParams.instrument,
        candlePeriod: location?.state?.candleTimeframe ?? candlePeriod,
        timestamp: DateTime.fromISO(location.state?.actualOpenDatetime).toSeconds(),
      })
    }
  }, [module])

  if (!controls.rsiPeriod || !controls.stochPeriod) {
    return <Loader />;
  }
  return (
    <div className="run-module">
      <h2 className="title2 bold">{module?.clientModuleParams.name}</h2>
      <ChartSyncProvider module={module} controls={controls}>
        <Controls
          module={module}
          controls={controls}
          setControls={setControls}
        />
        <ChartSync
          module={module}
          controls={controls}
          setControls={setControls}
        />
      </ChartSyncProvider>
    </div>
  );
};

RunModule.propTypes = {
  moduleStore: propTypes.instanceOf(ModuleStore),
  backtestStore: propTypes.instanceOf(BacktestStore),
};

const decorator = compose(
  observer,
  inject(stores => ({
    moduleStore: stores.moduleStore,
    backtestStore: stores.backtestStore,
  })),
);

export default decorator(RunModule);
