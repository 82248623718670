import React, { useCallback, useState } from 'react';
import _ from 'lodash';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form, useField, useFormState } from 'react-final-form';

import { useConfirmation, useNotify } from 'contexts/popup';
import { useNotification } from 'contexts/notification';
import { useSwitcher } from 'contexts/pageSwitcher';
import SimpleFormControls from 'components/SimpleFormControls';
import CandleCloseRule from './CandleCloseRule';
import RuleEditorForm from './RuleEditorForm';

import './styles.scss';
import { inject, observer } from 'mobx-react';

// Layout for editing candle close patterns
const CandleClose = observer(({ logicEditorStore: store }) => {
  const intl = useIntl();
  const { error } = useNotify();
  const switcher = useSwitcher();
  const { confirm } = useConfirmation();
  const notification = useNotification();
  const [rule, setRule] = useState(null);
  const { isLogicEditor, type, fieldName } = switcher.context;
  const field = useField(`${fieldName}.candle_close`);
  const wholeState = useFormState();

  const handleCancel = useCallback(dirty => {
    dirty ? confirm({
      error: true,
      title: intl.formatMessage({ id: 'dialog.title.confirmCandlePattern' }),
      description: intl.formatMessage({ id: 'dialog.description.confirmCandlePattern' })
    }).then(result => result === 'yes' && switcher.moveTo(0)) : switcher.moveTo(0);
  }, []);

  const validate = useCallback(values => {
    if (!field.input.value?.candle_pattern?.enabled &&
        values?.value?.every?.(rule => !rule.enabled)
    ) {
      return 'error';
    }
  }, [field]);

  const handleSubmit = useCallback(value => {
    field.input.onChange(value)
    notification.show(
      intl.formatMessage({ id: 'notification.title.candleCloseUpdated' }),
      intl.formatMessage({ id: 'notification.text.candleCloseUpdated' })
    );
    switcher.moveTo(0);
  }, [field, switcher]);

  const logicEditorHandleSubmit = useCallback(value => {
    store.updatePatternDefaults(_.set(
      { ...wholeState.values },
      fieldName, {
        ..._.get(wholeState.values, fieldName),
        candle_close: value
      }
    ))
    notification.show(
      intl.formatMessage({ id: 'notification.title.defaultCandlePatternsUpdated' }),
      intl.formatMessage({ id: 'notification.text.defaultCandlePatternsUpdated' })
    )
    switcher.moveTo(0);
  }, []);

  return (
    <Form
      initialValues={field.input.value}
      onSubmit={isLogicEditor ? logicEditorHandleSubmit : handleSubmit}
      validate={validate}
    >
      {({ handleSubmit, values, invalid }) => (
        <>
          <div className="rs-indicator-from create-module-form form">
            <div className="form__card">
              <div className="form__card-content">
                <h2 className="title2 bold">
                  <FormattedMessage id="candle_close.title" values={{ isLogicEditor }}/>
                </h2>
                <div className="candle-close">
                  {values.value.map((rule, index) => (
                    <CandleCloseRule
                      key={index}
                      index={index}
                      type={type}
                      rule={rule}
                      clickEdit={rule => () => { setRule({ ...rule, index }) }}
                    />
                  ))}
                </div>
                <SimpleFormControls
                  handleCancel={() => { handleCancel(!_.isEqual(field.input.value, values)) }}
                  handleSave={() => {
                    invalid ? error({
                      title: intl.formatMessage({ id: 'dialog.title.incorrectAction' }),
                      description: intl.formatMessage({ id: 'dialog.description.allPatternsDisabled' })
                    }) : handleSubmit()
                  }}
                />
              </div>
            </div>
          </div>
          <RuleEditorForm
            rule={rule}
            type={type}
            handleClose={() => { setRule(null) }}
          />
        </>
      )}
    </Form>
  );
});

export default inject('logicEditorStore')(CandleClose);
