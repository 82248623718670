import React from 'react';
import PropTypes from 'prop-types';

import { NotificationContext } from 'contexts/notification';

import Snackbar from '@material-ui/core/Snackbar';
import { CloseIcon } from 'components/Svgs';

import { DEFAULT_ANCHOR, HIDE_DURATION } from './consts';
import './styles.scss';

const Notification = ({
  hideDuration = HIDE_DURATION,
  anchorOrigin = DEFAULT_ANCHOR
}) => {
  const notification = React.useContext(NotificationContext);

  const handleClose = () => {
    notification.close();
  };

  return (
    <Snackbar
      anchorOrigin={anchorOrigin}
      open={notification.isOpen}
      autoHideDuration={hideDuration}
      onClose={handleClose}
    >
      <div className={'notification'}>
        <p className="notification__text bold">{notification.title} </p>
        <p className="notification__text">{notification.text} </p>

        <button
          onClick={handleClose}
          className="single-icon-btn single-icon-btn--white cross"
        >
          <CloseIcon />
        </button>
      </div>
    </Snackbar>
  );
};

Notification.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  isOpen: PropTypes.bool,
  hideDuration: PropTypes.number,
  anchorOrigin: PropTypes.object
};

export default Notification;
