import _ from 'lodash';
import createCalculateDecorator from 'final-form-calculate'
import { CONDITIONS_DEFAULTS } from 'globalConstants';

export const calculateDecorator = (form) => {
  let savedConditions;
  return createCalculateDecorator({
    // changes corresponding conditions on timeframes changes
    field: /(rsiTimeframes|stochTimeframes.(prearming|trigger))/,
    updates: (tfs, key, all, prevValue) => {
      const accessor = key.replace('Timeframes', 'Conditions'); // rsi or stoch
      const filteredTfs = tfs.filter(tf => tf.enabled);

      if (!savedConditions && filteredTfs.length !== 1) {
        savedConditions = _.get(prevValue, accessor);
      }

      if (savedConditions && filteredTfs.length <= 1) {
        const updatedConditions = _.mapKeys(savedConditions, () => `tf${filteredTfs[0]?.value}`);
        savedConditions = null;

        if (updatedConditions) {
          return { [accessor]: updatedConditions };
        }
      }

      savedConditions = null;

      // return {
      //   [accessor]: Object.fromEntries(
      //     tfs.filter(tf => tf.enabled).map(({ value }, index) => [
      //       `tf${value}`,
      //       _.values(_.get(all, accessor))[index] ?? _.get(CONDITIONS_DEFAULTS, accessor.replace('Conditions', ''))
      //     ]))
      // }

      return {
        [accessor]: Object.fromEntries(
          tfs.filter(tf => tf.enabled).map(({ value }) => [
            `tf${value}`,
            _.get(all, accessor)[`tf${value}`] ?? _.get(CONDITIONS_DEFAULTS, accessor.replace('Conditions', ''))
          ]))
      };
    }
  }, {
    // tie triple close & triple tolerance switches
    field: /entrySystemConditions\[\d\]\.enabled/,
    updates: (value, key, all) => {
      const type = _.get(all, key.replace('enabled', 'kind')).match(/^(long|short)_(.*)$/)[2];
      return _.fromPairs(all.entrySystemConditions.map((cond, index) => [
        `entrySystemConditions[${index}].enabled`,
        cond.kind.includes(type) ? value : !value
      ]))
    }
  }, {
    // tie candle close & candle pattern switches
    field: /entrySystemConditions\[\d\]\.value\.candle_(close|pattern)\.enabled/,
    updates: (value, key) => {
      const reversed = type => ({ close: 'pattern', pattern: 'close' }[type]);
      return { [key.replace(/close|pattern/, reversed)]: !value };
    }
  }, {
    // when above & below lines change => copy it to short
    // when candlestick enabled changes => copy in to short
    field: /entrySystemConditions\[\d\]$/,
    updates: (value, key, all) => {
      if (value.kind) {
        const reversed = type => ({ long: 'short', short: 'long' }[type]);
        const oppositeIndex = all.entrySystemConditions.findIndex(
          cond => cond.kind === value.kind.replace(/long|short/, reversed)
        );
        return _.fromPairs(['above_line', 'below_line'].map(k => [
          `entrySystemConditions[${oppositeIndex}].value.${k}.value`,
          value.value[k].value
        ]).concat(['candle_pattern', 'candle_close'].map(k => [
          `entrySystemConditions[${oppositeIndex}].value.${k}.enabled`,
          value.value[k].enabled
        ])));
      }
      return {};
    }
  }, {
    field: /tradeManagement.to_entry_on_(targets|points)/,
    updates: (value, key) => {
      const switched = type => ({
        targets: 'points.enabled',
        points: 'targets.enabled',
      }[type])

      return { [key.replace(/targets|points|static|calculate/, switched)]: !value.enabled }
    }
  }, {
    field: /tradeManagement.trade_size/,
    updates: (value, key, all, prevState) => {
      const prev = _.get(prevState, key);
      const tracked = ['enabled', 'autocalculate'];
      const source = tracked.find(e => value[e] !== prev[e]);
      const target = tracked.find(e => value[e] === prev[e]);
      return { [`${key}.${target}`]: !value[source] }
    }
  })(form);
}
