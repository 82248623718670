import _ from 'lodash';

export const updateProps = ([name, index, updates], state, { changeValue }) => {
  Object.entries(updates).forEach(([key, value]) =>
    changeValue(state, `${name}[${index}].${key}`, () => value)
  )
};

const getPath = (path, key, isArray) => (
  path + (isArray ? `[${key}]` : (path ? `.${key}` : key))
);

const traversal = (obj, callback, key, path = '') => {
  callback(obj, key, path);
  if (_.isObject(obj)) {
    _.forEach(obj, (sub, key) => {
      traversal(sub, callback, key, getPath(path, key, _.isArray(sub)))
    });
  }
}

export const copy = ([source, target], state, { changeValue, getIn }) => {
  changeValue(state, target, () => getIn(state.formState.values, source));
};

export const idAgnosticCopy = ([source, target], state, { changeValue, getIn }) => {
  const sourceState = _.cloneDeep(getIn(state.formState.values, source));
  const targetState = getIn(state.formState.values, target);
  traversal(targetState, (value, key, path) => {
    if (key !== 'id') return;
    _.set(sourceState, path, value);
  })
  changeValue(state, target, () => sourceState);
};
