import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Input } from 'components/Fields';
import DialogForm from 'components/DialogForm';
import { Form, Field } from 'react-final-form';
import { isEmpty } from 'lodash';
import '../styles.scss';
import { trimString } from 'helpers';
import * as validators from 'helpers/validators';

export default class TimeoutConditionForm extends React.PureComponent {
  handleSubmit = (values) => {
    this.props.handleSave(this.props.obj, values);
  };

  content = () => {
    return (
      <form className="condition-form-content">
        <div className="form">
          {['blocks', 'timestamps'].map((name, index) => (
            <div key={index} className="condition-form-content__field">
              <Field
                withHelper
                name={name}
                component={Input}
                autoFocus={index === 0}
                validate={value => (
                  validators.validateTimeoutField(value) ||
                  validators.validateWithRange({ min_value: 1, max_value: { blocks: 720, timestamps: 730 }[name] })(value) ||
                  validators.validateWithoutSpace(value) ||
                  validators.validateIntegerField(value) ||
                  trimString(value)
                )}
              />
              <div className="condition-form-content__symbol bold">
                <FormattedMessage id={`dialog.symbol.${name}`} />
              </div>
            </div>
          ))}
        </div>
      </form>
    );
  };

  title = () => {
    const { obj, prefix } = this.props;
    return (
      <div className="condition-form-header">
        <div className="title2 bold">
          <FormattedMessage id={`${prefix}.conditions.${obj.kind}`} />
        </div>
      </div>
    );
  };

  controls = (isDisabled, values) => {
    return (
      <div className="custom-dialog__actions">
        <button
          onClick={this.props.handleClose}
          className="primary-btn outline-btn form-action-btn bold"
        >
          <FormattedMessage id="modules.buttons.cancel" />
        </button>
        <button
          onClick={() => this.handleSubmit(values)}
          disabled={isDisabled}
          className="primary-btn main-btn form-action-btn bold"
        >
          <FormattedMessage id="modules.buttons.save" />
        </button>
      </div>
    );
  };

  render() {
    const { open, obj, handleClose } = this.props;
    const initialValues =
      typeof obj.value === 'object' ? obj.value : { value: obj.value };

    if (isEmpty(obj)) {
      return null;
    }

    return (
      <Form
        onSubmit={this.handleSubmit}
        initialValues={initialValues}
        render={({ values, invalid }) => {
          return (
            <DialogForm
              open={open}
              title={this.title()}
              content={this.content(initialValues)}
              controls={this.controls(invalid, values)}
              handleClose={handleClose}
              customClass="condition-form-popup"
            />
          );
        }}
      />
    );
  }
}

TimeoutConditionForm.propTypes = {
  open: PropTypes.bool,
  obj: PropTypes.object,
  prefix: PropTypes.string,
  handleClose: PropTypes.func,
  handleSave: PropTypes.func
};
