export default class LastPriceSocketIOService {
  constructor (config) {
    const { uri, options, eventName, provider, type } = config;
    this.type = type;
    this.eventName = eventName;
    this.uri = uri
    this.options = options
    this.provider = provider
    this.subscribe = this.subscribe.bind(this);
    this._socket = null;
  }

  subscribe (insturment, callback) {
    this.socket.emit('join', {
      type: this.type,
      symbol_id: insturment
    });
    this.socket.on(this.eventName, callback);
  }

  unsubscribe (instrument) {
    this.socket.emit('leave', {
      type: this.type,
      symbol_id: instrument
    });
  }

  get socket () {
    return (this._socket = this._socket || this.provider(this.uri, this.options));
  }
}
