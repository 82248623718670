import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  KDSlowPeriod, PeriodRSICalculation, EntrySystem,
  CommonTimeFrame, BaseIndicator
} from 'containers/Modules/General';

const StochPrearmingTimeframe = () => <CommonTimeFrame timeframesField="stochTimeframes.prearming" />;
const StochTriggerTimeframe = () => <CommonTimeFrame timeframesField="stochTimeframes.trigger" />;
const RSITimeframe = () => <CommonTimeFrame timeframesField="rsiTimeframes" />;
const StochIndicator = () => <BaseIndicator fieldName="stochConditions" prefix="stoch" />;
const RSIIndicator = () => <BaseIndicator fieldName="rsiConditions" prefix="rsi" />;

export const pages = [
  {
    className: 'time-frame-form',
    page: <StochPrearmingTimeframe />,
    title: <FormattedMessage id="modules.stochTimeframe.prearming" />
  },
  {
    className: 'time-frame-form',
    page: <StochTriggerTimeframe />,
    title: <FormattedMessage id="modules.stochTimeframe.trigger" />
  },
  {
    className: 'kds-slow-period-form',
    page: <KDSlowPeriod />,
    title: <FormattedMessage id="modules.kdperiods" />
  },
  {
    className: 'rs-indicator-form',
    page: <StochIndicator />,
    title: <FormattedMessage id="modules.stoch" />
  },
  {
    className: 'time-frame-form',
    page: <RSITimeframe />,
    title: <FormattedMessage id="modules.rsiTimeframe" />
  },
  {
    className: 'rsi-period-value-form',
    page: <PeriodRSICalculation />,
    title: <FormattedMessage id="modules.rsiPeriodValue" />
  },
  {
    className: 'rs-indicator-form',
    page: <RSIIndicator />,
    title: <FormattedMessage id="modules.rsi" />
  },
  {
    className: 'rs-indicator-form',
    page: <EntrySystem />,
    title: <FormattedMessage id="modules.entrySystem" />
  }
];
