import { makeAutoObservable } from 'mobx';

export default class LastItemStore {
  prices = new Map();

  constructor (socketIOService, provider) {
    makeAutoObservable(this);
    this.provider = provider;
    this.socketIOService = socketIOService;
    this.instruments = new Set();
    this.updatePrice = this.updatePrice.bind(this);
  }

  * firstLoad () {
    const { data } = yield this.provider();
    this.prices.merge(data);
  }

  subscribe (instrument) {
    if (this.instruments.has(instrument)) return;
    this.instruments.add(instrument);
    this.socketIOService.subscribe(instrument, this.updatePrice);
  }

  unsubscribe () {
    this.instruments.forEach(instrument => {
      this.socketIOService.unsubscribe(instrument);
    });
    this.instruments.clear();
  }

  updatePrice ({ symbol_id: insturment, price }) {
    this.prices.set(insturment, price);
  }
}
