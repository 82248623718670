import axios from 'axios';
import { convertDataToFormData, makeQueryParams } from './urlHelpers';
import { URLS } from './consts';

export const axiosInstance = (client, needAuth = false, isBlob = false, useNotification = true) => {
  const { baseURL } = URLS[client];
  const authToken = `JWT ${localStorage.getItem('authToken')}`;

  const authorization = needAuth ? authToken : null;
  const params = {
    baseURL: `${baseURL}`,
    // timeout: 30000,
    responseType: isBlob ? 'blob' : undefined,
    headers: {
      Authorization: authorization
    }
  };

  const instance = axios.create(params);
  instance.interceptors.response.use(undefined, err => {
    if (err.response?.status === 401) {
      localStorage.removeItem('authToken');
      window.location = '/login';
    } else {
      if (useNotification && !axios.isCancel(err)) {
        const notification = document.notificationContext;
        const title = 'There is trouble with server';
        const content = `${err.name}: ${err.message}`;
        notification.show(title, content);
      }
      console.error(err);
    }
    return Promise.reject(err);
  })
  return instance;
};

export const get = (client, path, needAuth = false, params = null, isBlob = false, cancelToken = null) => {
  const instance = axiosInstance(client, needAuth, isBlob);

  if (params) {
    params = makeQueryParams(params);
    path = `${path}?${params}`;
  }

  return new Promise((resolve, reject) => {
    instance
      .get(path, { cancelToken })
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        if (axios.isCancel(error)) {
          console.log(error.message);
        } else {
          reject(error.response);
        }
      });
  });
};

export const getBlob = (kind, path, needAuth = false, params = null, cancelToken = null) => {
  const instance = axiosInstance(kind, needAuth, true);

  if (params) {
    params = makeQueryParams(params);
    path = `${path}?${params}`;
  }

  return new Promise((resolve, reject) => {
    instance
      .get(path, { cancelToken })
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        if (axios.isCancel(error)) {
          console.log(error.message);
        } else {
          reject(error.response);
        }
      });
  });
};

export const post = (client, path, needAuth = false, data, isBlob = false, cancelToken = null, useNotification = true) => {
  const instance = axiosInstance(client, needAuth, isBlob, useNotification);
  return new Promise((resolve, reject) => {
    instance
      .post(path, data, { cancelToken })
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        if (axios.isCancel(error)) {
          console.log(error.message);
        } else {
          reject(error.response);
        }
      });
  });
};

export const postFormData = (kind, path, needAuth = false, data, cancelToken = null) => {
  const instance = axiosInstance(kind, needAuth, false);
  const formData = convertDataToFormData(data);

  return new Promise((resolve, reject) => {
    instance
      .post(path, formData, { cancelToken })
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        if (axios.isCancel(error)) {
          console.log(error.message);
        } else {
          reject(error.response);
        }
      });
  });
};

export const put = (client, path, needAuth = false, data = null, cancelToken = null) => {
  const instance = axiosInstance(client, needAuth);

  return new Promise((resolve, reject) => {
    instance
      .put(path, data, { cancelToken })
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        if (axios.isCancel(error)) {
          console.log(error.message);
        } else {
          reject(error.response);
        }
      });
  });
};

export const patch = (client, path, needAuth = false, data = null, cancelToken = null) => {
  const instance = axiosInstance(client, needAuth);

  return new Promise((resolve, reject) => {
    instance
      .patch(path, data, { cancelToken })
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        if (axios.isCancel(error)) {
          console.log(error.message);
        } else {
          reject(error.response);
        }
      });
  });
};

export const putFormData = (kind, path, needAuth = false, data, cancelToken = null) => {
  const instance = axiosInstance(kind, needAuth);
  const formData = convertDataToFormData(data);

  return new Promise((resolve, reject) => {
    instance
      .put(path, formData, { cancelToken })
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        if (axios.isCancel(error)) {
          console.log(error.message);
        } else {
          reject(error.response);
        }
      });
  });
};

export const patchFormData = (kind, path, needAuth = false, data, cancelToken = null) => {
  const instance = axiosInstance(kind, needAuth);
  const formData = convertDataToFormData(data);

  return new Promise((resolve, reject) => {
    instance
      .patch(path, formData, { cancelToken })
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        if (axios.isCancel(error)) {
          console.log(error.message);
        } else {
          reject(error.response);
        }
      });
  });
};

export const del = (client, path, needAuth = false, data = null, useNotification = true, cancelToken = null) => {
  const instance = axiosInstance(client, needAuth, false, useNotification);
  return new Promise((resolve, reject) => {
    instance
      .request({ method: 'delete', url: path, data, cancelToken })
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        if (axios.isCancel(error)) {
          console.log(error.message);
        } else {
          reject(error.response);
        }
      });
  });
};

export const getCancelTokenSource = () => axios.CancelToken.source();
