import { metaKey } from './meta';

export const CANDLE_CLOSE_LONG = [
  {
    enabled: true,
    kind: 'red_bodied',
    description: 'r',
    value: [
      {
        kind: 'high_length',
        symbol: 'Pts',
        description: '{min_value}-{max_value} {symbol}',
        enabled: true,
        value: {
          min_value: 0,
          max_value: 5
        },
        available_range: {
          min_value: 0,
          max_value: 50
        }
      },
      {
        kind: 'candle_length',
        symbol: 'Pts',
        description: '{min_value}-{max_value} {symbol}',
        enabled: true,
        value: {
          min_value: 1,
          max_value: 30
        },
        available_range: {
          min_value: 0,
          max_value: 50
        },
        [metaKey]: {
          swichable: false
        }
      },
      {
        kind: 'low_length',
        symbol: 'Pts',
        description: '{min_value}-{max_value} {symbol}',
        enabled: false,
        value: {
          min_value: 0,
          max_value: 20
        },
        available_range: {
          min_value: 0,
          max_value: 50
        }
      },
      {
        kind: 'dependency',
        enabled: true,
        value: '<>=',
        description: 'High Length {value} Low Length'
      }
    ]
  },
  {
    enabled: true,
    kind: 'red_hammer',
    description: 'r',
    value: [
      {
        kind: 'high_length',
        symbol: 'Pts',
        description: '{min_value}-{max_value} {symbol}',
        enabled: true,
        value: {
          min_value: 0,
          max_value: 2
        },
        available_range: {
          min_value: 0,
          max_value: 50
        }
      },
      {
        kind: 'candle_length',
        symbol: 'Pts',
        description: '{min_value}-{max_value} {symbol}',
        enabled: true,
        value: {
          min_value: 0,
          max_value: 2
        },
        available_range: {
          min_value: 0,
          max_value: 50
        },
        [metaKey]: {
          swichable: false
        }
      },
      {
        kind: 'low_length',
        symbol: 'Pts',
        description: '{min_value}-{max_value} {symbol}',
        enabled: true,
        value: {
          min_value: 3,
          max_value: 20
        },
        available_range: {
          min_value: 0,
          max_value: 50
        }
      },
      {
        kind: 'dependency',
        enabled: true,
        value: '<',
        description: 'High Length {value} Low Length'
      }
    ]
  },
  {
    enabled: true,
    kind: 'red_doji',
    description: 'r',
    value: [
      {
        kind: 'high_length',
        symbol: 'Pts',
        description: '{min_value}-{max_value} {symbol}',
        enabled: true,
        value: {
          min_value: 0,
          max_value: 2
        },
        available_range: {
          min_value: 0,
          max_value: 50
        }
      },
      {
        kind: 'candle_length',
        symbol: 'Pts',
        description: '{min_value}-{max_value} {symbol}',
        enabled: true,
        value: {
          min_value: 0,
          max_value: 1
        },
        available_range: {
          min_value: 0,
          max_value: 50
        },
        [metaKey]: {
          swichable: false
        }
      },
      {
        kind: 'low_length',
        symbol: 'Pts',
        description: '{min_value}-{max_value} {symbol}',
        enabled: true,
        value: {
          min_value: 0,
          max_value: 2
        },
        available_range: {
          min_value: 0,
          max_value: 50
        }
      },
      {
        kind: 'dependency',
        enabled: true,
        value: '<>=',
        description: 'High Length {value} Low Length'
      }
    ]
  }
];

export const CANDLE_CLOSE_SHORT = [
  {
    enabled: true,
    kind: 'green_bodied',
    description: 'r',
    value: [
      {
        kind: 'high_length',
        symbol: 'Pts',
        description: '{min_value}-{max_value} {symbol}',
        enabled: true,
        value: {
          min_value: 0,
          max_value: 20
        },
        available_range: {
          min_value: 0,
          max_value: 50
        }
      },
      {
        kind: 'candle_length',
        symbol: 'Pts',
        description: '{min_value}-{max_value} {symbol}',
        enabled: true,
        value: {
          min_value: 1,
          max_value: 30
        },
        available_range: {
          min_value: 0,
          max_value: 50
        },
        [metaKey]: {
          swichable: false
        }
      },
      {
        kind: 'low_length',
        symbol: 'Pts',
        description: '{min_value}-{max_value} {symbol}',
        enabled: false,
        value: {
          min_value: 0,
          max_value: 5
        },
        available_range: {
          min_value: 0,
          max_value: 50
        }
      },
      {
        kind: 'dependency',
        enabled: true,
        value: '<>=',
        description: 'High Length {value} Low Length'
      }
    ]
  },
  {
    enabled: true,
    kind: 'green_hangman',
    description: 'r',
    value: [
      {
        kind: 'high_length',
        symbol: 'Pts',
        description: '{min_value}-{max_value} {symbol}',
        enabled: true,
        value: {
          min_value: 3,
          max_value: 20
        },
        available_range: {
          min_value: 0,
          max_value: 50
        }
      },
      {
        kind: 'candle_length',
        symbol: 'Pts',
        description: '{min_value}-{max_value} {symbol}',
        enabled: true,
        value: {
          min_value: 0,
          max_value: 2
        },
        available_range: {
          min_value: 0,
          max_value: 50
        },
        [metaKey]: {
          swichable: false
        }
      },
      {
        kind: 'low_length',
        symbol: 'Pts',
        description: '{min_value}-{max_value} {symbol}',
        enabled: true,
        value: {
          min_value: 0,
          max_value: 2
        },
        available_range: {
          min_value: 0,
          max_value: 50
        }
      },
      {
        kind: 'dependency',
        enabled: true,
        value: '>',
        description: 'High Length {value} Low Length'
      }
    ]
  },
  {
    enabled: true,
    kind: 'green_doji',
    description: 'r',
    value: [
      {
        kind: 'high_length',
        symbol: 'Pts',
        description: '{min_value}-{max_value} {symbol}',
        enabled: true,
        value: {
          min_value: 0,
          max_value: 2
        },
        available_range: {
          min_value: 0,
          max_value: 50
        }
      },
      {
        kind: 'candle_length',
        symbol: 'Pts',
        description: '{min_value}-{max_value} {symbol}',
        enabled: true,
        value: {
          min_value: 0,
          max_value: 1
        },
        available_range: {
          min_value: 0,
          max_value: 50
        },
        [metaKey]: {
          swichable: false
        }
      },
      {
        kind: 'low_length',
        symbol: 'Pts',
        description: '{min_value}-{max_value} {symbol}',
        enabled: true,
        value: {
          min_value: 0,
          max_value: 2
        },
        available_range: {
          min_value: 0,
          max_value: 50
        }
      },
      {
        kind: 'dependency',
        enabled: true,
        value: '<>=',
        description: 'High Length {value} Low Length'
      }
    ]
  }
];
