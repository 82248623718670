import { metaKey } from './meta';

// colors and variations
export const GREEN = 'Green';
export const RED = 'Red';
export const NEUTRAL = 'Neutral';

export const CANDLE_OPEN = 'candle_open';
export const CANDLE_CLOSE = 'candle_close';
export const CANDLE_HIGH = 'candle_high';
export const CANDLE_LOW = 'candle_low';
export const CANDLE_LENGTH = 'candle_length';

export const relationTypeVariants = [
  CANDLE_OPEN,
  CANDLE_CLOSE,
  CANDLE_LOW,
  CANDLE_HIGH,
  CANDLE_LENGTH
];
export const symbolVariants = ['>', '<', '>=', '<='];
export const minCandleRuleCount = 2;
export const maxCandleRuleCount = 4;

// Candle defaults and reusable consts

const BASE_CANDLE_PATTERN = {
  enabled: true,
  description: ' '
};

export const BASE_PATTERN_RULE = {
  enabled: true,
  description: 'Candle',
  same_as: null,
  [metaKey]: {
    withoutToggle: true
  }
};

//

export const BASE_DEPENDENCY = {
  enabled: false,
  kind: 'dependency',
  description: 'High Length {value} Low Length',
  value: ''
};

const DEFAULT_ADD_POINTS = {
  trigger_range: true,
  value: 2
};

const DEFAULT_ADD_POINTS_LONG = {
  ...DEFAULT_ADD_POINTS,
  value: 2
};

const DEFAULT_ADD_POINTS_SHORT = {
  ...DEFAULT_ADD_POINTS,
  value: 2
};

// Ranges and lengths

const FROM_TO_AVAILABLE_RANGE = {
  min_value: 0,
  max_value: 50
};

const BASE_LENGTH = {
  enabled: false,
  symbol: ' Pts',
  description: '{min_value}-{max_value} {symbol}',
  available_range: FROM_TO_AVAILABLE_RANGE,
  value: FROM_TO_AVAILABLE_RANGE
};

export const LOW_LENGTH_MIXIN = {
  ...BASE_LENGTH,
  kind: 'low_length'
};

export const HIGH_LENGTH_MIXIN = {
  ...BASE_LENGTH,
  kind: 'high_length'
};

const makeRangeValue = (from, to) => ({
  value: {
    min_value: from,
    max_value: to
  }
});

export const FROM_TO_0_7_MIXIN = {
  ...makeRangeValue(0, 7)
};

const FROM_TO_0_2_MIXIN = {
  ...makeRangeValue(0, 2)
};

export const BASE_CANDLE_LENGTH = {
  ...BASE_LENGTH,
  kind: 'candle_length'
};

// Default candle

export const DEFAULT_CANDLE_PATTERN_RULE = {
  ...BASE_PATTERN_RULE,
  colors: [RED],
  value: [
    {
      ...HIGH_LENGTH_MIXIN,
      ...FROM_TO_0_7_MIXIN,
      enabled: true
    },
    {
      ...LOW_LENGTH_MIXIN,
      ...FROM_TO_0_7_MIXIN,
      enabled: true
    },
    {
      ...BASE_CANDLE_LENGTH,
      ...FROM_TO_0_7_MIXIN,
      enabled: true
    },
    {
      ...BASE_DEPENDENCY,
      value: '><=',
      enabled: true
    }
  ]
}

// Patterns declaration

export const CANDLE_PATTERNS_LONG = [
  {
    ...BASE_CANDLE_PATTERN,
    kind: 'double_d_bend_long',
    value: {
      candles: [
        {
          ...BASE_PATTERN_RULE,
          colors: [RED],
          value: [
            {
              ...HIGH_LENGTH_MIXIN,
              ...FROM_TO_0_7_MIXIN,
              enabled: true
            },
            {
              ...LOW_LENGTH_MIXIN
            },
            {
              ...BASE_CANDLE_LENGTH,
              ...makeRangeValue(3, 7),
              enabled: true
            },
            {
              ...BASE_DEPENDENCY,
              value: '<=',
              enabled: true
            }
          ]
        },
        {
          ...BASE_PATTERN_RULE,
          colors: [GREEN, RED, NEUTRAL],
          value: [
            {
              ...HIGH_LENGTH_MIXIN
            },
            {
              ...LOW_LENGTH_MIXIN
            },
            {
              ...BASE_CANDLE_LENGTH,
              ...makeRangeValue(0, 1),
              enabled: true
            },
            {
              ...BASE_DEPENDENCY
            }
          ]
        },
        {
          ...BASE_PATTERN_RULE,
          same_as: 1,
          colors: [],
          value: [
            {
              ...HIGH_LENGTH_MIXIN
            },
            {
              ...LOW_LENGTH_MIXIN
            },
            {
              ...BASE_CANDLE_LENGTH,
              ...makeRangeValue(0, 1),
              enabled: true
            },
            {
              ...BASE_DEPENDENCY
            }
          ]
        },
        {
          ...BASE_PATTERN_RULE,
          colors: [GREEN],
          value: [
            {
              ...HIGH_LENGTH_MIXIN,
              ...FROM_TO_0_7_MIXIN,
              enabled: true
            },
            {
              ...LOW_LENGTH_MIXIN
            },
            {
              ...BASE_CANDLE_LENGTH,
              ...makeRangeValue(3, 7),
              enabled: true
            },
            {
              ...BASE_DEPENDENCY,
              value: '<=',
              enabled: true
            }
          ]
        }
      ],
      settings: DEFAULT_ADD_POINTS_LONG,
      relations: [
        {
          candle_from: 4,
          candle_to: 1,
          from_type: CANDLE_CLOSE,
          to_type: CANDLE_LENGTH,
          percent: 75,
          symbol: '>'
        }
      ]
    }
  },
  {
    ...BASE_CANDLE_PATTERN,
    kind: 'cloud_cover_long',
    value: {
      candles: [
        {
          ...BASE_PATTERN_RULE,
          colors: [RED],
          value: [
            {
              ...HIGH_LENGTH_MIXIN,
              ...FROM_TO_0_7_MIXIN,
              enabled: true
            },
            {
              ...LOW_LENGTH_MIXIN
            },
            {
              ...BASE_CANDLE_LENGTH,
              ...makeRangeValue(4, 7),
              enabled: true
            },
            {
              ...BASE_DEPENDENCY,
              value: '<=',
              enabled: true
            }
          ]
        },
        {
          ...BASE_PATTERN_RULE,
          colors: [GREEN],
          value: [
            {
              ...HIGH_LENGTH_MIXIN,
              ...FROM_TO_0_7_MIXIN,
              enabled: true
            },
            {
              ...LOW_LENGTH_MIXIN
            },
            {
              ...BASE_CANDLE_LENGTH,
              ...makeRangeValue(3, 7),
              enabled: true
            },
            {
              ...BASE_DEPENDENCY
            }
          ]
        }
      ],
      settings: DEFAULT_ADD_POINTS_LONG,
      relations: [
        {
          candle_from: 2,
          from_type: CANDLE_CLOSE,
          symbol: '<',
          percent: 100,
          candle_to: 1,
          to_type: CANDLE_OPEN
        },
        {
          candle_from: 2,
          from_type: CANDLE_CLOSE,
          symbol: '>',
          percent: 50,
          candle_to: 1,
          to_type: CANDLE_LENGTH
        }
      ]
    }
  },
  {
    ...BASE_CANDLE_PATTERN,
    kind: 'dumpling_bottom_long',
    value: {
      candles: [
        {
          ...BASE_PATTERN_RULE,
          colors: [RED],
          value: [
            {
              ...HIGH_LENGTH_MIXIN,
              ...FROM_TO_0_7_MIXIN,
              enabled: true
            },
            {
              ...LOW_LENGTH_MIXIN
            },
            {
              ...BASE_CANDLE_LENGTH,
              ...makeRangeValue(3, 7),
              enabled: true
            },
            {
              ...BASE_DEPENDENCY,
              value: '<=',
              enabled: true
            }
          ]
        },
        {
          ...BASE_PATTERN_RULE,
          colors: [GREEN],
          value: [
            {
              ...HIGH_LENGTH_MIXIN
            },
            {
              ...LOW_LENGTH_MIXIN
            },
            {
              ...BASE_CANDLE_LENGTH,
              ...makeRangeValue(0, 2),
              enabled: true
            },
            {
              ...BASE_DEPENDENCY
            }
          ]
        },
        {
          ...BASE_PATTERN_RULE,
          colors: [RED],
          value: [
            {
              ...HIGH_LENGTH_MIXIN
            },
            {
              ...LOW_LENGTH_MIXIN
            },
            {
              ...BASE_CANDLE_LENGTH,
              ...makeRangeValue(0, 2),
              enabled: true
            },
            {
              ...BASE_DEPENDENCY
            }
          ]
        },
        {
          ...BASE_PATTERN_RULE,
          colors: [GREEN],
          value: [
            {
              ...HIGH_LENGTH_MIXIN,
              ...FROM_TO_0_7_MIXIN,
              enabled: true
            },
            {
              ...LOW_LENGTH_MIXIN
            },
            {
              ...BASE_CANDLE_LENGTH,
              ...makeRangeValue(4, 7),
              enabled: true
            },
            {
              ...BASE_DEPENDENCY
            }
          ]
        }
      ],
      settings: DEFAULT_ADD_POINTS_LONG,
      relations: [
        {
          candle_from: 4,
          candle_to: 1,
          from_type: CANDLE_CLOSE,
          to_type: CANDLE_LENGTH,
          percent: 75,
          symbol: '>'
        }
      ]
    }
  },
  {
    ...BASE_CANDLE_PATTERN,
    kind: 'ladder_bottom_long',
    value: {
      candles: [
        {
          ...BASE_PATTERN_RULE,
          colors: [RED],
          value: [
            {
              ...HIGH_LENGTH_MIXIN,
              ...FROM_TO_0_7_MIXIN,
              enabled: true
            },
            {
              ...LOW_LENGTH_MIXIN
            },
            {
              ...BASE_CANDLE_LENGTH,
              ...makeRangeValue(3, 7),
              enabled: true
            },
            {
              ...BASE_DEPENDENCY,
              value: '<=',
              enabled: true
            }
          ]
        },
        {
          ...BASE_PATTERN_RULE,
          colors: [GREEN, RED, NEUTRAL],
          value: [
            {
              ...HIGH_LENGTH_MIXIN
            },
            {
              ...LOW_LENGTH_MIXIN
            },
            {
              ...BASE_CANDLE_LENGTH,
              ...makeRangeValue(0, 2),
              enabled: true
            },
            {
              ...BASE_DEPENDENCY,
              value: '>',
              enabled: true
            }
          ]
        },
        {
          ...BASE_PATTERN_RULE,
          colors: [GREEN],
          value: [
            {
              ...HIGH_LENGTH_MIXIN,
              ...FROM_TO_0_7_MIXIN,
              enabled: true
            },
            {
              ...LOW_LENGTH_MIXIN
            },
            {
              ...BASE_CANDLE_LENGTH,
              ...makeRangeValue(4, 7),
              enabled: true
            },
            {
              ...BASE_DEPENDENCY,
              value: '<=',
              enabled: true
            }
          ]
        }
      ],
      settings: DEFAULT_ADD_POINTS_LONG,
      relations: [
        {
          candle_from: 2,
          candle_to: 1,
          from_type: CANDLE_HIGH,
          to_type: CANDLE_LENGTH,
          percent: 50,
          symbol: '>'
        },
        {
          candle_from: 3,
          candle_to: 1,
          from_type: CANDLE_CLOSE,
          to_type: CANDLE_LENGTH,
          percent: 75,
          symbol: '>'
        }
      ]
    }
  },
  {
    ...BASE_CANDLE_PATTERN,
    kind: 'three_outside_up_long',
    value: {
      candles: [
        {
          ...BASE_PATTERN_RULE,
          colors: [RED],
          value: [
            {
              ...HIGH_LENGTH_MIXIN
            },
            {
              ...LOW_LENGTH_MIXIN
            },
            {
              ...BASE_CANDLE_LENGTH,
              ...makeRangeValue(1, 7),
              enabled: true
            },
            {
              ...BASE_DEPENDENCY
            }
          ]
        },
        {
          ...BASE_PATTERN_RULE,
          colors: [GREEN],
          value: [
            {
              ...HIGH_LENGTH_MIXIN,
              ...FROM_TO_0_7_MIXIN,
              enabled: true
            },
            {
              ...LOW_LENGTH_MIXIN
            },
            {
              ...BASE_CANDLE_LENGTH,
              ...makeRangeValue(2, 7),
              enabled: true
            },
            {
              ...BASE_DEPENDENCY
            }
          ]
        },
        {
          ...BASE_PATTERN_RULE,
          colors: [GREEN],
          value: [
            {
              ...HIGH_LENGTH_MIXIN,
              ...FROM_TO_0_7_MIXIN,
              enabled: true
            },
            {
              ...LOW_LENGTH_MIXIN
            },
            {
              ...BASE_CANDLE_LENGTH,
              ...makeRangeValue(2, 7),
              enabled: true
            },
            {
              ...BASE_DEPENDENCY
            }
          ]
        }
      ],
      settings: DEFAULT_ADD_POINTS_LONG,
      relations: [
        {
          candle_from: 1,
          candle_to: 3,
          from_type: CANDLE_HIGH,
          to_type: CANDLE_CLOSE,
          percent: 100,
          symbol: '<'
        },
        {
          candle_from: 2,
          candle_to: 1,
          from_type: CANDLE_CLOSE,
          to_type: CANDLE_OPEN,
          percent: 100,
          symbol: '>'
        },
        {
          candle_from: 3,
          candle_to: 1,
          from_type: CANDLE_CLOSE,
          to_type: CANDLE_OPEN,
          percent: 100,
          symbol: '>'
        },
        {
          candle_from: 3,
          candle_to: 2,
          from_type: CANDLE_CLOSE,
          to_type: CANDLE_CLOSE,
          percent: 100,
          symbol: '>'
        },
        {
          candle_from: 3,
          candle_to: 2,
          from_type: CANDLE_CLOSE,
          to_type: CANDLE_LENGTH,
          percent: 50,
          symbol: '>'
        },
        {
          candle_from: 3,
          from_type: CANDLE_LOW,
          symbol: '<=',
          percent: 100,
          candle_to: 1,
          to_type: CANDLE_LOW
        }
      ]
    }
  },
  {
    ...BASE_CANDLE_PATTERN,
    kind: 'three_inside_up_long',
    value: {
      candles: [
        {
          ...BASE_PATTERN_RULE,
          colors: [RED],
          value: [
            {
              ...HIGH_LENGTH_MIXIN,
              ...FROM_TO_0_7_MIXIN,
              enabled: true
            },
            {
              ...LOW_LENGTH_MIXIN
            },
            {
              ...BASE_CANDLE_LENGTH,
              ...makeRangeValue(2, 7),
              enabled: true
            },
            {
              ...BASE_DEPENDENCY
            }
          ]
        },
        {
          ...BASE_PATTERN_RULE,
          colors: [GREEN],
          value: [
            {
              ...HIGH_LENGTH_MIXIN,
              ...FROM_TO_0_7_MIXIN,
              enabled: true
            },
            {
              ...LOW_LENGTH_MIXIN
            },
            {
              ...BASE_CANDLE_LENGTH,
              ...makeRangeValue(1, 7),
              enabled: true
            },
            {
              ...BASE_DEPENDENCY
            }
          ]
        },
        {
          ...BASE_PATTERN_RULE,
          colors: [GREEN],
          value: [
            {
              ...HIGH_LENGTH_MIXIN,
              ...FROM_TO_0_7_MIXIN,
              enabled: true
            },
            {
              ...LOW_LENGTH_MIXIN
            },
            {
              ...BASE_CANDLE_LENGTH,
              ...makeRangeValue(2, 7),
              enabled: true
            },
            {
              ...BASE_DEPENDENCY
            }
          ]
        }
      ],
      settings: DEFAULT_ADD_POINTS_LONG,
      relations: [
        {
          candle_from: 1,
          from_type: CANDLE_OPEN,
          symbol: '>',
          percent: 100,
          candle_to: 2,
          to_type: CANDLE_CLOSE
        },
        {
          candle_from: 2,
          from_type: CANDLE_CLOSE,
          symbol: '>=',
          percent: 50,
          candle_to: 1,
          to_type: CANDLE_LENGTH
        },
        {
          candle_from: 3,
          from_type: CANDLE_CLOSE,
          symbol: '>',
          percent: 100,
          candle_to: 1,
          to_type: CANDLE_OPEN
        },
        {
          candle_from: 3,
          from_type: CANDLE_LOW,
          symbol: '<=',
          percent: 100,
          candle_to: 1,
          to_type: CANDLE_LOW
        }
      ]
    }
  },
  {
    ...BASE_CANDLE_PATTERN,
    kind: 'belt_hold_long',
    value: {
      candles: [
        {
          ...BASE_PATTERN_RULE,
          colors: [RED],
          value: [
            {
              ...HIGH_LENGTH_MIXIN,
              ...FROM_TO_0_7_MIXIN,
              enabled: true
            },
            {
              ...LOW_LENGTH_MIXIN
            },
            {
              ...BASE_CANDLE_LENGTH,
              ...makeRangeValue(2, 7),
              enabled: true
            },
            {
              ...BASE_DEPENDENCY
            }
          ]
        },
        {
          ...BASE_PATTERN_RULE,
          colors: [RED],
          value: [
            {
              ...HIGH_LENGTH_MIXIN,
              ...FROM_TO_0_7_MIXIN,
              enabled: true
            },
            {
              ...LOW_LENGTH_MIXIN
            },
            {
              ...BASE_CANDLE_LENGTH,
              ...makeRangeValue(2, 7),
              enabled: true
            },
            {
              ...BASE_DEPENDENCY
            }
          ]
        },
        {
          ...BASE_PATTERN_RULE,
          colors: [GREEN],
          value: [
            {
              ...HIGH_LENGTH_MIXIN,
              ...FROM_TO_0_7_MIXIN,
              enabled: true
            },
            {
              ...LOW_LENGTH_MIXIN
            },
            {
              ...BASE_CANDLE_LENGTH,
              ...makeRangeValue(4, 7),
              enabled: true
            },
            {
              ...BASE_DEPENDENCY,
              value: '<=',
              enabled: true
            }
          ]
        }
      ],
      settings: DEFAULT_ADD_POINTS_LONG,
      relations: [
        {
          candle_from: 2,
          from_type: CANDLE_CLOSE,
          symbol: '<',
          percent: 100,
          candle_to: 1,
          to_type: CANDLE_CLOSE
        },
        {
          candle_from: 2,
          from_type: CANDLE_HIGH,
          symbol: '<',
          percent: 100,
          candle_to: 1,
          to_type: CANDLE_HIGH
        },
        {
          candle_from: 3,
          from_type: CANDLE_CLOSE,
          symbol: '>',
          percent: 100,
          candle_to: 2,
          to_type: CANDLE_OPEN
        },
        {
          candle_from: 3,
          from_type: CANDLE_CLOSE,
          symbol: '>',
          percent: 50,
          candle_to: 1,
          to_type: CANDLE_LENGTH
        }
      ]
    }
  },
  {
    ...BASE_CANDLE_PATTERN,
    kind: 'morning_star_long',
    value: {
      candles: [
        {
          ...BASE_PATTERN_RULE,
          colors: [RED],
          value: [
            {
              ...HIGH_LENGTH_MIXIN,
              ...FROM_TO_0_7_MIXIN,
              enabled: true
            },
            {
              ...LOW_LENGTH_MIXIN
            },
            {
              ...BASE_CANDLE_LENGTH,
              ...makeRangeValue(3, 7),
              enabled: true
            },
            {
              ...BASE_DEPENDENCY
            }
          ]
        },
        {
          ...BASE_PATTERN_RULE,
          colors: [GREEN, RED, NEUTRAL],
          value: [
            {
              ...HIGH_LENGTH_MIXIN,
              ...FROM_TO_0_2_MIXIN,
              enabled: true
            },
            {
              ...LOW_LENGTH_MIXIN,
              ...makeRangeValue(3, 7),
              enabled: true
            },
            {
              ...BASE_CANDLE_LENGTH,
              ...makeRangeValue(0, 2),
              enabled: true
            },
            {
              ...BASE_DEPENDENCY,
              value: '<',
              enabled: true
            }
          ]
        },
        {
          ...BASE_PATTERN_RULE,
          colors: [GREEN],
          value: [
            {
              ...HIGH_LENGTH_MIXIN,
              ...FROM_TO_0_7_MIXIN,
              enabled: true
            },
            {
              ...LOW_LENGTH_MIXIN
            },
            {
              ...BASE_CANDLE_LENGTH,
              ...makeRangeValue(3, 7),
              enabled: true
            },
            {
              ...BASE_DEPENDENCY,
              value: '<=',
              enabled: true
            }
          ]
        }
      ],
      settings: DEFAULT_ADD_POINTS_LONG,
      relations: [
        {
          candle_from: 1,
          from_type: CANDLE_LOW,
          symbol: '>',
          percent: 100,
          candle_to: 2,
          to_type: CANDLE_LOW
        },
        {
          candle_from: 3,
          from_type: CANDLE_CLOSE,
          symbol: '>',
          percent: 75,
          candle_to: 1,
          to_type: CANDLE_LENGTH
        },
        {
          candle_from: 3,
          from_type: CANDLE_LOW,
          symbol: '>',
          percent: 100,
          candle_to: 2,
          to_type: CANDLE_LOW
        }
      ]
    }
  },
  {
    ...BASE_CANDLE_PATTERN,
    kind: 'morning_doji_star_long',
    value: {
      candles: [
        {
          ...BASE_PATTERN_RULE,
          colors: [RED],
          value: [
            {
              ...HIGH_LENGTH_MIXIN,
              ...FROM_TO_0_7_MIXIN,
              enabled: true
            },
            {
              ...LOW_LENGTH_MIXIN
            },
            {
              ...BASE_CANDLE_LENGTH,
              ...makeRangeValue(3, 7),
              enabled: true
            },
            {
              ...BASE_DEPENDENCY
            }
          ]
        },
        {
          ...BASE_PATTERN_RULE,
          colors: [GREEN, RED, NEUTRAL],
          value: [
            {
              ...HIGH_LENGTH_MIXIN,
              ...FROM_TO_0_2_MIXIN,
              enabled: true
            },
            {
              ...LOW_LENGTH_MIXIN,
              ...makeRangeValue(0, 2),
              enabled: true
            },
            {
              ...BASE_CANDLE_LENGTH,
              ...makeRangeValue(0, 1),
              enabled: true
            },
            {
              ...BASE_DEPENDENCY
            }
          ]
        },
        {
          ...BASE_PATTERN_RULE,
          colors: [GREEN],
          value: [
            {
              ...HIGH_LENGTH_MIXIN,
              ...FROM_TO_0_7_MIXIN,
              enabled: true
            },
            {
              ...LOW_LENGTH_MIXIN
            },
            {
              ...BASE_CANDLE_LENGTH,
              ...makeRangeValue(3, 7),
              enabled: true
            },
            {
              ...BASE_DEPENDENCY
            }
          ]
        }
      ],
      settings: DEFAULT_ADD_POINTS_LONG,
      relations: [
        {
          candle_from: 3,
          from_type: CANDLE_CLOSE,
          symbol: '>',
          percent: 75,
          candle_to: 1,
          to_type: CANDLE_LENGTH
        }
      ]
    }
  },
  {
    ...BASE_CANDLE_PATTERN,
    kind: 'tristar_long',
    value: {
      candles: [
        {
          ...BASE_PATTERN_RULE,
          colors: [GREEN, RED, NEUTRAL],
          value: [
            {
              ...HIGH_LENGTH_MIXIN,
              ...FROM_TO_0_2_MIXIN,
              enabled: true
            },
            {
              ...LOW_LENGTH_MIXIN,
              ...FROM_TO_0_2_MIXIN,
              enabled: true
            },
            {
              ...BASE_CANDLE_LENGTH,
              ...makeRangeValue(0, 1),
              enabled: true
            },
            {
              ...BASE_DEPENDENCY
            }
          ]
        },
        {
          ...BASE_PATTERN_RULE,
          colors: [GREEN, RED, NEUTRAL],
          value: [
            {
              ...HIGH_LENGTH_MIXIN,
              ...FROM_TO_0_2_MIXIN,
              enabled: true
            },
            {
              ...LOW_LENGTH_MIXIN,
              ...FROM_TO_0_2_MIXIN,
              enabled: true
            },
            {
              ...BASE_CANDLE_LENGTH,
              ...makeRangeValue(0, 1),
              enabled: true
            },
            {
              ...BASE_DEPENDENCY
            }
          ]
        },
        {
          ...BASE_PATTERN_RULE,
          colors: [GREEN, RED, NEUTRAL],
          value: [
            {
              ...HIGH_LENGTH_MIXIN,
              ...FROM_TO_0_2_MIXIN,
              enabled: true
            },
            {
              ...LOW_LENGTH_MIXIN,
              ...FROM_TO_0_2_MIXIN,
              enabled: true
            },
            {
              ...BASE_CANDLE_LENGTH,
              ...makeRangeValue(0, 1),
              enabled: true
            },
            {
              ...BASE_DEPENDENCY
            }
          ]
        }
      ],
      settings: {
        ...DEFAULT_ADD_POINTS_LONG,
        value: 0
      },
      relations: []
    }
  },
  {
    ...BASE_CANDLE_PATTERN,
    kind: 'twin_tower_long',
    value: {
      candles: [
        {
          ...BASE_PATTERN_RULE,
          colors: [RED],
          value: [
            {
              ...HIGH_LENGTH_MIXIN,
              ...FROM_TO_0_7_MIXIN,
              enabled: true
            },
            {
              ...LOW_LENGTH_MIXIN
            },
            {
              ...BASE_CANDLE_LENGTH,
              ...makeRangeValue(4, 7),
              enabled: true
            },
            {
              ...BASE_DEPENDENCY
            }
          ]
        },
        {
          ...BASE_PATTERN_RULE,
          colors: [GREEN],
          value: [
            {
              ...HIGH_LENGTH_MIXIN,
              ...FROM_TO_0_7_MIXIN,
              enabled: true
            },
            {
              ...LOW_LENGTH_MIXIN
            },
            {
              ...BASE_CANDLE_LENGTH,
              ...makeRangeValue(4, 7),
              enabled: true
            },
            {
              ...BASE_DEPENDENCY
            }
          ]
        }
      ],
      settings: DEFAULT_ADD_POINTS_LONG,
      relations: [
        {
          candle_from: 2,
          from_type: CANDLE_CLOSE,
          symbol: '>',
          percent: 95,
          candle_to: 1,
          to_type: CANDLE_LENGTH
        }
      ]
    }
  },
  {
    ...BASE_CANDLE_PATTERN,
    kind: 'engulfing_long',
    value: {
      candles: [
        {
          ...BASE_PATTERN_RULE,
          colors: [RED],
          value: [
            {
              ...HIGH_LENGTH_MIXIN,
              ...FROM_TO_0_7_MIXIN,
              enabled: true
            },
            {
              ...LOW_LENGTH_MIXIN
            },
            {
              ...BASE_CANDLE_LENGTH,
              ...makeRangeValue(2, 7),
              enabled: true
            },
            {
              ...BASE_DEPENDENCY
            }
          ]
        },
        {
          ...BASE_PATTERN_RULE,
          colors: [GREEN],
          value: [
            {
              ...HIGH_LENGTH_MIXIN,
              ...FROM_TO_0_7_MIXIN,
              enabled: true
            },
            {
              ...LOW_LENGTH_MIXIN
            },
            {
              ...BASE_CANDLE_LENGTH,
              ...makeRangeValue(3, 7),
              enabled: true
            },
            {
              ...BASE_DEPENDENCY
            }
          ]
        }
      ],
      settings: DEFAULT_ADD_POINTS_LONG,
      relations: [
        {
          candle_from: 2,
          from_type: CANDLE_CLOSE,
          symbol: '>',
          percent: 100,
          candle_to: 1,
          to_type: CANDLE_OPEN
        }
      ]
    }
  },
  {
    ...BASE_CANDLE_PATTERN,
    kind: 'reverse_belt_hold_long',
    value: {
      candles: [
        {
          ...BASE_PATTERN_RULE,
          colors: [RED],
          value: [
            {
              ...HIGH_LENGTH_MIXIN,
              ...FROM_TO_0_7_MIXIN,
              enabled: true
            },
            {
              ...LOW_LENGTH_MIXIN
            },
            {
              ...BASE_CANDLE_LENGTH,
              ...makeRangeValue(3, 7),
              enabled: true
            },
            {
              ...BASE_DEPENDENCY
            }
          ]
        },
        {
          ...BASE_PATTERN_RULE,
          colors: [GREEN],
          value: [
            {
              ...HIGH_LENGTH_MIXIN,
              ...FROM_TO_0_7_MIXIN,
              enabled: true
            },
            {
              ...LOW_LENGTH_MIXIN
            },
            {
              ...BASE_CANDLE_LENGTH,
              ...makeRangeValue(1, 7),
              enabled: true
            },
            {
              ...BASE_DEPENDENCY
            }
          ]
        },
        {
          ...BASE_PATTERN_RULE,
          colors: [GREEN],
          value: [
            {
              ...HIGH_LENGTH_MIXIN,
              ...FROM_TO_0_7_MIXIN,
              enabled: true
            },
            {
              ...LOW_LENGTH_MIXIN
            },
            {
              ...BASE_CANDLE_LENGTH,
              ...makeRangeValue(3, 7),
              enabled: true
            },
            {
              ...BASE_DEPENDENCY
            }
          ]
        }
      ],
      settings: DEFAULT_ADD_POINTS_LONG,
      relations: [
        {
          candle_from: 2,
          from_type: CANDLE_CLOSE,
          symbol: '<=',
          percent: 50,
          candle_to: 1,
          to_type: CANDLE_LENGTH
        },
        {
          candle_from: 2,
          from_type: CANDLE_HIGH,
          symbol: '<=',
          percent: 100,
          candle_to: 1,
          to_type: CANDLE_OPEN
        },
        {
          candle_from: 3,
          from_type: CANDLE_CLOSE,
          symbol: '>',
          percent: 75,
          candle_to: 1,
          to_type: CANDLE_LENGTH
        },
        {
          candle_from: 3,
          from_type: CANDLE_CLOSE,
          symbol: '>',
          percent: 100,
          candle_to: 2,
          to_type: CANDLE_CLOSE
        }
      ]
    }
  }
];

export const CANDLE_PATTERNS_SHORT = [
  {
    ...BASE_CANDLE_PATTERN,
    kind: 'double_d_bend_short',
    value: {
      candles: [
        {
          ...BASE_PATTERN_RULE,
          colors: [GREEN],
          value: [
            {
              ...HIGH_LENGTH_MIXIN
            },
            {
              ...LOW_LENGTH_MIXIN,
              ...FROM_TO_0_7_MIXIN,
              enabled: true
            },
            {
              ...BASE_CANDLE_LENGTH,
              ...makeRangeValue(3, 7),
              enabled: true
            },
            {
              ...BASE_DEPENDENCY,
              value: '>=',
              enabled: true
            }
          ]
        },
        {
          ...BASE_PATTERN_RULE,
          colors: [GREEN, RED, NEUTRAL],
          value: [
            {
              ...HIGH_LENGTH_MIXIN
            },
            {
              ...LOW_LENGTH_MIXIN
            },
            {
              ...BASE_CANDLE_LENGTH,
              ...makeRangeValue(0, 1),
              enabled: true
            },
            {
              ...BASE_DEPENDENCY
            }
          ]
        },
        {
          ...BASE_PATTERN_RULE,
          same_as: 1,
          colors: [],
          value: [
            {
              ...HIGH_LENGTH_MIXIN
            },
            {
              ...LOW_LENGTH_MIXIN
            },
            {
              ...BASE_CANDLE_LENGTH,
              ...makeRangeValue(0, 1),
              enabled: true
            },
            {
              ...BASE_DEPENDENCY
            }
          ]
        },
        {
          ...BASE_PATTERN_RULE,
          colors: [RED],
          value: [
            {
              ...HIGH_LENGTH_MIXIN
            },
            {
              ...LOW_LENGTH_MIXIN,
              ...FROM_TO_0_7_MIXIN,
              enabled: true
            },
            {
              ...BASE_CANDLE_LENGTH,
              ...makeRangeValue(3, 7),
              enabled: true
            },
            {
              ...BASE_DEPENDENCY,
              value: '>=',
              enabled: true
            }
          ]
        }
      ],
      settings: DEFAULT_ADD_POINTS_SHORT,
      relations: [
        {
          candle_from: 4,
          from_type: CANDLE_CLOSE,
          symbol: '>',
          percent: 75,
          candle_to: 1,
          to_type: CANDLE_LENGTH
        }
      ]
    }
  },
  {
    ...BASE_CANDLE_PATTERN,
    kind: 'cloud_cover_short',
    value: {
      candles: [
        {
          ...BASE_PATTERN_RULE,
          colors: [GREEN],
          value: [
            {
              ...HIGH_LENGTH_MIXIN
            },
            {
              ...LOW_LENGTH_MIXIN,
              ...FROM_TO_0_7_MIXIN,
              enabled: true
            },
            {
              ...BASE_CANDLE_LENGTH,
              ...makeRangeValue(4, 7),
              enabled: true
            },
            {
              ...BASE_DEPENDENCY,
              value: '>=',
              enabled: true
            }
          ]
        },
        {
          ...BASE_PATTERN_RULE,
          colors: [RED],
          value: [
            {
              ...HIGH_LENGTH_MIXIN
            },
            {
              ...LOW_LENGTH_MIXIN,
              ...FROM_TO_0_7_MIXIN,
              enabled: true
            },
            {
              ...BASE_CANDLE_LENGTH,
              ...makeRangeValue(3, 7),
              enabled: true
            },
            {
              ...BASE_DEPENDENCY
            }
          ]
        }
      ],
      settings: DEFAULT_ADD_POINTS_SHORT,
      relations: [
        {
          candle_from: 2,
          from_type: CANDLE_CLOSE,
          symbol: '>',
          percent: 100,
          candle_to: 1,
          to_type: CANDLE_OPEN
        },
        {
          candle_from: 2,
          from_type: CANDLE_CLOSE,
          symbol: '>',
          percent: 50,
          candle_to: 1,
          to_type: CANDLE_LENGTH
        }
      ]
    }
  },
  {
    ...BASE_CANDLE_PATTERN,
    kind: 'dumpling_top_short',
    value: {
      candles: [
        {
          ...BASE_PATTERN_RULE,
          colors: [GREEN],
          value: [
            {
              ...HIGH_LENGTH_MIXIN
            },
            {
              ...LOW_LENGTH_MIXIN,
              ...FROM_TO_0_7_MIXIN,
              enabled: true
            },
            {
              ...BASE_CANDLE_LENGTH,
              ...makeRangeValue(3, 7),
              enabled: true
            },
            {
              ...BASE_DEPENDENCY,
              value: '>=',
              enabled: true
            }
          ]
        },
        {
          ...BASE_PATTERN_RULE,
          colors: [RED],
          value: [
            {
              ...HIGH_LENGTH_MIXIN
            },
            {
              ...LOW_LENGTH_MIXIN
            },
            {
              ...BASE_CANDLE_LENGTH,
              ...makeRangeValue(0, 2),
              enabled: true
            },
            {
              ...BASE_DEPENDENCY
            }
          ]
        },
        {
          ...BASE_PATTERN_RULE,
          colors: [GREEN],
          value: [
            {
              ...HIGH_LENGTH_MIXIN
            },
            {
              ...LOW_LENGTH_MIXIN
            },
            {
              ...BASE_CANDLE_LENGTH,
              ...makeRangeValue(0, 2),
              enabled: true
            },
            {
              ...BASE_DEPENDENCY
            }
          ]
        },
        {
          ...BASE_PATTERN_RULE,
          colors: [RED],
          value: [
            {
              ...HIGH_LENGTH_MIXIN
            },
            {
              ...LOW_LENGTH_MIXIN,
              ...FROM_TO_0_7_MIXIN,
              enabled: true
            },
            {
              ...BASE_CANDLE_LENGTH,
              ...makeRangeValue(4, 7),
              enabled: true
            },
            {
              ...BASE_DEPENDENCY,
              value: '>=',
              enabled: true
            }
          ]
        }
      ],
      settings: DEFAULT_ADD_POINTS_SHORT,
      relations: [
        {
          candle_from: 4,
          from_type: CANDLE_CLOSE,
          symbol: '>',
          percent: 75,
          candle_to: 1,
          to_type: CANDLE_LENGTH
        }
      ]
    }
  },
  {
    ...BASE_CANDLE_PATTERN,
    kind: 'ladder_top_short',
    value: {
      candles: [
        {
          ...BASE_PATTERN_RULE,
          colors: [GREEN],
          value: [
            {
              ...HIGH_LENGTH_MIXIN
            },
            {
              ...LOW_LENGTH_MIXIN
            },
            {
              ...BASE_CANDLE_LENGTH,
              ...makeRangeValue(3, 7),
              enabled: true
            },
            {
              ...BASE_DEPENDENCY,
              value: '>=',
              enabled: true
            }
          ]
        },
        {
          ...BASE_PATTERN_RULE,
          colors: [GREEN, RED, NEUTRAL],
          value: [
            {
              ...HIGH_LENGTH_MIXIN
            },
            {
              ...LOW_LENGTH_MIXIN
            },
            {
              ...BASE_CANDLE_LENGTH,
              ...makeRangeValue(0, 2),
              enabled: true
            },
            {
              ...BASE_DEPENDENCY,
              value: '<',
              enabled: true
            }
          ]
        },
        {
          ...BASE_PATTERN_RULE,
          colors: [RED],
          value: [
            {
              ...HIGH_LENGTH_MIXIN
            },
            {
              ...LOW_LENGTH_MIXIN,
              ...FROM_TO_0_7_MIXIN,
              enabled: true
            },
            {
              ...BASE_CANDLE_LENGTH,
              ...makeRangeValue(4, 7),
              enabled: true
            },
            {
              ...BASE_DEPENDENCY,
              value: '>=',
              enabled: true
            }
          ]
        }
      ],
      settings: DEFAULT_ADD_POINTS_SHORT,
      relations: [
        {
          candle_from: 2,
          from_type: CANDLE_LOW,
          symbol: '>',
          percent: 50,
          candle_to: 1,
          to_type: CANDLE_LENGTH
        },
        {
          candle_from: 3,
          from_type: CANDLE_CLOSE,
          symbol: '>',
          percent: 75,
          candle_to: 1,
          to_type: CANDLE_LENGTH
        }
      ]
    }
  },
  {
    ...BASE_CANDLE_PATTERN,
    kind: 'three_outside_down_short',
    value: {
      candles: [
        {
          ...BASE_PATTERN_RULE,
          colors: [GREEN],
          value: [
            {
              ...HIGH_LENGTH_MIXIN
            },
            {
              ...LOW_LENGTH_MIXIN
            },
            {
              ...BASE_CANDLE_LENGTH,
              ...makeRangeValue(1, 7),
              enabled: true
            },
            {
              ...BASE_DEPENDENCY
            }
          ]
        },
        {
          ...BASE_PATTERN_RULE,
          colors: [RED],
          value: [
            {
              ...HIGH_LENGTH_MIXIN
            },
            {
              ...LOW_LENGTH_MIXIN,
              ...FROM_TO_0_7_MIXIN,
              enabled: true
            },
            {
              ...BASE_CANDLE_LENGTH,
              ...makeRangeValue(2, 7),
              enabled: true
            },
            {
              ...BASE_DEPENDENCY
            }
          ]
        },
        {
          ...BASE_PATTERN_RULE,
          colors: [RED],
          value: [
            {
              ...HIGH_LENGTH_MIXIN
            },
            {
              ...LOW_LENGTH_MIXIN,
              ...FROM_TO_0_7_MIXIN,
              enabled: true
            },
            {
              ...BASE_CANDLE_LENGTH,
              ...makeRangeValue(2, 7),
              enabled: true
            },
            {
              ...BASE_DEPENDENCY
            }
          ]
        }
      ],
      settings: DEFAULT_ADD_POINTS_SHORT,
      relations: [
        {
          candle_from: 1,
          from_type: CANDLE_LOW,
          symbol: '<=',
          percent: 100,
          candle_to: 3,
          to_type: CANDLE_CLOSE
        },
        {
          candle_from: 2,
          from_type: CANDLE_CLOSE,
          symbol: '<',
          percent: 100,
          candle_to: 1,
          to_type: CANDLE_OPEN
        },
        {
          candle_from: 3,
          from_type: CANDLE_CLOSE,
          symbol: '<',
          percent: 100,
          candle_to: 1,
          to_type: CANDLE_OPEN
        },
        {
          candle_from: 3,
          from_type: CANDLE_CLOSE,
          symbol: '<',
          percent: 100,
          candle_to: 2,
          to_type: CANDLE_CLOSE
        },
        {
          candle_from: 3,
          from_type: CANDLE_CLOSE,
          symbol: '>',
          percent: 50,
          candle_to: 2,
          to_type: CANDLE_LENGTH
        },
        {
          candle_from: 3,
          from_type: CANDLE_HIGH,
          symbol: '<=',
          percent: 100,
          candle_to: 1,
          to_type: CANDLE_HIGH
        }
      ]
    }
  },
  {
    ...BASE_CANDLE_PATTERN,
    kind: 'three_inside_down_short',
    value: {
      candles: [
        {
          ...BASE_PATTERN_RULE,
          colors: [GREEN],
          value: [
            {
              ...HIGH_LENGTH_MIXIN
            },
            {
              ...LOW_LENGTH_MIXIN,
              ...FROM_TO_0_7_MIXIN,
              enabled: true
            },
            {
              ...BASE_CANDLE_LENGTH,
              ...makeRangeValue(2, 7),
              enabled: true
            },
            {
              ...BASE_DEPENDENCY
            }
          ]
        },
        {
          ...BASE_PATTERN_RULE,
          colors: [RED],
          value: [
            {
              ...HIGH_LENGTH_MIXIN
            },
            {
              ...LOW_LENGTH_MIXIN,
              ...FROM_TO_0_7_MIXIN,
              enabled: true
            },
            {
              ...BASE_CANDLE_LENGTH,
              ...makeRangeValue(1, 7),
              enabled: true
            },
            {
              ...BASE_DEPENDENCY
            }
          ]
        },
        {
          ...BASE_PATTERN_RULE,
          colors: [RED],
          value: [
            {
              ...HIGH_LENGTH_MIXIN
            },
            {
              ...LOW_LENGTH_MIXIN,
              ...FROM_TO_0_7_MIXIN,
              enabled: true
            },
            {
              ...BASE_CANDLE_LENGTH,
              ...makeRangeValue(2, 7),
              enabled: true
            },
            {
              ...BASE_DEPENDENCY
            }
          ]
        }
      ],
      settings: DEFAULT_ADD_POINTS_SHORT,
      relations: [
        {
          candle_from: 1,
          from_type: CANDLE_OPEN,
          symbol: '<',
          percent: 100,
          candle_to: 2,
          to_type: CANDLE_CLOSE
        },
        {
          candle_from: 2,
          from_type: CANDLE_CLOSE,
          symbol: '>=',
          percent: 50,
          candle_to: 1,
          to_type: CANDLE_LENGTH
        },
        {
          candle_from: 3,
          from_type: CANDLE_CLOSE,
          symbol: '<',
          percent: 100,
          candle_to: 1,
          to_type: CANDLE_OPEN
        },
        {
          candle_from: 3,
          from_type: CANDLE_HIGH,
          symbol: '<=',
          percent: 100,
          candle_to: 1,
          to_type: CANDLE_HIGH
        }
      ]
    }
  },
  {
    ...BASE_CANDLE_PATTERN,
    kind: 'belt_hold_short',
    value: {
      candles: [
        {
          ...BASE_PATTERN_RULE,
          colors: [GREEN],
          value: [
            {
              ...HIGH_LENGTH_MIXIN
            },
            {
              ...LOW_LENGTH_MIXIN,
              ...FROM_TO_0_7_MIXIN,
              enabled: true
            },
            {
              ...BASE_CANDLE_LENGTH,
              ...makeRangeValue(2, 7),
              enabled: true
            },
            {
              ...BASE_DEPENDENCY
            }
          ]
        },
        {
          ...BASE_PATTERN_RULE,
          colors: [GREEN],
          value: [
            {
              ...HIGH_LENGTH_MIXIN
            },
            {
              ...LOW_LENGTH_MIXIN,
              ...FROM_TO_0_7_MIXIN,
              enabled: true
            },
            {
              ...BASE_CANDLE_LENGTH,
              ...makeRangeValue(2, 7),
              enabled: true
            },
            {
              ...BASE_DEPENDENCY
            }
          ]
        },
        {
          ...BASE_PATTERN_RULE,
          colors: [RED],
          value: [
            {
              ...HIGH_LENGTH_MIXIN
            },
            {
              ...LOW_LENGTH_MIXIN,
              ...FROM_TO_0_7_MIXIN,
              enabled: true
            },
            {
              ...BASE_CANDLE_LENGTH,
              ...makeRangeValue(4, 7),
              enabled: true
            },
            {
              ...BASE_DEPENDENCY,
              value: '>=',
              enabled: true
            }
          ]
        }
      ],
      settings: DEFAULT_ADD_POINTS_SHORT,
      relations: [
        {
          candle_from: 2,
          from_type: CANDLE_CLOSE,
          symbol: '>',
          percent: 100,
          candle_to: 1,
          to_type: CANDLE_CLOSE
        },
        {
          candle_from: 2,
          from_type: CANDLE_LOW,
          symbol: '>',
          percent: 100,
          candle_to: 1,
          to_type: CANDLE_LOW
        },
        {
          candle_from: 3,
          from_type: CANDLE_CLOSE,
          symbol: '<',
          percent: 100,
          candle_to: 2,
          to_type: CANDLE_OPEN
        },
        {
          candle_from: 3,
          from_type: CANDLE_CLOSE,
          symbol: '>',
          percent: 50,
          candle_to: 1,
          to_type: CANDLE_LENGTH
        }
      ]
    }
  },
  {
    ...BASE_CANDLE_PATTERN,
    kind: 'evening_star_short',
    value: {
      candles: [
        {
          ...BASE_PATTERN_RULE,
          colors: [GREEN],
          value: [
            {
              ...HIGH_LENGTH_MIXIN
            },
            {
              ...LOW_LENGTH_MIXIN,
              ...FROM_TO_0_7_MIXIN,
              enabled: true
            },
            {
              ...BASE_CANDLE_LENGTH,
              ...makeRangeValue(3, 7),
              enabled: true
            },
            {
              ...BASE_DEPENDENCY
            }
          ]
        },
        {
          ...BASE_PATTERN_RULE,
          colors: [GREEN, RED, NEUTRAL],
          value: [
            {
              ...HIGH_LENGTH_MIXIN,
              ...makeRangeValue(3, 7),
              enabled: true
            },
            {
              ...LOW_LENGTH_MIXIN,
              ...FROM_TO_0_2_MIXIN,
              enabled: true
            },
            {
              ...BASE_CANDLE_LENGTH,
              ...makeRangeValue(0, 2),
              enabled: true
            },
            {
              ...BASE_DEPENDENCY,
              value: '>',
              enabled: true
            }
          ]
        },
        {
          ...BASE_PATTERN_RULE,
          colors: [RED],
          value: [
            {
              ...HIGH_LENGTH_MIXIN
            },
            {
              ...LOW_LENGTH_MIXIN,
              ...FROM_TO_0_7_MIXIN,
              enabled: true
            },
            {
              ...BASE_CANDLE_LENGTH,
              ...makeRangeValue(3, 7),
              enabled: true
            },
            {
              ...BASE_DEPENDENCY,
              value: '>=',
              enabled: true
            }
          ]
        }
      ],
      settings: DEFAULT_ADD_POINTS_SHORT,
      relations: [
        {
          candle_from: 1,
          from_type: CANDLE_HIGH,
          symbol: '<',
          percent: 100,
          candle_to: 2,
          to_type: CANDLE_HIGH
        },
        {
          candle_from: 3,
          from_type: CANDLE_CLOSE,
          symbol: '>',
          percent: 75,
          candle_to: 1,
          to_type: CANDLE_LENGTH
        },
        {
          candle_from: 3,
          from_type: CANDLE_HIGH,
          symbol: '<',
          percent: 100,
          candle_to: 2,
          to_type: CANDLE_HIGH
        }
      ]
    }
  },
  {
    ...BASE_CANDLE_PATTERN,
    kind: 'evening_doji_star_short',
    value: {
      candles: [
        {
          ...BASE_PATTERN_RULE,
          colors: [GREEN],
          value: [
            {
              ...HIGH_LENGTH_MIXIN
            },
            {
              ...LOW_LENGTH_MIXIN,
              ...FROM_TO_0_7_MIXIN,
              enabled: true
            },
            {
              ...BASE_CANDLE_LENGTH,
              ...makeRangeValue(3, 7),
              enabled: true
            },
            {
              ...BASE_DEPENDENCY
            }
          ]
        },
        {
          ...BASE_PATTERN_RULE,
          colors: [GREEN, RED, NEUTRAL],
          value: [
            {
              ...HIGH_LENGTH_MIXIN,
              ...FROM_TO_0_2_MIXIN,
              enabled: true
            },
            {
              ...LOW_LENGTH_MIXIN,
              ...FROM_TO_0_2_MIXIN,
              enabled: true
            },
            {
              ...BASE_CANDLE_LENGTH,
              ...makeRangeValue(0, 1),
              enabled: true
            },
            {
              ...BASE_DEPENDENCY
            }
          ]
        },
        {
          ...BASE_PATTERN_RULE,
          colors: [RED],
          value: [
            {
              ...HIGH_LENGTH_MIXIN
            },
            {
              ...LOW_LENGTH_MIXIN,
              ...FROM_TO_0_7_MIXIN,
              enabled: true
            },
            {
              ...BASE_CANDLE_LENGTH,
              ...makeRangeValue(3, 7),
              enabled: true
            },
            {
              ...BASE_DEPENDENCY
            }
          ]
        }
      ],
      settings: DEFAULT_ADD_POINTS_SHORT,
      relations: [
        {
          candle_from: 3,
          from_type: CANDLE_CLOSE,
          symbol: '>',
          percent: 75,
          candle_to: 1,
          to_type: CANDLE_LENGTH
        }
      ]
    }
  },
  {
    ...BASE_CANDLE_PATTERN,
    kind: 'tristar_short',
    value: {
      candles: [
        {
          ...BASE_PATTERN_RULE,
          colors: [GREEN, RED, NEUTRAL],
          value: [
            {
              ...HIGH_LENGTH_MIXIN,
              ...FROM_TO_0_2_MIXIN,
              enabled: true
            },
            {
              ...LOW_LENGTH_MIXIN,
              ...FROM_TO_0_2_MIXIN,
              enabled: true
            },
            {
              ...BASE_CANDLE_LENGTH,
              ...makeRangeValue(0, 1),
              enabled: true
            },
            {
              ...BASE_DEPENDENCY
            }
          ]
        },
        {
          ...BASE_PATTERN_RULE,
          colors: [GREEN, RED, NEUTRAL],
          value: [
            {
              ...HIGH_LENGTH_MIXIN,
              ...FROM_TO_0_2_MIXIN,
              enabled: true
            },
            {
              ...LOW_LENGTH_MIXIN,
              ...FROM_TO_0_2_MIXIN,
              enabled: true
            },
            {
              ...BASE_CANDLE_LENGTH,
              ...makeRangeValue(0, 1),
              enabled: true
            },
            {
              ...BASE_DEPENDENCY
            }
          ]
        },
        {
          ...BASE_PATTERN_RULE,
          colors: [GREEN, RED, NEUTRAL],
          value: [
            {
              ...HIGH_LENGTH_MIXIN,
              ...FROM_TO_0_2_MIXIN,
              enabled: true
            },
            {
              ...LOW_LENGTH_MIXIN,
              ...FROM_TO_0_2_MIXIN,
              enabled: true
            },
            {
              ...BASE_CANDLE_LENGTH,
              ...makeRangeValue(0, 1),
              enabled: true
            },
            {
              ...BASE_DEPENDENCY
            }
          ]
        }
      ],
      settings: {
        ...DEFAULT_ADD_POINTS_SHORT,
        value: 0
      },
      relations: []
    }
  },
  {
    ...BASE_CANDLE_PATTERN,
    kind: 'twin_tower_short',
    value: {
      candles: [
        {
          ...BASE_PATTERN_RULE,
          colors: [GREEN],
          value: [
            {
              ...HIGH_LENGTH_MIXIN
            },
            {
              ...LOW_LENGTH_MIXIN,
              ...FROM_TO_0_7_MIXIN,
              enabled: true
            },
            {
              ...BASE_CANDLE_LENGTH,
              ...makeRangeValue(4, 7),
              enabled: true
            },
            {
              ...BASE_DEPENDENCY
            }
          ]
        },
        {
          ...BASE_PATTERN_RULE,
          colors: [RED],
          value: [
            {
              ...HIGH_LENGTH_MIXIN
            },
            {
              ...LOW_LENGTH_MIXIN,
              ...FROM_TO_0_7_MIXIN,
              enabled: true
            },
            {
              ...BASE_CANDLE_LENGTH,
              ...makeRangeValue(4, 7),
              enabled: true
            },
            {
              ...BASE_DEPENDENCY
            }
          ]
        }
      ],
      settings: DEFAULT_ADD_POINTS_SHORT,
      relations: [
        {
          candle_from: 2,
          from_type: CANDLE_CLOSE,
          symbol: '>=',
          percent: 95,
          candle_to: 1,
          to_type: CANDLE_LENGTH
        },
        {
          candle_from: 2,
          from_type: CANDLE_CLOSE,
          symbol: '<=',
          percent: 100,
          candle_to: 1,
          to_type: CANDLE_OPEN
        }
      ]
    }
  },
  {
    ...BASE_CANDLE_PATTERN,
    kind: 'engulfing_short',
    value: {
      candles: [
        {
          ...BASE_PATTERN_RULE,
          colors: [GREEN],
          value: [
            {
              ...HIGH_LENGTH_MIXIN
            },
            {
              ...LOW_LENGTH_MIXIN,
              ...FROM_TO_0_7_MIXIN,
              enabled: true
            },
            {
              ...BASE_CANDLE_LENGTH,
              ...makeRangeValue(2, 7),
              enabled: true
            },
            {
              ...BASE_DEPENDENCY
            }
          ]
        },
        {
          ...BASE_PATTERN_RULE,
          colors: [RED],
          value: [
            {
              ...HIGH_LENGTH_MIXIN
            },
            {
              ...LOW_LENGTH_MIXIN,
              ...FROM_TO_0_7_MIXIN,
              enabled: true
            },
            {
              ...BASE_CANDLE_LENGTH,
              ...makeRangeValue(3, 7),
              enabled: true
            },
            {
              ...BASE_DEPENDENCY
            }
          ]
        }
      ],
      settings: DEFAULT_ADD_POINTS_SHORT,
      relations: [
        {
          candle_from: 2,
          from_type: CANDLE_CLOSE,
          symbol: '<',
          percent: 100,
          candle_to: 1,
          to_type: CANDLE_OPEN
        }
      ]
    }
  },
  {
    ...BASE_CANDLE_PATTERN,
    kind: 'reverse_belt_hold_short',
    value: {
      candles: [
        {
          ...BASE_PATTERN_RULE,
          colors: [GREEN],
          value: [
            {
              ...HIGH_LENGTH_MIXIN
            },
            {
              ...LOW_LENGTH_MIXIN,
              ...FROM_TO_0_7_MIXIN,
              enabled: true
            },
            {
              ...BASE_CANDLE_LENGTH,
              ...makeRangeValue(3, 7),
              enabled: true
            },
            {
              ...BASE_DEPENDENCY
            }
          ]
        },
        {
          ...BASE_PATTERN_RULE,
          colors: [RED],
          value: [
            {
              ...HIGH_LENGTH_MIXIN
            },
            {
              ...LOW_LENGTH_MIXIN,
              ...FROM_TO_0_7_MIXIN,
              enabled: true
            },
            {
              ...BASE_CANDLE_LENGTH,
              ...makeRangeValue(1, 7),
              enabled: true
            },
            {
              ...BASE_DEPENDENCY
            }
          ]
        },
        {
          ...BASE_PATTERN_RULE,
          colors: [RED],
          value: [
            {
              ...HIGH_LENGTH_MIXIN
            },
            {
              ...LOW_LENGTH_MIXIN,
              ...FROM_TO_0_7_MIXIN,
              enabled: true
            },
            {
              ...BASE_CANDLE_LENGTH,
              ...makeRangeValue(3, 7),
              enabled: true
            },
            {
              ...BASE_DEPENDENCY
            }
          ]
        }
      ],
      settings: DEFAULT_ADD_POINTS_SHORT,
      relations: [
        {
          candle_from: 2,
          from_type: CANDLE_CLOSE,
          symbol: '<=',
          percent: 50,
          candle_to: 1,
          to_type: CANDLE_LENGTH
        },
        {
          candle_from: 2,
          from_type: CANDLE_LOW,
          symbol: '>',
          percent: 100,
          candle_to: 1,
          to_type: CANDLE_OPEN
        },
        {
          candle_from: 3,
          from_type: CANDLE_CLOSE,
          symbol: '>',
          percent: 75,
          candle_to: 1,
          to_type: CANDLE_LENGTH
        },
        {
          candle_from: 3,
          from_type: CANDLE_CLOSE,
          symbol: '<',
          percent: 100,
          candle_to: 2,
          to_type: CANDLE_CLOSE
        }
      ]
    }
  }
];

export const DEFAULT_CANDLE_PATTERN = {
  ...BASE_CANDLE_PATTERN,
  value: {
    candles: [
      { ...DEFAULT_CANDLE_PATTERN_RULE },
      { ...DEFAULT_CANDLE_PATTERN_RULE }
    ],
    settings: {
      ...DEFAULT_ADD_POINTS
    },
    relations: []
  }
}

export const DEFAULT_RELATION = {
  candle_from: 1,
  candle_to: 2,
  from_type: CANDLE_CLOSE,
  to_type: CANDLE_LENGTH,
  percent: 75,
  symbol: '>'
};
