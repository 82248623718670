import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { inject, observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import ModuleStore from 'store/moduleStore';
import BacktestStore from 'store/backtestStore';
import ModulesList from 'components/ModulesList';
import DialogWindow from 'components/DialogWindow';
import TimeFrameEditor from './TimeFrameEditor';
import InstrumentPicker from './InstrumentPicker';
import BackTestModal from 'components/BackTestModal';

import { toRow } from './utils';
import { useNotify } from 'contexts/popup';
import classNames from 'classnames';

import { compose, downloadToFile } from 'helpers';
import ModulesTypeTabs from 'components/ModulesList/ModulesTypeTabs';
import ModulesFilters from 'components/ModulesList/ModulesFilters';
import { ACTIVE_MODULE_TYPE, SAVED_MODULE_TYPE } from './consts';
import { filterConfig, useModules } from 'components/ModulesList/helpers';
import { useStore, useTimezone } from 'hooks';

import './styles.scss';

const ModulesListContainer = ({ moduleStore, backtestStore, type }) => {
  const history = useHistory();
  const intl = useIntl();
  const { tz } = useTimezone();
  const { error } = useNotify();
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [isTfsEditorOpen, setIsTfsEditorOpen] = React.useState(false);
  const [isInstrumentPickerOpen, setIsInstumentPickerOpen] = React.useState(false);
  const [tfsEditorType, setTfsEdiorType] = React.useState('');
  const [obj, setObj] = React.useState({});
  const [isRunBacktestOpen, setIsRunBacktestOpen] = React.useState(false);
  const [filtering, setFiltering] = useState({
    instrument: ' ',
    prearming: ' '
  })

  const { instrumentsStore } = useStore();

  const { instrument, prearming } = filtering;

  const { selectFields } = useModules();

  useEffect(() => {
    moduleStore.getModuleList({ kind: type, instrument, prearming }, true);
  }, [moduleStore, type, instrument, prearming]);

  const deleteHandler = (obj) => {
    setObj(obj);
    setDialogOpen(true);
  };

  const confirmHandler = async () => {
    setDialogOpen(false);
    try {
      await moduleStore.deleteModule(obj.id)
    } catch (e) {
      if (e.data.message) {
        error({ title: 'Error', description: e.data.message });
      }
    }
  };

  const editHandler = (obj) => {
    history.push(`/module/${obj.id}/edit`);
  };

  const editTfsHandler = (obj, type) => {
    setObj(obj);
    setTfsEdiorType(type);
    setIsTfsEditorOpen(true);
  };

  const editInstrumentHandler = obj => {
    setObj(obj);
    setIsInstumentPickerOpen(true);
  };

  const nextModulesHandler = () => {
    moduleStore.getModuleList({ kind: type, instrument, prearming })
  }

  const duplicateHandler = obj => {
    moduleStore.duplicateModule(obj.id);
  };

  const runHandler = (obj) => {
    history.push(`/module/${obj.id}/run`);
  };

  const createHandler = (backtest) => {
    return backtestStore.create(backtest)
  }

  const changeSelectedModule = (module) => {
    moduleStore.setSelectedModule(module);
  };

  useEffect(() => () => {
    moduleStore.selectedModuleList = [];
  }, [type]);

  const downloadHandler = async ({ id, title }) => {
    const response = await moduleStore.downloadModule(id);
    downloadToFile(response.data, `${title}.zip`, 'application/zip')
  };

  const moveTo = async (module, type) => {
    await moduleStore.moveTo(module, type);
    history.push(`/modules/${type}`);
  }

  const handleFiltering = ({ values }) => {
    setFiltering(old => ({ ...old, ...values }));
  }

  const viewHandler = (module) => {
    history.push(`/module/${module.runtimeId}`)
  }

  const changeTitleHandler = async ({ id, title }) => {
    await moduleStore.putModule(id, { moduleName: title });
    moduleStore.filterSelectedModuleList(id)
  }

  const rows = React.useMemo(() => (
    moduleStore.currentModuleList.map(toRow(instrumentsStore.instruments))
  ), [moduleStore.currentModuleList, tz]);

  const title = intl.formatMessage({ id: 'dialog.title.deleteModule' });
  const description = intl.formatMessage(
    { id: 'dialog.description.deleteModule' },
    { moduleName: obj.title }
  );

  const isLoading = moduleStore.isLoading || backtestStore.isLoading;

  const onChange = (value) => {
    history.push(`/modules/${value}`);
  }

  return (
    <>
      <div className="form saved-module">
        <div className="form__card">
          <div className="form__card-content">
            <h2 className="title2 bold">
              <FormattedMessage id={`modules.${type}`} />
            </h2>
            {type !== ACTIVE_MODULE_TYPE && <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <ModulesTypeTabs activeTab={type} onChange={onChange} />
              {type === SAVED_MODULE_TYPE &&
                <button
                  className={classNames(
                    'form-action-btn form-action-btn--spaces btn-uppercased',
                    'main-btn btn-icon--right primary-btn bold'
                  )}
                  type="button"
                  disabled={!moduleStore.selectedModuleList.length || isLoading}
                  onClick={() => setIsRunBacktestOpen(true)}
                >
                  <FormattedMessage id="modules.savedModules.run.button" />
                </button>
              }
            </div>}

            <ModulesFilters config={{ ...filterConfig, selectFields }} getModules={handleFiltering} isLoading={moduleStore.isLoading} />
            <ModulesList
              rows={rows}
              activeTab={type}
              isLoading={isLoading}
              selectedModuleList={moduleStore.selectedModuleList}
              allLoaded={moduleStore.allLoaded}
              handlers={{
                edit: editHandler,
                delete: deleteHandler,
                run: runHandler,
                next: nextModulesHandler,
                changeSelectedModule: changeSelectedModule,
                changeTitle: changeTitleHandler,
                duplicate: duplicateHandler,
                download: downloadHandler,
                editTfs: editTfsHandler,
                editInstrument: editInstrumentHandler,
                moveTo: moveTo,
                view: viewHandler
              }}
            />
          </div>
        </div>
      </div>
      <DialogWindow
        title={title}
        description={description}
        open={dialogOpen}
        handleClose={() => setDialogOpen(false)}
        confirmHandler={confirmHandler}
        error
      />
      <TimeFrameEditor
        open={isTfsEditorOpen}
        module={obj}
        type={tfsEditorType}
        handleClose={() => { setIsTfsEditorOpen(false) }}
      />
      <InstrumentPicker
        module={obj}
        open={isInstrumentPickerOpen}
        showButton={true}
        handleClose={() => { setIsInstumentPickerOpen(false) }}
      />
      <BackTestModal
        open={isRunBacktestOpen}
        selectedModuleList={moduleStore.selectedModuleList}
        handlers={{
          handleClose: () => setIsRunBacktestOpen(false),
          clearSelectedModuleList: moduleStore.clearSelectedModuleList,
          create: createHandler
        }}
      />
    </>
  );
};

ModulesListContainer.propTypes = {
  moduleStore: propTypes.instanceOf(ModuleStore),
  backtestStore: propTypes.instanceOf(BacktestStore),
  type: propTypes.string,
};

const decorator = compose(
  observer,
  inject('moduleStore', 'backtestStore'),
);

export default decorator(ModulesListContainer);
