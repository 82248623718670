import React, { useEffect } from 'react';
import propTypes from 'prop-types';
import classNames from 'classnames';
import { useDropzone } from 'react-dropzone';

import './styles.scss'
import { FormattedMessage } from 'react-intl';

const FilePicker = ({ multiple, acceptType, onAccept }) => {
  const { getRootProps, getInputProps, open, isDragActive, acceptedFiles } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: acceptType,
    multiple
  });

  useEffect(() => {
    if (multiple) {
      onAccept(acceptedFiles);
    } else {
      onAccept(acceptedFiles?.[0]);
    }
  }, [acceptedFiles]);

  return (
    <div className="container">
      <div { ...getRootProps({ className: classNames('dropzone', { focus: isDragActive }) })}>
        <input { ...getInputProps() } />
        {acceptedFiles.length
          ? <FormattedMessage id="modules.fileUploader.chosen" tagName="p" values={{ name: acceptedFiles[0].name }} />
          : <FormattedMessage id="modules.fileUploader.choose" tagName="p" />}
        <button className="primary-btn outline-btn" type="button" onClick={open}>
          <FormattedMessage id="modules.fileUploader.button" />
        </button>
      </div>
    </div>
  );
};

FilePicker.propTypes = {
  onAccept: propTypes.func.isRequired,
  acceptType: propTypes.oneOfType([
    propTypes.string,
    propTypes.arrayOf(propTypes.string)
  ]).isRequired,
  multiple: propTypes.bool
}

FilePicker.defaultProps = {
  multiple: false
}

export default FilePicker;
