export const CANDLESTICK_API_URL = 'runtimes/:dataSource/:runtimeId/candles/:setId';
export const RSI_API_URL = 'runtimes/:dataSource/:runtimeId/indicators/rsi/:setId';
export const STOCH_API_URL = 'runtimes/:dataSource/:runtimeId/indicators/stoch/:setId';
export const CHART_CSV_API_URL = 'runtimes/:dataSource/file_data/:runtimeId/';
export const CURRENT_PRICE_URL = 'candles/current/';
export const INSTUMENTS_URL = 'instruments/';

export const ARMING_LINES_URL = ':position/arming_lines/:timeframe/:runtimeItemId';

export const ALLOWED_KIND_TYPES = ['demo', 'saved', 'active'];
export const LOGS_3S = 'logs/:runtimeItemId'

export const DATA_SOURCE = 'fxcm_ew';
export const DATA_SOURCE_FILE = 'file';
