export const NAV_LINKS = [
  { title: 'nav.tabs.newModule', path: '/module/create' },
  { title: 'nav.tabs.dataFeed', path: '/data/feed' },
  { title: 'nav.tabs.tradeExecution', path: 'trade/execute' },
  { title: 'nav.tabs.modules', path: '/modules/saved' },
  { title: 'nav.tabs.backtest', path: '/backtest/list' },
  { title: 'nav.tabs.activeModules', path: '/modules/active' },
  { title: 'nav.tabs.logicEditor', path: '/logic/editor' },
  { title: 'nav.tabs.logout', path: '/login' }
];
