import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import { SUMMARY_ROWS } from './consts';
import './styles.scss';

const TradeHistorySummary = ({ tradeHistoryData }) => {
  const historyData = tradeHistoryData.total;

  return (
    <div className="trade-history-summary">
      <div className="trade-history-summary__title bold">
        <FormattedMessage
          id='trade.history.summary.title'
        />
      </div>
      <ul className="trade-history-summary__list">
        {SUMMARY_ROWS.map(({ attribute, symbol, additionalParams, formatter }) => {
          if (!historyData) {
            return;
          }
          const value = historyData[attribute];
          const additionalValues = { value };
          if (additionalParams) {
            additionalParams.forEach(({ attribute, fieldName, symbol, formatter }) => {
              const value = historyData[attribute];
              additionalValues[fieldName] = formatter({ value });
            })
          }

          return (
            <li key={attribute} className="trade-history-summary__item">
              <FormattedMessage
                id={`trade.history.summary.${attribute}`}
                values={additionalValues}
              />
              {!additionalParams && <span className="trade-history-summary__item-value">{formatter({ value })}</span>}
            </li>
          )
        })}
      </ul>
    </div>
  );
};

TradeHistorySummary.propTypes = {
  tradeHistoryData: PropTypes.object
};

export default TradeHistorySummary;
