export const INSTRUMENTS_LINKS = [
  { title: 'FX', path: '' },
  { title: 'Cryptocurrency', path: '' },
  { title: 'Commodities', path: '' }
];

export const SIDE_LINKS = [
  { title: 'Alerts', path: '' },
  { title: 'Positions', path: '/open-orders' },
  { title: 'History', path: '/trade-history' },
  { title: 'Fund management', path: '/fund-management' },
  { title: 'Risk Management', path: '/risk-management' },
  { title: 'Trade exclusion', path: '/trade-exclusion' },
  { title: 'Workspace', path: '' }
];
