import React, { useCallback, useEffect, useMemo, useState } from 'react';
import propTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Dialog, DialogContent, MenuItem } from '@material-ui/core';
import { CustomSelect } from 'components/Controls';
import { CloseIcon } from 'components/Svgs';
import { Input } from 'components/Fields';
import { DIMENSION_OPTIONS } from './consts';
import { validateTimeframe } from 'helpers/validators';

const TimeframePicker = ({ open, onClose, onSave, defaultLabel, type, timeframes }) => {
  const { defTimeframe, defDimension } = useMemo(() => {
    const [defTimeframe, label] = defaultLabel.split(' ');
    const defDimension = DIMENSION_OPTIONS.find(({ value }) => value === label);
    return { defTimeframe, defDimension };
  });

  const [timeframe, setTimeframe] = useState(defTimeframe);
  const [dimension, setDimension] = useState(defDimension);

  useEffect(() => {
    setTimeframe(defTimeframe);
    setDimension(defDimension);
  }, [open]);

  const handleClose = useCallback(() => {
    onClose();
  });

  const error = useMemo(() => (
    validateTimeframe(timeframe, timeframes, dimension)
  ), [timeframes, timeframe, dimension]);

  const handleSave = useCallback(() => (onSave({
    label: `${timeframe} ${dimension.value}`,
    value: +timeframe * dimension.multiplier
  })));

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      className="custom-dialog add-frame-form"
    >
      <DialogContent>
        <button
          className="single-icon-btn single-icon-btn--white cross"
          onClick={handleClose}
        >
          <CloseIcon />
        </button>
        <h2 className="title2 bold">
          <FormattedMessage id={`modules.${type}Timeframe`} />
        </h2>
        <div className="add-frame-form__fields">
          <Input
            value={timeframe}
            autoFocus
            name="timeframe"
            onChange={e => setTimeframe(e.target.value)}
            error={error}
            helperText={error}
          />
          <CustomSelect
            name="timeDimension"
            value={dimension.value}
            defaultValue="min"
          >
            {DIMENSION_OPTIONS.map((item) => (
              <MenuItem
                className="custom-select__option"
                key={item.value}
                value={item.value}
                onClick={() => setDimension(item) }
              >
                {item.label}
              </MenuItem>
            ))}
          </CustomSelect>
        </div>
      </DialogContent>
      <div className="custom-dialog__actions">
        <button onClick={handleClose} className="primary-btn outline-btn form-action-btn bold">
          <FormattedMessage id="modules.buttons.cancel" />
        </button>
        <button
          onClick={() => { handleSave(); onClose() }}
          disabled={error}
          className="primary-btn main-btn form-action-btn bold"
        >
          <FormattedMessage id={`modules.buttons.${type}`} />
        </button>
      </div>
    </Dialog>
  );
};

TimeframePicker.propTypes = {
  open: propTypes.bool.isRequired,
  type: propTypes.string.isRequired,
  onSave: propTypes.func.isRequired,
  onClose: propTypes.func.isRequired,
  defaultLabel: propTypes.string.isRequired,
  timeframes: propTypes.array.isRequired,
  validate: propTypes.func
};

export default TimeframePicker;
