export const radiosHandler = ({ form, id, fieldName }) => {
  form.change(fieldName, id);
};

export const checkboxesHandler = ({ form, values, id, fieldName }) => {
  const isSelected = values[fieldName].some((value) => value === id);
  let result = [];
  if (isSelected) {
    if (values[fieldName].length === 1) {
      return;
    }
    result = values[fieldName].filter((value) => value !== id);
  } else {
    result = [...values[fieldName], id];
  }
  form.change(fieldName, result);
};

export const checkFields = ({ values, form }) => {
  if ((!values.timeCycle || values.timeCycle === 'all') && (values.timeframeStart || values.timeframeEnd)) {
    form.reset({ ...values, timeframeStart: '', timeframeEnd: '' })
  }

  if (values.frequencyPeriod !== 'weekly' && values.frequencyPeriod !== 'monthly' && values.frequencyDay) {
    form.reset({ ...values, frequencyDay: '' })
  }

  if (values.frequencyPeriod !== 'monthly' && values.frequencySequence) {
    form.reset({ ...values, frequencySequence: '' })
  }

  if ((values.frequencyPeriod === 'weekly' || values.frequencyPeriod === 'monthly') && !values.frequencyDay) {
    form.reset({ ...values, frequencyDay: 'monday' })
    if (values.frequencyPeriod === 'monthly' && !values.frequencySequence) {
      form.reset({ ...values, frequencySequence: 'first' })
    }
  }
}
