import { computed, makeObservable } from 'mobx';
import IndicatorStore from './baseIndicatorStore';

export default class CandleStore extends IndicatorStore {
  constructor () {
    super(...arguments);
    makeObservable(this, {
      forEChart: computed,
    });
  }

  get forEChart () {
    return this.allData.map(stick => [
      stick.timestamp,
      stick.bid_open,
      stick.bid_close,
      stick.bid_low,
      stick.bid_high,
    ]);
  }
}
