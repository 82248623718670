import React, { useMemo } from 'react';
import { DateTime } from 'luxon';
import propTypes from 'prop-types';
import { useIntl } from 'react-intl';
import cn from 'classnames';
import { Field, Form } from 'react-final-form';
import { TextField } from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';

import { useStore, useTimezone } from 'hooks';
import DialogForm from 'components/DialogForm';
import CustomDateTimePicker from 'components/Calendar';
import SimpleFormControls from 'components/SimpleFormControls';
import { CustomSelect, Input } from 'components/Fields';
import CustomCheckbox from 'components/Controls/CustomCheckbox';
import * as validators from 'helpers/validators';

import { initialValues, actions, days, frequency, sequences, timeCycles } from './consts';
import { checkFields, checkboxesHandler, radiosHandler } from './helpers';

import './styles.scss';
import { CustomRadio } from 'components/Controls';

const TradeExclusionModal = ({ open, handlers, preFilledData, isEdit }) => {
  useTimezone();
  const intl = useIntl();
  const { instrumentsStore } = useStore();
  const onSubmit = (formValue, { reset }) => {
    const convertTime = time => typeof time !== 'string' ? time.toFormat('HH:mm') : time;

    const data = {
      ...formValue,
      instruments: formValue.instruments.map((instrument) => instrument?.id || instrument),
      timeCycle: formValue.timeCycle || null,
      timeframeStart: formValue.timeframeStart || null,
      timeframeEnd: formValue.timeframeEnd || null,
      startTime: convertTime(formValue.startTime),
      finishTime: convertTime(formValue.finishTime),
      frequencyDay: formValue.frequencyDay || null,
      frequencySequence: formValue.frequencySequence || null
    };

    handlers.handleApply({ data }).then(success => success && reset());
  };

  const instruments = useMemo(() => {
    return instrumentsStore.instruments.map((instrument) => ({ ...instrument, value: instrument.label }));
  }, [instrumentsStore.instruments.length]);

  const saveButtonId = isEdit ? 'trade-exclusion.modal.button.save' : 'trade-exclusion.modal.button.add';
  const customSelectStyles = { padding: 0, margin: 0, width: '100%', fontSize: '18px' };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={preFilledData || initialValues}
      validate={(value) => {
        const errors = {};
        errors.timeFrameRange = validators.validateTimeFrameRange(value.timeframeStart, value.timeframeEnd, value.timeCycle)
        errors.startTime = validators.validateIsBeforeDate(value.startTime, value.finishTime);
        errors.action = value.action ? undefined : intl.formatMessage({ id: 'modules.validation.emptyArray' });
        errors.instruments = value.instruments.length ? undefined : intl.formatMessage({ id: 'modules.validation.emptyArray' });

        return errors;
      }}

      render={({ handleSubmit, errors, values, form, valid }) => {
        checkFields({ values, form })
        return (
          <DialogForm
            open={open}
            handleClose={() => { handlers.handleClose(); form.reset(); }}
            customClass="trade-exclusion-modal"
            disableEnforceFocus
            title={intl.formatMessage({ id: 'trade-exclusion.add.button' })}
            controls={
              <SimpleFormControls
                handleCancel={() => { handlers.handleClose(); form.reset() }}
                cancelButtonId={'trade-exclusion.modal.button.back'}
                handleSave={handleSubmit}
                saveButtonId={saveButtonId}
                withCancelIcon
                flexEnd
                disabled={!valid}
              />
            }
            content={
              <form>
                <div className="trade-exclusion-form">
                  <div className="trade-exclusion-form__row trade-exclusion-form__row--column">
                    <div className="trade-exclusion-form__label title">
                      {intl.formatMessage({ id: 'trade-exclusion.modal.label.title' })}
                    </div>
                    <Field
                      autoFocus
                      withHelper
                      formatOnBlur
                      name="title"
                      component={Input}
                      parse={value => value || ''}
                      format={v => v.trim()}
                      validate={validators.validateTitle()}
                      isSmallField
                    />
                  </div>

                  <div className="trade-exclusion-form__row-wrap">
                    <div className="trade-exclusion-form__row">
                      <div className="trade-exclusion-form__label title">
                        {intl.formatMessage({ id: 'trade-exclusion.modal.label.instruments' })}
                      </div>
                      <div className="trade-exclusion-form__field-wrap">
                        <div className="trade-exclusion-form__field trade-exclusion-form__field--wide">
                          <ul className="trade-exclusion-form__list">
                            <Field name={'instruments'} withHelper>
                              {({ input }) => {
                                return instruments.map(({ value, id }) => (
                                  <MenuItem className="trade-exclusion-form__list-item" key={id} value={id} onClick={() => checkboxesHandler({ form, values, id, fieldName: 'instruments' })}>
                                    <CustomCheckbox
                                      checked={values.instruments.some((value) => value === id)}
                                    />
                                    <ListItemText className={'custom-multiple-select__item-text'} primary={value} />
                                  </MenuItem>
                                ))
                              }}
                            </Field>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="trade-exclusion-form__row">
                    <div className="trade-exclusion-form__label title">
                      {intl.formatMessage({ id: 'trade-exclusion.modal.label.time-cycle' })}
                    </div>
                    <div className="trade-exclusion-form__field-wrap">
                      <div className="trade-exclusion-form__field trade-exclusion-form__field--wide">
                        <Field
                          name={'timeCycle'}
                          component={CustomSelect}
                          items={timeCycles}
                          style={customSelectStyles}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="trade-exclusion-form__block-wrap">
                    <div className="trade-exclusion-form__block trade-exclusion-form__block--smallgap">
                      <div className="trade-exclusion-form__row">
                        <div className="trade-exclusion-form__label trade-exclusion-form__label--wide title">
                          {intl.formatMessage({ id: 'trade-exclusion.modal.label.time-frame-range' })}
                        </div>
                        <Field
                          formatOnBlur
                          name="timeframeStart"
                          component={Input}
                          error={errors.timeFrameRange}
                          disabled={!values.timeCycle || values.timeCycle === 'all'}
                          parse={value => parseInt(value) || ''}
                          format={value => parseInt(value) || ''}
                          type='number'
                          isSmallField
                        />
                      </div>
                      <div className="trade-exclusion-form__row-connector">
                        –
                      </div>
                      <div className="trade-exclusion-form__row">
                        <div className="trade-exclusion-form__label trade-exclusion-form__label--wide title">
                          &nbsp;
                        </div>
                        <Field
                          formatOnBlur
                          name="timeframeEnd"
                          component={Input}
                          error={errors.timeFrameRange}
                          disabled={!values.timeCycle || values.timeCycle === 'all'}
                          parse={value => parseInt(value) || ''}
                          format={value => parseInt(value) || ''}
                          type='number'
                          isSmallField
                        />
                      </div>
                    </div>
                    <div className="trade-exclusion-form__error trade-exclusion-form__error">{errors.timeFrameRange}</div>
                  </div>

                  <div className="trade-exclusion-form__block-wrap">
                    <div className="trade-exclusion-form__block">
                      <div className="trade-exclusion-form__row">
                        <div className="trade-exclusion-form__label trade-exclusion-form__label--middle title">
                          {intl.formatMessage({ id: 'trade-exclusion.modal.label.start-time' })}
                        </div>
                        <div className="trade-exclusion-form__field-wrap">
                          <div className="trade-exclusion-form__field">
                            <Field name="startTime">
                              {({ input: { onChange, value } }) => (
                                <CustomDateTimePicker
                                  date={DateTime.fromISO(value, { zone: 'utc' }).setZone('default')}
                                  inputFormat="HH:mm"
                                  views={['hours', 'minutes']}
                                  isMakeUtcOffset={false}
                                  onAccept={date => onChange(date.setZone('utc').toFormat('HH:mm'))}
                                  renderInput={(params) => (
                                    <TextField className={cn('trade-exclusion-form__date-field', {
                                      error: errors.startTime
                                    })} {...params} />
                                  )}
                                />
                              )}
                            </Field>
                          </div>
                        </div>
                      </div>

                      <div className="trade-exclusion-form__row">
                        <div className="trade-exclusion-form__label trade-exclusion-form__label--middle title">
                          {intl.formatMessage({ id: 'trade-exclusion.modal.label.finish-time' })}
                        </div>
                        <div className="trade-exclusion-form__field-wrap">
                          <div className="trade-exclusion-form__field">
                            <Field name="finishTime">
                              {({ input: { onChange, value } }) => (
                                <CustomDateTimePicker
                                  date={DateTime.fromISO(value, { zone: 'utc' }).setZone('default')}
                                  inputFormat="HH:mm"
                                  views={['hours', 'minutes']}
                                  isMakeUtcOffset={false}
                                  onAccept={date => onChange(date.setZone('utc').toFormat('HH:mm'))}
                                  renderInput={(params) => (
                                    <TextField className={cn('trade-exclusion-form__date-field', {
                                      error: errors.startTime
                                    })} {...params} />
                                  )}
                                />
                              )}
                            </Field>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="trade-exclusion-form__error trade-exclusion-form__error">{errors.startTime}</div>
                  </div>

                  <div className="trade-exclusion-form__row">
                    <div className="trade-exclusion-form__label title">
                      {intl.formatMessage({ id: 'trade-exclusion.modal.label.frequency' })}
                    </div>
                    <div className="trade-exclusion-form__field-wrap">
                      <div className="trade-exclusion-form__field trade-exclusion-form__field--wide">
                        <Field
                          name={'frequencyPeriod'}
                          component={CustomSelect}
                          items={frequency}
                          style={{ width: '100%', fontSize: '18px' }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="trade-exclusion-form__block-wrap">
                    <div className="trade-exclusion-form__block">
                      <div className="trade-exclusion-form__row trade-exclusion-form__row--gap">
                        <div className="trade-exclusion-form__label trade-exclusion-form__label--wide title">
                          {intl.formatMessage({ id: 'trade-exclusion.modal.label.day' })}
                        </div>
                        <Field
                          disabled={values.frequencyPeriod !== 'weekly' && values.frequencyPeriod !== 'monthly'}
                          name={'frequencyDay'}
                          component={CustomSelect}
                          items={days}
                          style={customSelectStyles}
                        />
                      </div>

                      <div className="trade-exclusion-form__row trade-exclusion-form__row--gap">
                        <div className="trade-exclusion-form__label trade-exclusion-form__label--wide title">
                          #
                        </div>
                        <Field
                          disabled={values.frequencyPeriod !== 'monthly'}
                          name={'frequencySequence'}
                          component={CustomSelect}
                          items={sequences}
                          style={customSelectStyles}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="trade-exclusion-form__row">
                    <div className="trade-exclusion-form__label bold title">
                      {intl.formatMessage({ id: 'trade-exclusion.modal.label.actions' })}
                    </div>

                    <ul className="trade-exclusion-form__list--column">
                      <Field name={'action'} withHelper>
                        {({ input }) => {
                          return actions.map(({ value, id }) => (
                            <MenuItem className="trade-exclusion-form__list-item" key={id} value={id} onClick={() => radiosHandler({ form, id, fieldName: 'action' })}>
                              <CustomRadio checked={values.action === id} />
                              <ListItemText className={'custom-multiple-select__item-text'} primary={intl.formatMessage({ id: `trade-exclusion.table.${id}` })} />
                            </MenuItem>
                          ))
                        }}
                      </Field>

                      <div className="trade-exclusion-form__error trade-exclusion-form__error--right">{errors.action}</div>
                    </ul>
                  </div>
                </div>
              </form>
            }
          />
        )
      }}
    >
    </Form>
  );
};

TradeExclusionModal.propTypes = {
  open: propTypes.bool,
  handlers: propTypes.objectOf(propTypes.func),
  preFilledData: propTypes.object,
  isEdit: propTypes.bool,
}

export default TradeExclusionModal;
