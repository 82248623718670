import React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import ClearIcon from '@material-ui/icons/Clear';

import { getValues } from 'helpers';
import { CirclePlus, EditIcon } from 'components/Svgs';
import { CustomSwitch } from 'components/Controls';
import './styles.scss';
import { metaKey } from 'globalConstants';
import _ from 'lodash';

const ConditionRow = ({
  field,
  index,
  handleChange,
  handleAdd,
  handleDelete,
  handleClick,
  isDisabled,
  prefix
}) => {
  const isArray = _.isNumber(field?.nestedIndex);
  const isFirstNested = isArray && field.nestedIndex === 0
  const isLastNested = isArray && field.isLastNested
  const isMaxNested = isArray && field.isMaxNested

  const renderAction = () => (isFirstNested || !isArray) ? (
    <CustomSwitch
      edge="start"
      checked={field.enabled}
      inputProps={{
        'aria-labelledby': 'switch-list-label-wifi'
      }}
      name={field.kind}
      onChange={handleChange?.(field)}
      disabled={isDisabled?.(field) || field[metaKey]?.disabledToggle}
    />) : (
    <button className="single-icon-btn" type="button" onClick={handleDelete(field)}>
      <ClearIcon />
    </button>
  )

  const renderName = (nestedIdx = null) => (
    <p style={{ maxWidth: 300 }}>
      <FormattedMessage id={`${prefix}.conditions.${field.kind}`} />
      {isArray && nestedIdx !== 0 && <> ({nestedIdx})</>}
    </p>
  )

  const renderValue = () => (
    <>
      <div className="rs-indicator-list__interval">
        <FormattedMessage
          id="description"
          defaultMessage={field.description || ' '}
          values={getValues(field)}
        />
      </div>
      <button
        type="button"
        className="single-icon-btn"
        onClick={handleClick?.(field, index)}
        disabled={isDisabled?.(field) || !field.enabled}
      >
        <EditIcon />
      </button>
    </>
  )

  const renderAddButton = () => (
    <div
      onClick={handleAdd(field)}
      className="bold single-icon-btn rs-indicator-list__addhint"
    >
      <CirclePlus className="icon" />
      <FormattedMessage id="modules.buttons.add" />
      {renderName(field?.nestedIndex + 1)}
    </div>
  )

  return (
    <>
      <div
        key={index}
        className={classNames('rs-indicator-list__item', {
          unchecked: !(field.enabled ?? true),
          disabled: isDisabled?.(field),
          padding: field[metaKey]?.padding,
          hidden: field[metaKey]?.hidden
        })}
      >
        <div className="switch-container">
          {!field[metaKey]?.withoutToggle && renderAction()}
        </div>
        {prefix && field.kind && renderName(isArray ? field.nestedIndex : null)}
        {!handleClick || field[metaKey]?.boolean || renderValue()}

      </div >
      {isLastNested && !isMaxNested && !isDisabled?.(field) && field.enabled && renderAddButton()}
    </>
  )
};

ConditionRow.propTypes = {
  field: PropTypes.object,
  index: PropTypes.number,
  handleChange: PropTypes.func,
  handleClick: PropTypes.func,
  handleAdd: PropTypes.func,
  handleDelete: PropTypes.func,
  isDisabled: PropTypes.func,
  prefix: PropTypes.string,
  titleId: PropTypes.string
};

export default ConditionRow;
