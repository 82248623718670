import { makeAutoObservable, toJS } from 'mobx';
import { represent, toInternal } from 'helpers/utils';
import { States } from './consts'

export default class BacktestStore {
  backtestList = []
  copyBacktest = null;
  backtestModuleList = []
  selectedModuleList = []
  states = States
  state = States.Pending

  constructor (backtestService) {
    makeAutoObservable(this);
    this.backtestService = backtestService;
  }

  * create (backtest) {
    this.state = this.states.Loading;
    try {
      const formatted = represent(backtest);
      const response = yield this.backtestService.create(formatted);
      yield this.list();
      this.state = this.states.Done;
      return toInternal(response.data);
    } catch (e) {
      this.state = this.states.Error;
      throw e;
    }
  }

  * list () {
    this.state = this.states.Loading;
    try {
      const retrieved = yield this.backtestService.list();
      const formatted = retrieved.data.map(toInternal);
      this.backtestList = formatted;
      this.state = this.states.Done;
      return formatted;
    } catch (e) {
      this.state = this.states.Error;
      throw e;
    }
  }

  * get (id) {
    this.state = this.states.Loading;
    try {
      const retrieved = yield this.backtestService.get(id);
      const formatted = toInternal(retrieved.data)
      // yield this.list();
      this.state = this.states.Done;
      return formatted;
    } catch (e) {
      this.state = this.states.Error;
      throw e;
    }
  }

  * getItem (id, itemId) {
    this.state = this.states.Loading;
    try {
      const retrieved = yield this.backtestService.getItem(id, itemId);
      const formatted = toInternal(retrieved.data)
      this.state = this.states.Done;
      return formatted;
    } catch (e) {
      this.state = this.states.Error;
      throw e;
    }
  }

  * delete (id) {
    this.state = this.states.Loading;
    try {
      yield this.backtestService.delete(id);
      yield this.list();
      this.state = this.states.Done;
    } catch (e) {
      this.state = this.states.Error;
      throw e;
    }
  }

  * getbacktestModuleList (id) {
    this.state = this.states.Loading;
    try {
      yield this.backtestService.getbacktestModuleList(id);
      const retrieved = yield this.backtestService.getbacktestModuleList(id);
      this.backtestModuleList = retrieved.data.map(toInternal);
      this.state = this.states.Done;
    } catch (e) {
      this.state = this.states.Error;
      throw e;
    }
  }

  * getDemoModule (id) {
    this.state = this.states.Loading;
    try {
      const retrieved = yield this.backtestService.getDemoModule(id);
      const formatted = toInternal(retrieved.data)
      this.state = this.states.Done;
      return formatted;
    } catch (e) {
      this.state = this.states.Error;
      throw e;
    }
  }

  setSelectedModuleList = (module) => {
    const isSelected = this.selectedModuleList.some(({ id }) => id === module.id);

    if (isSelected) {
      this.selectedModuleList = this.selectedModuleList.filter(({ id }) => id !== module.id);

      return;
    }

    this.selectedModuleList = [...this.selectedModuleList, module];
  }

  clearSelectedModuleList = () => {
    this.selectedModuleList = []
  }

  get isLoading () {
    return this.state === States.Loading;
  }

  get currentModuleList () {
    return toJS(this.backtestModuleList);
  }
}
