import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import TradeExclusionTable from 'components/TradeExclusionTable';
import TradeExclusionModal from 'components/TradeExclusionModal';
import { HEADER_COLUMNS as headerColumns, ROW_COLUMNS as rowColumns } from './consts';
import { useStore } from 'hooks';
import { useControls } from './hooks';

import './styles.scss';

const TradeExclusion = () => {
  const { instrumentsStore, tradeExclusionStore } = useStore();
  const {
    handlers: {
      handlerOpen,
      handlerClose,
      handlerApply,
      handlerDelete,
      handlerToggle,
    },
    state: {
      isDialogOpen,
      preFilledData,
      isEdit
    },
  } = useControls({ tradeExclusionStore });

  useEffect(() => {
    tradeExclusionStore.getTradeExclusions();
  }, []);

  return (
    <div className="form trade-exclusion-container">
      <div className="form__card">
        <div className="form__card-content">
          <div className="trade-exclusion-container__title-wrap">
            <h2 className="title2 bold trade-exclusion-container__title">
              <FormattedMessage id="trade-exclusion.page.title" />
            </h2>
            <button className="main-btn secondary-btn bold" onClick={() => handlerOpen()}>
              <FormattedMessage id='trade-exclusion.add.button' />
            </button>
          </div>

          <TradeExclusionTable
            headerColumns={headerColumns}
            rowColumns={rowColumns}
            data={tradeExclusionStore.tradeExclusions}
            instruments={instrumentsStore.instruments}
            handlers={{
              toggle: handlerToggle,
              edit: handlerOpen,
              delete: handlerDelete,
            }}
          />
        </div>
      </div>

      <TradeExclusionModal
        isEdit={isEdit}
        preFilledData={preFilledData}
        open={isDialogOpen}
        handlers={{
          handleClose: handlerClose,
          handleApply: handlerApply
        }}
      />
    </div>
  );
};

export default observer(TradeExclusion);
