import * as mobx from 'mobx';
import { moduleStoreBuilder } from 'store/moduleStore/utils';
import { backtestStoreBuilder } from 'store/backtestStore/utils';

import { SOCKET_IO_CONFIG } from 'services/socketIOService/consts';
import { tradeHistoryStoreBuilder } from 'store/tradeHistory/utils';
import { tradeOpenOrdersStoreBuilder } from 'store/tradeOpenOrders/utils';
import * as providers from 'providers/apiHistoryProvider';
import AuthStore from 'store/authStore';
import { logciEditorStoreBuilder } from 'store/logicEditorStore/utils';
import { fundManagementStoreBuilder } from 'store/fundManagementStore/utils';
import { lastItemStoreBuilder } from 'store/lastItem/utils';

import { riskManagementStoreBuilder } from '../riskManagementStore/utils';
import { tradeExclusionStoreBuilder } from '../tradeExclusionStore/utils';
import ChartStore from 'store/chartStore';
import InstrumentsStore from 'store/instrumentsStore';
import LogsStore from 'store/logsStore';

const stores = {
  chartStore: new ChartStore(),
  moduleStore: moduleStoreBuilder(),
  backtestStore: backtestStoreBuilder(),
  tradeHistoryStore: tradeHistoryStoreBuilder(),
  logicEditorStore: logciEditorStoreBuilder(),

  paintingLogsCandleCloseStore: {
    long: new LogsStore(providers.logsProvider, { positions: 'long', indicator: 'candle_close' }),
    short: new LogsStore(providers.logsProvider, { positions: 'short', indicator: 'candle_close' }),
  },
  wholeSequenceLogsStore: {
    long: new LogsStore(providers.logsProvider, { position: 'long' }),
    short: new LogsStore(providers.logsProvider, { position: 'short' }),
  },

  logsStochLongPrearmingStore: new LogsStore(providers.logsProvider, { position: 'long', indicator: 'stoch_prearming' }),
  logsStochShortPrearmingStore: new LogsStore(providers.logsProvider, { position: 'short', indicator: 'stoch_prearming' }),
  logsRsiLongStore: new LogsStore(providers.logsProvider, { position: 'long', indicator: 'rsi_trigger' }),
  logsRsiShortStore: new LogsStore(providers.logsProvider, { position: 'short', indicator: 'rsi_trigger' }),
  logsCandleCloseLongStore: new LogsStore(providers.logsProvider, { position: 'long', indicator: 'candle_close' }),
  logsCandleCloseShortStore: new LogsStore(providers.logsProvider, { position: 'short', indicator: 'candle_close' }),

  authStore: new AuthStore(),
  demoFundManagementStore: fundManagementStoreBuilder('demo'),
  activeFundManagementStore: fundManagementStoreBuilder('active'),
  tradeOpenOrdersStore: tradeOpenOrdersStoreBuilder(),
  lastItemStore: lastItemStoreBuilder(SOCKET_IO_CONFIG.openOrders, providers.currentPrices),
  instrumentsStore: new InstrumentsStore(providers.instruments),
  riskManagementStore: riskManagementStoreBuilder(),
  tradeExclusionStore: tradeExclusionStoreBuilder(),
};

// assign root property as root store of all stores
const setRoot = _stores => {
  Object.values(_stores).forEach(store => {
    if ('long' in store && 'short' in store) {
      setRoot(store);
    } else {
      store.root = stores;
    }
  });
}
setRoot(stores);
stores.authStore.triggerPostLogin();

window.rootStore = stores;
window.mobx = mobx;

export default stores;
