import _ from 'lodash';

export function syncEntrySystemTripleLines (values) {
  const entrySystem = _.keyBy(values.entrySystemConditions, 'kind')

  entrySystem.short_triple_tolerance.value.below_line = { ...entrySystem.long_triple_tolerance.value.below_line }
  entrySystem.short_triple_tolerance.value.above_line = { ...entrySystem.long_triple_tolerance.value.above_line }
  entrySystem.short_triple_close.value.below_line = { ...entrySystem.long_triple_close.value.below_line }
  entrySystem.short_triple_close.value.above_line = { ...entrySystem.long_triple_close.value.above_line }

  values.entrySystemConditions = Object.values(entrySystem)
  return values
}
