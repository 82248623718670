import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import CloseIcon from '@material-ui/icons/Close';

import './styles.scss';

const DialogWindow = ({
  title,
  description,
  open,
  onClose,
  handleClose,
  confirmHandler,
  customClassName,
  error,
  noText,
  yesText
}) => {
  return (
    <Dialog
      className={classNames('custom-dialog', customClassName, { error })}
      open={open}
      onClose={onClose ?? handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        {onClose && (
          <button
            className="single-icon-btn single-icon-btn--white cross"
            onClick={onClose}
          >
            <CloseIcon />
          </button>
        )}
        <h2 className="title2 bold">{title}</h2>
        <DialogContentText id="alert-dialog-description">
          {description}
        </DialogContentText>
      </DialogContent>
      <div className="custom-dialog__actions">
        {handleClose && (
          <button onClick={handleClose} className="primary-btn outline-btn form-action-btn bold">
            {noText ?? 'No'}
          </button>
        )}
        {confirmHandler && (
          <button
            autoFocus
            onClick={confirmHandler}
            className="primary-btn main-btn form-action-btn bold"
          >
            {yesText ?? 'Yes'}
          </button>
        )}
      </div>
    </Dialog>
  );
};

DialogWindow.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  handleClose: PropTypes.func,
  confirmHandler: PropTypes.func,
  customClassName: PropTypes.string,
  error: PropTypes.bool,
  noText: PropTypes.string,
  yesText: PropTypes.string
};

export default DialogWindow;
