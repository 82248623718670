import React from 'react';

const ArrowIcon = ({ ...props }) => {
  return (
    <svg {...props} viewBox="0 0 14 12" fill="none">
      <path
        d="M13.5127 6.30371C13.5127 6.00293 13.3896 5.68848 13.1914 5.49707L9.01465 1.32715C8.78906 1.09473 8.51562 0.978516 8.25586 0.978516C7.59961 0.978516 7.16895 1.42969 7.16895 2.01758C7.16895 2.35254 7.31934 2.60547 7.52441 2.80371L9.00781 4.28027L10.0811 5.27148L8.73438 5.20312H1.80273C1.1123 5.20312 0.647461 5.64062 0.647461 6.30371C0.647461 6.95996 1.1123 7.4043 1.80273 7.4043H8.73438L10.0811 7.33594L9.00781 8.32715L7.52441 9.79688C7.31934 9.99512 7.16895 10.248 7.16895 10.583C7.16895 11.1709 7.59961 11.6221 8.25586 11.6221C8.51562 11.6221 8.78906 11.5059 9.01465 11.2803L13.1914 7.11035C13.3896 6.91211 13.5127 6.59766 13.5127 6.30371Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ArrowIcon;
