/* eslint-disable camelcase */
import React from 'react'

export const belt_hold_long = (
  <svg
    width="1130"
    height="1130"
    viewBox="0 0 1130 1130"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="238" y="104" width="178" height="696" fill="#FF0000" />
    <rect x="316" y="104" width="22" height="744" fill="#FF0000" />
    <rect x="730" y="134" width="178" height="928" fill="#17C147" />
    <rect x="807" width="24" height="1062" fill="#17C147" />
    <rect x="484" y="818" width="178" height="261" fill="#FF0000" />
    <rect x="562" y="671" width="22" height="432" fill="#FF0000" />
  </svg>
);
export const belt_hold_short = (
  <svg
    width="1130"
    height="1130"
    viewBox="0 0 1130 1130"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1031_581)">
      <rect
        width="178"
        height="696"
        transform="matrix(1 0 0 -1 238 999)"
        fill="#17C147"
      />
      <rect
        width="22"
        height="744"
        transform="matrix(1 0 0 -1 316 999)"
        fill="#17C147"
      />
      <rect
        width="178"
        height="945"
        transform="matrix(1 0 0 -1 730 969)"
        fill="#FF0000"
      />
      <rect
        width="24"
        height="1062"
        transform="matrix(1 0 0 -1 807 1103)"
        fill="#FF0000"
      />
      <rect
        width="178"
        height="279"
        transform="matrix(1 0 0 -1 484 303)"
        fill="#17C147"
      />
      <rect
        width="22"
        height="432"
        transform="matrix(1 0 0 -1 562 432)"
        fill="#17C147"
      />
    </g>
    <defs>
      <clipPath id="clip0_1031_581">
        <rect width="1130" height="1130" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export const cloud_cover_long = (
  <svg
    width="1130"
    height="1130"
    viewBox="0 0 1130 1130"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="318" y="135" width="178" height="859" fill="#FF0000" />
    <rect x="396" width="22" height="1130" fill="#FF0000" />
    <rect x="601" y="244" width="178" height="750" fill="#17C147" />
    <rect x="679" y="1" width="22" height="1129" fill="#17C147" />
  </svg>
);
export const cloud_cover_short = (
  <svg
    width="1130"
    height="1130"
    viewBox="0 0 1130 1130"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="318" y="135" width="178" height="859" fill="#17C147" />
    <rect x="396" width="22" height="1130" fill="#17C147" />
    <rect x="601" y="135" width="178" height="727" fill="#FF0000" />
    <rect x="679" y="59" width="22" height="865" fill="#FF0000" />
  </svg>
);
export const double_d_bend_long = (
  <svg
    width="1130"
    height="1130"
    viewBox="0 0 1130 1130"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="142" y="315" width="178" height="649" fill="#FF0000" />
    <rect x="220" y="149" width="22" height="981" fill="#FF0000" />
    <rect x="820" y="166" width="178" height="648" fill="#17C147" />
    <rect x="897" width="24" height="980" fill="#17C147" />
    <rect x="368" y="885" width="178" height="81" fill="#17C147" />
    <rect x="446" y="721" width="22" height="409" fill="#17C147" />
    <rect x="594" y="820" width="178" height="65" fill="#17C147" />
    <rect x="671" y="522" width="23" height="398" fill="#17C147" />
  </svg>
);
export const double_d_bend_short = (
  <svg
    width="1130"
    height="1130"
    viewBox="0 0 1130 1130"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="142" y="246" width="178" height="718" fill="#17C147" />
    <rect x="220" y="149" width="22" height="981" fill="#17C147" />
    <rect x="820" y="361" width="178" height="453" fill="#FF0000" />
    <rect x="897" width="24" height="980" fill="#FF0000" />
    <rect x="594" y="312" width="178" height="49" fill="#FF0000" />
    <rect x="672" width="22" height="409" fill="#FF0000" />
    <rect
      x="546.5"
      y="315.704"
      width="178"
      height="69.8921"
      transform="rotate(-179.738 546.5 315.704)"
      fill="#FF0000"
    />
    <rect
      x="468"
      y="643.457"
      width="23"
      height="398"
      transform="rotate(-179.738 468 643.457)"
      fill="#FF0000"
    />
  </svg>
);
export const dumpling_bottom_long = (
  <svg
    width="1130"
    height="1130"
    viewBox="0 0 1130 1130"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="142" y="499" width="178" height="518" fill="#FF0000" />
    <rect x="220" y="430" width="22" height="649" fill="#FF0000" />
    <rect x="821" y="50" width="178" height="998" fill="#17C147" />
    <rect x="898" width="24" height="1092" fill="#17C147" />
    <rect x="368" y="862" width="178" height="155" fill="#17C147" />
    <rect x="446" y="815" width="22" height="315" fill="#17C147" />
    <path d="M594 862H772V1048H594V862Z" fill="#FF0000" />
    <rect x="671" y="535" width="23" height="557" fill="#FF0000" />
  </svg>
);
export const dumpling_top_short = (
  <svg
    width="1130"
    height="1130"
    viewBox="0 0 1130 1130"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1031_651)">
      <rect
        x="320.879"
        y="617.997"
        width="178"
        height="518"
        transform="rotate(179.815 320.879 617.997)"
        fill="#17C147"
      />
      <rect
        x="243.102"
        y="687.249"
        width="22"
        height="649"
        transform="rotate(179.815 243.102 687.249)"
        fill="#17C147"
      />
      <rect
        x="1002.27"
        y="1080"
        width="178"
        height="998.206"
        transform="rotate(179.815 1002.27 1080)"
        fill="#FF0000"
      />
      <rect
        x="925.426"
        y="1130.24"
        width="24"
        height="1144.14"
        transform="rotate(179.815 925.426 1130.24)"
        fill="#FF0000"
      />
      <rect
        x="546.867"
        y="266.999"
        width="178"
        height="167.737"
        transform="rotate(179.815 546.867 266.999)"
        fill="#FF0000"
      />
      <rect
        x="469.02"
        y="314.251"
        width="22"
        height="315"
        transform="rotate(179.815 469.02 314.251)"
        fill="#FF0000"
      />
      <rect
        x="772.746"
        y="269.211"
        width="178"
        height="187.213"
        transform="rotate(179.815 772.746 269.211)"
        fill="#17C147"
      />
      <rect
        x="696.625"
        y="541.337"
        width="23"
        height="503.09"
        transform="rotate(179.815 696.625 541.337)"
        fill="#17C147"
      />
    </g>
    <defs>
      <clipPath id="clip0_1031_651">
        <rect width="1130" height="1130" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export const engulfing_long = (
  <svg
    width="1130"
    height="1130"
    viewBox="0 0 1130 1130"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="318" y="599" width="178" height="418" fill="#FF0000" />
    <rect x="396" y="434" width="22" height="634" fill="#FF0000" />
    <rect x="601" y="244" width="178" height="773" fill="#17C147" />
    <rect x="679" y="1" width="22" height="1113" fill="#17C147" />
  </svg>
);
export const engulfing_short = (
  <svg
    width="1130"
    height="1130"
    viewBox="0 0 1130 1130"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="333" y="388" width="178" height="435" fill="#17C147" />
    <rect
      width="22"
      height="687"
      transform="matrix(1 0 0 -1 411 874)"
      fill="#17C147"
    />
    <rect x="601" y="388" width="178" height="704" fill="#FF0000" />
    <rect x="679" y="102" width="22" height="1012" fill="#FF0000" />
  </svg>
);
export const evening_doji_star_short = (
  <svg
    width="1130"
    height="1130"
    viewBox="0 0 1130 1130"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="892"
      y="1130"
      width="178"
      height="899"
      transform="rotate(-180 892 1130)"
      fill="#FF0000"
    />
    <rect
      x="814"
      y="1130"
      width="22"
      height="1042"
      transform="rotate(-180 814 1130)"
      fill="#FF0000"
    />
    <rect
      x="393"
      y="935"
      width="178"
      height="631"
      transform="rotate(-180 393 935)"
      fill="#17C147"
    />
    <rect
      x="316"
      y="1006"
      width="24"
      height="848"
      transform="rotate(-180 316 1006)"
      fill="#17C147"
    />
    <rect
      x="646"
      y="304"
      width="178"
      height="73"
      transform="rotate(-180 646 304)"
      fill="#17C147"
    />
    <rect
      x="568"
      y="375"
      width="22"
      height="329"
      transform="rotate(-180 568 375)"
      fill="#17C147"
    />
  </svg>
);
export const evening_star_short = (
  <svg
    width="1130"
    height="1130"
    viewBox="0 0 1130 1130"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="892"
      y="991.133"
      width="178"
      height="518.807"
      transform="rotate(-180 892 991.133)"
      fill="#FF0000"
    />
    <rect
      x="814"
      y="1130"
      width="22"
      height="701"
      transform="rotate(-180 814 1130)"
      fill="#FF0000"
    />
    <rect
      x="400"
      y="1041"
      width="178"
      height="472"
      transform="rotate(-180 400 1041)"
      fill="#17C147"
    />
    <rect
      x="323"
      y="1041"
      width="24"
      height="561"
      transform="rotate(-180 323 1041)"
      fill="#17C147"
    />
    <rect
      x="646"
      y="569"
      width="178"
      height="104"
      transform="rotate(-180 646 569)"
      fill="#17C147"
    />
    <rect
      x="568"
      y="665"
      width="22"
      height="638"
      transform="rotate(-180 568 665)"
      fill="#17C147"
    />
  </svg>
);
export const ladder_bottom_long = (
  <svg
    width="1130"
    height="1130"
    viewBox="0 0 1130 1130"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="238" y="218" width="178" height="680" fill="#FF0000" />
    <rect x="316" y="75" width="22" height="941" fill="#FF0000" />
    <rect x="730" y="134" width="178" height="845" fill="#17C147" />
    <rect x="807" width="24" height="1130" fill="#17C147" />
    <rect x="484" y="898" width="178" height="81" fill="#FF0000" />
    <rect x="562" y="328" width="22" height="775" fill="#FF0000" />
  </svg>
);
export const ladder_top_short = (
  <svg
    width="1130"
    height="1130"
    viewBox="0 0 1130 1130"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1031_571)">
      <rect
        x="415.832"
        y="904"
        width="178"
        height="680"
        transform="rotate(179.94 415.832 904)"
        fill="#17C147"
      />
      <rect
        x="337.98"
        y="1047.08"
        width="22"
        height="941"
        transform="rotate(179.94 337.98 1047.08)"
        fill="#17C147"
      />
      <rect
        x="909.039"
        y="996"
        width="178"
        height="845"
        transform="rotate(179.94 909.039 996)"
        fill="#FF0000"
      />
      <rect
        x="832.18"
        y="1130.08"
        width="24"
        height="1130"
        transform="rotate(179.94 832.18 1130.08)"
        fill="#FF0000"
      />
      <rect
        x="661.645"
        y="223.667"
        width="178"
        height="72.7596"
        transform="rotate(179.94 661.645 223.667)"
        fill="#17C147"
      />
      <rect
        x="584.246"
        y="801.989"
        width="22"
        height="775"
        transform="rotate(179.94 584.246 801.989)"
        fill="#17C147"
      />
    </g>
    <defs>
      <clipPath id="clip0_1031_571">
        <rect width="1130" height="1130" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export const morning_doji_star_long = (
  <svg
    width="1130"
    height="1130"
    viewBox="0 0 1130 1130"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="730" y="89" width="178" height="715" fill="#17C147" />
    <rect x="807" width="24" height="849" fill="#17C147" />
    <rect x="238" y="118" width="178" height="635" fill="#FF0000" />
    <rect x="316" width="22" height="809" fill="#FF0000" />
    <rect x="484" y="753" width="178" height="51" fill="#FF0000" />
    <rect x="562" y="501" width="22" height="602" fill="#FF0000" />
  </svg>
);
export const morning_star_long = (
  <svg
    width="1130"
    height="1130"
    viewBox="0 0 1130 1130"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="238" y="125" width="178" height="467" fill="#FF0000" />
    <rect x="316" width="22" height="631" fill="#FF0000" />
    <rect x="730" y="89" width="178" height="576" fill="#17C147" />
    <rect x="807" y="89" width="24" height="576" fill="#17C147" />
    <rect x="484" y="592" width="178" height="73" fill="#FF0000" />
    <rect x="562" y="465" width="22" height="638" fill="#FF0000" />
  </svg>
);
export const reverse_belt_hold_long = (
  <svg
    width="1130"
    height="1130"
    viewBox="0 0 1130 1130"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="238" y="188" width="178" height="746" fill="#FF0000" />
    <rect x="316" width="22" height="1016" fill="#FF0000" />
    <rect x="730" y="262" width="178" height="287" fill="#17C147" />
    <rect x="807" y="65" width="24" height="1065" fill="#17C147" />
    <rect x="484" y="549" width="178" height="385" fill="#17C147" />
    <rect x="562" y="478" width="22" height="576" fill="#17C147" />
  </svg>
);
export const reverse_belt_hold_short = (
  <svg
    width="1130"
    height="1130"
    viewBox="0 0 1130 1130"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="238" y="247" width="178" height="787" fill="#17C147" />
    <rect x="316" y="16" width="22" height="1093" fill="#17C147" />
    <rect
      width="178"
      height="500"
      transform="matrix(1 0 0 -1 730 1017)"
      fill="#FF0000"
    />
    <rect
      width="24"
      height="769"
      transform="matrix(1 0 0 -1 807 1090)"
      fill="#FF0000"
    />
    <rect
      width="178"
      height="258"
      transform="matrix(1 0 0 -1 484 505)"
      fill="#FF0000"
    />
    <rect
      width="22"
      height="629"
      transform="matrix(1 0 0 -1 562 629)"
      fill="#FF0000"
    />
  </svg>
);
export const three_inside_down_short = (
  <svg
    width="1130"
    height="1130"
    viewBox="0 0 1130 1130"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1031_631)">
      <rect
        x="416"
        y="584"
        width="178"
        height="356"
        transform="rotate(180 416 584)"
        fill="#17C147"
      />
      <rect
        x="338"
        y="832"
        width="22"
        height="707"
        transform="rotate(180 338 832)"
        fill="#17C147"
      />
      <rect x="730" y="479" width="178" height="399" fill="#FF0000" />
      <rect x="807" y="202" width="24" height="928" fill="#FF0000" />
      <rect
        x="662"
        y="479"
        width="178"
        height="251"
        transform="rotate(-180 662 479)"
        fill="#FF0000"
      />
      <rect
        x="583.797"
        y="613.516"
        width="22"
        height="614"
        transform="rotate(-180 583.797 613.516)"
        fill="#FF0000"
      />
    </g>
    <defs>
      <clipPath id="clip0_1031_631">
        <rect width="1130" height="1130" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export const three_inside_up_long = (
  <svg
    width="1130"
    height="1130"
    viewBox="0 0 1130 1130"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="238" y="557" width="178" height="356" fill="#FF0000" />
    <rect x="316" y="309" width="22" height="707" fill="#FF0000" />
    <rect x="730" y="225" width="178" height="451" fill="#17C147" />
    <rect x="807" width="24" height="928" fill="#17C147" />
    <rect x="484" y="651" width="178" height="277" fill="#17C147" />
    <rect x="562" y="516" width="22" height="614" fill="#17C147" />
  </svg>
);
export const three_outside_down_short = (
  <svg
    width="1130"
    height="1130"
    viewBox="0 0 1130 1130"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="907.938"
      y="906.819"
      width="178"
      height="321.561"
      transform="rotate(-179.941 907.938 906.819)"
      fill="#FF0000"
    />
    <rect
      x="829.898"
      y="1129.92"
      width="22"
      height="601.432"
      transform="rotate(-179.941 829.898 1129.92)"
      fill="#FF0000"
    />
    <rect
      x="415.996"
      y="407.233"
      width="178"
      height="248.051"
      transform="rotate(-179.941 415.996 407.233)"
      fill="#17C147"
    />
    <rect
      x="338.766"
      y="632.906"
      width="24"
      height="617.157"
      transform="rotate(-179.941 338.766 632.906)"
      fill="#17C147"
    />
    <rect
      x="661.824"
      y="589.396"
      width="178"
      height="438.213"
      transform="rotate(-179.941 661.824 589.396)"
      fill="#FF0000"
    />
    <rect
      x="583.559"
      y="845.103"
      width="22"
      height="845"
      transform="rotate(-179.941 583.559 845.103)"
      fill="#FF0000"
    />
  </svg>
);
export const three_outside_up_long = (
  <svg
    width="1130"
    height="1130"
    viewBox="0 0 1130 1130"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="238" y="774" width="178" height="191" fill="#FF0000" />
    <rect x="316" y="466" width="22" height="646" fill="#FF0000" />
    <rect x="730" y="285" width="178" height="348" fill="#17C147" />
    <rect x="807" width="24" height="731" fill="#17C147" />
    <rect x="484" y="633" width="178" height="332" fill="#17C147" />
    <rect x="562" y="285" width="22" height="845" fill="#17C147" />
  </svg>
);
export const tristar_long = (
  <svg
    width="1130"
    height="1130"
    viewBox="0 0 1130 1130"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="722" y="469" width="178" height="54" fill="#17C147" />
    <rect x="799" y="357" width="24" height="260" fill="#17C147" />
    <rect x="230" y="617" width="178" height="55" fill="#17C147" />
    <rect x="308" y="429" width="22" height="289" fill="#17C147" />
    <rect x="476" y="552" width="178" height="51" fill="#17C147" />
    <rect x="554" y="334" width="22" height="462" fill="#17C147" />
  </svg>
);
export const tristar_short = (
  <svg
    width="1130"
    height="1130"
    viewBox="0 0 1130 1130"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="178"
      height="54"
      transform="matrix(-1 0 0 1 408 469)"
      fill="#FF0000"
    />
    <rect
      width="24"
      height="216"
      transform="matrix(-1 0 0 1 331 357)"
      fill="#FF0000"
    />
    <rect
      width="178"
      height="55"
      transform="matrix(-1 0 0 1 900 617)"
      fill="#FF0000"
    />
    <rect
      width="22"
      height="289"
      transform="matrix(-1 0 0 1 822 429)"
      fill="#FF0000"
    />
    <rect
      width="178"
      height="51"
      transform="matrix(-1 0 0 1 654 552)"
      fill="#FF0000"
    />
    <rect
      width="22"
      height="338"
      transform="matrix(-1 0 0 1 576 334)"
      fill="#FF0000"
    />
  </svg>
);
export const twin_tower_long = (
  <svg
    width="1130"
    height="1130"
    viewBox="0 0 1130 1130"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="318" y="244" width="178" height="827" fill="#FF0000" />
    <rect x="396" y="192" width="22" height="938" fill="#FF0000" />
    <rect x="601" y="244" width="178" height="827" fill="#17C147" />
    <rect x="679" y="1" width="22" height="1113" fill="#17C147" />
  </svg>
);
export const twin_tower_short = (
  <svg
    width="1130"
    height="1130"
    viewBox="0 0 1130 1130"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="178"
      height="827"
      transform="matrix(-1 0 0 1 812 244)"
      fill="#FF0000"
    />
    <rect
      width="22"
      height="1091"
      transform="matrix(-1 0 0 1 734 39)"
      fill="#FF0000"
    />
    <rect
      width="178"
      height="798"
      transform="matrix(-1 0 0 1 529 244)"
      fill="#17C147"
    />
    <rect
      width="22"
      height="1105"
      transform="matrix(-1 0 0 1 451 1)"
      fill="#17C147"
    />
  </svg>
);
