import { makeAutoObservable } from 'mobx';
import { represent, toInternal } from 'helpers/utils';
import { States } from './consts'
import { DateTime } from 'luxon';
import _ from 'lodash';

export default class TradeHistoryStore {
  POLLING_SPEED = 10000

  tradeHistoryReportData = {};
  tradeHistoryData = {};

  filePollingId = null;

  states = States;
  state = States.Pending;

  constructor (tradeHistoryService) {
    makeAutoObservable(this);
    this.tradeHistoryService = tradeHistoryService;
  }

  getPollingKey (id = null) {
    return _.isNull(id) ? 'polling/history' : `polling/report_${id}`
  }

  getPolling (key) {
    return localStorage.getItem(key)
  }

  stopPolling () {
    if (this.filePollingId) {
      clearInterval(this.filePollingId)
      this.filePollingId = null
    }
  }

  deletePolling (key) {
    this.stopPolling()
    localStorage.removeItem(key)
  }

  * setPolling (key, taskId, callback) {
    localStorage.setItem(key, taskId)
    this.filePollingId = setInterval(async () => await this.filePolling(key, taskId, callback), this.POLLING_SPEED)
  }

  * filePolling (key, taskId, callback) {
    const response = yield this.tradeHistoryService.pollingXlsx(represent({ taskId }))
    if (response.status === 200) {
      yield callback(response.data)
      this.deletePolling(key)
    }
  }

  * getTradeHistoryReport (id, params) {
    this.state = this.states.Loading;
    try {
      const response = yield this.tradeHistoryService.getTradeHistoryReport(id, represent(params));
      this.tradeHistoryReportData = toInternal(response.data);
      this.state = this.states.Done;
    } catch (e) {
      this.state = this.states.Error;
      throw e;
    }
  }

  * getTradeHistory (params) {
    this.state = this.states.Loading;
    try {
      // TO DO: implement a better solution for close_order_range_to param
      if (DateTime.now().hasSame(DateTime.fromMillis(params.closeOrderRangeTo), 'day')) {
        params.closeOrderRangeTo = DateTime.now().toMillis()
      }
      const response = yield this.tradeHistoryService.getTradeHistory(represent(params));
      this.tradeHistoryData = toInternal(response.data);
      this.tradeHistoryData.filters.versions = response?.data?.filters?.versions || [];
      this.state = this.states.Done;
    } catch (e) {
      this.state = this.states.Error;
      throw e;
    }
  }

  * downloadTradeReport (id, params) {
    this.state = this.states.Loading;
    try {
      const response = yield this.tradeHistoryService.download(id, represent({
        ...params,
        format: 'xlsx',
        tzOffset: DateTime.now().offset,
      }));
      this.state = this.states.Done;
      return toInternal(response.data)
    } catch (e) {
      this.state = this.states.Error;
      throw e;
    }
  }

  * downloadTradeHistory (params) {
    this.state = this.states.Loading;
    try {
      const response = yield this.tradeHistoryService.downloadTradeHistory(represent({
        ...params,
        format: 'xlsx',
        tzOffset: DateTime.now().offset,
      }));
      this.state = this.states.Done;
      return toInternal(response.data)
    } catch (e) {
      this.state = this.states.Error;
      throw e;
    }
  }

  * deleteAll () {
    this.state = this.states.Loading;
    try {
      yield this.tradeHistoryService.deleteAll();
      this.tradeHistoryData = {};
      yield this.tradeHistoryService.getTradeHistory();
      this.state = this.states.Done;
    } catch (e) {
      this.state = this.states.Error;
      throw e;
    }
  }

  get ready () {
    return this.state === this.states.Done;
  }

  get downloading () {
    return !_.isNull(this.filePollingId)
  }
}
