import React from 'react';
import _ from 'lodash';
import { formatUnixTimestamp, roundNum, toPrecision } from 'helpers';
import { ANGLE_PRECISION, COMMON_PRECISION } from 'globalConstants';
import { intl } from 'translations/provider';

const withSetting = (name, value) => {
  if (_.isNil(value)) return name;
  if (_.isObject(value)) {
    // min - max
    if (value.minValue && value.maxValue) {
      return `${name} (${value.minValue}-${value.maxValue})`;
      // for timeout disarm
    } else if (value.blocks) {
      return `${name} (${value.blocks}/${value.timestamps})`
      // angle for peaks & troughs
    } else if (value.angle) {
      return `${name} (` +
        `${value.angle.minValue}\u00b0-${value.angle.maxValue}\u00b0, ` +
        `${value.left_depth.minValue}-${value.left_depth.maxValue}Pts, ` +
        `${value.right_depth.minValue}-${value.right_depth.maxValue}Pts)`
    } else {
      return name
    }
  }
  return `${name} (${value})`;
}

export const filters = {
  common: (accessor, paramsStates) => paramsStates?.[`${accessor}_enabled`],
  angle: (accessor, paramsStates) => (
    paramsStates?.[`${accessor}_angle_enabled`] &&
    paramsStates?.[`${accessor}_left_depth_enabled`] &&
    paramsStates?.[`${accessor}_right_depth_enabled`]
  ),
  patterns: (accessor, paramsStates, logs) => (
    accessor === 'candle_close'
    // logs.find(param => ({
    //   candle_close: accessor.startsWith('p'),
    //   candle_pattern: accessor.startsWith('c'),
    // }[param.kind]))
  ),
  prearmingStoch: (module, controls) => (
    module.clientModuleParams.stochTimeframes?.some(tf => (
      tf.type.includes('prearming') &&
      tf.value === controls.stochPeriod
    ))
  ),
  rsi: (module, controls) => (
    module.clientModuleParams.rsiTimeframes?.some(({ value, type }) => (
      value === controls.rsiPeriod && type.includes('rsi'))
    )
  )
};

const angle = (angle, left, right, angleEnabled, leftEnabled, rightEnabled) => (
  angle || left || right ? (
    (angleEnabled ? `${toPrecision(angle, ANGLE_PRECISION)}\u00b0, ` : '..., ') +
    (leftEnabled ? `${toPrecision(left, ANGLE_PRECISION)} Pts, ` : '..., ') +
    (rightEnabled ? `${toPrecision(right, ANGLE_PRECISION)} Pts` : '...')
  ) : null
);

export const performers = {
  common: ({ value }) => value && roundNum(value, COMMON_PRECISION),
  commonWithBool: ({ value }) => value && `${roundNum(value, COMMON_PRECISION)} / True`,
  bool: ({ value }) => value ? 'True' : null,
  fullBool: ({ value }) => value ? 'True' : 'False',
  withDegrees: ({ value }) => value && roundNum(value, COMMON_PRECISION) + '\u00b0',
  withDegreesAndBool: ({ value }) => value && `${roundNum(value, COMMON_PRECISION)}\u00b0 / True`,
  withDegreesAndBoolCondition: ({ value }) => value && `${roundNum(value, COMMON_PRECISION)}\u00b0 / ${value < 0 ? 'False' : 'True'}`,
  withSign: ({ value }) => value && (value > 0 ? '+' : '') + roundNum(value, COMMON_PRECISION),
  withSignAndBool: ({ value }) => value && (value > 0 ? '+' : '') + roundNum(value, COMMON_PRECISION) + ' / True',
  toleranceRange: ({ row }) => (row.value.actual_value > 0 ? '+' : '') + roundNum(row.value.actual_value, COMMON_PRECISION) + ' / ' + performers.fullBool(row.value),
  wsCondition: type => ({ row, patternTitles, settingsValues }) => {
    const { indicator, condition, value, timeframe } = row;

    if (condition.startsWith('rsi_trend_disarm_below') || condition.startsWith('rsi_trend_disarm_above')) {
      const matches = condition.match(/rsi_trend_disarm_(above|below)_(1st_point_found|2nd_point_found|3rd_point_found|tal_went_range|ws_is_stopped|ws_is_restarted)_([0-9]+)/)
      if (matches) {
        const key = type === 'long' ? 'long_rsi_trend_disarm_below' : 'short_rsi_trend_disarm_above'
        const rsiCondition = settingsValues.rsi_trigger[timeframe][key].find(i => i.index === parseInt(matches[3]))

        if (rsiCondition) {
          const msgKey = rsiCondition.index === 0 ? '' : '_with_index'
          return intl.formatMessage({
            id: `logs.whole_sequence.${type}.trend_disarm.${matches[2]}${msgKey}`
          }, rsiCondition);
        }
      }
    }

    if (/rsi|stoch/.test(indicator)) {
      const value = settingsValues[indicator][timeframe][`${type}_${condition}`];
      const name = intl.formatMessage({
        id: `logs.${indicator.match(/rsi|stoch/)[0]}.${type}.${condition}`
      })
      if (/direction/.test(condition)) return name;
      if (/(rsi_.*_line_extension|rsi_inside_tolerance_range)$/.test(condition)) {
        const s = v => v ? `${v > 0 ? '+' : ''}${v}` : v
        const esType = intl.formatMessage({ id: `trade.history.formatter.${settingsValues.escType}` })

        const { belowLine, aboveLine } = settingsValues.entrySystem[type]
        const belowRange = `${s(-(belowLine.value.maxValue ?? belowLine.value))}:${s(-(belowLine.value.minValue ?? 0))}`
        const aboveRange = `${s(aboveLine.value.minValue ?? 0)}:${s(aboveLine.value.maxValue ?? aboveLine.value)}`
        const belowLabel = belowLine.enabled ? belowRange : ''
        const aboveLabel = aboveLine.enabled ? aboveRange : ''
        const delimiter = belowLine.enabled && aboveLine.enabled ? ':' : ''

        return `${name} (${esType}, ${belowLabel}${delimiter} ${aboveLabel})`
      }
      return withSetting(name, value);
    }

    if (/candle_(close|pattern)/.test(indicator)) {
      return row.value.patterns.map(pattern => intl.formatMessage({
        id: `candle_close.conditions.${pattern}`,
        defaultMessage: patternTitles?.[pattern],
      })).join(',');
    }

    if (indicator === 'candlestick_chart') {
      return intl.formatMessage({
        id: `logs.${indicator}.${condition}.${type}`
      });
    }

    if (indicator === 'profit_target') {
      return intl.formatMessage({
        id: `logs.${indicator}.${condition}`
      }, value);
    }

    if (condition === 'position_opened' ||
      condition === 'fund_amount_exceeded' ||
      condition === 'trade_slots_unavailable' ||
      condition === 'module_in_transition' ||
      condition === 'end_of_trade_day') {
      return intl.formatMessage({
        id: `logs.${condition}`
      }, value);
    }

    if (indicator === 'stop_loss' || indicator === 'status_transition' || indicator === 'manual_close') {
      return intl.formatMessage({
        id: `logs.${indicator}.${condition}`
      }, value);
    }

    if (indicator === 'risk_management') {
      const val = {
        ...value,
        param: intl.formatMessage({ id: `risk-management.title.${value.risk_param_type}` }),
        kind: intl.formatMessage({ id: `risk-management.title.${value.kind}` }),
      }
      return intl.formatMessage({
        id: `logs.${indicator}.${condition}`
      }, val);
    }

    if (indicator === 'time_exclusion') {
      const val = {
        ...value,
        names: value.names.map(n => `'${n}'`).join(', '),
        count: value.names?.length
      }
      return intl.formatMessage({
        id: `logs.${indicator}.${condition}`
      }, val);
    }
  },
  timeframe: ({ row: { timeframe, indicator, condition, value }, tfs }) => {
    if (condition === 'fund_amount_exceeded' || condition === 'trade_slots_unavailable') {
      return tfs.rsi_trigger[value.timeframe]
    }
    return tfs[indicator]?.[timeframe]
  },
  stoch: f => ({ row: { value: { k_value: k, d_value: d } }, value }) => (
    [...f(k, d), _.upperFirst(!!value)].join(' / ')
  ),
  indicator: ({ row: { indicator, value, condition } }) => {
    return intl.formatMessage({
      id: `logs.whole_sequence.indicator.${indicator === 'failed_to_open' ? condition : indicator}`,
      defaultMessage: ' '
    }, value)
  },
  wsValue: ALL_CONDITIONS => ({ row, ...remains }) => {
    if (row.condition.startsWith('rsi_trend_disarm_below') || row.condition.startsWith('rsi_trend_disarm_above')) {
      return roundNum(row.value[`${row.condition}_value`], COMMON_PRECISION)
    }

    if (row.indicator === 'candlestick_chart') {
      return 'True'
    };

    if (
      row.indicator === 'stop_loss' &&
      (row.condition === 'entry_on_points' || row.condition === 'entry_on_targets')
    ) {
      return 'True'
    };

    const fixedValue = {
      ...row.value,
      stop_loss: row.value?.stop_loss?.toFixed?.(5),
      trade_size: row.value?.trade_size?.toFixed?.(5),
      exit_price: row.value?.exit_price?.toFixed?.(5),
      entry_price: row.value?.entry_price?.toFixed?.(5),
      profit_target: row.value?.profit_target?.toFixed?.(5),
    };

    if (row.condition === 'position_opened') {
      return intl.formatMessage({ id: 'wholeSequence.table.condition.opened' }, fixedValue);
    };

    if (row.indicator === 'profit_target' && row.condition === 'position_closed') {
      return intl.formatMessage({ id: 'wholeSequence.table.condition.closed' }, fixedValue);
    };

    if (row.indicator === 'risk_management' && row.condition === 'position_closed') {
      return intl.formatMessage({ id: 'wholeSequence.table.condition.closed' }, fixedValue);
    };

    if (row.indicator === 'time_exclusion' && row.condition === 'position_closed') {
      return intl.formatMessage({ id: 'wholeSequence.table.condition.closed' }, fixedValue);
    };

    if (row.indicator === 'profit_target' && row.condition === 'reassignment') {
      return (
        row.value.map(order => (
          intl.formatMessage({ id: 'wholeSequence.table.condition.reassignment' }, order)
        )).reduce((a, b) => <>{a}, {b}</>)
      )
    };

    if ((row.indicator === 'stop_loss' && row.condition === 'position_closed') || row.indicator === 'status_transition' || row.indicator === 'manual_close') {
      return intl.formatMessage({ id: 'wholeSequence.table.condition.closed' }, fixedValue);
    };

    if (row.condition === 'trade_slots_unavailable') {
      const maxOrdersCnt = row?.value?.max_orders_count
      return `${maxOrdersCnt} of ${maxOrdersCnt}`
    }

    // default value
    return ALL_CONDITIONS[row.condition]?.performer?.({
      row,
      ...remains,
      accessor: row.condition,
      value: row.value[`${row.condition}_value`] ?? row.value.value
    });
  },
  candle_time: ({ row }) => row && formatUnixTimestamp(row.candle_timestamp),
  actual_time: ({ row }) => row && formatUnixTimestamp(row.actual_timestamp),
  angle: ({ accessor, row, paramsStates }) => row && angle(
    row?.value?.[`${accessor}_angle_value`],
    row?.value?.[`${accessor}_left_depth_value`],
    row?.value?.[`${accessor}_right_depth_value`],
    paramsStates?.[`${accessor}_angle_enabled`] ?? true,
    paramsStates?.[`${accessor}_left_depth_enabled`] ?? true,
    paramsStates?.[`${accessor}_right_depth_enabled`] ?? true
  ),
  candlePattern: ({ row, patternTitles }) => {
    return (
      row.value.patterns.map(pattern => {
        const patternName = typeof pattern === 'string' ? pattern : Object.keys(pattern)[0];
        return (
          intl.formatMessage({
            id: `candle_close.conditions.${patternName}`,
            defaultMessage: patternTitles?.[patternName]
          })
        )
      }).join(', ')
    )
  }
};

export const isHighlight = (row, position) =>
  (row.condition?.includes?.('disarm') && position.value.startsWith('WHOLE')) ||
  (row.indicator === 'stoch_trigger' && !row.value);
