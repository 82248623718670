import React, { useMemo, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Field, useField } from 'react-final-form';
import { trimString } from 'helpers';
import * as validators from 'helpers/validators';
import { CustomSwitch } from 'components/Controls';
import { Input } from 'components/Fields';

import './styles.scss';

export const CandlePatternSettings = React.memo(() => {
  const settingsField = useField('value.settings');
  const disabled = useMemo(() => (
    !settingsField.input.value.trigger_range
  ), [settingsField]);

  useEffect(() => {
    if (disabled && settingsField.meta.invalid) {
      settingsField.input.onChange({
        ...settingsField.input.value,
        value: 2
      });
    };
  }, [disabled]);

  const range = { min_value: 0, max_value: 100 };

  return (
    <div>
      <h2>
        <FormattedMessage id="module.candlePatterns.settings" />
      </h2>
      <div className="candle-pattern-field__item">
        <div className="text-container">
          <div className="switch-container">
            <Field
              edge="start"
              type="checkbox"
              component={CustomSwitch}
              name="value.settings.trigger_range"
            />
          </div>
          <p style={{ maxWidth: 300 }}>
            <FormattedMessage id="candlePatterns.conditions.additional_points" />
          </p>
        </div>
        <Field
          withHelper
          isSmallField
          component={Input}
          name="value.settings.value"
          validate={(value) => (
            validators.validateWithRange(range)(value) ||
            validators.validateWithoutSpace(value) ||
            validators.validateIntegerField(value) ||
            trimString(value)
          )}
          disabled={disabled}
        />
        <p> Pts</p>
      </div>
    </div>
  );
});

CandlePatternSettings.displayName = 'CandlePatternSettings';
