import React from 'react';
import PropTypes from 'prop-types';
import Switch from '@material-ui/core/Switch';

import './styles.scss';
import classNames from 'classnames';

const CustomSwitch = ({ hidden, input, ...props }) => {
  const customProps = Object.assign({}, props, input);
  return <Switch {...customProps} className={classNames('custom-switch', { hidden })} />;
};

CustomSwitch.propTypes = {
  input: PropTypes.object,
  hidden: PropTypes.bool
};

export default CustomSwitch;
