import React from 'react';
import { Button } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { FieldArray, useFieldArray } from 'react-final-form-arrays';
import { CandlePatternRelation } from './CandlePatternRelation';

import { DEFAULT_RELATION } from 'globalConstants/candlePatterns';
import { validate } from './validation';

export const CandlePatternRelations = React.memo(() => {
  const candlesField = useFieldArray('value.candles');
  const candleCount = React.useMemo(() => candlesField.fields.length, [candlesField]);
  return (
    <div>
      <h2>
        <FormattedMessage id="module.candlePatterns.relations" />
      </h2>
      <FieldArray
        name="value.relations"
        validate={validate}
        render={({ fields }) =>
          <>
            {fields.map((name, index) => (
              <CandlePatternRelation
                key={index}
                name={name}
                handleDelete={() => { fields.remove(index) }}
                candleCount={candleCount}
              />
            ))}
            <Button
              variant="outlined"
              className="outlined-borderless-button"
              type="button"
              onClick={() => fields.push(DEFAULT_RELATION)}
            >
              <FormattedMessage id="module.candlePatterns.addRelation" />
            </Button>
          </>
        }
      />
    </div>
  );
});

CandlePatternRelations.displayName = 'CandlePatternRelations';
