import { makeAutoObservable } from 'mobx';
import { represent, toInternal } from 'helpers/utils';
import { States } from './consts'

export default class FundManagementStore {
  states = States;
  state = States.Pending;

  constructor (fundManagementService) {
    makeAutoObservable(this);
    this.fundManagementService = fundManagementService;
  }

  * get () {
    this.state = this.states.Loading;
    try {
      const response = yield this.fundManagementService.get();
      this.state = this.states.Done;
      return toInternal(response.data);
    } catch (e) {
      this.state = this.states.Error;
      throw e;
    }
  }

  * patch (params) {
    this.state = this.states.Loading;
    try {
      const response = yield this.fundManagementService.patch(represent(params));
      this.state = this.states.Done;
      return response;
    } catch (e) {
      this.state = this.states.Error;
      throw e;
    }
  }

  get isLoading () {
    return this.state === States.Loading;
  }
}
