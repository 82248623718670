import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

export const SwitcherContext = React.createContext(null);
export const useSwitcher = () => React.useContext(SwitcherContext);

/**
 * Util that helps freely jumps between pages. Used in Wizard only currently.
 * Accept children. Use context method `.moveTo(page: number)` for jumping to `page` page.
 * Context also can be used to store/flash context.
 * @param {node} children - several children with ability to jumps between them
 */
export const SwticherProvider = ({ children }) => {
  const [pageIndex, setPageIndex] = React.useState(0);
  const [context, setContext] = React.useState(undefined);
  const pages = React.useMemo(() => React.Children.toArray(children));
  const [scrollPositions, setScrollPositions] = React.useState(Array(pages.length).fill(0));
  const getElement = () => document.getElementsByClassName('main-wrapper__content')[0];

  const moveTo = React.useCallback((index, context, restoreScroll = false) => {
    if (index >= 0 && index < pages.length) {
      // Saving scrollPosition
      const scrollPositionsCopy = _.clone(scrollPositions);
      scrollPositionsCopy[pageIndex] = getElement().scrollTop;
      scrollPositionsCopy[index] = restoreScroll ? scrollPositions[index] : 0;
      setScrollPositions(scrollPositionsCopy);

      setPageIndex(index);
      if (!_.isUndefined(context)) {
        setContext(old => ({ ...old, ...context }));
      }
    } else {
      console.warn(`Switch to non-existent page ${index} (${pages.length} provided)`);
    }
  }, [setPageIndex, pages, setContext]);

  React.useEffect(() => {
    getElement().scrollTo(0, scrollPositions[pageIndex])
  }, [pageIndex]);

  return (
    <SwitcherContext.Provider value={{ moveTo, context, pageIndex }}>
      {pages[pageIndex]}
    </SwitcherContext.Provider>
  );
};

SwticherProvider.propTypes = {
  children: PropTypes.node.isRequired
};
