import React, { useMemo, useState } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { FieldArray } from 'react-final-form-arrays';
import Conditions from './Condition';
import { useForm } from 'react-final-form';
import { Filter } from 'components/Controls';

const ConditionList = ({ prefix, fieldName }) => {
  const form = useForm();

  // get sorted array of timeframes
  const timeframes = useMemo(() => {
    const tfs = _.get(form.getState().values, fieldName.replace('Conditions', 'Timeframes')).filter(tf => tf.enabled);
    tfs.sort((a, b) => a.value - b.value);
    return tfs;
  }, [form]);

  const [timeframe, setTimeframe] = useState(timeframes[0].value);

  return (
    <>
      <div className="controls">
        <Filter
          value={timeframe}
          items={timeframes}
          handleChange={(type, value) => { setTimeframe(value) }}
        />
        {timeframes.length !== 1 && (
          <div className="copy-conditions">
            {timeframes.filter(tf => tf.value !== timeframe).map(tf => (
              <button
                key={tf.value}
                type="button"
                onClick={() => form.mutators.idAgnosticCopy(`${fieldName}.tf${tf.value}`, `${fieldName}.tf${timeframe}`)}
                className="custom-filter__button main-btn"
              >
                <FormattedMessage id={tf.label + (tf.kind ? `.${tf.kind}` : '') } />
              </button>
            ))}
          </div>
        )}
      </div>
      <div className="rs-indicator-from__content">
        <div className="rs-indicator-list">
          <div className="title3 bold">
            <FormattedMessage id={`modules.indicator.${prefix}.long`}/>
          </div>
          <FieldArray
            name={`${fieldName}.tf${timeframe}`}
            component={Conditions}
            kind={'long'}
            prefix={prefix}
          />
        </div>
        <div className={'rs-indicator-list'}>
          <div className="title3 bold">
            <FormattedMessage id={`modules.indicator.${prefix}.short`}/>
          </div>
          <FieldArray
            name={`${fieldName}.tf${timeframe}`}
            component={Conditions}
            kind={'short'}
            prefix={prefix}
          />
        </div>
      </div>
    </>
  )
};

ConditionList.propTypes = {
  prefix: PropTypes.element,
  fieldName: PropTypes.string
};

export default ConditionList;
