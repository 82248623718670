import * as api from 'api/utils';
import { TRADE_EXCLUSION_URL, DETAILED_URL } from './consts';

export default class TradeExclusionService {
  getTradeExclusions() {
    return api.get('tradeManagement', TRADE_EXCLUSION_URL, true);
  }
  createTradeExclusion(data) {
    return api.post('tradeManagement', TRADE_EXCLUSION_URL, true, data, false, null, false);
  }

  updateTradeExclusion(id, data) {
    return api.patch('tradeManagement', DETAILED_URL(id), true, data);
  }

  launchTradeExclusion(id) {
    return api.post('tradeManagement', `${DETAILED_URL(id)}/launch`, true, null, false, null, false);
  }
  stopTradeExclusion(id) {
    return api.post('tradeManagement', `${DETAILED_URL(id)}/stop`, true, null, false, null, false);
  }

  deleteTradeExclusion(id) {
    return api.del('tradeManagement', DETAILED_URL(id), true);
  }
}
