import { computed, makeObservable } from 'mobx';
import _ from 'lodash';
import IndicatorStore from './baseIndicatorStore';

export default class RsiStore extends IndicatorStore {
  constructor () {
    super(...arguments);
    makeObservable(this, {
      forEChart: computed,
      boundaries: computed,
    });
  }

  get forEChart () {
    return this.allData.map(e => [e.timestamp, e.value]);
  }

  get boundaries () {
    return {
      begin: _.nth(this.allData, 0)?.timestamp,
      end: _.nth(this.allData, -1)?.timestamp,
    }
  }
}
