import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { observer } from 'mobx-react';
import { flowResult } from 'mobx';

import { IconButton, Tooltip } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import LastPageIcon from '@mui/icons-material/LastPage';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';

import Calendar from 'components/Calendar';
import { useChartSync } from 'contexts/chart';
import { downloadToFile } from 'helpers';

import TimeframeDropdown from './TimeframeDropdown';
import { instrumentMessage } from 'globalConstants/tradeManagement';
import { useStore } from 'hooks';

import './styles.scss';

const Controls = ({ module, controls, setControls }) => {
  const [open, setOpen] = useState(false);
  const { observers } = useChartSync();
  const intl = useIntl();

  const { instrumentsStore, chartStore } = useStore();

  const onChange = (name, val) => {
    setControls(old => ({ ...old, [name]: val }));
  };

  const downloadCSV = async () => {
    const { instrument, period } = controls;
    if (!instrument && !period) {
      return;
    }
    const filename = `${module.name}.csv`;
    const { data } = await flowResult(chartStore.downloadFile());
    downloadToFile(data, filename);
  }

  const closeCalendar = () => {
    setOpen(false);
  }

  const onAccept = (newDate) => {
    observers.selectedDate.publish(newDate.toSeconds());
    closeCalendar();
  }

  return (
    <>
      <div className="run-module-controls">
        <div className="instrument">
          <p>{instrumentMessage({ instruments: instrumentsStore.instruments, id: controls.instrument })}</p>
        </div>
        {module.isPatternKind && (
          <TimeframeDropdown
            controls={controls}
            onChange={onChange}
            labelType="candlePattern"
            attr="candlePattern"
            tfs={module.clientModuleParams.candlePatternTimeframe}
          />
        )}
        <TimeframeDropdown
          controls={controls}
          onChange={onChange}
          labelType={module.isPatternKind ? 'rsi' : 'rsi.candle'}
          attr="rsi"
          tfs={module.clientModuleParams.rsiTimeframes}
        />
        <TimeframeDropdown
          controls={controls}
          onChange={onChange}
          labelType="stoch"
          attr="stoch"
          isShowDescription
          tfs={module.clientModuleParams.stochTimeframes}
        />
        <IconButton
          color="inherit"
          size="medium"
          onClick={() => setOpen(true)}
        >
          <CalendarMonthIcon fontSize="inherit"/>
        </IconButton>

        <IconButton
          onClick={downloadCSV}
          color="inherit"
          size="medium"
        >
          <GetAppIcon fontSize="inherit" />
        </IconButton>

        <IconButton
          color="inherit"
          size="medium"
          onClick={() => observers.toBegin.publish()}
        >
          <FirstPageIcon fontSize="inherit"/>
        </IconButton>

        <Tooltip title={intl.formatMessage({ id: 'controls.rsi.button.tooltip.prev' })}>
          <IconButton
            color="inherit"
            size="medium"
            disabled={chartStore.armingLine.buttonAvailability.prev}
            onClick={() => observers.armingLine.publish('prev')}
          >
            <KeyboardArrowLeftIcon fontSize="inherit"/>
          </IconButton>
        </Tooltip>
        <Tooltip title={intl.formatMessage({ id: 'controls.rsi.button.tooltip.next' })}>
          <IconButton
            color="inherit"
            size="medium"
            disabled={chartStore.armingLine.buttonAvailability.next}
            onClick={() => observers.armingLine.publish('next')}
          >
            <KeyboardArrowRightIcon fontSize="inherit"/>
          </IconButton>
        </Tooltip>
        <Tooltip title={intl.formatMessage({ id: 'controls.rsi.button.tooltip.end' })}>
          <IconButton
            color="inherit"
            size="medium"
            disabled={chartStore.armingLine.buttonAvailability.last}
            onClick={() => observers.armingLine.publish('last')}
          >
            <KeyboardDoubleArrowRightIcon fontSize="inherit"/>
          </IconButton>
        </Tooltip>

        <IconButton
          color="inherit"
          size="medium"
          onClick={() => observers.toEnd.publish()}
        >
          <LastPageIcon fontSize="inherit"/>
        </IconButton>
      </div>

      <Calendar
        open={open}
        onClose={closeCalendar}
        onAccept={onAccept}
        views={['year', 'month', 'day', 'hours', 'minutes']}
        inputFormat={'yyyy/MM/dd'}
        isDisplayTime
      />
    </>
  );
};

Controls.propTypes = {
  module: PropTypes.object,
  controls: PropTypes.object,
  setControls: PropTypes.func,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
};

export default observer(Controls);
