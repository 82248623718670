import { metaKey } from './meta';
import 'lodash.permutations';
import _ from 'lodash';
import { CANDLE_CLOSE_LONG, CANDLE_CLOSE_SHORT } from './candleClose';

const DEFAULT_TIMEFRAMES = [
  { value: 600, label: '10 min', enabled: false },
  { value: 720, label: '12 min', enabled: false },
  { value: 900, label: '15 min', enabled: false },
  { value: 2700, label: '45 min', enabled: false },
  { value: 3600, label: '1 hr', enabled: false },
  { value: 7200, label: '2 hr', enabled: false },
  { value: 10800, label: '3 hr', enabled: false },
  { value: 14400, label: '4 hr', enabled: false },
  { value: 21600, label: '6 hr', enabled: false },
  { value: 43200, label: '12 hr', enabled: false },
  { value: 86400, label: 'Daily', enabled: false }
];

export const DEFAULT_TRADE_SIZE = {
  enabled: true,
  value: 1,
  unit: 'full_lot',
  autocalculate: false,
}

export const DEFAULT_PROFIT_TARGETS = {
  enabled: true,
  targets: [50, 75, 100, 150, 175, 200],
  reassignment: 10
}

export const DEFAULT_STOP = {
  enabled: true,
  value: 10,
}

export const DEFAULT_TARGETS = {
  enabled: true,
  value: 3
}

export const DEFAULT_POINTS = {
  enabled: false,
  value: 20
}

export const DEFAULT_TRADE_EXECUTION = {
  enabled: true,
  pattern_close: { enabled: true, value: 6 },
  pattern_50_retrace: { enabled: false, value: 0 },
  pattern_off: { enabled: false, value: 0 }
}

export const STOCH_TIMEFRAMES = [
  { ...DEFAULT_TIMEFRAMES[0], enabled: true },
  ...DEFAULT_TIMEFRAMES.slice(1)
];

export const CANDLE_PATTERN_TIMEFRAMES = [
  { value: 60, label: '1 min', enabled: true },
  { value: 120, label: '2 mins', enabled: false },
  { value: 300, label: '5 mins', enabled: false },
  { value: 600, label: '10 mins', enabled: false },
  { value: 900, label: '15 mins', enabled: false },
  { value: 1200, label: '20 mins', enabled: false },
  { value: 1800, label: '30 mins', enabled: false },
  { value: 3600, label: '1 hr', enabled: false }
];

export const RSI_TIMEFRAMES = [
  { value: 120, label: '2 min', enabled: true },
  { value: 180, label: '3 min', enabled: false },
  { value: 300, label: '5 min', enabled: false },
  ...DEFAULT_TIMEFRAMES
];

export const PRECISION = 5;
export const DEFAULT_MODULE_NAME = 'Module';
export const DEFAULT_K_PERIOD = '10';
export const DEFAULT_SLOWING_PERIOD = '3';
export const DEFAULT_D_PERIOD = '3';
export const DEFAULT_RSI_PERIOD_VALUE = 14;

export const AVAILABILITY = {
  LONG: 'long_availability',
  SHORT: 'short_availability'
};

const RSI_CONDITIONS_KIND_LONG = {
  TROUGH_1_ANGLE: 'long_trough_1_angle',
  TROUGH_1_LEFT_DEPTH: 'long_trough_1_left_depth',
  TROUGH_1_RIGHT_DEPTH: 'long_trough_1_right_depth',
  TROUGH_2_ANGLE: 'long_trough_2_angle',
  TROUGH_2_LEFT_DEPTH: 'long_trough_2_left_depth',
  TROUGH_2_RIGHT_DEPTH: 'long_trough_2_right_depth',
  RSI_TRADE_ARMING_LINE_ASCENDING_ANGLE: 'long_rsi_trade_arming_line_ascending_angle',
  RSI_TRADE_ARMING_SUPPORT_LINE_EXTENSION: 'long_rsi_trade_arming_support_line_extension',
  RSI_TROUGH_1_RESET_INCLINE: 'long_rsi_trough_1_reset_incline',
  RSI_TROUGH_2_RESET_TOLERANCE: 'long_rsi_trough_2_reset_tolerance',
  TROUGH_1_CLOSE_BELOW_DISARM: 'long_trough_1_close_below_disarm',
  TROUGH_1_PUSH_BELOW_DISARM: 'long_trough_1_push_below_disarm',
  TROUGH_2_CLOSE_BELOW_DISARM: 'long_trough_2_close_below_disarm',
  TROUGH_2_PUSH_BELOW_DISARM: 'long_trough_2_push_below_disarm',
  TROUGH_1_RSI_LINE_CLOSED_ABOVE_DISARM: 'long_trough_1_rsi_line_closed_above_disarm',
  TROUGH_2_RSI_LINE_CLOSED_ABOVE_DISARM: 'long_trough_2_rsi_line_closed_above_disarm',
  RSI_ARMING_CLOSED_BELOW: 'long_rsi_arming_closed_below',
  RSI_ARMING_CLOSED_BACK_ABOVE: 'long_rsi_arming_closed_back_above',
  RSI_ARMING_BETWEEN: 'long_rsi_arming_between',
  RSI_CLOSED_ABOVE_DISARM: 'long_rsi_closed_above_disarm',
  RSI_DIRECTION_UP: 'long_rsi_direction_up',
  RSI_TIME_OUT_DISARM_TROUGH_1: 'long_rsi_time_out_disarm_trough_1',
  RSI_TIME_OUT_DISARM_TROUGH_2: 'long_rsi_time_out_disarm_trough_2',
  RSI_TREND_DISARM_BELOW: 'long_rsi_trend_disarm_below',
};

const RSI_CONDITIONS_KIND_SHORT = {
  PEAK_1_ANGLE: 'short_peak_1_angle',
  PEAK_1_LEFT_DEPTH: 'short_peak_1_left_depth',
  PEAK_1_RIGHT_DEPTH: 'short_peak_1_right_depth',
  PEAK_2_ANGLE: 'short_peak_2_angle',
  PEAK_2_LEFT_DEPTH: 'short_peak_2_left_depth',
  PEAK_2_RIGHT_DEPTH: 'short_peak_2_right_depth',
  RSI_TRADE_ARMING_LINE_DESCENDING_ANGLE: 'short_rsi_trade_arming_line_descending_angle',
  RSI_TRADE_ARMING_RESISTANCE_LINE_EXTENSION: 'short_rsi_trade_arming_resistance_line_extension',
  RSI_PEAK_1_RESET_DECLINE: 'short_rsi_peak_1_reset_decline',
  RSI_PEAK_2_RESET_TOLERANCE: 'short_rsi_peak_2_reset_tolerance',
  PEAK_1_CLOSE_ABOVE_DISARM: 'short_peak_1_close_above_disarm',
  PEAK_1_PUSH_ABOVE_DISARM: 'short_peak_1_push_above_disarm',
  PEAK_2_CLOSE_ABOVE_DISARM: 'short_peak_2_close_above_disarm',
  PEAK_2_PUSH_ABOVE_DISARM: 'short_peak_2_push_above_disarm',
  PEAK_1_RSI_LINE_CLOSED_BELOW_DISARM: 'short_peak_1_rsi_line_closed_below_disarm',
  PEAK_2_RSI_LINE_CLOSED_BELOW_DISARM: 'short_peak_2_rsi_line_closed_below_disarm',
  RSI_ARMING_CLOSED_ABOVE: 'short_rsi_arming_closed_above',
  RSI_ARMING_CLOSED_BACK_BELOW: 'short_rsi_arming_closed_back_below',
  RSI_ARMING_BETWEEN: 'short_rsi_arming_between',
  RSI_CLOSED_BELOW_DISARM: 'short_rsi_closed_below_disarm',
  RSI_DIRECTION_DOWN: 'short_rsi_direction_down',
  RSI_TIME_OUT_DISARM_PEAK_1: 'short_rsi_time_out_disarm_peak_1',
  RSI_TIME_OUT_DISARM_PEAK_2: 'short_rsi_time_out_disarm_peak_2',
  RSI_TREND_DISARM_ABOVE: 'short_rsi_trend_disarm_above',
};

export const STOCH_CONDITIONS_KIND_LONG = {
  K_ONLY_CLOSED_BELOW: 'long_k_only_closed_below',
  K_D_CLOSED_BELOW: 'long_k_d_closed_below',
  K_D_DISTANCE_TOLERANCE: 'long_k_d_distance_tolerance',
  K_CLOSED_ABOVE_D_BELOW: 'long_k_closed_above_d_below',
  K_D_CLOSED_BACK_ABOVE: 'long_k_d_closed_back_above',
  K_D_BETWEEN: 'long_k_d_between',
  K_D_ABOVE_LEVEL_DISARM: 'long_k_d_above_level_disarm',
  K_D_DIRECTION_UP: 'long_k_d_direction_up',
  D_DIRECTION_UP: 'long_d_direction_up',
  K_DIRECTION_UP: 'long_k_direction_up'
};

export const STOCH_CONDITIONS_KIND_SHORT = {
  K_ONLY_CLOSED_ABOVE: 'short_k_only_closed_above',
  K_D_CLOSED_ABOVE: 'short_k_d_closed_above',
  K_D_DISTANCE_TOLERANCE: 'short_k_d_distance_tolerance',
  K_CLOSED_BELOW_D_ABOVE: 'short_k_closed_below_d_above',
  K_D_CLOSED_BACK_BELOW: 'short_k_d_closed_back_below',
  K_D_BETWEEN: 'short_k_d_between',
  K_D_BELOW_LEVEL_DISARM: 'short_k_d_below_level_disarm',
  K_D_DIRECTION_DOWN: 'short_k_d_direction_down',
  D_DIRECTION_DOWN: 'short_d_direction_down',
  K_DIRECTION_DOWN: 'short_k_direction_down'
};

const ENTRY_SYSTEM_CONDITIONS_KIND_LONG = {
  TRIPLE_CLOSE: 'long_triple_close',
  TRIPLE_TOLERANCE: 'long_triple_tolerance',
}

const ENTRY_SYSTEM_CONDITIONS_KIND_SHORT = {
  TRIPLE_CLOSE: 'short_triple_close',
  TRIPLE_TOLERANCE: 'short_triple_tolerance',
}

const RSI_CONDITIONS_LONG = [
  {
    enabled: true,
    kind: AVAILABILITY.LONG,
    value: 1,
    [metaKey]: {
      boolean: true,
      padding: true,
      rsiAvailability: true
    }
  },
  {
    enabled: true,
    kind: RSI_CONDITIONS_KIND_LONG.TROUGH_1_ANGLE,
    symbol: '\u00B0',
    description: '\u2265 {min}{symbol} \u2264 {max}{symbol}',
    available_range: {
      min_value: 0.01,
      max_value: 180.0
    },
    value: {
      min_value: 0.01,
      max_value: 90.0
    },
    [metaKey]: {
      withoutToggle: true
    }
  },
  {
    enabled: true,
    kind: RSI_CONDITIONS_KIND_LONG.TROUGH_1_LEFT_DEPTH,
    symbol: ' Pts',
    description: '\u2265 {min}{symbol} \u2264 {max}{symbol}',
    available_range: {
      min_value: 0.10,
      max_value: 30.0
    },
    value: {
      min_value: 2.0,
      max_value: 12.0
    },
    [metaKey]: {
      withoutToggle: true
    }
  },
  {
    enabled: true,
    kind: RSI_CONDITIONS_KIND_LONG.TROUGH_1_RIGHT_DEPTH,
    symbol: ' Pts',
    description: '\u2265 {min}{symbol} \u2264 {max}{symbol}',
    available_range: {
      min_value: 0.10,
      max_value: 30.00
    },
    value: {
      min_value: 2.00,
      max_value: 12.00
    },
    [metaKey]: {
      withoutToggle: true
    }
  },
  {
    enabled: true,
    kind: RSI_CONDITIONS_KIND_LONG.TROUGH_2_ANGLE,
    symbol: '\u00B0',
    description: '\u2265 {min}{symbol} \u2264 {max}{symbol}',
    available_range: {
      min_value: 0.01,
      max_value: 180.0
    },
    value: {
      min_value: 0.01,
      max_value: 90.00
    },
    [metaKey]: {
      withoutToggle: true
    }
  },
  {
    enabled: true,
    kind: RSI_CONDITIONS_KIND_LONG.TROUGH_2_LEFT_DEPTH,
    symbol: ' Pts',
    description: '\u2265 {min}{symbol} \u2264 {max}{symbol}',
    available_range: {
      min_value: 0.10,
      max_value: 30.0
    },
    value: {
      min_value: 2.0,
      max_value: 12.0
    },
    [metaKey]: {
      withoutToggle: true
    }
  },
  {
    enabled: true,
    kind: RSI_CONDITIONS_KIND_LONG.TROUGH_2_RIGHT_DEPTH,
    symbol: ' Pts',
    description: '\u2265 {min}{symbol} \u2264 {max}{symbol}',
    available_range: {
      min_value: 0.10,
      max_value: 30.0
    },
    value: {
      min_value: 2.0,
      max_value: 12.0
    },
    [metaKey]: {
      withoutToggle: true
    }
  },
  {
    enabled: true,
    kind: RSI_CONDITIONS_KIND_LONG.RSI_TRADE_ARMING_SUPPORT_LINE_EXTENSION,
    symbol: ' Pts',
    description: '{value}{symbol}',
    available_range: {
      min_value: 0.1,
      max_value: 100.0
    },
    value: 60,
    [metaKey]: {
      withoutToggle: true
    }
  },
  {
    enabled: true,
    kind: RSI_CONDITIONS_KIND_LONG.RSI_TRADE_ARMING_LINE_ASCENDING_ANGLE,
    symbol: '\u00B0',
    description: '\u2265 {value}{symbol}',
    available_range: {
      min_value: 0.01,
      max_value: 90.0
    },
    value: 12,
    [metaKey]: {
      onValue: {
        ge: [
          RSI_CONDITIONS_KIND_LONG.RSI_TROUGH_1_RESET_INCLINE
        ]
      }
    }
  },
  {
    enabled: true,
    kind: RSI_CONDITIONS_KIND_LONG.RSI_TROUGH_1_RESET_INCLINE,
    symbol: '\u00B0',
    description: '< {value}{symbol}',
    available_range: {
      min_value: 0.01,
      max_value: 90.0
    },
    value: 12,
    [metaKey]: {
      onValue: {
        le: [
          RSI_CONDITIONS_KIND_LONG.RSI_TRADE_ARMING_LINE_ASCENDING_ANGLE
        ]
      }
    }
  },
  {
    enabled: false,
    kind: RSI_CONDITIONS_KIND_LONG.RSI_TROUGH_2_RESET_TOLERANCE,
    symbol: ' Pts',
    description: '> {value}{symbol}',
    available_range: {
      min_value: 0,
      max_value: 30.0
    },
    value: 5
  },
  {
    enabled: true,
    kind: RSI_CONDITIONS_KIND_LONG.TROUGH_1_PUSH_BELOW_DISARM,
    symbol: ' Pts',
    description: '\u2265 {value}{symbol}',
    available_range: {
      min_value: 0.0,
      max_value: 10.0
    },
    value: 0.01,
    [metaKey]: {
      switchWith: RSI_CONDITIONS_KIND_LONG.TROUGH_1_CLOSE_BELOW_DISARM
    }
  },
  {
    enabled: false,
    kind: RSI_CONDITIONS_KIND_LONG.TROUGH_1_CLOSE_BELOW_DISARM,
    symbol: ' Pts',
    description: '\u2265 {value}{symbol}',
    available_range: {
      min_value: 0.0,
      max_value: 10.0
    },
    value: 0.01,
    [metaKey]: {
      switchWith: RSI_CONDITIONS_KIND_LONG.TROUGH_1_PUSH_BELOW_DISARM
    }
  },
  {
    enabled: true,
    kind: RSI_CONDITIONS_KIND_LONG.TROUGH_2_PUSH_BELOW_DISARM,
    symbol: ' Pts',
    description: '\u2265 {value}{symbol}',
    available_range: {
      min_value: 0.0,
      max_value: 10.0
    },
    value: 0.01,
    [metaKey]: {
      switchWith: RSI_CONDITIONS_KIND_LONG.TROUGH_2_CLOSE_BELOW_DISARM
    }
  },
  {
    enabled: false,
    kind: RSI_CONDITIONS_KIND_LONG.TROUGH_2_CLOSE_BELOW_DISARM,
    symbol: ' Pts',
    description: '\u2265 {value}{symbol}',
    available_range: {
      min_value: 0.0,
      max_value: 10.0
    },
    value: 0.01,
    [metaKey]: {
      switchWith: RSI_CONDITIONS_KIND_LONG.TROUGH_2_PUSH_BELOW_DISARM
    }
  },
  {
    enabled: false,
    kind: RSI_CONDITIONS_KIND_LONG.TROUGH_1_RSI_LINE_CLOSED_ABOVE_DISARM,
    symbol: ' Pts',
    description: '\u2265 {value}{symbol}',
    available_range: {
      min_value: 0.0,
      max_value: 100.0
    },
    value: 50.0,
    [metaKey]: {
      onValue: {
        le: [RSI_CONDITIONS_KIND_LONG.TROUGH_2_RSI_LINE_CLOSED_ABOVE_DISARM]
      }
    }
  },
  {
    enabled: false,
    kind: RSI_CONDITIONS_KIND_LONG.TROUGH_2_RSI_LINE_CLOSED_ABOVE_DISARM,
    symbol: ' Pts',
    description: '\u2265 {value}{symbol}',
    available_range: {
      min_value: 0.0,
      max_value: 100.0
    },
    value: 60.0,
    [metaKey]: {
      onValue: {
        ge: [RSI_CONDITIONS_KIND_LONG.TROUGH_1_RSI_LINE_CLOSED_ABOVE_DISARM]
      }
    }
  },
  {
    enabled: false,
    kind: RSI_CONDITIONS_KIND_LONG.RSI_ARMING_CLOSED_BELOW,
    symbol: ' Pts',
    description: '{value}{symbol}',
    available_range: {
      min_value: 0.0,
      max_value: 100.0
    },
    value: 30.0,
    [metaKey]: {
      onValue: {
        le: [RSI_CONDITIONS_KIND_LONG.RSI_CLOSED_ABOVE_DISARM]
      },
      dependsOn: [
        RSI_CONDITIONS_KIND_LONG.RSI_ARMING_BETWEEN,
        RSI_CONDITIONS_KIND_LONG.RSI_ARMING_CLOSED_BACK_ABOVE
      ]
    }
  },
  {
    enabled: false,
    kind: RSI_CONDITIONS_KIND_LONG.RSI_ARMING_CLOSED_BACK_ABOVE,
    symbol: ' Pts',
    description: '{value}{symbol}',
    available_range: {
      min_value: 0.0,
      max_value: 100.0
    },
    value: 30.0,
    [metaKey]: {
      onValue: {
        le: [RSI_CONDITIONS_KIND_LONG.RSI_CLOSED_ABOVE_DISARM]
      },
      dependsOn: [
        RSI_CONDITIONS_KIND_LONG.RSI_ARMING_BETWEEN,
        RSI_CONDITIONS_KIND_LONG.RSI_ARMING_CLOSED_BELOW
      ]
    }
  },
  {
    enabled: false,
    kind: RSI_CONDITIONS_KIND_LONG.RSI_ARMING_BETWEEN,
    symbol: ' Pts',
    description: '{min}{symbol} - {max}{symbol}',
    available_range: {
      min_value: 0.0,
      max_value: 100.0
    },
    value: {
      min_value: 30,
      max_value: 70
    },
    [metaKey]: {
      hidden: true,
      onValue: {
        le: [RSI_CONDITIONS_KIND_LONG.RSI_CLOSED_ABOVE_DISARM]
      },
      dependsOn: [
        RSI_CONDITIONS_KIND_LONG.RSI_ARMING_CLOSED_BELOW,
        RSI_CONDITIONS_KIND_LONG.RSI_ARMING_CLOSED_BACK_ABOVE
      ]
    }
  },
  {
    enabled: false,
    kind: RSI_CONDITIONS_KIND_LONG.RSI_CLOSED_ABOVE_DISARM,
    symbol: ' Pts',
    description: '\u2265 {value}{symbol}',
    available_range: {
      min_value: 0.0,
      max_value: 100.0
    },
    value: 50.0,
    [metaKey]: {
      onValue: {
        ge: [
          RSI_CONDITIONS_KIND_LONG.RSI_ARMING_CLOSED_BELOW,
          RSI_CONDITIONS_KIND_LONG.RSI_ARMING_CLOSED_BACK_ABOVE,
          RSI_CONDITIONS_KIND_LONG.RSI_ARMING_BETWEEN
        ]
      }
    }
  },
  {
    enabled: false,
    kind: RSI_CONDITIONS_KIND_LONG.RSI_DIRECTION_UP,
    value: 1.0,
    [metaKey]: {
      hidden: true,
      boolean: true
    }
  },
  {
    enabled: true,
    kind: RSI_CONDITIONS_KIND_LONG.RSI_TIME_OUT_DISARM_TROUGH_1,
    description: '{blocks, plural, =1 {1 block} other {# blocks}}, {timestamps, plural, =1 {1 timestamp} other {# timestamps}}',
    value: {
      blocks: 6,
      timestamps: 15
    }
  },
  {
    enabled: true,
    kind: RSI_CONDITIONS_KIND_LONG.RSI_TIME_OUT_DISARM_TROUGH_2,
    symbol: 'blocks',
    description: '{blocks, plural, =1 {1 block} other {# blocks}}, {timestamps, plural, =1 {1 timestamp} other {# timestamps}}',
    value: {
      blocks: 6,
      timestamps: 15
    }
  },
  {
    enabled: false,
    kind: RSI_CONDITIONS_KIND_LONG.RSI_TREND_DISARM_BELOW,
    symbol: ' Pts',
    available_range: {
      min_value: 0,
      max_value: 100.0
    },
    description: '{rsi_level}{symbol}, < {rsi_reset_level2}{symbol}, > {rsi_reset_level1}{symbol}',
    value: [
      {
        index: 0,
        rsi_level: 30,
        rsi_reset_level2: 40,
        rsi_reset_level1: 20
      },
    ],
    [metaKey]: {
      defaults: [
        {
          index: 0,
          rsi_level: 30,
          rsi_reset_level2: 40,
          rsi_reset_level1: 20
        },
        {
          index: 1,
          rsi_level: 40,
          rsi_reset_level2: 50,
          rsi_reset_level1: 30
        },
        {
          index: 2,
          rsi_level: 50,
          rsi_reset_level2: 60,
          rsi_reset_level1: 40
        }
      ]
    }
  }
];

const RSI_CONDITIONS_SHORT = [
  {
    enabled: true,
    kind: AVAILABILITY.SHORT,
    value: 1,
    [metaKey]: {
      boolean: true,
      padding: true,
      rsiAvailability: true
    }
  },
  {
    enabled: true,
    kind: RSI_CONDITIONS_KIND_SHORT.PEAK_1_ANGLE,
    symbol: '\u00B0',
    description: '\u2265 {min}{symbol} \u2264 {max}{symbol}',
    available_range: {
      min_value: 0.01,
      max_value: 180.0
    },
    value: {
      min_value: 0.01,
      max_value: 90.0
    },
    [metaKey]: {
      withoutToggle: true
    }
  },
  {
    enabled: true,
    kind: RSI_CONDITIONS_KIND_SHORT.PEAK_1_LEFT_DEPTH,
    symbol: ' Pts',
    description: '\u2265 {min}{symbol} \u2264 {max}{symbol}',
    available_range: {
      min_value: 0.10,
      max_value: 30.0
    },
    value: {
      min_value: 2.0,
      max_value: 12.0
    },
    [metaKey]: {
      withoutToggle: true
    }
  },
  {
    enabled: true,
    kind: RSI_CONDITIONS_KIND_SHORT.PEAK_1_RIGHT_DEPTH,
    symbol: ' Pts',
    description: '\u2265 {min}{symbol} \u2264 {max}{symbol}',
    available_range: {
      min_value: 0.10,
      max_value: 30.00
    },
    value: {
      min_value: 2.00,
      max_value: 12.00
    },
    [metaKey]: {
      withoutToggle: true
    }
  },
  {
    enabled: true,
    kind: RSI_CONDITIONS_KIND_SHORT.PEAK_2_ANGLE,
    symbol: '\u00B0',
    description: '\u2265 {min}{symbol} \u2264 {max}{symbol}',
    available_range: {
      min_value: 0.01,
      max_value: 180.0
    },
    value: {
      min_value: 0.01,
      max_value: 90.0
    },
    [metaKey]: {
      withoutToggle: true
    }
  },
  {
    enabled: true,
    kind: RSI_CONDITIONS_KIND_SHORT.PEAK_2_LEFT_DEPTH,
    symbol: ' Pts',
    description: '\u2265 {min}{symbol} \u2264 {max}{symbol}',
    available_range: {
      min_value: 0.10,
      max_value: 30.0
    },
    value: {
      min_value: 2.0,
      max_value: 12.0
    },
    [metaKey]: {
      withoutToggle: true
    }
  },
  {
    enabled: true,
    kind: RSI_CONDITIONS_KIND_SHORT.PEAK_2_RIGHT_DEPTH,
    symbol: ' Pts',
    description: '\u2265 {min}{symbol} \u2264 {max}{symbol}',
    available_range: {
      min_value: 0.10,
      max_value: 30.0
    },
    value: {
      min_value: 2.0,
      max_value: 12.0
    },
    [metaKey]: {
      withoutToggle: true
    }
  },
  {
    enabled: true,
    kind: RSI_CONDITIONS_KIND_SHORT.RSI_TRADE_ARMING_RESISTANCE_LINE_EXTENSION,
    symbol: ' Pts',
    description: '{value}{symbol}',
    available_range: {
      min_value: 0.1,
      max_value: 100.0
    },
    value: 40,
    [metaKey]: {
      withoutToggle: true
    }
  },
  {
    enabled: true,
    kind: RSI_CONDITIONS_KIND_SHORT.RSI_TRADE_ARMING_LINE_DESCENDING_ANGLE,
    symbol: '\u00B0',
    description: '\u2265 {value}{symbol}',
    available_range: {
      min_value: 0.01,
      max_value: 90.0
    },
    value: 12,
    [metaKey]: {
      onValue: {
        ge: [
          RSI_CONDITIONS_KIND_SHORT.RSI_PEAK_1_RESET_DECLINE
        ]
      }
    }
  },
  {
    enabled: true,
    kind: RSI_CONDITIONS_KIND_SHORT.RSI_PEAK_1_RESET_DECLINE,
    symbol: '\u00B0',
    description: '< {value}{symbol}',
    available_range: {
      min_value: 0.01,
      max_value: 90.0
    },
    value: 12,
    [metaKey]: {
      onValue: {
        le: [
          RSI_CONDITIONS_KIND_SHORT.RSI_TRADE_ARMING_LINE_DESCENDING_ANGLE
        ]
      }
    }
  },
  {
    enabled: false,
    kind: RSI_CONDITIONS_KIND_SHORT.RSI_PEAK_2_RESET_TOLERANCE,
    symbol: ' Pts',
    description: '< {value}{symbol}',
    available_range: {
      min_value: 0,
      max_value: 30.0
    },
    value: 5
  },
  {
    enabled: true,
    kind: RSI_CONDITIONS_KIND_SHORT.PEAK_1_PUSH_ABOVE_DISARM,
    symbol: ' Pts',
    description: '\u2265 {value}{symbol}',
    available_range: {
      min_value: 0.0,
      max_value: 10.0
    },
    value: 0.01,
    [metaKey]: {
      switchWith: RSI_CONDITIONS_KIND_SHORT.PEAK_1_CLOSE_ABOVE_DISARM
    }
  },
  {
    enabled: false,
    kind: RSI_CONDITIONS_KIND_SHORT.PEAK_1_CLOSE_ABOVE_DISARM,
    symbol: ' Pts',
    description: '\u2265 {value}{symbol}',
    available_range: {
      min_value: 0.0,
      max_value: 10.0
    },
    value: 0.01,
    [metaKey]: {
      switchWith: RSI_CONDITIONS_KIND_SHORT.PEAK_1_PUSH_ABOVE_DISARM
    }
  },
  {
    enabled: true,
    kind: RSI_CONDITIONS_KIND_SHORT.PEAK_2_PUSH_ABOVE_DISARM,
    symbol: ' Pts',
    description: '\u2265 {value}{symbol}',
    available_range: {
      min_value: 0.0,
      max_value: 10.0
    },
    value: 0.01,
    [metaKey]: {
      switchWith: RSI_CONDITIONS_KIND_SHORT.PEAK_2_CLOSE_ABOVE_DISARM
    }
  },
  {
    enabled: false,
    kind: RSI_CONDITIONS_KIND_SHORT.PEAK_2_CLOSE_ABOVE_DISARM,
    symbol: ' Pts',
    description: '\u2265 {value}{symbol}',
    available_range: {
      min_value: 0.0,
      max_value: 10.0
    },
    value: 0.01,
    [metaKey]: {
      switchWith: RSI_CONDITIONS_KIND_SHORT.PEAK_2_PUSH_ABOVE_DISARM
    }
  },
  {
    enabled: false,
    kind: RSI_CONDITIONS_KIND_SHORT.PEAK_1_RSI_LINE_CLOSED_BELOW_DISARM,
    symbol: ' Pts',
    description: '\u2264 {value}{symbol}',
    available_range: {
      min_value: 0.0,
      max_value: 100.0
    },
    value: 50.00,
    [metaKey]: {
      onValue: {
        ge: [RSI_CONDITIONS_KIND_SHORT.PEAK_2_RSI_LINE_CLOSED_BELOW_DISARM]
      }
    }
  },
  {
    enabled: false,
    kind: RSI_CONDITIONS_KIND_SHORT.PEAK_2_RSI_LINE_CLOSED_BELOW_DISARM,
    symbol: ' Pts',
    description: '\u2264 {value}{symbol}',
    available_range: {
      min_value: 0.0,
      max_value: 100.0
    },
    value: 40.00,
    [metaKey]: {
      onValue: {
        le: [RSI_CONDITIONS_KIND_SHORT.PEAK_1_RSI_LINE_CLOSED_BELOW_DISARM]
      }
    }
  },
  {
    enabled: false,
    kind: RSI_CONDITIONS_KIND_SHORT.RSI_ARMING_CLOSED_ABOVE,
    symbol: ' Pts',
    description: '{value}{symbol}',
    available_range: {
      min_value: 0.0,
      max_value: 100.0
    },
    value: 70.0,
    [metaKey]: {
      onValue: {
        ge: [RSI_CONDITIONS_KIND_SHORT.RSI_CLOSED_BELOW_DISARM]
      },
      dependsOn: [
        RSI_CONDITIONS_KIND_SHORT.RSI_ARMING_BETWEEN,
        RSI_CONDITIONS_KIND_SHORT.RSI_ARMING_CLOSED_BACK_BELOW,
      ]
    }
  },
  {
    enabled: false,
    kind: RSI_CONDITIONS_KIND_SHORT.RSI_ARMING_CLOSED_BACK_BELOW,
    symbol: ' Pts',
    description: '{value}{symbol}',
    available_range: {
      min_value: 0.0,
      max_value: 100.0
    },
    value: 70.0,
    [metaKey]: {
      onValue: {
        ge: [RSI_CONDITIONS_KIND_SHORT.RSI_CLOSED_BELOW_DISARM]
      },
      dependsOn: [
        RSI_CONDITIONS_KIND_SHORT.RSI_ARMING_BETWEEN,
        RSI_CONDITIONS_KIND_SHORT.RSI_ARMING_CLOSED_ABOVE,
      ]
    }
  },
  {
    enabled: false,
    kind: RSI_CONDITIONS_KIND_SHORT.RSI_ARMING_BETWEEN,
    symbol: ' Pts',
    description: '{min}{symbol} - {max}{symbol}',
    available_range: {
      min_value: 0.0,
      max_value: 100.0
    },
    value: {
      min_value: 30,
      max_value: 70
    },
    [metaKey]: {
      hidden: true,
      onValue: {
        ge: [RSI_CONDITIONS_KIND_SHORT.RSI_CLOSED_BELOW_DISARM]
      },
      dependsOn: [
        RSI_CONDITIONS_KIND_SHORT.RSI_ARMING_CLOSED_ABOVE,
        RSI_CONDITIONS_KIND_SHORT.RSI_ARMING_CLOSED_BACK_BELOW
      ]
    }
  },
  {
    enabled: false,
    kind: RSI_CONDITIONS_KIND_SHORT.RSI_CLOSED_BELOW_DISARM,
    symbol: ' Pts',
    description: '\u2264 {value}{symbol}',
    available_range: {
      min_value: 0.0,
      max_value: 100.0
    },
    value: 50.0,
    [metaKey]: {
      onValue: {
        le: [
          RSI_CONDITIONS_KIND_SHORT.RSI_ARMING_CLOSED_ABOVE,
          RSI_CONDITIONS_KIND_SHORT.RSI_ARMING_CLOSED_BACK_BELOW,
          RSI_CONDITIONS_KIND_SHORT.RSI_ARMING_BETWEEN
        ]
      }
    }
  },
  {
    enabled: false,
    kind: RSI_CONDITIONS_KIND_SHORT.RSI_DIRECTION_DOWN,
    value: 1.0,
    [metaKey]: {
      hidden: true,
      boolean: true
    }
  },
  {
    enabled: true,
    kind: RSI_CONDITIONS_KIND_SHORT.RSI_TIME_OUT_DISARM_PEAK_1,
    description: '{blocks, plural, =1 {1 block} other {# blocks}}, {timestamps, plural, =1 {1 timestamp} other {# timestamps}}',
    value: {
      blocks: 6,
      timestamps: 15
    }
  },
  {
    enabled: true,
    kind: RSI_CONDITIONS_KIND_SHORT.RSI_TIME_OUT_DISARM_PEAK_2,
    description: '{blocks, plural, =1 {1 block} other {# blocks}}, {timestamps, plural, =1 {1 timestamp} other {# timestamps}}',
    value: {
      blocks: 6,
      timestamps: 15
    }
  },
  {
    enabled: false,
    kind: RSI_CONDITIONS_KIND_SHORT.RSI_TREND_DISARM_ABOVE,
    symbol: ' Pts',
    available_range: {
      min_value: 0,
      max_value: 100.0
    },
    description: '{rsi_level}{symbol}, < {rsi_reset_level2}{symbol}, > {rsi_reset_level1}{symbol}',
    value: [
      {
        index: 0,
        rsi_level: 70,
        rsi_reset_level2: 80,
        rsi_reset_level1: 60
      }
    ],
    [metaKey]: {
      defaults: [
        {
          index: 0,
          rsi_level: 70,
          rsi_reset_level2: 80,
          rsi_reset_level1: 60
        },
        {
          index: 1,
          rsi_level: 60,
          rsi_reset_level2: 70,
          rsi_reset_level1: 50
        },
        {
          index: 2,
          rsi_level: 50,
          rsi_reset_level2: 60,
          rsi_reset_level1: 40
        },
      ]
    }
  }
];

const DEFAULT_RSI_CONDITIONS = [
  ...RSI_CONDITIONS_LONG,
  ...RSI_CONDITIONS_SHORT
];

const STOCH_CONDITIONS_LONG = [
  {
    enabled: true,
    kind: AVAILABILITY.LONG,
    value: 1,
    [metaKey]: {
      boolean: true,
      padding: true
    }
  },
  {
    enabled: false,
    kind: STOCH_CONDITIONS_KIND_LONG.K_ONLY_CLOSED_BELOW,
    symbol: '%',
    description: '{value}{symbol}',
    available_range: {
      min_value: 0.01,
      max_value: 100.0
    },
    value: 20.0,
    [metaKey]: {
      dependsOn: [
        STOCH_CONDITIONS_KIND_LONG.K_D_CLOSED_BELOW,
        STOCH_CONDITIONS_KIND_LONG.K_D_BETWEEN,
        STOCH_CONDITIONS_KIND_LONG.K_CLOSED_ABOVE_D_BELOW,
        STOCH_CONDITIONS_KIND_LONG.K_D_CLOSED_BACK_ABOVE
      ],
      onValue: {
        le: [
          STOCH_CONDITIONS_KIND_LONG.K_D_ABOVE_LEVEL_DISARM
        ]
      }
    }
  },
  {
    enabled: false,
    kind: STOCH_CONDITIONS_KIND_LONG.K_D_CLOSED_BELOW,
    symbol: '%',
    description: '{value}{symbol}',
    available_range: {
      min_value: 0.01,
      max_value: 100.0
    },
    value: 20.0,
    [metaKey]: {
      dependsOn: [
        STOCH_CONDITIONS_KIND_LONG.K_ONLY_CLOSED_BELOW,
        STOCH_CONDITIONS_KIND_LONG.K_CLOSED_ABOVE_D_BELOW,
        STOCH_CONDITIONS_KIND_LONG.K_D_BETWEEN,
        STOCH_CONDITIONS_KIND_LONG.K_D_CLOSED_BACK_ABOVE
      ],
      onValue: {
        le: [
          STOCH_CONDITIONS_KIND_LONG.K_D_ABOVE_LEVEL_DISARM
        ]
      }
    }
  },
  {
    enabled: false,
    kind: STOCH_CONDITIONS_KIND_LONG.K_D_DISTANCE_TOLERANCE,
    symbol: '%',
    description: '{value}{symbol}',
    available_range: {
      min_value: 0.01,
      max_value: 15.0
    },
    value: 2.0,
    [metaKey]: {
      dependsOn: [
        STOCH_CONDITIONS_KIND_LONG.K_CLOSED_ABOVE_D_BELOW,
        STOCH_CONDITIONS_KIND_LONG.K_D_BETWEEN,
        STOCH_CONDITIONS_KIND_LONG.K_D_CLOSED_BACK_ABOVE
      ],
      dependsOnInversed: [
        STOCH_CONDITIONS_KIND_LONG.K_ONLY_CLOSED_BELOW,
        STOCH_CONDITIONS_KIND_LONG.K_D_CLOSED_BELOW,
      ]
    }
  },
  {
    enabled: false,
    kind: STOCH_CONDITIONS_KIND_LONG.K_CLOSED_ABOVE_D_BELOW,
    symbol: '%',
    description: '{value}{symbol}',
    available_range: {
      min_value: 0.01,
      max_value: 100.0
    },
    value: 20.0,
    [metaKey]: {
      dependsOn: [
        STOCH_CONDITIONS_KIND_LONG.K_ONLY_CLOSED_BELOW,
        STOCH_CONDITIONS_KIND_LONG.K_D_CLOSED_BELOW,
        STOCH_CONDITIONS_KIND_LONG.K_D_DISTANCE_TOLERANCE,
        STOCH_CONDITIONS_KIND_LONG.K_D_BETWEEN,
        STOCH_CONDITIONS_KIND_LONG.K_D_CLOSED_BACK_ABOVE
      ],
      onValue: {
        le: [
          STOCH_CONDITIONS_KIND_LONG.K_D_ABOVE_LEVEL_DISARM
        ]
      }
    }
  },
  {
    enabled: false,
    kind: STOCH_CONDITIONS_KIND_LONG.K_D_CLOSED_BACK_ABOVE,
    symbol: '%',
    description: '{value}{symbol}',
    available_range: {
      min_value: 0.01,
      max_value: 100.0
    },
    value: 20.0,
    [metaKey]: {
      dependsOn: [
        STOCH_CONDITIONS_KIND_LONG.K_D_BETWEEN,
        STOCH_CONDITIONS_KIND_LONG.K_CLOSED_ABOVE_D_BELOW,
        STOCH_CONDITIONS_KIND_LONG.K_D_DISTANCE_TOLERANCE,
        STOCH_CONDITIONS_KIND_LONG.K_ONLY_CLOSED_BELOW,
        STOCH_CONDITIONS_KIND_LONG.K_D_CLOSED_BELOW
      ],
      onValue: {
        le: [
          STOCH_CONDITIONS_KIND_LONG.K_D_ABOVE_LEVEL_DISARM
        ]
      }
    }
  },
  {
    enabled: false,
    kind: STOCH_CONDITIONS_KIND_LONG.K_D_BETWEEN,
    symbol: '%',
    description: '{min}{symbol}-{max}{symbol}',
    available_range: {
      min_value: 0.01,
      max_value: 100.0
    },
    value: {
      min_value: 20.0,
      max_value: 80.0
    },
    [metaKey]: {
      dependsOn: [
        STOCH_CONDITIONS_KIND_LONG.K_ONLY_CLOSED_BELOW,
        STOCH_CONDITIONS_KIND_LONG.K_D_CLOSED_BELOW,
        STOCH_CONDITIONS_KIND_LONG.K_D_DISTANCE_TOLERANCE,
        STOCH_CONDITIONS_KIND_LONG.K_CLOSED_ABOVE_D_BELOW,
        STOCH_CONDITIONS_KIND_LONG.K_D_CLOSED_BACK_ABOVE
      ],
      onValue: {
        le: [
          STOCH_CONDITIONS_KIND_LONG.K_D_ABOVE_LEVEL_DISARM
        ]
      }
    }
  },
  {
    enabled: true,
    kind: STOCH_CONDITIONS_KIND_LONG.K_D_ABOVE_LEVEL_DISARM,
    symbol: '%',
    description: '{value}{symbol}',
    available_range: {
      min_value: 0.01,
      max_value: 100.0
    },
    value: 50.0,
    [metaKey]: {
      onValue: {
        ge: [
          STOCH_CONDITIONS_KIND_LONG.K_ONLY_CLOSED_BELOW,
          STOCH_CONDITIONS_KIND_LONG.K_D_CLOSED_BELOW,
          STOCH_CONDITIONS_KIND_LONG.K_CLOSED_ABOVE_D_BELOW,
          STOCH_CONDITIONS_KIND_LONG.K_D_CLOSED_BACK_ABOVE,
          STOCH_CONDITIONS_KIND_LONG.K_D_BETWEEN
        ]
      }
    }
  },
  {
    enabled: false,
    kind: STOCH_CONDITIONS_KIND_LONG.K_D_DIRECTION_UP,
    value: 1,
    [metaKey]: {
      boolean: true,
      dependsOn: [
        STOCH_CONDITIONS_KIND_LONG.D_DIRECTION_UP,
        STOCH_CONDITIONS_KIND_LONG.K_DIRECTION_UP
      ]
    }
  },
  {
    enabled: false,
    kind: STOCH_CONDITIONS_KIND_LONG.D_DIRECTION_UP,
    value: 1,
    [metaKey]: {
      boolean: true,
      dependsOn: [
        STOCH_CONDITIONS_KIND_LONG.K_D_DIRECTION_UP,
        STOCH_CONDITIONS_KIND_LONG.K_DIRECTION_UP
      ]
    }
  },
  {
    enabled: false,
    kind: STOCH_CONDITIONS_KIND_LONG.K_DIRECTION_UP,
    value: 1,
    [metaKey]: {
      boolean: true,
      dependsOn: [
        STOCH_CONDITIONS_KIND_LONG.K_D_DIRECTION_UP,
        STOCH_CONDITIONS_KIND_LONG.D_DIRECTION_UP
      ]
    }
  }
];

const STOCH_CONDITIONS_SHORT = [
  {
    enabled: true,
    kind: AVAILABILITY.SHORT,
    value: 1,
    [metaKey]: {
      boolean: true,
      padding: true
    }
  },
  {
    enabled: false,
    kind: STOCH_CONDITIONS_KIND_SHORT.K_ONLY_CLOSED_ABOVE,
    symbol: '%',
    description: '{value}{symbol}',
    available_range: {
      min_value: 0.01,
      max_value: 100.0
    },
    value: 80.0,
    [metaKey]: {
      dependsOn: [
        STOCH_CONDITIONS_KIND_SHORT.K_D_CLOSED_ABOVE,
        STOCH_CONDITIONS_KIND_SHORT.K_CLOSED_BELOW_D_ABOVE,
        STOCH_CONDITIONS_KIND_SHORT.K_D_BETWEEN,
        STOCH_CONDITIONS_KIND_SHORT.K_D_CLOSED_BACK_BELOW
      ],
      onValue: {
        ge: [
          STOCH_CONDITIONS_KIND_SHORT.K_D_BELOW_LEVEL_DISARM
        ]
      }
    }
  },
  {
    enabled: false,
    kind: STOCH_CONDITIONS_KIND_SHORT.K_D_CLOSED_ABOVE,
    symbol: '%',
    description: '{value}{symbol}',
    available_range: {
      min_value: 0.01,
      max_value: 100.0
    },
    value: 80.0,
    [metaKey]: {
      dependsOn: [
        STOCH_CONDITIONS_KIND_SHORT.K_ONLY_CLOSED_ABOVE,
        STOCH_CONDITIONS_KIND_SHORT.K_CLOSED_BELOW_D_ABOVE,
        STOCH_CONDITIONS_KIND_SHORT.K_D_BETWEEN,
        STOCH_CONDITIONS_KIND_SHORT.K_D_CLOSED_BACK_BELOW
      ],
      onValue: {
        ge: [
          STOCH_CONDITIONS_KIND_SHORT.K_D_BELOW_LEVEL_DISARM
        ]
      }
    }
  },
  {
    enabled: false,
    kind: STOCH_CONDITIONS_KIND_SHORT.K_D_DISTANCE_TOLERANCE,
    symbol: '%',
    description: '{value}{symbol}',
    available_range: {
      min_value: 0.01,
      max_value: 15.0
    },
    value: 2.0,
    [metaKey]: {
      dependsOn: [
        STOCH_CONDITIONS_KIND_SHORT.K_CLOSED_BELOW_D_ABOVE,
        STOCH_CONDITIONS_KIND_SHORT.K_D_BETWEEN,
        STOCH_CONDITIONS_KIND_SHORT.K_D_CLOSED_BACK_BELOW
      ],
      dependsOnInversed: [
        STOCH_CONDITIONS_KIND_SHORT.K_ONLY_CLOSED_ABOVE,
        STOCH_CONDITIONS_KIND_SHORT.K_D_CLOSED_ABOVE
      ]
    }
  },
  {
    enabled: false,
    kind: STOCH_CONDITIONS_KIND_SHORT.K_CLOSED_BELOW_D_ABOVE,
    symbol: '%',
    description: '{value}{symbol}',
    available_range: {
      min_value: 0.01,
      max_value: 100.0
    },
    value: 80.0,
    [metaKey]: {
      dependsOn: [
        STOCH_CONDITIONS_KIND_SHORT.K_ONLY_CLOSED_ABOVE,
        STOCH_CONDITIONS_KIND_SHORT.K_D_CLOSED_ABOVE,
        STOCH_CONDITIONS_KIND_SHORT.K_D_DISTANCE_TOLERANCE,
        STOCH_CONDITIONS_KIND_SHORT.K_D_BETWEEN,
        STOCH_CONDITIONS_KIND_SHORT.K_D_CLOSED_BACK_BELOW
      ],
      onValue: {
        ge: [
          STOCH_CONDITIONS_KIND_SHORT.K_D_BELOW_LEVEL_DISARM
        ]
      }
    }
  },
  {
    enabled: false,
    kind: STOCH_CONDITIONS_KIND_SHORT.K_D_CLOSED_BACK_BELOW,
    symbol: '%',
    description: '{value}{symbol}',
    available_range: {
      min_value: 0.01,
      max_value: 100.0
    },
    value: 80.0,
    [metaKey]: {
      dependsOn: [
        STOCH_CONDITIONS_KIND_SHORT.K_D_BETWEEN,
        STOCH_CONDITIONS_KIND_SHORT.K_CLOSED_BELOW_D_ABOVE,
        STOCH_CONDITIONS_KIND_SHORT.K_D_DISTANCE_TOLERANCE,
        STOCH_CONDITIONS_KIND_SHORT.K_ONLY_CLOSED_ABOVE,
        STOCH_CONDITIONS_KIND_SHORT.K_D_CLOSED_ABOVE
      ],
      onValue: {
        ge: [
          STOCH_CONDITIONS_KIND_SHORT.K_D_BELOW_LEVEL_DISARM
        ]
      }
    }
  },
  {
    enabled: false,
    kind: STOCH_CONDITIONS_KIND_SHORT.K_D_BETWEEN,
    symbol: '%',
    description: '{min}{symbol}-{max}{symbol}',
    available_range: {
      min_value: 0.01,
      max_value: 100.0
    },
    value: {
      min_value: 20.0,
      max_value: 80.0
    },
    [metaKey]: {
      dependsOn: [
        STOCH_CONDITIONS_KIND_SHORT.K_ONLY_CLOSED_ABOVE,
        STOCH_CONDITIONS_KIND_SHORT.K_D_CLOSED_ABOVE,
        STOCH_CONDITIONS_KIND_SHORT.K_D_DISTANCE_TOLERANCE,
        STOCH_CONDITIONS_KIND_SHORT.K_CLOSED_BELOW_D_ABOVE,
        STOCH_CONDITIONS_KIND_SHORT.K_D_CLOSED_BACK_BELOW
      ],
      onValue: {
        ge: [
          STOCH_CONDITIONS_KIND_SHORT.K_D_BELOW_LEVEL_DISARM
        ]
      }
    }
  },
  {
    enabled: true,
    kind: STOCH_CONDITIONS_KIND_SHORT.K_D_BELOW_LEVEL_DISARM,
    symbol: '%',
    description: '{value}{symbol}',
    available_range: {
      min_value: 0.01,
      max_value: 100.0
    },
    value: 50.0,
    [metaKey]: {
      onValue: {
        le: [
          STOCH_CONDITIONS_KIND_SHORT.K_ONLY_CLOSED_ABOVE,
          STOCH_CONDITIONS_KIND_SHORT.K_D_CLOSED_ABOVE,
          STOCH_CONDITIONS_KIND_SHORT.K_CLOSED_BELOW_D_ABOVE,
          STOCH_CONDITIONS_KIND_SHORT.K_D_CLOSED_BACK_BELOW,
          STOCH_CONDITIONS_KIND_SHORT.K_D_BETWEEN
        ]
      }
    }
  },
  {
    enabled: false,
    kind: STOCH_CONDITIONS_KIND_SHORT.K_D_DIRECTION_DOWN,
    value: 1,
    [metaKey]: {
      boolean: true,
      dependsOn: [
        STOCH_CONDITIONS_KIND_SHORT.D_DIRECTION_DOWN,
        STOCH_CONDITIONS_KIND_SHORT.K_DIRECTION_DOWN
      ]
    }
  },
  {
    enabled: false,
    kind: STOCH_CONDITIONS_KIND_SHORT.D_DIRECTION_DOWN,
    value: 1,
    [metaKey]: {
      boolean: true,
      dependsOn: [
        STOCH_CONDITIONS_KIND_SHORT.K_D_DIRECTION_DOWN,
        STOCH_CONDITIONS_KIND_SHORT.K_DIRECTION_DOWN
      ]
    }
  },
  {
    enabled: false,
    kind: STOCH_CONDITIONS_KIND_SHORT.K_DIRECTION_DOWN,
    value: 1,
    [metaKey]: {
      boolean: true,
      dependsOn: [
        STOCH_CONDITIONS_KIND_SHORT.K_D_DIRECTION_DOWN,
        STOCH_CONDITIONS_KIND_SHORT.D_DIRECTION_DOWN
      ]
    }
  }
];

const DEFAULT_STOCH_CONDITIONS = [
  ...STOCH_CONDITIONS_LONG,
  ...STOCH_CONDITIONS_SHORT
];

const ENTRY_SYSTEM_CONDITIONS_LONG = [
  {
    enabled: false,
    kind: ENTRY_SYSTEM_CONDITIONS_KIND_LONG.TRIPLE_TOLERANCE,
    symbol: '',
    description: ' ',
    available_range: {},
    value: {
      candle_close: {
        enabled: false,
        kind: 'candle_close',
        description: ' ',
        value: CANDLE_CLOSE_LONG
      },
      candle_pattern: {
        enabled: true,
        kind: 'candle_pattern',
        description: ' ',
        value: []
      },
      above_line: {
        enabled: true,
        kind: 'above_line',
        symbol: ' Pts',
        description: '\u2265 {min}{symbol} \u2264 {max}{symbol}',
        available_range: {
          min_value: 0.01,
          max_value: 15.0
        },
        value: {
          min_value: 2,
          max_value: 6
        }
      },
      below_line: {
        enabled: true,
        kind: 'below_line',
        symbol: ' Pts',
        description: '\u2265 {min}{symbol} \u2264 {max}{symbol}',
        available_range: {
          min_value: 0.01,
          max_value: 15.0,
        },
        value: {
          min_value: 2,
          max_value: 6,
        }
      }
    }
  },
  {
    enabled: true,
    kind: ENTRY_SYSTEM_CONDITIONS_KIND_LONG.TRIPLE_CLOSE,
    symbol: '',
    description: ' ',
    available_range: {},
    value: {
      candle_close: {
        enabled: false,
        kind: 'candle_close',
        description: ' ',
        value: CANDLE_CLOSE_LONG
      },
      candle_pattern: {
        enabled: true,
        kind: 'candle_pattern',
        description: ' ',
        value: []
      },
      above_line: {
        enabled: true,
        kind: 'above_line',
        symbol: ' Pts',
        available_range: {
          min_value: 0.01,
          max_value: 15.0,
        },
        description: '\u2265 {value}{symbol}',
        value: 2,
      },
      below_line: {
        enabled: true,
        kind: 'below_line',
        symbol: ' Pts',
        available_range: {
          min_value: 0.01,
          max_value: 15.0,
        },
        description: '\u2265 {value}{symbol}',
        value: 2,
      }
    }
  }
]

const ENTRY_SYSTEM_CONDITIONS_SHORT = [
  {
    enabled: false,
    kind: ENTRY_SYSTEM_CONDITIONS_KIND_SHORT.TRIPLE_TOLERANCE,
    symbol: '',
    description: ' ',
    available_range: {},
    value: {
      candle_close: {
        enabled: false,
        kind: 'candle_close',
        description: ' ',
        value: CANDLE_CLOSE_SHORT
      },
      candle_pattern: {
        enabled: true,
        kind: 'candle_pattern',
        description: ' ',
        value: []
      },
      above_line: {
        enabled: true,
        kind: 'above_line',
        symbol: ' Pts',
        description: '\u2265 {min}{symbol} \u2264 {max}{symbol}',
        available_range: {
          min_value: 0.01,
          max_value: 15.0
        },
        value: {
          min_value: 2,
          max_value: 6
        }
      },
      below_line: {
        enabled: true,
        kind: 'below_line',
        symbol: ' Pts',
        description: '\u2265 {min}{symbol} \u2264 {max}{symbol}',
        available_range: {
          min_value: 0.01,
          max_value: 15.0,
        },
        value: {
          min_value: 2,
          max_value: 6,
        }
      }
    }
  },
  {
    enabled: true,
    kind: ENTRY_SYSTEM_CONDITIONS_KIND_SHORT.TRIPLE_CLOSE,
    symbol: '',
    description: ' ',
    available_range: {},
    value: {
      candle_close: {
        enabled: false,
        kind: 'candle_close',
        description: ' ',
        value: CANDLE_CLOSE_SHORT
      },
      candle_pattern: {
        enabled: true,
        kind: 'candle_pattern',
        description: ' ',
        value: []
      },
      above_line: {
        enabled: true,
        kind: 'above_line',
        symbol: ' Pts',
        description: '\u2265 {value}{symbol}',
        value: 2,
      },
      below_line: {
        enabled: true,
        kind: 'below_line',
        symbol: ' Pts',
        description: '\u2265 {value}{symbol}',
        value: 2,
      }
    }
  }
]

export const DEFAULT_ENTRY_SYSTEM_CONDITIONS = [
  ...ENTRY_SYSTEM_CONDITIONS_LONG,
  ...ENTRY_SYSTEM_CONDITIONS_SHORT
]

export const CONDITIONS_DEFAULTS = {
  rsi: DEFAULT_RSI_CONDITIONS,
  stoch: {
    prearming: DEFAULT_STOCH_CONDITIONS,
    trigger: DEFAULT_STOCH_CONDITIONS.map(cond => (
      // hide some conditions for stoch trigger
      !cond.kind.endsWith('disarm') && !cond.kind.includes('closed_back')
        ? cond
        : { ...cond, enabled: false, [metaKey]: { hidden: true } }
    )).map(cond => {
      const copy = _.cloneDeep(cond)

      //  --- logic for changing conditions fields ---

      if ([STOCH_CONDITIONS_KIND_LONG.K_D_DISTANCE_TOLERANCE,
        STOCH_CONDITIONS_KIND_SHORT.K_D_DISTANCE_TOLERANCE].includes(copy.kind)) {
        copy.available_range.max_value = 40.0
      }

      //  --- logic for changing conditions dependecies ---

      const triggerDependsOnInversed = {
        LONG: [
          ['K_D_DISTANCE_TOLERANCE', 'K_D_BETWEEN'],
          ['K_D_DIRECTION_UP', 'K_D_BETWEEN'],
          ['D_DIRECTION_UP', 'K_D_BETWEEN'],
          ['K_DIRECTION_UP', 'K_D_BETWEEN']
        ],
        SHORT: [
          ['K_D_DISTANCE_TOLERANCE', 'K_D_BETWEEN'],
          ['K_D_DIRECTION_DOWN', 'K_D_BETWEEN'],
          ['D_DIRECTION_DOWN', 'K_D_BETWEEN'],
          ['K_DIRECTION_DOWN', 'K_D_BETWEEN']
        ]
      }

      const triggerDependsOnDisable = {
        LONG: [
          ['K_D_DISTANCE_TOLERANCE', 'K_ONLY_CLOSED_BELOW'],
          ['K_D_DISTANCE_TOLERANCE', 'K_D_CLOSED_BELOW'],
          ['K_D_DISTANCE_TOLERANCE', 'K_D_BETWEEN'],
        ],
        SHORT: [
          ['K_D_DISTANCE_TOLERANCE', 'K_D_CLOSED_ABOVE'],
          ['K_D_DISTANCE_TOLERANCE', 'K_ONLY_CLOSED_ABOVE'],
          ['K_D_DISTANCE_TOLERANCE', 'K_D_BETWEEN'],
        ],
      }

      // disable any addictional inversed dependencies for trigger
      if (copy[metaKey]?.dependsOnInversed) {
        delete copy[metaKey]?.dependsOnInversed
      }
      copy[metaKey].dependsOnInversed = []

      // enable additional inversed dependencies for trigger
      for (const [type, deps] of Object.entries(triggerDependsOnInversed)) {
        const pos = type === 'LONG' ? STOCH_CONDITIONS_KIND_LONG : STOCH_CONDITIONS_KIND_SHORT
        for (const dep of deps) {
          const from = dep[0]
          const to = dep[1]
          if (cond.kind === pos[from]) {
            copy[metaKey].dependsOnInversed.push(pos[to])
          }
        }
      }

      // disable additional dependencies for trigger
      for (const [type, deps] of Object.entries(triggerDependsOnDisable)) {
        const pos = type === 'LONG' ? STOCH_CONDITIONS_KIND_LONG : STOCH_CONDITIONS_KIND_SHORT
        for (const dep of deps) {
          for (const [from, to] of _(dep).permutations(2)) {
            if (cond.kind === pos[from]) {
              copy[metaKey].dependsOn = copy[metaKey].dependsOn.filter(dep => dep !== pos[to])
            }
          }
        }
      }

      return copy
    })
  }
};
