import _ from 'lodash';
import * as providers from 'providers/apiHistoryProvider';
import { CandleStore, RsiStore, StochStore, TrendDisarmStore } from './indicators';
import { SOCKET_IO_CONFIG } from 'services/socketIOService/consts';
import { flow, makeObservable } from 'mobx';
import { TimeframeGrouper } from './tfGrouper';
import { ArmingLineStore } from './armingLine';

// Store is used to aggregate all data related to chart
export default class ChartStore {
  constructor () {
    makeObservable(this, {
      goToByPercentage: flow,
      downloadFile: flow,
      download: flow,
      newPage: flow,
      goTo: flow,
    });

    this.candle = new CandleStore(SOCKET_IO_CONFIG.candle, providers.candle);
    this.rsi = new RsiStore(SOCKET_IO_CONFIG.rsi, providers.rsi);
    this.stoch = new StochStore(SOCKET_IO_CONFIG.stoch, providers.stoch);
    this.armingLine = new ArmingLineStore(this);
    this.trendDisarm = new TrendDisarmStore(this);

    this.orderedStores = [this.candle, this.rsi, this.stoch];
    this.tfGrouper = new TimeframeGrouper(this.orderedStores);
  }

  * downloadFile () {
    const candleParams = this.candle?.params;
    const params = {
      rsiSetId: this.rsi?.params?.setId,
      stochSetId: this.stoch?.params?.setId,
      candleSetId: candleParams?.setId,
      kind: candleParams?.kind,
      runtimeId: candleParams?.runtimeId,
    }
    const data = yield providers.chartCSVApiProvider(params)
    return data
  }

  * download (index, storeInjector) {
    // [armingLines.long, armingLines.short].forEach(store => store.clear());
    const result = yield Promise.all(this.tfGrouper.groups[index].map(storeInjector));
    const isRetrieved = result.some(e => e);
    if (this.tfGrouper.groupedByPeriod[index].includes(1) && isRetrieved) {
      yield Promise.all([
        this.armingLine.fetchData(),
        ..._.values(this.root.paintingLogsCandleCloseStore).map(store => store.followRSI())
      ])
    }
    return isRetrieved;
  }

  * newPage (index, direction) {
    return yield this.download(index, store => store[direction]());
  }

  * goTo (index, to) {
    const isExists = yield this.download(index, store => store.goTo(to));
    const { isRightReached, isLeftReached } = this.orderedStores[index];
    return { isExists, isRightReached, isLeftReached };
  }

  * goToByPercentage (percent) {
    this.armingLine.stores.forEach(store => store.clear());

    yield Promise.all(this.tfGrouper.groupedByPeriod.map((group, index) =>
      this.download(index, store => store.goToByPercentage(percent))
    ));
  }

  unsubscribe () {
    this.orderedStores.forEach(store => store.unsubscribe());
  }
}
