import { action, computed, flow, makeObservable, observable, toJS } from 'mobx';
import _ from 'lodash';
import { SocketIOService } from 'services';

import { States, DEFAULT_COUNT } from 'globalConstants';
import { DateTime } from 'luxon';

export default class IndicatorStore {
  streamingData = new Map();
  historyData = [];

  isRightReached = true
  isLeftReached = false

  state = States.Pending;
  params = {};
  lastSeriesTimestamp = null;
  dateRange = {
    /** @type {DateTime | null} */ startPeriod: null,
    /** @type {DateTime | null} */ endPeriod: null,
  };

  constructor (socketIOConfig, historyProvider) {
    makeObservable(this, {
      streamingData: observable,
      historyData: observable,
      isRightReached: observable,
      isLeftReached: observable,
      state: observable,
      dateRange: observable,
      params: observable,
      fetchData: flow,
      clear: action,
      unsubscribe: action,
      toLeft: flow,
      toRight: flow,
      goTo: flow,
      goToByPercentage: flow,
      median: computed,
      setState: action,
      setParams: action,
      addRecord: action,
      streaming: computed,
      history: computed,
      allData: computed,
      isLoading: computed,
      lastSeriesTimestamp: observable,
    });
    this.socketIOService = new SocketIOService(socketIOConfig);
    this.historyProvider = historyProvider;
  }

  _emulateRealtimeUpdates () {
    setInterval(() => {
      if (this.allData.length < 21) return;
      const copy = _.nth(this.allData, -20);
      this.addRecord({ ...copy, timestamp: copy.timestamp + 20 * this.params.period });
    }, 1000);
  }

  * fetchData (params) {
    this.clear();
    this.params = Object.assign(this.params || {}, params);
    try {
      this.setState(States.Loading);
      const { data: payload } = yield this.historyProvider(this.params, true);
      const { set, is_end_reached: isRightReached, is_begin_reached: isLeftReached, start_period: startPeriod, end_period: endPeriod } = payload;
      this.dateRange = {
        startPeriod: DateTime.fromSeconds(startPeriod),
        endPeriod: endPeriod ? DateTime.fromSeconds(endPeriod) : DateTime.now()
      };
      if (set.length) {
        this.historyData = set;
        this.lastSeriesTimestamp = set[set.length - 1].timestamp;
      }
      this.isLeftReached = isLeftReached;
      this.isRightReached = isRightReached;
      this.setState(States.Done);
    } catch (e) {
      console.log(e)
      this.setState(States.Error);
    }
    this.listeningSocketIO();
  }

  clear () {
    this.streamingData.clear();
    this.historyData.clear();
  }

  listeningSocketIO = () => {
    if (!this.params.isRealtime) return;
    this.socketIOService.unsubscribe();
    const throttled = _.throttle(this.addRecord, 2000)
    this.socketIOService.subscribe(this.params, throttled);
    this.streamingData.clear()
  }

  unsubscribe () {
    return this.socketIOService.unsubscribe();
  }

  * toLeft () {
    if (this.isLeftReached) return;
    const median = this.median.timestamp;
    yield this.fetchData({
      from: undefined,
      count: DEFAULT_COUNT,
      to: median
    });
    this.isRightReached = false;
    this.isLeftReached = this.historyData.length < DEFAULT_COUNT;
    return this.historyData.length !== 0;
  };

  * toRight () {
    if (this.isRightReached) return;
    const median = this.median.timestamp;
    yield this.fetchData({
      from: median,
      count: DEFAULT_COUNT,
      to: undefined
    });
    this.isLeftReached = false;
    this.isRightReached = this.historyData.length < DEFAULT_COUNT;
    return this.historyData.length !== 0;
  };

  * goTo (selectedDate, params) {
    if (params) {
      this.params = Object.assign(this.params || {}, params);
    }
    const from = selectedDate - this.params.period * 1000;
    const to = selectedDate + this.params.period * 1000;
    yield this.fetchData({ from, to, count: undefined })

    // lines below fix HSG-1898, but they cause HSG-2002,
    // so comment it and if first bug will reproduce - find another fix

    // this.isLeftReached = this.isLeftReached || (_.nth(this.historyData, 0).timestamp - from) >= this.params.period;
    // this.isRightReached = this.isRightReached || (to - _.nth(this.historyData, -1).timestamp) >= this.params.period;

    return this.historyData.length !== 0;
  }

  * goToByPercentage (percent) {
    this.params.from = null;
    this.params.to = null;
    yield this.fetchData({ percent, count: DEFAULT_COUNT });
    this.isLeftReached = percent === 0 || this.historyData.length !== DEFAULT_COUNT;
    this.isRightReached = percent === 100 || this.historyData.length !== DEFAULT_COUNT;
    this.params.percent = undefined;
    return true;
  }

  get median () {
    const index = Math.floor(this.allData.length / 2);
    return this.allData[index];
  }

  setState (value) {
    this.state = value;
  }

  setParams (params) {
    this.params = Object.assign(this.params || {}, params);
  }

  addRecord = obj => {
    // avoid filling streaming data during the rsi/candle tf switching
    if (obj.timeframe !== this.params.period || !this.isRightReached) return;

    if (!this.lastSeriesTimestamp || obj.timestamp >= this.lastSeriesTimestamp) {
      this.streamingData.set(obj.timestamp, obj);
      this.lastSeriesTimestamp = obj.timestamp;
    }
  }

  get streaming () {
    if (!this.isRightReached) return [];
    return Array.from(toJS(this.streamingData.values()));
  }

  get history () {
    return toJS(this.historyData);
  }

  get isLoading () {
    return this.state === States.Loading;
  }

  get allData () {
    if (this.isLoading) return [];
    return [...this.history, ...this.streaming];
  }
}
