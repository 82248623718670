import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import _ from 'lodash';
import { DateTime } from 'luxon'

import { useStore } from 'hooks';

export const MIN_DATE = DateTime.fromISO('2019-10-17');

const mapFilters = filter => ({
  id: filter,
  value: filter
})

export function useGetFilters ({ data, lastFilters }) {
  const intl = useIntl();
  // use ' ' as id for all, because such name is incorrect for module name
  const defaultSelectValue = { id: ' ', value: intl.formatMessage({ id: 'trade.history.filter.all' }) };

  const getFilters = _useGetFilters({ data, lastFilters, defaultSelectValue });
  const instrumentsFilter = _useInstrumentsFilter({ data, defaultSelectValue, intl });

  return { getFilters, instrumentsFilter };
}

function _useInstrumentsFilter ({ data, defaultSelectValue, intl }) {
  const { instrumentsStore } = useStore();

  return useMemo(() => {
    if (!instrumentsStore.instruments.length) {
      return [defaultSelectValue];
    }

    return [defaultSelectValue]
      .concat(data?.filters?.instruments?.map(instrument => ({
        id: instrument,
        value: intl.formatMessage({ id: _(instrumentsStore.instruments).find({ id: instrument })?.label }),
      })) || []);
  }, [data?.filters?.instruments]);
}

function _useGetFilters ({ data, lastFilters, defaultSelectValue }) {
  return function (fieldName, isFirstAll = true) {
    if (!data?.filters || !data?.filters[fieldName]) {
      return [defaultSelectValue];
    }

    let filters = [];

    if (fieldName === 'versions' && lastFilters?.moduleName?.trim()) {
      filters = data.filters[fieldName][lastFilters?.moduleName].map(mapFilters) || [];
    } else if (fieldName !== 'versions') {
      filters = data.filters[fieldName].map(mapFilters) || [];
    }

    return isFirstAll ? [defaultSelectValue].concat(filters) : filters;
  };
}
