export const MODULE_API_URL = 'strategies/';
export const DATA_EDITOR_ARCHIVE_FORMATION = 'data_editor';
export const SINGLE_MODULE_API_URL = id => `${MODULE_API_URL}${id}/`;
export const DUPLICATE_MODULE_API_URL = id => `${SINGLE_MODULE_API_URL(id)}duplicate/`
export const RUNTIMES_URL = 'api/v1/runtimes/';
export const MOVE_TO_SAVE_API_URL = `${RUNTIMES_URL}move_to_save_mode/`;
export const MOVE_TO_DEMO_API_URL = `${RUNTIMES_URL}demo/`;
export const MOVE_TO_ACTIVE_API_URL = `${RUNTIMES_URL}active/`;
export const STOP_MODULE = id => `${RUNTIMES_URL}${id}/hard_stop/`;
export const STOP_SYSTEM = `${RUNTIMES_URL}hard_stop/`;
