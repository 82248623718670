import React from 'react';
import PropTypes from 'prop-types';

import NavBar from 'components/NavBar';
import SideBar from 'components/SideBar';

import './styles.scss';

const AuthorizedLayout = ({ children }) => {
  return (
    // TO DO: remove without-sidebar class after SideBar implementation
    <div className="main-wrapper without-sidebar">
      <NavBar />
      <div id="scrollable-page" className="main-wrapper__content-wrap">
        <div className="main-wrapper__content">
          {/* SideBar was hidden by css without-sidebar class */}
          <SideBar />
          <div className="main-content">
            <div className="main-content__left-bar">{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

AuthorizedLayout.propTypes = {
  children: PropTypes.any
};

export default AuthorizedLayout;
