import React from 'react';
import PropTypes from 'prop-types';
import DescriptionRow from '../../EntrySystem/Patterns/Common/DescriptionRow';
import { FormattedMessage, useIntl } from 'react-intl';
import * as pictures from 'assets/images/candlePatterns';
import { CandlePatternRule } from '../CandlePatternRule';
import { CandlePatternTitle } from '../CandlePatternTitle';

import './styles.scss';
import { useField } from 'react-final-form';

const PatternRelation = ({ relation }) => {
  const intl = useIntl();
  return (
    <FormattedMessage
      id="candlePatterns.relation"
      tagName="p"
      values={{
        candle_from: intl.formatMessage({ id: 'candlePatterns.relationNumber' }, { number: relation.candle_from }),
        from_type: intl.formatMessage({ id: `candlePatterns.${relation.from_type}` }),
        symbol: relation.symbol,
        percent: relation.percent,
        candle_to: intl.formatMessage({ id: 'candlePatterns.relationNumber' }, { number: relation.candle_to }),
        to_type: intl.formatMessage({ id: `candlePatterns.${relation.to_type}` })
      }}
    />
  );
};

PatternRelation.propTypes = {
  relation: PropTypes.object.isRequired,
  index: PropTypes.number
};

export const CandlePattern = React.memo(({ patternName, index, handleDelete, isLogicEditor }) => {
  const patternField = useField(patternName);
  const pattern = patternField.input.value;

  return <div>
    <CandlePatternTitle
      index={index}
      isLogicEditor={isLogicEditor}
      patternName={patternName}
      handleDelete={handleDelete}
    />
    <div className="candle-pattern">
      <div className="candle-pattern-image">
        <div className="candle-pattern-image">
          {pictures[pattern.kind] || (pattern.image && (
            <img src={pattern.image} alt="" />
          ))}
        </div>
      </div>
      <div className="candle-pattern-info">
        <div className="candle-pattern-info-top">
          {pattern.value.candles.map((rule, ruleIndex) => (
            <div key={ruleIndex} className="candle-pattern-rule">
              <CandlePatternRule rule={rule} index={ruleIndex} />
            </div>
          ))}
        </div>
        <div className="candle-pattern-info-bottom">
          <div className="candle-pattern-settings">
            <h3 className="bold">
              <FormattedMessage id="module.candlePatterns.settings" />
            </h3>
            <DescriptionRow
              field={{ ...pattern.value.settings, kind: 'additional_points' }}
              prefix="candlePatterns"
              descriptionId="candlePatterns.additional_points"
            />
          </div>
          <div className="candle-pattern-relations">
            <h3 className="bold">
              <FormattedMessage id="module.candlePatterns.relations" />
            </h3>
            {pattern.value.relations.map((r, relationIndex) => (
              <PatternRelation key={relationIndex} relation={r} />
            ))}
          </div>
        </div>
      </div>
    </div>
  </div>
});

CandlePattern.displayName = 'CandlePattern';

CandlePattern.propTypes = {
  patternName: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  isLogicEditor: PropTypes.bool,
  handleDelete: PropTypes.func
};
