import React from 'react';
import { defaultHeaderColumnClasses, globalClassNames, globalFormatters } from 'globalConstants/formatters';
import { intl } from 'translations/provider';
import { toJS } from 'mobx';
import moment from 'moment';
import { DateTime } from 'luxon';

const {
  identity,
  instrument: insFormatter,
  time,
} = globalFormatters;

const {
  left,
} = globalClassNames;

const localFormatters = {
  instrumentsList: ({ value, instruments }) => (
    <div className="table-data-list-wrap">
      <ul className="table-data-list">
        {value.map((id) => (
          <li className="table-data-list__item" key={id}>
            {insFormatter({ value: id, instruments })}
          </li>
        ))}
      </ul>
    </div>
  ),
  dataList: ({ value }) => (
    <div className="table-data-list-wrap">
      <ul className="table-data-list table-data-list">
        {value.map((id) => (
          <li className="table-data-list__item" key={id}>
            {value && intl.formatMessage({ id: `trade-exclusion.table.${id}` })}
          </li>
        ))}
      </ul>
    </div>
  ),
  formatValue: ({ value }) => {
    return value && intl.formatMessage({ id: `trade-exclusion.table.${value}` })
  },
  timeFrameRange: ({ value, row }) => {
    if (!(row.timeframeStart && row.timeframeEnd)) return ''
    return `${row.timeframeStart} - ${row.timeframeEnd}`
  },
  frequencyPeriod: ({ value, row }) => {
    let result = intl.formatMessage({ id: `trade-exclusion.table.${value}` });
    const frequencyDay = row.frequencyDay ? intl.formatMessage({ id: `trade-exclusion.table.${row.frequencyDay}` }) : '';
    const frequencySequence = row.frequencySequence ? intl.formatMessage({ id: `trade-exclusion.table.${row.frequencySequence}` }) : '';

    if (value === 'daily') {
      return result;
    }

    if ((value === 'weekly' || value === 'monthly') && row.frequencyDay) {
      result += ` - ${frequencyDay}`;
    }

    if (value === 'monthly' && row.frequencySequence) {
      result += ` - ${frequencySequence}`;
    }

    return result;
  },
  formatTime: ({ value }) => {
    return DateTime.fromFormat(value, 'HH:mm:ss', { zone: 'utc' }).setZone('default').toFormat('HH:mm')
  }
}

export const ROW_COLUMNS = [
  { accessor: 'title', formatter: identity, className: left },
  { accessor: 'instruments', formatter: localFormatters.instrumentsList, className: left },
  { accessor: 'timeCycle', formatter: localFormatters.formatValue, className: left },
  { accessor: 'timeframeStart', formatter: localFormatters.timeFrameRange, className: left },
  { accessor: 'startTime', formatter: localFormatters.formatTime, className: left },
  { accessor: 'finishTime', formatter: localFormatters.formatTime, className: left },
  { accessor: 'frequencyPeriod', formatter: localFormatters.frequencyPeriod, className: left },
  { accessor: 'action', formatter: localFormatters.formatValue, className: left },
];

export const HEADER_COLUMNS = [
  { classes: defaultHeaderColumnClasses, attribute: 'title' },
  { classes: defaultHeaderColumnClasses, attribute: 'instrument' },
  { classes: defaultHeaderColumnClasses, attribute: 'time-cycle' },
  { classes: defaultHeaderColumnClasses, attribute: 'time-frame-range' },
  { classes: defaultHeaderColumnClasses, attribute: 'start-time' },
  { classes: defaultHeaderColumnClasses, attribute: 'finish-time' },
  { classes: defaultHeaderColumnClasses, attribute: 'frequency' },
  { classes: defaultHeaderColumnClasses, attribute: 'actions' },
];
