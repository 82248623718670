import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import TradeExclusionRow from './TradeExclusionRow';

import './styles.scss';

const TradeExclusionTable = ({ data, headerColumns, rowColumns, handlers, isStickyHeader, instruments }) => {
  return (
    <div className="trade-exclusion">
      <table className="app-table">
        <thead className="app-table__head">
          <tr>
            {headerColumns.map(({ attribute, classes }, index) => (
              <th key={index} className={classes} style={{ textAlign: 'left' }}>
                <FormattedMessage id={`trade-exclusion.${attribute}`} defaultMessage=" " />
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="app-table__content">
          {data.map((row) => (
            <TradeExclusionRow
              key={row.id}
              row={row}
              rowColumns={rowColumns}
              handlers={handlers}
              instruments={instruments}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

TradeExclusionTable.propTypes = {
  data: PropTypes.array,
  headerColumns: PropTypes.array,
  instruments: PropTypes.array,
  rowColumns: PropTypes.array,
  handlers: PropTypes.object,
  isStickyHeader: PropTypes.bool,
}

export default TradeExclusionTable;
