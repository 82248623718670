import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { Input } from 'components/Fields';
import DialogForm from 'components/DialogForm';
import { Form, Field } from 'react-final-form';
import _ from 'lodash';
import './styles.scss';

export default class ConditionForm extends React.PureComponent {
  handleSubmit = (values) => {
    if ('value' in values) {
      this.props.handleSave(this.props.obj, parseFloat(values.value));
    } else {
      const value = {
        min_value: parseFloat(values.min_value),
        max_value: parseFloat(values.max_value)
      };
      this.props.handleSave(this.props.obj, value);
    }
  };

  isValid = (value) => {
    const { obj } = this.props;
    const { available_range: availableRange } = obj;
    const regex = /^\d+(\.)?\d*$/;

    value = value.toString()

    if (value === '' || value.indexOf(' ') >= 0) {
      return <FormattedMessage id="modules.validation.empty" />;
    } else if (!regex.test(value)) {
      return <FormattedMessage id="modules.validation.onlyNumeric" />;
    } else if (availableRange) {
      const min = parseFloat(availableRange.min_value);
      const max = parseFloat(availableRange.max_value);
      if (+value < min || +value > max) {
        return <FormattedMessage id="modules.validation.validRange" />;
      }
    }
  };

  validate = (values) => {
    const errors = {};

    // use initial value because min/max disapear when they're empty
    const isMultiple = _.isObject(this.props.obj.value);

    if (isMultiple) {
      const minValue = values.min_value ?? '';
      const maxValue = values.max_value ?? '';
      errors.min_value = this.isValid(minValue);
      errors.max_value = this.isValid(maxValue);
      if ((+minValue && +maxValue) && +minValue > +maxValue) {
        errors.min_value = (
          <FormattedMessage id={'modules.validation.less'} />
        );
      }
    } else {
      const value = values.value ?? '';
      errors.value = this.isValid(value);
    }
    return errors;
  };

  getSignFromDescription = (description) => {
    if (description.indexOf('≥') !== -1) {
      return '≥';
    }

    if (description.indexOf('≤') !== -1) {
      return '≤';
    }

    if (description.indexOf('≤') === -1 || description.indexOf('≥') === -1) {
      return '';
    }
  };

  getIntervalSign = (value, description) => {
    let sign = null;

    switch (value) {
      case 'value':
        sign = this.getSignFromDescription(description);
        break;
      case 'min_value':
        sign = '≥';
        break;
      case 'max_value':
        sign = '≤';
        break;
      default:
        sign = null;
    }

    return sign;
  };

  content = (values, handleSubmit) => {
    const { obj } = this.props;
    const { symbol } = obj;

    return (
      <form className="condition-form-content" onSubmit={handleSubmit}>
        <div className="form">
          {Object.keys(values).map((name, index) => (
            <div key={name} className="condition-form-content__field">
              <div
                className={classNames('condition-form-content__sign bold', {
                  hidden: this.getIntervalSign(name, obj.description) === ''
                })}
              >
                {this.getIntervalSign(name, obj.description)}
              </div>
              <Field
                withHelper
                key={name}
                name={name}
                component={Input}
                autoFocus={index === 0}
              />
              <div className="condition-form-content__symbol bold">
                {symbol}
              </div>
            </div>
          ))}
          <input type="submit" hidden />
        </div>
      </form>
    );
  };

  title = () => {
    const { obj, prefix } = this.props;
    const { symbol, available_range: availableRange } = obj;

    return (
      <div className="condition-form-header">
        <div className="title2 bold">
          <FormattedMessage id={`${prefix}.conditions.${obj.kind}`} />
        </div>

        {availableRange && (
          <span className="condition-form-header__description">
            Allowable range: {availableRange.min_value} —{' '}
            {availableRange.max_value}
            {symbol}
          </span>
        )}
      </div>
    );
  };

  controls = (isDisabled, handleSubmit) => {
    return (
      <div className="custom-dialog__actions">
        <button
          type="button"
          onClick={this.props.handleClose}
          className="primary-btn outline-btn form-action-btn bold"
        >
          <FormattedMessage id="modules.buttons.cancel" />
        </button>
        <button
          type="button"
          onClick={handleSubmit}
          disabled={isDisabled}
          className="primary-btn main-btn form-action-btn bold"
        >
          <FormattedMessage id="modules.buttons.save" />
        </button>
      </div>
    );
  };

  render () {
    const { open, obj, handleClose } = this.props;
    const initialValues =
      _.isObject(obj?.value) ? obj?.value : { value: obj?.value };

    if (_.isEmpty(obj)) {
      return null;
    }

    return open && (
      <Form
        onSubmit={this.handleSubmit}
        initialValues={initialValues}
        validate={this.validate}
        render={({ handleSubmit, invalid }) => (
          <DialogForm
            open={open}
            title={this.title()}
            content={this.content(initialValues, handleSubmit)}
            controls={this.controls(invalid, handleSubmit)}
            handleClose={handleClose}
            customClass="condition-form-popup" />
        )}
      />
    );
  }
}

ConditionForm.propTypes = {
  open: PropTypes.bool,
  obj: PropTypes.object,
  prefix: PropTypes.string,
  handleClose: PropTypes.func,
  handleSave: PropTypes.func
};
