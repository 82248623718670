import { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';

import { useStore } from 'hooks';

export function useLazyLoad (chart) {
  [0, 1, 2].forEach(dataZoomIndex => _useLazyLoad({ chart, dataZoomIndex }))
}

function _useLazyLoad ({ chart, dataZoomIndex }) {
  const { chartStore } = useStore();
  const data = chartStore.tfGrouper.groups[dataZoomIndex]?.[0]?.allData;
  const [zoom, setZoom] = useState([null, null]);
  const [isBlocked, setIsBlocked] = useState(undefined);

  const lazyLoad = useCallback(params => {
    if (params.source === 'select-date') return;
    if (params.source === 'slider') return;
    const dataZoom = chart.getOption().dataZoom[dataZoomIndex];
    if (!dataZoom || !_.isUndefined(isBlocked)) return

    if ( // stop process lazy load on dataZoom index miss; 1st for mouse zoom, 2nd for tal buttons
      params?.batch?.[0]?.dataZoomId !== `dataZoom${dataZoomIndex}` &&
      params.dataZoomIndex !== dataZoomIndex
    ) return;

    console.log(dataZoom.start, dataZoom.end)
    const currentZoom = [data[dataZoom.startValue]?.timestamp, data[dataZoom.endValue]?.timestamp]
    if (!_.every(currentZoom)) return;
    setZoom(currentZoom);

    if (dataZoom.start <= 20) {
      setIsBlocked(null);
      chartStore.newPage(dataZoomIndex, 'toLeft').then(setIsBlocked)
    }
    if (dataZoom.end >= 80) {
      setIsBlocked(null);
      chartStore.newPage(dataZoomIndex, 'toRight').then(setIsBlocked)
    }
  }, [chart, zoom, setZoom, dataZoomIndex, isBlocked, data])

  useEffect(() => {
    if (_.isNil(isBlocked)) return;
    isBlocked && chart.dispatchAction({
      type: 'dataZoom',
      dataZoomIndex,
      startValue: _.sortedIndexBy(data, { timestamp: zoom[0] }, 'timestamp'),
      endValue: _.sortedIndexBy(data, { timestamp: zoom[1] }, 'timestamp'),
      source: 'lazy-load',
    })
    setIsBlocked(undefined);
  }, [isBlocked, chart, data]);

  useEffect(() => {
    if (!chart || !data) return;
    chart.on('datazoom', { dataZoomIndex }, lazyLoad);
    return () => { chart.off('datazoom', lazyLoad) };
  }, [chart, lazyLoad]);
}
