import React, { useRef, useLayoutEffect, useState, useCallback } from 'react';
import propTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Scrollbars } from 'react-custom-scrollbars';
import { FormattedMessage } from 'react-intl';
import hasLoader from 'hocs/hasLoader';
import Loader from 'components/Loader';
import { useTimezone } from 'hooks';
import _ from 'lodash';

import './styles.scss';
import cn from 'classnames';
import { useAdjustColumn } from './hooks';
import { COLUMNS } from '../consts';
import LogsStore from 'store/logsStore';

import { isHighlight } from '../helpers';

const LogsTable = ({ store, scroll, changeScroll, position, controls, module, tfs }) => {
  useTimezone();
  const logs = store.allData;
  const { tBodyRef, tHeadRowRef } = useAdjustColumn();
  const { patternTitles, settingsValues } = module;

  // Store & restore scrolling position
  const scrollRef = useRef();
  useLayoutEffect(() => {
    if (_.isEmpty(logs) || !tBodyRef.current) return;
    if (_.isNumber(scroll)) {
      scrollRef.current.view.scroll({ top: scroll });
    } else if (_.isNumber(+scroll)) {
      const foundDOMNode = tBodyRef.current?.childNodes[+scroll];
      foundDOMNode && scrollRef.current.scrollTop(foundDOMNode.offsetTop);
    }
  }, [position, scroll]);

  const [pos, setPos] = useState(0);
  const [changer, setChanger] = useState(() => changeScroll);
  useLayoutEffect(() => {
    changer(pos);
    setChanger(() => changeScroll);
  }, [position]);

  const cols = COLUMNS[position.value].filter(
    ({ accessor, isEnabled }) => isEnabled(accessor, store.paramsStates, logs));

  const showTableValue = useCallback(accessor => {
    if (!/^(RSI|STOCH)/.test(position.value)) return;

    // Hide RSI trigger value
    if (
      accessor === 'rsi_trade_arming_support_line_extension' ||
      accessor === 'rsi_trade_arming_resistance_line_extension'
    ) return

    const isRSI = position.value.includes('RSI');
    const period = isRSI ? controls.rsiPeriod : controls.stochPeriod;
    const kind = `${position.nameSplitter}_${accessor}`
    const value = settingsValues[isRSI ? 'rsi_trigger' : 'stoch_prearming']?.[period]?.[kind]

    return typeof value === 'object' ? null : value
  }, [position.value, controls])

  const onScroll = async ({ scrollTop, scrollHeight }) => {
    setPos(scrollTop);
    const pixelsRemain = scrollHeight - scrollTop;
    if (pixelsRemain < 20_000) {
      await store.nextPage();
    }
    if (scrollTop < 20_000) {
      await store.prevPage();
    }
  }

  if (_.isEmpty(logs) || position.disabled) {
    return (
      <div className="app-table_empty-content">
        <FormattedMessage id='modules.emptyTable' />
      </div>
    );
  }

  const common = { tfs, paramsStates: store.paramsStates, patternTitles, settingsValues };

  return (
    <table className="app-table logs-table">
      <thead className="app-table__head">
        <tr ref={tHeadRowRef}>
          {cols.map(({ accessor }) => {
            const settingValue = showTableValue(accessor)
            return (
              <th key={accessor}>
                <span className='prearm-title'>
                  <FormattedMessage id={`${position.label}.${accessor}`} />
                </span>
                {(settingValue || settingValue === 0) && <span>({ settingValue })</span>}
              </th>
            )
          })}
        </tr>
      </thead>
      <Scrollbars
        ref={scrollRef}
        style={{ width: '100%', height: 300 }}
        renderThumbHorizontal={(props) => <div {...props} className="thumb-vertical" />}
        renderThumbVertical={(props) => <div {...props} className="thumb-horizontal" />}
        onScrollFrame={onScroll}
      >
        <tbody ref={tBodyRef} className="app-table__content">
          {logs.map(row => (
            <tr data-id={row.id} key={row.id} className={cn('app-table__row', { disarm: isHighlight(row, position) })}>
              {cols.map(({ accessor, performer }) => performer(
                { row, accessor, value: row.value?.[`${accessor}_value`], ...common }
              )).map((value, index) => <td key={index}>{value}</td>)}
            </tr>
          ))}
        </tbody>
      </Scrollbars>
    </table>
  );
};

LogsTable.propTypes = {
  store: propTypes.instanceOf(LogsStore),
  scroll: propTypes.number,
  changeScroll: propTypes.func,
  tfs: propTypes.any,
  module: propTypes.object,
  controls: propTypes.object,
  position: propTypes.object,
}

export default hasLoader(observer(LogsTable), Loader);
