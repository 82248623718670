import { ioFinance, ioMarket } from 'providers/socketIOProvider';

const AUTHORIZATION_TOKEN = `JWT ${localStorage.getItem('authToken')}`;

const BASE_CONFIG = {
  options: {
    // forceNew: true,
    transportOptions: {
      polling: {
        extraHeaders: {
          Authorization: AUTHORIZATION_TOKEN
        }
      }
    }
  },
};

const MARKET_PROVIDER_CONFIG = {
  ...BASE_CONFIG,
  uri: process.env.REACT_APP_MARKET_DATA_SOCKET_IO_URL,
  provider: ioMarket,
};

const LOGS_PROVIDER_CONFIG = {
  ...BASE_CONFIG,
  uri: process.env.REACT_APP_FINANCE_SOCKET_IO_URL,
  provider: ioFinance,
};

export const SOCKET_IO_CONFIG = {
  candle: {
    ...MARKET_PROVIDER_CONFIG,
    type: 'candle',
    eventName: 'REALTIME_CANDLE_UPDATED',
  },
  rsi: {
    ...MARKET_PROVIDER_CONFIG,
    type: 'rsi',
    eventName: 'REALTIME_RSI_UPDATED',
  },
  stoch: {
    ...MARKET_PROVIDER_CONFIG,
    type: 'stoch',
    eventName: 'REALTIME_STOCH_UPDATED',
  },
  logsRsiLong: {
    ...LOGS_PROVIDER_CONFIG,
    type: 'logs_rsi_long',
    eventName: 'LOGS_RSI_LONG_UPDATED',
  },
  logsRsiShort: {
    ...LOGS_PROVIDER_CONFIG,
    type: 'logs_rsi_short',
    eventName: 'LOGS_RSI_SHORT_UPDATED',
  },
  logsStochShort: {
    ...LOGS_PROVIDER_CONFIG,
    type: 'logs_stoch_short',
    eventName: 'LOGS_STOCH_SHORT_UPDATED',
  },
  logsStochLong: {
    ...LOGS_PROVIDER_CONFIG,
    type: 'logs_stoch_long',
    eventName: 'LOGS_STOCH_LONG_UPDATED',
  },
  logsCandleCloseLong: {
    ...LOGS_PROVIDER_CONFIG,
    type: 'logs_candle_pattern_long',
    eventName: 'CANDLE_PATTERN_LONG_LOGS_UPDATE',
  },
  logsCandleCloseShort: {
    ...LOGS_PROVIDER_CONFIG,
    type: 'logs_candle_pattern_short',
    eventName: 'CANDLE_PATTERN_SHORT_LOGS_UPDATE',
  },
  logsWholeSequenceLong: {
    ...LOGS_PROVIDER_CONFIG,
    type: 'logs_whole_sequence_long',
    eventName: 'WHOLE_SEQUENCE_LONG_LOGS_UPDATE',
  },
  logsWholeSequenceShort: {
    ...LOGS_PROVIDER_CONFIG,
    type: 'logs_whole_sequence_short',
    eventName: 'WHOLE_SEQUENCE_SHORT_LOGS_UPDATE',
  },
  shortArmingLine: {
    ...LOGS_PROVIDER_CONFIG,
    type: 'short_arming_line',
    eventName: 'SHORT_ARMING_LINE_UPDATED',
  },
  longArmingLine: {
    ...LOGS_PROVIDER_CONFIG,
    type: 'long_arming_line',
    eventName: 'LONG_ARMING_LINE_UPDATED',
  },
  openOrders: {
    ...MARKET_PROVIDER_CONFIG,
    type: 'rates',
    eventName: 'RATE_UPDATED',
  }
};
