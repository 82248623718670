import React, { useCallback } from 'react';
import _ from 'lodash';
import propTypes from 'prop-types';
import { inject } from 'mobx-react';
import { useNotification } from 'contexts/notification';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form, useField, useFormState } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';

import { SwticherProvider, useSwitcher } from 'contexts/pageSwitcher';
import { useConfirmation, useNotify } from 'contexts/popup';
import SimpleFormControls from 'components/SimpleFormControls';
import CandlePatternEditor from './CandlePatternEditor';
import { CandlePattern } from './CandlePattern';
import { generateDefaultPattern } from './utils';

import ArrowBack from '@material-ui/icons/ArrowBack';

import './styles.scss';

const CandlePatterns = ({ store }) => {
  const intl = useIntl();
  const { error } = useNotify();
  const switcher = useSwitcher();
  const { confirm } = useConfirmation();
  const notification = useNotification()
  const { isLogicEditor, fieldName } = switcher.context;
  const field = useField(`${fieldName}.candle_pattern`);
  const wholeState = useFormState();

  const handleCancel = useCallback(dirty => {
    dirty ? confirm({
      error: true,
      title: intl.formatMessage({ id: 'dialog.title.confirmCandlePattern' }),
      description: intl.formatMessage({ id: 'dialog.description.confirmCandlePattern' })
    }).then(result => result === 'yes' && switcher.moveTo(0)) : switcher.moveTo(0);
  }, []);

  const validate = useCallback(values => (
    !field.input.value?.candle_close?.enabled &&
    values?.value?.every?.(rule => !rule.enabled) ? 'error' : null
  ), [field]);

  const handleSubmit = useCallback(value => {
    field.input.onChange(value);
    notification.show(
      intl.formatMessage({ id: 'notification.title.candlePatternsUpdated' }),
      intl.formatMessage({ id: 'notification.text.candlePatternsUpdated' })
    )
    switcher.moveTo(0);
  }, [field, switcher]);

  const logicEditorHandleSubmit = useCallback(value => {
    store.updatePatternDefaults(_.set(
      { ...wholeState.values },
      fieldName, {
        ..._.get(wholeState.values, fieldName),
        candle_pattern: value
      }
    ))
    notification.show(
      intl.formatMessage({ id: 'notification.title.defaultCandlePatternsUpdated' }),
      intl.formatMessage({ id: 'notification.text.defaultCandlePatternsUpdated' })
    )
    switcher.moveTo(0);
  }, []);

  const handleAdd = useCallback(fields => event => {
    fields.push(generateDefaultPattern(Object.freeze(fields.value)))
  }, []);
  const handleDelete = useCallback((fields, index) => event => {
    fields.remove(index);
  }, [field.input.value])

  return (
    <Form
      validate={validate}
      initialValues={field.input.value}
      onSubmit={isLogicEditor ? logicEditorHandleSubmit : handleSubmit}
      mutators={{ ...arrayMutators }}
    >
      {({ handleSubmit, values, invalid }) => (
        <SwticherProvider>
          <div className="candle-patterns-form">
            <div className="form__card">
              <div className="form__card-content">
                <div>
                  <button
                    type="button"
                    className="single-icon-btn arrow"
                    onClick={() => handleCancel(!_.isEqual(values, field.input.value))}
                  >
                    <ArrowBack fontSize='large' />
                  </button>
                </div>

                <h2 className="title2 bold">
                  <FormattedMessage
                    id={isLogicEditor ? 'logicEditor.defaultPatterns' : 'modules.candlePatterns'}
                  />
                </h2>

                <FieldArray
                  name="value"
                  render={({ fields }) => (
                    <>
                      {fields.map((value, index) => (
                        <div className="form__item" key={value}>
                          <CandlePattern
                            index={index}
                            patternName={value}
                            isLogicEditor={isLogicEditor}
                            handleDelete={handleDelete(fields, index)}
                          />
                        </div>
                      ))}
                      {isLogicEditor && (
                        <button
                          onClick={handleAdd(fields)}
                          className="primary-btn main-btn form-action-btn bold"
                        >
                          <FormattedMessage id="logicEditor.buttons.addPattern" />
                        </button>
                      )}
                    </>
                  )}/>
                <SimpleFormControls
                  handleCancel={() => handleCancel(!_.isEqual(values, field.input.value))}
                  handleSave={() => {
                    invalid ? error({
                      title: intl.formatMessage({ id: 'dialog.title.incorrectAction' }),
                      description: intl.formatMessage({ id: 'dialog.description.allPatternsDisabled' })
                    }) : handleSubmit()
                  }}
                />
              </div>
            </div>
          </div>
          <CandlePatternEditor isLogicEditor={isLogicEditor} prefix={isLogicEditor ? 'value' : fieldName}/>
        </SwticherProvider>
      )}
    </Form>
  );
};

CandlePatterns.propTypes = {
  store: propTypes.object
};

export default inject(stores => ({
  store: stores.logicEditorStore
}))(CandlePatterns);
