import React from 'react';
import PropTypes from 'prop-types';
import { Link, useRouteMatch } from 'react-router-dom';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

const LinkMatch = ({ to, label, className, disabled, ifMatch, exact = true }) => {
  const match = useRouteMatch({
    path: to,
    exact
  });
  const classes = classNames(className, { [ifMatch]: match });
  return (disabled ? (
    <button className={classes} disabled={disabled}>
      <FormattedMessage id={label} />
    </button>) : (
    <Link to={to} className={classes}>
      <FormattedMessage id={label} />
    </Link>
  ));
};

LinkMatch.propTypes = {
  to: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  ifMatch: PropTypes.string,
  exact: PropTypes.bool
};

export default LinkMatch;
