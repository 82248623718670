import { defaultHeaderColumnClasses, globalClassNames, globalFormatters } from 'globalConstants/formatters';

const {
  identity,
  datetime,
  instrument,
  fixedCurrency,
  fixed,
  pl: plFormatters,
  viewButton,
  closeButton,
  notionalValue,
} = globalFormatters;

const {
  center,
  pl: plClassNames,
  position,
  inline
} = globalClassNames;

export const ROW_COLUMNS = [
  { accessor: 'candleOpenDatetime', formatter: datetime, className: center },
  { accessor: 'actualOpenDatetime', formatter: datetime, className: center },
  { accessor: 'instrument', formatter: instrument, className: center },
  { accessor: 'size', formatter: fixedCurrency(), className: center },
  { accessor: 'notionalValue', formatter: notionalValue, className: center },
  { accessor: 'openPrice', formatter: fixed(6), className: center },
  { accessor: 'lastPrice', formatter: fixed(6), className: center },
  { accessor: 'pl', formatter: plFormatters, className: plClassNames },
  { accessor: 'stopLoss', formatter: fixed(6), className: center },
  { accessor: 'stopLossPts', formatter: identity, className: center },
  { accessor: 'profitTarget', formatter: fixed(6), className: center },
  { accessor: 'profitTargetPts', formatter: identity, className: center },
  { accessor: 'position', formatter: identity, className: position },
  { accessor: 'moduleName', formatter: identity, className: center },
  { accessor: 'kind', formatter: identity, className: inline },
  { accessor: 'viewButton', formatter: viewButton, className: inline },
  { accessor: 'closeButton', formatter: closeButton, className: inline },
];

export const HEADER_COLUMNS = [
  { classes: defaultHeaderColumnClasses, attribute: 'candle-open-datetime' },
  { classes: defaultHeaderColumnClasses, attribute: 'actual-open-datetime' },
  { classes: defaultHeaderColumnClasses, attribute: 'instrument' },
  { classes: defaultHeaderColumnClasses, attribute: 'size' },
  { classes: defaultHeaderColumnClasses, attribute: 'notional-value' },
  { classes: defaultHeaderColumnClasses, attribute: 'open-price' },
  { classes: defaultHeaderColumnClasses, attribute: 'last-price' },
  { classes: defaultHeaderColumnClasses, attribute: 'p&l' },
  { classes: defaultHeaderColumnClasses, attribute: 'stop-loss-usd' },
  { classes: defaultHeaderColumnClasses, attribute: 'stop-loss-pts' },
  { classes: defaultHeaderColumnClasses, attribute: 'profit-target-uds' },
  { classes: defaultHeaderColumnClasses, attribute: 'profit-target-pts' },
  { classes: defaultHeaderColumnClasses, attribute: 'position' },
  { classes: defaultHeaderColumnClasses, attribute: 'module' },
  { classes: defaultHeaderColumnClasses, attribute: 'kind' },
  { classes: defaultHeaderColumnClasses, attribute: 'viewButton' },
  { classes: defaultHeaderColumnClasses, attribute: 'closeButton' },
];
