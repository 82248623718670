import React, { useCallback, useState } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import { FormControl, FormGroup, FormControlLabel, RadioGroup } from '@material-ui/core';
import { CustomCheckbox, CustomRadio } from 'components/Controls';
import { CloseIcon, CirclePlus } from 'components/Svgs';
import TimeframePicker from 'components/TimeframePicker';
import { useForm } from 'react-final-form';

import './styles.scss';

const TimeFrameList = ({ fields, isRadio }) => {
  const form = useForm();
  const [checkedIndex, setCheckedIndex] = useState(fields.value.findIndex(field => field.enabled));
  const handleChange = (e) => {
    const { value, checked } = e.target;
    const index = fields.value.findIndex(field => field.value === +value);
    form.mutators.updateProps(fields.name, index, { enabled: isRadio ? true : checked });
    if (isRadio) {
      form.mutators.updateProps(fields.name, checkedIndex, { enabled: false });
    }
    setCheckedIndex(index)
  };

  const [open, setOpen] = useState(false);

  const handleAdd = useCallback(({ value, label }) => {
    fields.push({ value, label, enabled: false });
  }, [fields]);

  const handleDelete = useCallback(field => {
    const indexToDelete = fields.value.indexOf(field)
    fields.remove(indexToDelete);
    fields.value.splice(indexToDelete, 1);
  }, [fields]);

  const timeframes = _.sortBy(fields.value, (item) => item.value);
  const Group = isRadio ? RadioGroup : FormGroup;
  const Control = isRadio ? CustomRadio : CustomCheckbox;

  return (
    <>
      <FormControl component="fieldset">
        <Group
          className="time-frame-form"
          aria-label="timeframe"
          name="timeframe"
          onChange={handleChange}
        >
          {timeframes.map((obj, index) => (
            <div key={obj.value} className={classNames('radio-item', { selected: obj.enabled })}>
              <FormControlLabel
                name={obj.label}
                label={obj.label}
                value={obj.value}
                checked={obj.enabled}
                control={<Control />}
              />
              {timeframes.length > 1 && (
                <button
                  type="button"
                  onClick={() => handleDelete(obj)}
                  className="single-icon-btn"
                >
                  <CloseIcon />
                </button>
              )}
            </div>
          ))}
        </Group>
        <button
          type="button"
          onClick={() => setOpen(true)}
          className="bold single-icon-btn time-frame-form__add-btn"
        >
          <CirclePlus className="icon" />
          <FormattedMessage id="modules.addTimeframe" />
        </button>
      </FormControl>
      <TimeframePicker
        open={open}
        onSave={handleAdd}
        defaultLabel="1 min"
        onClose={() => setOpen(false)}
        timeframes={timeframes}
        type="add"
      />
    </>
  );
};

TimeFrameList.propTypes = {
  isRadio: PropTypes.bool,
  fields: PropTypes.object,
};

TimeFrameList.defaultProps = {
  isRadio: false,
};

export default TimeFrameList;
