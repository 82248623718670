import _ from 'lodash';
import { intl } from 'translations/provider';
import { AVAILABILITY, STOCH_CONDITIONS_KIND_LONG, STOCH_CONDITIONS_KIND_SHORT } from 'globalConstants';

const validateAvailabilityCompatibility = ({ values, isTfEditor }) => {
  const rsiConditions = _.values(values.rsiConditions);
  const stochPrearmingConditions = _.flatten(_.values(values.stochConditions.prearming));

  // can't be disabled both of RSI availabilities
  const rsiAvail = rsiConditions.map(conditions =>
    ['LONG', 'SHORT'].map(type => conditions.find(({ kind }) => kind === AVAILABILITY[type]).enabled)
  );
  for (const [long, short] of rsiAvail) {
    if (!long && !short) {
      return intl.formatMessage({ id: 'modules.validation.availabilitiesDisabled' })
    }
  }

  // can't be non unique set of trend disarms for one position
  for (const rsiCond of rsiConditions[0]) {
    if (rsiCond.kind.includes('trend_disarm')) {
      const originLen = rsiCond.value.length;
      const uniquesLen = new Set(rsiCond.value.map(v => `${v.rsi_level}_${v.rsi_reset_level1}_${v.rsi_reset_level2}`)).size
      if (originLen !== uniquesLen) {
        return intl.formatMessage({ id: 'modules.validation.nonQuniqueTrendDisarm' })
      }
    }
  }
  console.log(rsiConditions)

  // The parallel logic is also in the file: src/containers/Modules/General/BaseIndicator/ConditionList/Condition/helpers.js
  // need to allow module creation having only disarms in stochastic
  const disarms = [
    stochPrearmingConditions.find((cond) => cond.kind === STOCH_CONDITIONS_KIND_LONG.K_D_ABOVE_LEVEL_DISARM && cond.enabled),
    stochPrearmingConditions.find((cond) => cond.kind === STOCH_CONDITIONS_KIND_SHORT.K_D_BELOW_LEVEL_DISARM && cond.enabled),
  ]
  // rsi can't be OFF while at least one stoch is ON for corresponding position
  // getting boolean results for long | short
  const stochPrearmingEnabled = ['long', 'short'].map(kind => {
    const enabled = stochPrearmingConditions.filter(cond => cond.kind.startsWith(kind) && cond.enabled && !disarms.includes(cond));
    const avail = enabled.find(cond => cond.kind === AVAILABILITY[kind.toUpperCase()])?.enabled;
    return avail && enabled.length > 1
  })

  const [stochLong, stochShort] = stochPrearmingEnabled;

  const [rsiLong, rsiShort] = (
    rsiAvail.reduce((res, curr) => [res[0] && curr[0], res[1] && curr[1]], [true, true])
  );

  if ((stochLong && !rsiLong) || (stochShort && !rsiShort)) {
    return isTfEditor
      ? intl.formatMessage({ id: 'modules.validation.stochAvailabilityDependsOnRsi' })
      : intl.formatMessage({ id: 'modules.validation.rsiAvailabilityDependsOnStoch' });
  }
};

const validateTfsCount = ({ values }) => {
  for (const condition of ['rsiTimeframes', 'stochTimeframes.prearming', 'stochTimeframes.trigger']) {
    const count = _.get(values, condition).filter(field => field.enabled).length;
    if (count < 1) {
      return intl.formatMessage({ id: 'modules.validation.tooFew' });
    }
    if (count > 3) {
      return intl.formatMessage({ id: 'modules.validation.tooMuch' });
    }
  }
};

const candleCloseOnly = ({ values }) => {
  if (_(values.entrySystemConditions).filter('enabled').map('value.candle_pattern.enabled').some()) {
    return intl.formatMessage({ id: 'modules.validation.candleCloseOnly' });
  }
}

export const validators = [
  validateTfsCount,
  validateAvailabilityCompatibility
];

export const validatorsForSave = [
  candleCloseOnly,
  validateTfsCount,
  validateAvailabilityCompatibility
];
