import React, { useState } from 'react';
import propTypes from 'prop-types';

import { Field } from 'react-final-form';
import { EditIcon } from 'components/Svgs';
import { trimString } from 'helpers';
import * as validators from 'helpers/validators';
import { metaKey } from 'globalConstants';

import ConditionRow from '../../BaseIndicator/ConditionList/Condition/ConditionRow';
import ConditionLoss from '../ConditionLoss';
import classNames from 'classnames';

const ConditionManagement = ({ name, symbol, withoutToggle, range }) => {
  const [open, setOpen] = useState(false);

  return (
    <Field name={`tradeManagement.${name}`}>
      {({ input: { value, onChange } }) => (
        <>
          <div className={classNames('rs-indicator-list__item', { disabled: !value.enabled })}>
            <div>
              <ConditionRow
                field={{ ...value, kind: name, [metaKey]: { withoutToggle } }} prefix="entry_system"
                handleChange={() => e => { onChange({ ...value, enabled: e.target.checked }) }}
              />
            </div>
            <div className="rs-indicator-list__interval">
              <div className="rs-indicator-list__interval">
                {value.value + ' ' + symbol}
              </div>
            </div>
            <button
              type="button"
              className="single-icon-btn"
              onClick={() => setOpen(true)}
              disabled={!value.enabled}
            >
              <EditIcon />
            </button>
          </div>
          <ConditionLoss
            symbol={`${symbol}`}
            obj={{ ...value, kind: name }}
            open={open}
            handleClose={() => setOpen(false)}
            validate={(value) => (
              validators.validateWithRange(range)(value) ||
              validators.validateWithoutSpace(value) ||
              validators.validateIntegerField(value) ||
              trimString(value)
            )}
            handleSave={onChange}
          />
        </>
      )}
    </Field>
  )
}

ConditionManagement.propTypes = {
  withoutToggle: false,
  name: propTypes.string,
  symbol: propTypes.string,
  range: propTypes.number,
};

export default ConditionManagement;
