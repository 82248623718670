import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FIELDS } from './consts';
import { FormControl } from '@material-ui/core';
import { Field } from 'react-final-form';
import { trimString } from 'helpers';
import * as validators from 'helpers/validators';
import { Input } from 'components/Fields';

import './styles.scss';

const KDSlowPeriod = () => {
  return (
    <>
      <FormControl component="fieldset">
        {FIELDS.map(item => (
          <div className="form__item" key={item.id}>
            <Field
              withHelper
              name={item.name}
              component={Input}
              validate={value => (
                validators.validateWithRange({
                  min_value: item.min_value,
                  max_value: item.max_value
                })(value) ||
                validators.validateWithoutSpace(value) ||
                validators.validateIntegerField(value) ||
                trimString(value)
              )}
              label={<FormattedMessage id={item.label}/>}
            />
          </div>
        ))}
      </FormControl>
    </>
  );
};

export default KDSlowPeriod;
