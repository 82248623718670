import React from 'react';

function getDisplayName (WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

const hasLoader = (WrappedComponent, Loader) => {
  const HasLoader = props => {
    const { isLoading } = props;
    if (isLoading === null) {
      return null;
    }
    return isLoading ? <Loader /> : <WrappedComponent {...props} />;
  };
  HasLoader.displayName = `HasLoader${getDisplayName(WrappedComponent)}`;
  return HasLoader;
};

export default hasLoader;
