import React from 'react';
import cn from 'classnames';
import propTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Field, Form, FormSpy } from 'react-final-form';
import { DateTime } from 'luxon';
import { TextField } from '@mui/material';
import GetAppIcon from '@material-ui/icons/GetApp';
import { CircularProgress, Tooltip } from '@material-ui/core';

import { useLocation } from 'react-router-dom';
import { CustomSelect } from 'components/Fields';
import CustomDateTimePicker from 'components/Calendar';
import * as validators from 'helpers/validators';

import './styles.scss';

const TradeHistoryFilter = ({ getLogs, downloadHandler, downloading, reportDownloadEnabled, config: { minDate, initialValues, selectFields, rangeFilterConfig } }) => {
  const intl = useIntl();
  const location = useLocation()

  const onChange = (data) => {
    const key = 'trades_filters'.concat(location.pathname)
    const prevTradesFilters = JSON.parse(localStorage.getItem(key))

    if (prevTradesFilters && prevTradesFilters.kind !== data.values.kind) {
      data.values.instrument = ' '
      data.values.moduleName = ' '
      data.values.timeCycle = ' '
      data.values.version = ' '
    }
    localStorage.setItem(key, JSON.stringify(data.values))
    getLogs(data)
  }

  return (
    <div className="trade-history-filter">
      <Form
        onSubmit={() => { }}
        initialValues={initialValues}
        validate={value => ({
          [rangeFilterConfig.rangeFrom]: (
            validators.validateIsBeforeDate(value[rangeFilterConfig.rangeFrom], value[rangeFilterConfig.rangeTo])
          )
        })}
        render={({ errors, values, form }) => (
          <form className="trade-history-filter-form">

            {selectFields?.map(({ label, name, items, isDisabled, checkAndReset }) => {
              if (checkAndReset) {
                checkAndReset(form.reset, values);
              }

              return (
                <div className="trade-history-filter-form__block" key={name}>
                  <div className="trade-history-filter-form__label bold">
                    {intl.formatMessage({ id: label })}
                  </div>

                  <Field
                    disabled={isDisabled && isDisabled({ values })}
                    defaultValue={values[name] || items[0]?.id}
                    name={name}
                    component={CustomSelect}
                    items={items}
                  />
                </div>
              )
            })}

            <div className="trade-history-filter-form__block">
              <div className="trade-history-filter-form__label bold">
                {intl.formatMessage({ id: 'trade.history.filter.timerange.label' })}
              </div>

              <div className="trade-history-filter-form__time-range-wrap">
                <div className="trade-history-filter-form__time-range">

                  <Field name={rangeFilterConfig.rangeFrom}>
                    {({ input: { value, onChange }, meta: { error } }) => (
                      <CustomDateTimePicker
                        date={DateTime.fromMillis(value)}
                        minDate={minDate}
                        onAccept={date => onChange(date.startOf('day').toMillis())}
                        inputFormat={'dd/MM/yyyy'}
                        renderInput={(params) => (
                          <TextField className={cn('trade-history-filter-form__date-field', { error })} {...params} />
                        )}
                      />
                    )}
                  </Field>

                  <div>-</div>

                  <Field name={rangeFilterConfig.rangeTo}>
                    {({ input: { value, onChange }, meta: { error } }) => (
                      <CustomDateTimePicker
                        date={DateTime.fromMillis(value)}
                        onAccept={date => onChange(date.endOf('day').toMillis())}
                        inputFormat={'dd/MM/yyyy'}
                        renderInput={(params) => (
                          <TextField className={cn('trade-history-filter-form__date-field', { error })} {...params} />
                        )}
                      />
                    )}
                  </Field>
                </div>
                <div className="trade-history-filter-form__date-range-error">{errors[rangeFilterConfig.rangeFrom]}</div>
              </div>
            </div>

            {downloadHandler && (
              <div className="trade-history-filter-form__block trade-history-filter-form__block--end">
                {!downloading ? (
                  <Tooltip title={intl.formatMessage({ id: 'modules.savedModules.tooltip.download' })} placement="top">
                    <button
                      type="button"
                      className="single-icon-btn"
                      disabled={!reportDownloadEnabled}
                      onClick={downloadHandler}
                    >
                      <GetAppIcon />

                    </button>
                  </Tooltip>
                ) : <CircularProgress style={{ height: 20, width: 20, marginBottom: 10 }} />}
              </div>
            )}

            <FormSpy
              subscription={{ values: true }}
              onChange={onChange}
            />
          </form>
        )}
      >
      </Form>
    </div>
  );
};

TradeHistoryFilter.propTypes = {
  config: propTypes.object,
  getLogs: propTypes.func,
  downloadHandler: propTypes.func,
  downloading: propTypes.bool,
  reportDownloadEnabled: propTypes.bool
};

export default TradeHistoryFilter;
