import { intl } from 'translations/provider';

export const isValidConsecutiveLosses = ({ value, obj, displayedData }) => {
  const filedName = 'consecutive_losses'

  if (obj.type !== filedName) {
    return;
  }

  const targetRuntimeValue = +displayedData.runtime.find(({ type }) => type === filedName).value;
  const targetSystemValue = +displayedData.system.find(({ type }) => type === filedName).value;

  if (obj.kind === 'system' && +value < targetRuntimeValue) {
    return intl.formatMessage({ id: 'risk-management.error.system.consecutive-losses' });
  }

  if (obj.kind === 'runtime' && +value > targetSystemValue) {
    return intl.formatMessage({ id: 'risk-management.error.module.consecutive-losses' });
  }
}
