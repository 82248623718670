import _ from 'lodash';

const mapKeysDeep = customizer => obj => {
  if (_.isArray(obj)) {
    return obj.map(mapKeysDeep(customizer))
  }
  if (_.isPlainObject(obj)) {
    return _(obj)
      .mapKeys((v, k) => customizer(k))
      .mapValues(mapKeysDeep(customizer))
      .value();
  }
  return obj;
}

export const represent = mapKeysDeep(_.snakeCase);
export const toInternal = mapKeysDeep(_.camelCase);
