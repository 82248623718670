import React, { useEffect, useMemo, useRef } from 'react';
import propTypes from 'prop-types';
import * as echarts from 'echarts';
import { observer } from 'mobx-react';
import { useLayoutMemo, useStore, useTimezone } from 'hooks';

import Slider from './Slider';
import * as config from './config';
import * as hooks from './hooks';

import './styles.scss'

const Chart = ({ controls }) => {
  const chartRef = useRef();
  const { tz } = useTimezone()
  const { instrumentsStore, chartStore, paintingLogsCandleCloseStore: logs } = useStore();

  // echart instance
  const chart = useLayoutMemo(() => {
    if (!chartRef.current) return;
    return echarts.init(chartRef.current);
  }, []);

  const candleLabelPrecision = useMemo(() => (
    Math.log10(instrumentsStore.get(controls.instrument).coefficient) + 1
  ), [controls.instrument]);

  // set init options
  useEffect(() => {
    if (!chart) return;
    chart.setOption(config.initOptions({ chartStore, candleLabelPrecision }));
  }, [chart, candleLabelPrecision, chartStore.tfGrouper.groupedByPeriod, tz])

  // candle chart and candle patterns highlight
  useEffect(() => {
    if (!chart) return;
    chart.setOption(config.candleOptions({ chartStore, logs, candleLabelPrecision }));
  }, [chart, logs.long.allData, logs.short.allData, chartStore.candle.forEChart]);

  // rsi chart
  useEffect(() => {
    if (!chart) return;
    chart.setOption(config.rsiOptions({ chartStore, candleLabelPrecision }));
  }, [chart, chartStore.rsi.forEChart]);

  // stoch chart
  useEffect(() => {
    if (!chart) return;
    chart.setOption(config.stochOptions({ chartStore, candleLabelPrecision }));
  }, [chart, chartStore.stoch.forEChart]);

  // armingLines
  useEffect(() => {
    if (!chart) return;
    chart.setOption(config.armingLineOptions({ chart, chartStore, candleLabelPrecision }));
  }, [chart, chartStore.armingLine.lines]);

  // rsi trend disarm
  useEffect(() => {
    if (!chart) return;
    chart.setOption(config.trendDisarmOptions({ chartStore }));
  }, [chart, chartStore.trendDisarm.forEChart]);

  hooks.useResize(chart);
  hooks.useLazyLoad(chart);
  hooks.useSelectedDate(chart);
  hooks.useLoadChartData(chart);
  hooks.useControlsButtons(chart);
  hooks.useArmingLinesButtons(chart);
  hooks.useListenerArmingLines(chart);

  useEffect(() => () => chartStore.unsubscribe(), []);
  return (
    <>
      <Slider chart={chart} />
      <div className="chart-wrap" ref={chartRef} />
    </>
  );
};

Chart.propTypes = {
  controls: propTypes.object,
};

export default observer(Chart);
