import React from 'react';
import TradeHistory from 'containers/TradeHistory'
import { useScrollToTop } from 'hooks';

const TradeHistoryPage = () => {
  useScrollToTop();
  return <TradeHistory />;
};

export default TradeHistoryPage;
