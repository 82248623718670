// Publish/subscribe pattern

// Usage:
// > const observer = pubSub();
// > const unsub1 = observer.subscibe(data => { console.log('1st', data) });
// > const unsub2 = observer.subscibe(data => { console.log('2nd', data) });
// > observer.publish('data');
// 1st data
// 2nd data
// > unsub1(); unsub2();

export function pubSub () {
  const subscribers = [];

  function subscribe (callback) {
    subscribers.push(callback);
    return function unsubscribe () {
      const index = subscribers.indexOf(callback);
      ~index && subscribers.splice(index, 1);
    }
  }

  function publish (...args) {
    return subscribers.map(subscription => subscription(...args));
  }

  return { subscribe, publish };
}
