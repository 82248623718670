import React from 'react';
import FundManagement from 'containers/FundManagement';
import PasswordProtectedRoute from 'containers/PasswordProtected';

const FundManagementPage = () => (
  <PasswordProtectedRoute pageName={'fundManagement'}>
    <FundManagement />
  </PasswordProtectedRoute>
);

export default FundManagementPage;
