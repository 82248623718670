export const DEFAULT_COLUMNS = [
  { classes: 'left', attribute: 'title' },
  { classes: 'center', attribute: 'list-of-modules' },
  { classes: 'center', attribute: 'starting-fund' },
  { classes: 'center', attribute: 'order-size' },
  { classes: 'center', attribute: 'max-open-orders' },
  { classes: 'center', attribute: 'time-range' },
  { classes: 'center', attribute: 'status-pattern' },
  { class: 'classes', attribute: 'controls' }
];
