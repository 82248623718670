import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Wizard from 'containers/Wizard';
import { useHistory } from 'react-router-dom';
import { useNotification } from 'contexts/notification';
import Loader from 'components/Loader';
import { useIntl } from 'react-intl';
import { INITIAL_VALUES } from 'globalConstants';
import { observer } from 'mobx-react';
import { useStore } from 'hooks';
import { syncEntrySystemTripleLines } from '../helpers';

const CreateModule = ({ store, logicEditorStore }) => {
  const intl = useIntl();
  const history = useHistory();
  const notification = useNotification();
  const [isLoading, setIsLoading] = useState(false);
  const { instrumentsStore } = useStore();

  React.useEffect(() => {
    logicEditorStore.getPatternDefaultsList();
  }, []);

  const initialValues = React.useMemo(() => {
    if (logicEditorStore.isReady) {
      const data = logicEditorStore.currentPatternDefaultList;
      const values = _.cloneDeep(INITIAL_VALUES);
      values.instrument = instrumentsStore.instruments[0]?.id || INITIAL_VALUES.instrument;
      for (const position of ['long', 'short']) {
        for (const type of ['triple_close', 'triple_tolerance']) {
          Object.assign(
            values.entrySystemConditions.find(cond => cond.kind === `${position}_${type}`).value,
            data[type][position]
          );
        }
      }
      return values;
    }
  }, [logicEditorStore.state, logicEditorStore.currentPatternDefaultList, instrumentsStore.instruments.length])

  const onSubmit = async values => {
    try {
      setIsLoading(true);
      const payload = syncEntrySystemTripleLines(values)
      const response = await store.createModule(payload);
      const moduleName = response.data.name;
      const title = intl.formatMessage({
        id: 'notification.title.moduleCreated'
      });
      const text = intl.formatMessage({
        id: 'notification.text.moduleCreated'
      }, { moduleName });
      notification.show(title, text);
    } catch {}
    history.push('/modules/saved');
  };

  if (isLoading || !initialValues) {
    return <Loader />;
  }

  return <Wizard initialValues={initialValues} onSubmit={onSubmit} />;
};

CreateModule.propTypes = {
  store: PropTypes.object,
  logicEditorStore: PropTypes.object,
};

export default observer(CreateModule);
