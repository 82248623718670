import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { LOGIN_URL } from './consts';

const AuthenticatedRoute = ({
  compontent: Component,
  layout: Layout,
  ...rest
}) => {
  const authToken = localStorage.getItem('authToken')
  if (!authToken) {
    return <Redirect to={LOGIN_URL} />
  }

  return (
    <Route {...rest} render={props => Layout ? (
      <Layout>
        <Component {...props} />
      </Layout>
    ) : (
      <Component {...props} />
    )} />
  )
}

AuthenticatedRoute.propTypes = {
  compontent: PropTypes.elementType,
  layout: PropTypes.elementType,
  protect: PropTypes.bool
}

export default AuthenticatedRoute
