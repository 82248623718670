import { DEFAULT_COUNT } from 'globalConstants';

export const loadDataByMiddleTimestamp = (data, dataZoomIndex, index, chartRef, observers) => {
  const { startValue, endValue } = chartRef.getOption().dataZoom.find(dz => dz.xAxisIndex.includes(dataZoomIndex));
  // Shift by 2 points (timeframes) to save the desired position
  const middleIndex = (startValue || endValue) ? Math.floor((startValue + endValue - 2) / 2) : 0;
  const timestamp = data[middleIndex]?.timestamp;
  if (!timestamp) return;
  // async block to let dependencies get refreshed
  setTimeout(() => observers.selectedDate.publish(timestamp, index), 0);
}

export const buildParams = ({ module, indicator, controlPeriod }) => {
  const { id: setId, tf: period } = module.marketDataIds?.[indicator].find(({ tf }) => tf === controlPeriod)

  return {
    setId,
    period,
    isRealtime: module.isRealtime,
    runtimeId: module.runtime.marketDataRuntimeId,
    count: DEFAULT_COUNT,
    kind: module.runtime?.kind
  };
};
