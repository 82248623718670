import React, { useCallback } from 'react';
import propTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Dialog, DialogContent } from '@material-ui/core';
import { CloseIcon } from 'components/Svgs';
import { Input } from 'components/Fields';
import { Field, Form } from 'react-final-form';
import _ from 'lodash';
import { trimString } from 'helpers';
import * as validators from 'helpers/validators';

import './styles.scss'

const ProfitFrame = ({ open, onClose, onSave, value }) => {
  const handleSubmit = useCallback(value => {
    onSave(value);
    onClose();
  }, [])

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      className="custom-dialog add-frame-form"
    >
      <Form
        onSubmit={handleSubmit}
        validate={validators.validatePrevious}
        initialValues={value}
        render={({ handleSubmit, errors, invalid }) => (
          <>
            <DialogContent>
              <button
                className="single-icon-btn single-icon-btn--white cross"
                onClick={onClose}
              >
                <CloseIcon />
              </button>

              <h2 className="title2 bold">
                <FormattedMessage id='entry_system.conditions.profit_targets' />
              </h2>

              <div className="add-frame-form__fields profit">
                {_.range(0, 6).map(index => (
                  <>
                    <div className='profit__container'>
                      <div className='title3 bold title'>
                        Target <span className='title__index'>{index + 1}</span>
                      </div>

                      <Field
                        component={Input}
                        autoFocus={index === 0}
                        withHelper={errors?.targets?.[index] !== ' '}
                        parse={value => value !== (+value).toString()
                          ? value
                          : +value
                        }
                        validate={value => (
                          validators.validateWithRange({ min_value: 1, max_value: 1000 })(value) ||
                          validators.validateWithoutSpace(value) ||
                          validators.validateIntegerField(value) ||
                          trimString(value)
                        )}
                        name={`targets[${index}]`}
                      />

                      <div className='condition-form-content__symbol bold'>
                        Pts
                      </div>
                    </div>
                  </>
                ))}

                <div className='error-field'>
                  {errors.errorTextPrevious}
                </div>

                <div className='profit__container'>
                  <div className='title4 bold title reassignment'>
                    Target <FormattedMessage id='entry_system.conditions.reassignment_level' />
                  </div>

                  <Field
                    component={Input}
                    withHelper
                    isSmall
                    name={'reassignment'}
                    validate={(reassignment, { targets }, value) => (
                      validators.validateTheSmallest(targets, reassignment) ||
                      validators.validateWithRange({ min_value: 1, max_value: 1000 })(reassignment) ||
                      validators.validateWithoutSpace(reassignment) ||
                      validators.validateIntegerField(reassignment) ||
                      trimString(reassignment)
                    )}
                  />
                  <div className='condition-form-content__symbol bold'>
                    Pts
                  </div>
                </div>
              </div>
            </DialogContent>

            <div className="custom-dialog__actions">
              <button onClick={onClose} className="primary-btn outline-btn form-action-btn bold">
                <FormattedMessage id="modules.buttons.cancel" />
              </button>
              <button
                onClick={handleSubmit}
                disabled={invalid}
                className="primary-btn main-btn form-action-btn bold"
              >
                <FormattedMessage id='modules.buttons.save' />
              </button>
            </div>
          </>
        )}
      />
    </Dialog>
  );
};

ProfitFrame.propTypes = {
  open: propTypes.bool.isRequired,
  type: propTypes.string.isRequired,
  onSave: propTypes.func.isRequired,
  onClose: propTypes.func.isRequired,
  validate: propTypes.func,
  value: propTypes.number
};

export default ProfitFrame;
