import React from 'react';
import PropTypes from 'prop-types';

import { useStore, useTimezone } from 'hooks';

import './styles.scss';

const TradeHistoryRow = ({ row, rowColumns, handlers }) => {
  useTimezone();
  const { instrumentsStore } = useStore();

  return (
    <tr className="app-table__row trade-history-row">
      {rowColumns.map(({ accessor, formatter, className }, index) => {
        const context = {
          accessor,
          value: row[accessor],
          row,
          formattedValue: formatter({ value: row[accessor], row, handlers, instruments: instrumentsStore.instruments })
        };

        return <td key={index} className={className(context)}>{context.formattedValue}</td>;
      })}
    </tr>
  );
};

TradeHistoryRow.propTypes = {
  row: PropTypes.object,
  rowColumns: PropTypes.array,
  handlers: PropTypes.object,
};

export default TradeHistoryRow;
