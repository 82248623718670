import * as api from 'api/utils';
import { RUNTIME_URL, RUNTIME_ITEM_URL, DETAILED_URL, VIEW_DEMO_MODULE } from './consts';
import _ from 'lodash';

export default class BacktestService {
  create (backtest) {
    const formData = new FormData();
    _(backtest).entries().filter('1').forEach(([key, value]) => {
      formData.append(key, key === 'data_editor_file'
        ? new Blob([value], { type: 'application/zip' })
        : JSON.stringify(value));
    });
    return api.post('finance', RUNTIME_URL, true, formData);
  }

  list () {
    return api.get('finance', RUNTIME_URL, true);
  }

  get (id) {
    return api.get('finance', DETAILED_URL(id), true);
  }

  getItem (id, itemId) {
    return api.get('finance', RUNTIME_ITEM_URL(id, itemId), true);
  }

  delete (id) {
    return api.del('finance', DETAILED_URL(id), true);
  }

  getbacktestModuleList (id) {
    // return api.get('finance', DETAILED_URL(id), true);
  }

  getDemoModule (id) {
    return api.get('finance', VIEW_DEMO_MODULE(id), true);
  }
}
