import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import propTypes from 'prop-types';

import { compose, downloadToFile } from 'helpers';
import TradeHistoryStore from 'store/tradeHistory';
import TradeHistoryTable from 'components/TradeHistoryTable';
import TradeHistoryFilter from 'components/TradeHistoryFilter';
import TradeHistoryPagination from 'components/TradeHistoryPagination';
import TradeHistorySummary from 'components/TradeHistorySummary';
import { filterConfig, useLogs } from './helpers';
import { HEADER_COLUMNS as headerColumns, ROW_COLUMNS as rowColumns, TOTAL_COLUMNS as totalColumns } from './consts';
import { States } from 'store/tradeHistory/consts';
import { intl } from 'translations/provider';
import { useConfirmation, useNotify } from 'contexts/popup';

const TradeHistory = ({ tradeStore }) => {
  const { lastFilters, defaultValues, selectFields, getLogs, paginationState } = useLogs();
  const location = useLocation()
  const { error } = useNotify();
  const { confirm } = useConfirmation();

  const downloadHandler = async () => {
    const result = await tradeStore.downloadTradeHistory(lastFilters);
    await filePolling(result.taskId)
  };

  const filePolling = async (taskId) => {
    const pkey = tradeStore.getPollingKey()

    await tradeStore.setPolling(pkey, taskId, async (responseData) => {
      downloadToFile(responseData, 'Trade History Report.xlsx', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
    })
  }

  const setupPolling = async () => {
    const taskId = tradeStore.getPolling(tradeStore.getPollingKey())
    if (taskId) {
      await filePolling(taskId)
    }
  }

  const filters = React.useMemo(() => {
    const cachedFilters = JSON.parse(localStorage.getItem('trades_filters'.concat(location.pathname)))
    return cachedFilters || defaultValues
  }, []);

  useEffect(() => {
    setupPolling()
    return () => {
      localStorage.removeItem('trades_filters'.concat(location.pathname))
      tradeStore.stopPolling()
    }
  }, [])

  const deleteHandler = async () => {
    const result = await confirm({
      error: true,
      title: intl.formatMessage({ id: 'dialog.title.deleteTradeHistory' }),
      description: intl.formatMessage({ id: 'dialog.description.deleteAllTradeHistory' })
    })
    if (result !== 'yes') return;
    try {
      tradeStore.deleteAll()
    } catch (e) {
      if (e.data.message) {
        error({ title: 'Error', description: e.data.message });
      }
    }
  };

  return (
    <div className="form1 trade-history">
      <div className="form__card1">
        <div className="trade-history__title-wrap">
          <div className="title2 bold trade-history__title">
            <FormattedMessage id='trade.history.title' />
          </div>

          {tradeStore.tradeHistoryData?.logs?.length > 0 && (
            <button className="main-btn secondary-btn bold" onClick={deleteHandler}>
              <FormattedMessage id='trade.history.delete-all' />
            </button>
          )}
        </div>
        <TradeHistorySummary tradeHistoryData={tradeStore.tradeHistoryData} />
        <TradeHistoryFilter config={{ ...filterConfig, selectFields, initialValues: filters }} downloading={tradeStore.downloading} getLogs={getLogs} reportDownloadEnabled={true} downloadHandler={downloadHandler} />
        <TradeHistoryTable headerColumns={headerColumns} rowColumns={rowColumns} data={tradeStore.tradeHistoryData} totalColumns={totalColumns} />
        <TradeHistoryPagination data={tradeStore.tradeHistoryData} paginationState={paginationState} isLoading={tradeStore.state === States.Loading} />
      </div>
    </div>
  );
};

TradeHistory.propTypes = {
  tradeStore: propTypes.instanceOf(TradeHistoryStore),
};

const decorator = compose(
  observer,
  inject(stores => ({
    tradeStore: stores.tradeHistoryStore,
  })),
);

export default decorator(TradeHistory);
