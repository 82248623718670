import React, { useState } from 'react';
import propTypes from 'prop-types';

import { useIntl } from 'react-intl';
import { Field } from 'react-final-form';
import { EditIcon } from 'components/Svgs';

import SizeFrame from 'components/SizeFrame';
import ConditionRow from '../../BaseIndicator/ConditionList/Condition/ConditionRow';
import classNames from 'classnames';

const TradeSizeStatic = ({ name }) => {
  const [open, setOpen] = useState(false);
  const intl = useIntl();

  return (
    <Field name={`tradeManagement.${name}`}>
      {({ input: { value, onChange } }) => (
        <>
          <div className={classNames('rs-indicator-list__item align', { disabled: !value.enabled })}>
            <ConditionRow
              field={{ ...value, kind: name }}
              prefix="entry_system"
              handleChange={() => e => { onChange({ ...value, enabled: e.target.checked }) }}
            />
            <div className="rs-indicator-list__interval">
              {value.value + ' ' + intl.formatMessage({ id: `entry_system.trade_options.${value.unit}` })}
            </div>

            <button
              type="button"
              className="single-icon-btn"
              onClick={() => setOpen(true)}
              disabled={!value.enabled}
            >
              <EditIcon />
            </button>
          </div>
          <SizeFrame
            open={open}
            value={value}
            onClose={() => setOpen(false)}
            onSave={onChange}
            type="select"
          />
        </>
      )}
    </Field>
  );
}

TradeSizeStatic.propTypes = {
  name: propTypes.string
};

export default TradeSizeStatic
