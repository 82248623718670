import React from 'react';
import classNames from 'classnames';
import propTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { toPrecision } from 'helpers';

import './styles.scss'

// Represent row in pattern row (title - description)
const DescriptionRow = ({
  field: {
    kind,
    description,
    value,
    symbol,
    enabled,
    trigger_range: triggerRange
  },
  prefix,
  descriptionId
}) => {
  const intl = useIntl();
  const getValues = value => ({
    min_value: toPrecision(value.min_value, 2),
    max_value: toPrecision(value.max_value, 2),
    value: Number.isNaN(+value) ? value : toPrecision(value, 2)
  })

  return (
    <div data-kind={kind} className={classNames('description-row', { disabled: !((enabled ?? true) && (triggerRange ?? true)) })}>
      <div>
        {intl.formatMessage({ id: `${prefix}.conditions.${kind}` }, { value })}
      </div>
      {kind !== 'dependency' && (
        <div>
          {value ? intl.formatMessage({
            id: descriptionId,
            defaultMessage: description
          }, { symbol, ...getValues(value) }) : null}
        </div>
      )}
    </div>
  )
};

DescriptionRow.propTypes = {
  field: propTypes.shape({
    kind: propTypes.string,
    description: propTypes.string,
    value: propTypes.oneOfType([
      propTypes.shape({
        min_value: propTypes.number,
        max_value: propTypes.number
      }),
      propTypes.string,
      propTypes.number
    ]),
    symbol: propTypes.string,
    enabled: propTypes.bool,
    trigger_range: propTypes.bool
  }),
  prefix: propTypes.string,
  descriptionId: propTypes.string
};

DescriptionRow.defaultProps = {
  descriptionId: 'description'
}

export default DescriptionRow;
