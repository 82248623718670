import { useRef, useCallback, useLayoutEffect } from 'react';
import _ from 'lodash';

export function useAdjustColumn () {
  // Adjusting th width to respective td width
  const tBodyRef = useRef();
  const tHeadRowRef = useRef();
  const adjustColumnWidth = useCallback(() => {
    if (!tBodyRef.current || !tHeadRowRef.current) {
      return
    }
    const ths = tHeadRowRef.current.children;
    const trs = tBodyRef.current.children[0].children;
    _.zip(ths, trs).forEach(([th, tr]) => {
      th.style.width = window
        .getComputedStyle(tr, null)
        .getPropertyValue('width');
    })
  }, [tBodyRef, tHeadRowRef]);

  useLayoutEffect(() => {
    adjustColumnWidth();
    window.addEventListener('resize', adjustColumnWidth);
    return () => {
      window.removeEventListener('resize', adjustColumnWidth);
    };
  }, [adjustColumnWidth]);
  return { tBodyRef, tHeadRowRef }
}
