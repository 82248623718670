import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Field, Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { useHistory } from 'react-router-dom';
import { createForm } from 'final-form';
import { useIntl } from 'react-intl';
import { updateProps, copy, idAgnosticCopy } from 'helpers/final-form';
import FormControls from 'components/FormControls';
import { Input } from 'components/Fields';
import { useNotify, useConfirmation } from 'contexts/popup';
import { SwticherProvider } from 'contexts/pageSwitcher';
import { calculateDecorator } from './reaction';
import { validators, validatorsForSave } from './validation';
import { pages } from './pages';
import CandleClose from 'containers/Modules/General/EntrySystem/Patterns/CandleClose';
import { CandlePatterns } from 'containers/Modules/General';
import { Step, StepButton, Stepper } from '@material-ui/core';
import { validateTitle } from 'helpers/validators';

import './styles.scss'

const Wizard = ({ onSubmit, initialValues, tfEditorType, isEdit, children }) => {
  const intl = useIntl();
  const history = useHistory();
  const [page, setPage] = useState(0);
  const { error } = useNotify();
  const { confirm } = useConfirmation();
  const [isFirst, isLast] = [page === 0, page === pages.length - 1];

  // form initialization
  const form = createForm({
    onSubmit,
    initialValues,
    mutators: { ...arrayMutators, updateProps, copy, idAgnosticCopy }
  });
  useEffect(() => calculateDecorator(form), []);

  // Function that validate whole state (availability & tfs count)
  const validate = useCallback(isSave => {
    const { values, invalid } = form.getState();
    const validator = isSave ? validatorsForSave : validators;

    const errorMessage = (invalid && intl.formatMessage({ id: 'dialog.description.leavePageWithError' })) || validator.reduce((error, validator) => (
      error ?? validator({ values, tfEditorType, isLast })
    ), null);

    if (errorMessage) {
      const title = intl.formatMessage({ id: 'dialog.title.incorrectAction' });
      error({ title, description: errorMessage });
    };
    return !errorMessage
  }, []);

  // Show confirmation on form leaving
  useEffect(() => {
    const block = history.block(prompt => {
      confirm({
        title: isEdit
          ? intl.formatMessage({ id: 'dialog.title.leavePageEditModule' })
          : intl.formatMessage({ id: 'dialog.title.leavePage' }),
        description: intl.formatMessage({ id: 'dialog.description.leavePage' }),
        error: true
      }).then(result => {
        if (result === 'yes') {
          if (validate(true)) {
            form.submit();
          } else return
        }
        if (['yes', 'no'].includes(result)) {
          block && block();
          history.push(prompt.pathname);
        }
      });
      return false;
    });
    return () => block?.();
  }, []);

  const { title, page: child, className } = pages[page];

  return (
    <Form form={form}>
      {({ handleSubmit, invalid }) =>
        <form onSubmit={e => { e.preventDefault(); validate(true) && handleSubmit() }}>
          {children || (
            <SwticherProvider>
              <div className="create-module-form form">
                <div className="create-module-form__item">
                  <Field
                    withHelper
                    isSmallField
                    formatOnBlur
                    name="moduleName"
                    component={Input}
                    format={v => v?.trim()}
                    validate={validateTitle()}
                    parse={value => value || ''}
                  />
                </div>
                <Stepper nonLinear alternativeLabel activeStep={page}>
                  {pages.map(({ title }, index) => (
                    <Step key={index} disabled={invalid}>
                      <StepButton onClick={() => { validate(false) && setPage(index) }} >
                        {title}
                      </StepButton>
                    </Step>
                  ))}
                </Stepper>
                <div className={classNames('form__card', className)}>
                  <div className="form__card-content">
                    <h2 className="title2 bold">
                      {title}
                    </h2>
                    {child}
                  </div>
                  <FormControls
                    isFirst={isFirst}
                    isLast={isLast}
                    onNext={() => validate(isLast) && (isLast ? handleSubmit() : setPage(old => old + 1))}
                    onPrev={() => validate(false) && setPage(old => old - 1)}
                    disabled={invalid}
                  />
                </div>
              </div>
              <CandleClose />
              <CandlePatterns />
            </SwticherProvider>
          )}
        </form>
      }
    </Form>
  );
};

Wizard.propTypes = {
  initialValues: PropTypes.object,
  tfEditorType: PropTypes.string,
  onSubmit: PropTypes.func,
  isEdit: PropTypes.bool,
  children: PropTypes.node
};

export default Wizard;
