import { useEffect } from 'react';
import _ from 'lodash';

import { buildParams } from './helpers';
import { useStore } from 'hooks';

export const useUpdateParams = ({ module, controls }) => {
  const { chartStore, paintingLogsCandleCloseStore } = useStore();
  // Updates params
  useEffect(() => {
    chartStore.candle.setParams(buildParams({ module, indicator: 'candles', controlPeriod: controls.candlePeriod }));
    chartStore.rsi.setParams(buildParams({ module, indicator: 'rsi', controlPeriod: controls.rsiPeriod }));
    chartStore.stoch.setParams(buildParams({ module, indicator: 'stoch', controlPeriod: controls.stochPeriod }));

    chartStore.armingLine.stores.forEach(store => store.setParams({
      timeframe: controls.rsiPeriod,
      runtimeItemId: module.id,
    }));

    _.values(paintingLogsCandleCloseStore).forEach(store => store.setParams({
      timeframe: controls.candlePeriod,
      runtimeItemId: module.id,
      rsi_period: controls.rsiPeriodValue
    }));
  }, [module.id, controls.rsiPeriod, controls.stochPeriod, controls.candlePeriod]);
}
