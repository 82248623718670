export default class SocketIOService {
  constructor (config) {
    const { uri, options, eventName, provider, type } = config;
    this.lastEvent = null;
    this.type = type;
    this.eventName = eventName;
    this.uri = uri
    this.options = options
    this.provider = provider
    this.subscribe = this.subscribe.bind(this);
  }

  subscribe (params, callback) {
    const { runtimeId, setId, runtimeItemId, period: timeframe, ...remains } = params;
    this.socket = this.provider(this.uri, this.options);
    const eventParams = { type: this.type, ...remains };
    const event = this.eventName;
    if (this.lastEvent) {
      this.socket.emit('leave', this.lastEvent)
      this.lastEvent = null;
    }
    if (event) {
      this.lastEvent = {
        runtime_item_id: runtimeItemId,
        timeframe,
        runtime_id: runtimeId,
        set_id: setId,
        type: this.type,
        params: eventParams
      };
      this.socket.emit('join', this.lastEvent)
      this.socket.on(event, callback);
    }
  }

  unsubscribe () {
    this.socket && this.socket.emit('leave', this.lastEvent);
    this.socket && this.socket.removeAllListeners(this.eventName);
  }
}
