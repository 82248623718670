import { useEffect, useState } from 'react';
import { flowResult } from 'mobx';
import _ from 'lodash';

import { useChartSync } from 'contexts/chart';
import { useNotification } from 'contexts/notification';
import { zoomToBegin, zoomToEnd } from '../helpers';
import { useStore } from 'hooks';

export function useSelectedDate (chart) {
  const { chartStore } = useStore();

  [0, 1, 2].forEach(dataZoomIndex => {
    const store = chartStore.tfGrouper.groups[dataZoomIndex]?.[0];
    _useSelectedDate({ chart, dataZoomIndex, store });
  });
}

function _useSelectedDate ({ chart, dataZoomIndex, store }) {
  const notification = useNotification();
  const { observers } = useChartSync();
  const [result, setResult] = useState({});
  const [selectedDate, setSelectedDate] = useState();
  const { chartStore, wholeSequenceLogsStore } = useStore();

  useEffect(() => observers.selectedDate.subscribe(async (selectedDate, index) => {
    const data = store?.allData;
    if (!selectedDate || !data) return;
    if (!_.isNil(index) && chartStore.tfGrouper.getIndexDataZoom(index) !== dataZoomIndex) return;
    const result = await flowResult(chartStore.goTo(dataZoomIndex, selectedDate));
    setSelectedDate(selectedDate);
    setResult(result);

    // move wholeSequence logs
    // if (dataZoomIndex === getIndexDataZoom(1)) {
    if (index === 1 || chartStore.tfGrouper.groupedByPeriod?.[dataZoomIndex].some((e) => e === 1)) {
      await Promise.all(_.values(wholeSequenceLogsStore).map(store => store.followRSI()));
      observers.selectedDateForWsLogs.publish(selectedDate);
    }
  }), [store]);

  // move to specified timestamp
  useEffect(() => {
    const data = store?.allData;
    if (!data || !chart) return;
    const { isExists, isRightReached, isLeftReached } = result;
    if (_.isNil(isExists)) return;

    const selectedIndex = _.sortedIndexBy(data, { timestamp: selectedDate }, 'timestamp');
    const isOutOfRange = selectedIndex === 0 || selectedIndex === data.length;

    if (!isExists) {
      notification.show('Oops', 'Selected data doesn\'t exist');
    } else if (isRightReached && isOutOfRange) {
      zoomToEnd(chart, data, dataZoomIndex);
    } else if (isLeftReached && isOutOfRange) {
      zoomToBegin(chart, data, dataZoomIndex);
    } else {
      const period = store.params.period;
      chart.dispatchAction({
        type: 'dataZoom',
        source: 'select-date',
        dataZoomIndex: dataZoomIndex,
        startValue: _.sortedIndexBy(data, { timestamp: selectedDate - 80 * period }, 'timestamp'),
        endValue: _.sortedIndexBy(data, { timestamp: selectedDate + 80 * period }, 'timestamp'),
      });
    }
    setResult({});
  }, [chart, result, dataZoomIndex]);
}
