import { States } from 'globalConstants';
import { DEFAULT_PATTERNS_INITIAL_VALUES } from 'globalConstants/logicEditor';
import { makeAutoObservable, runInAction, toJS } from 'mobx';

export default class LogicEditorStore {
  patternDefaults = {};
  state = States.Pending;

  constructor (logicEditorService) {
    makeAutoObservable(this);
    this.logicEditorService = logicEditorService;
  }

  createPatternDefaults = async (params) => {
    const data = params;
    try {
      this.setState(States.Loading);
      const response = await this.logicEditorService.createPatternDefaults(data);
      await this.getPatternDefaultsList({});
      this.setState(States.Done);
      return response;
    } catch (e) {
      this.setState(States.Error);
      throw e;
    }
  };

  updatePatternDefaults = async (params) => await this.createPatternDefaults(params)

  getPatternDefaultsList = async (params) => {
    this.setState(States.Loading);
    try {
      const data = await this.logicEditorService.getListPatternDefaults(params);
      runInAction(() => {
        if (data.data) {
          this.patternDefaults = data.data;
        } else {
          this.patternDefaults = DEFAULT_PATTERNS_INITIAL_VALUES;
        }
      });
      this.setState(States.Done);
    } catch (e) {
      this.patternDefaults = DEFAULT_PATTERNS_INITIAL_VALUES;
      this.setState(States.Error);
    }
  };

  setState (value) {
    this.state = value;
  }

  get isLoading () {
    return this.state === States.Loading;
  }

  get isReady () {
    return !this.isLoading && this.state !== States.Pending
  }

  get currentPatternDefaultList () {
    return toJS(this.patternDefaults);
  }
}
