import React, { useEffect } from 'react';
import propTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Dialog } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import BackTestCopyModulesList from './BackTestCopyModulesList';

import './styles.scss';

const DialogBackTestCopy = ({ open, title = 'Title', handlers, rows, isDisabledButton, selectedModuleList }) => {
  const intl = useIntl();

  useEffect(() => () => {
    handlers.clearSelectedModuleList();
  }, [])

  return (
    <Dialog
      className="dialog-back-test-copy custom-dialog"
      open={open}
      onClose={handlers.onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <button
        className="single-icon-btn single-icon-btn--white cross"
        onClick={handlers.onClose}
      >
        <CloseIcon />
      </button>
      {title && <h2 className="title2 bold">{title}</h2>}

      <BackTestCopyModulesList rows={rows} onSelectModule={handlers.onSelectModule} selectedModuleList={selectedModuleList} />

      <div className="custom-dialog__actions">
        <button
          autoFocus
          disabled={isDisabledButton}
          onClick={handlers.runBackTestModal}
          className="primary-btn main-btn form-action-btn bold"
        >
          {intl.formatMessage({ id: 'modules.savedModules.run.button' })}
        </button>
      </div>
    </Dialog>
  );
};

DialogBackTestCopy.propTypes = {
  open: propTypes.bool,
  isDisabledButton: propTypes.bool,
  title: propTypes.string,
  rows: propTypes.array,
  selectedModuleList: propTypes.array,
  handlers: propTypes.objectOf(propTypes.func),
};

export default DialogBackTestCopy;
