import React from 'react';
import TradeHistoryReport from 'containers/TradeHistoryReport';
import { useScrollToTop } from 'hooks';

const TradeHistoryReportPage = () => {
  useScrollToTop();
  return <TradeHistoryReport />;
};

export default TradeHistoryReportPage;
