import React from 'react';
import PropTypes from 'prop-types';
import { Field, useField } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import { CustomSwitch } from 'components/Controls';
import LabeledCheckbox from './LabeledCheckbox';

import './styles.scss';

// Show row with dependency between low & high lengths
const Dependency = ({ prefix }) => {
  const checkbox = useField(`${prefix}.enabled`)
  return (
    <div className="dependency">
      <Field name={`${prefix}.enabled`} type="checkbox" component={CustomSwitch} />
      <FormattedMessage id="candle_close.conditions.high_length" />
      <Field name={`${prefix}.value`}>
        {({ input }) => {
          const value = input.value;
          const onChange = ({ target: { name, checked } }) => {
            const symbols = ['<', '>', '='].filter(symbol => (
              name === symbol ? checked : value.includes(symbol)
            ));
            input.onChange(symbols.join(''));
          };
          return (
            <>
              <LabeledCheckbox onChange={onChange} symbol="<" value={value} disabled={!checkbox.input.value} />
              <LabeledCheckbox onChange={onChange} symbol="=" value={value} disabled={!checkbox.input.value} />
              <LabeledCheckbox onChange={onChange} symbol=">" value={value} disabled={!checkbox.input.value} />
            </>
          )
        }}
      </Field>
      <FormattedMessage id="candle_close.conditions.low_length" />
    </div>
  );
};

Dependency.propTypes = {
  prefix: PropTypes.string
};

export default Dependency;
