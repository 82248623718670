import React, { useMemo } from 'react';
import _ from 'lodash';
import { useFieldArray } from 'react-final-form-arrays';
import { FormattedMessage } from 'react-intl';

import TripleRow from './TripleRow';
import InstrumentRow from './InstruementRow';

import ConditionManagement from './ConditionManagement';

import TradeSizeCalculate from './TradeSizeCalculate';
import TradeSizeStatic from './TradeSizeStatic';
import ProfitTargets from './ProfitTargets';
import CandlePatternTimeframeRow from './TimeframeRow';
import MultipleTradeExecution from './MultipleTrade';

const EntrySystem = () => {
  const entrySystemField = useFieldArray('entrySystemConditions');

  const indexing = useMemo(() => (
    _(entrySystemField.fields.value)
      .map((field, index) => [field.kind, index])
      .fromPairs().value()
  ), [entrySystemField.fields.value]);

  return (
    <>
      <div className="rs-indicator-from__content">
        <div className="rs-indicator-list">
          <div className="title3 bold">
            <FormattedMessage id="modules.indicator.entry_system" />
          </div>
          <TripleRow
            long={`entrySystemConditions[${indexing.long_triple_close}]`}
            short={`entrySystemConditions[${indexing.short_triple_close}]`}
          />
          <TripleRow
            long={`entrySystemConditions[${indexing.long_triple_tolerance}]`}
            short={`entrySystemConditions[${indexing.short_triple_tolerance}]`}
          />
        </div>
      </div>

      <div className="rs-indicator-from__content">
        <div className="rs-indicator-list">
          <div className="title3 bold">
            <FormattedMessage id="modules.indicator.trade_management"/>
          </div>
          <TradeSizeCalculate name="trade_size_calculate" />
          <TradeSizeStatic name="trade_size" />
          <ProfitTargets />
          <MultipleTradeExecution />
          <InstrumentRow />
          <CandlePatternTimeframeRow />
        </div>
      </div>

      <div className="rs-indicator-from__content">
        <div className="rs-indicator-list">
          <div className="title3 bold">
            <FormattedMessage id="modules.indicator.loss_management"/>
          </div>
          <ConditionManagement
            withoutToggle
            name="stop_loss"
            symbol="Pts"
            range={{ min_value: 1, max_value: 100 }}
          />
          <ConditionManagement
            name="to_entry_on_targets"
            symbol="Tgts"
            range={{ min_value: 1, max_value: 5 }}
          />
          <ConditionManagement
            name="to_entry_on_points"
            symbol="Pts"
            range={{ min_value: 1, max_value: 1000 }}
          />
        </div>
      </div>
    </>
  );
};

export default EntrySystem;
