import _ from 'lodash';
import { metaKey, STOCH_CONDITIONS_KIND_LONG, STOCH_CONDITIONS_KIND_SHORT } from 'globalConstants';

// compare values of fields
const compare = (field, collision, op) => {
  if (_.isObject(field.value)) {
    return op(field.value.min_value, collision.value) && op(field.value.max_value, collision.value);
  }
  if (_.isObject(collision.value)) {
    return op(field.value, collision.value.min_value) && op(field.value, collision.value.max_value);
  }
  return op(field.value, collision.value)
};

// The most inappropriate (the biggest for long and the smallest for short)
// condition should be shown in error message. The method is for.
const getExtremeCollision = (collisions, kind) => {
  let extremeValue = kind === 'long' ? -Infinity : Infinity;
  let extremeCollision;
  for (const collision of collisions) {
    const value = (kind === 'long' ? collision.value?.max_value : collision.value?.min_value) ?? collision.value;
    if (kind === 'long' ? extremeValue < value : extremeValue > value) {
      extremeValue = value;
      extremeCollision = collision;
    }
  }
  return extremeCollision;
};

// validate condition by toggle compatibility
export const isToggleInconsensual = (field, mapping) => {
  const dependsOn = field[metaKey]?.dependsOn || [];
  const collision = dependsOn.find(key => mapping[key].enabled);
  if (collision) {
    return ['toggle', field]
  }

  const dependsOnInversed = field[metaKey]?.dependsOnInversed || [];
  const found = dependsOnInversed.find(key => mapping[key].enabled);
  if (!found && dependsOnInversed.length && field.enabled) {
    return ['toggleInversed', field]
  }

  if (!field.enabled) {
    for (const cond of Object.values(mapping)) {
      if (cond[metaKey]?.dependsOnInversed?.includes(field.kind) && cond.enabled) {
        return ['toggleInversedConcreate', cond]
      }
    }
  }
  return false;
};

// validate condition by value compatibility
export const isValueInconsensual = (field, isToggle, mapping, kind) => {
  const operators = [
    ['lt', (a, b) => a < b],
    ['le', (a, b) => a <= b],
    ['gt', (a, b) => a > b],
    ['ge', (a, b) => a >= b]
  ];
  const onValue = field[metaKey]?.onValue || [];
  for (const [key, op] of operators) {
    const collisions = onValue[key]
      ?.map(collision => mapping[collision])
      ?.filter(collision => (
        field.enabled &&
        collision.enabled &&
        !compare(field, collision, op)
      ));
    if (collisions && collisions.length) {
      const collision = getExtremeCollision(collisions, kind);
      return [isToggle ? 'toggleOnValue' : 'changeOnValue', collision];
    }
  }
  return false;
};

// The parallel logic is also in the file: src/containers/Wizard/validation.js
// RSI can't be disabled when at least one stoch enabled
// for corresponding position
export const checkAvailability = (field, form, kind) => {
  if (field[metaKey]?.rsiAvailability && !field.enabled) {
    const formValues = form.getState().values;
    const stochPrearmingConditions = _.flatten(_.values(formValues.stochConditions.prearming));
    const filteredConditions = stochPrearmingConditions.filter(cond => cond.kind.startsWith(kind) && cond.enabled);
    const disarms = _.filter([
      filteredConditions.find((cond) => cond.kind === STOCH_CONDITIONS_KIND_LONG.K_D_ABOVE_LEVEL_DISARM && cond.enabled),
      filteredConditions.find((cond) => cond.kind === STOCH_CONDITIONS_KIND_SHORT.K_D_BELOW_LEVEL_DISARM && cond.enabled),
    ]);
    // need to allow module creation having only disarms in stochastic
    const enabled = filteredConditions.filter(cond => cond.kind.startsWith(kind) && cond.enabled && !disarms.includes(cond));
    const avail = filteredConditions.find(({ kind }) => kind === field.kind)?.enabled;

    if (avail && enabled.length > 1) {
      return ['modules.validation.rsiAvailabilityDependsOnStoch', null];
    }
  }
};
