import React from 'react';
import PropTypes from 'prop-types';

export const NotificationContext = React.createContext(null);
export const useNotification = () => React.useContext(NotificationContext);

export const NotificationProvider = ({ children }) => {
  const show = (title, text) => {
    setState(prevState => ({ ...prevState, isOpen: true, title, text }));
  };

  const close = () => {
    setState(prevState => ({ ...prevState, isOpen: false }));
  };

  const [state, setState] = React.useState({
    title: '',
    text: '',
    isOpen: false,
    show,
    close
  });

  React.useEffect(() => {
    document.notificationContext = state;
  }, [state]);

  return (
    <NotificationContext.Provider value={state}>
      {children}
    </NotificationContext.Provider>
  );
};

NotificationProvider.propTypes = {
  children: PropTypes.object
};
