import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import InfiniteScroll from 'react-infinite-scroll-component';

import { EmptyIcon } from 'components/Svgs';
import Loader from 'components/Loader';
import hasLoader from 'hocs/hasLoader';
import ModuleRow from './ModuleRow';
import { DEFAULT_COLUMNS as columns } from './consts';
import './styles.scss';
import { CircularProgress } from '@material-ui/core';

const ModulesList = ({ rows, handlers, activeTab, allLoaded, selectedModuleList }) => {
  if (!rows || !rows.length) {
    return (
      <div className="module-empty-view">
        <div className="module-empty-view__icon">
          <EmptyIcon />
        </div>
        <h3 className="bold title3">
          <FormattedMessage id="modules.emptyList" />
        </h3>
        <div className="module-empty-view__description">
          <FormattedMessage id="modules.emptyListHint" />
        </div>
      </div>
    );
  }

  return (
    <InfiniteScroll
      next={handlers.next}
      dataLength={rows.length}
      scrollableTarget={'scrollable-page'}
      hasMore={!allLoaded}
      loader={<div style={{
        height: '50px',
        marginTop: '20px',
        display: 'flex',
        justifyContent: 'center'
      }}>
        <CircularProgress />
      </div>}
    >
      <table className="app-table">
        <thead className="app-table__head">
          <tr>
            {columns.map(({ attribute, classes, filterOptions }, index) => (
              <th key={index} className={classes} >
                <div className="column-title__label">
                  <FormattedMessage id={`modules.savedModules.${attribute}`} defaultMessage=" " />
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="app-table__content">
          {rows.map(obj =>
            <ModuleRow
              key={obj.id}
              module={obj}
              activeTab={activeTab}
              handlers={handlers}
              selectedModuleList={selectedModuleList}
            />
          )}
        </tbody>
      </table>
    </InfiniteScroll>
  );
};

ModulesList.propTypes = {
  rows: PropTypes.array,
  allLoaded: PropTypes.bool,
  activeTab: PropTypes.string,
  handlers: PropTypes.objectOf(PropTypes.func),
  selectedModuleList: PropTypes.array
};

export default hasLoader(ModulesList, Loader);
