import React from 'react';
import { Checkbox } from '@material-ui/core';

import './styles.scss';

const CheckedIcon = () => (
  <svg className="Mui-checked" focusable="false" viewBox="0 0 24 24" aria-label="true">
    <path d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z" />
    <path d="M17 7H9c-1.1 0-2 .9-2 2v6c0 1.1.9 2 2 2h6c1.1 0 2-.9 2 -2v-6c0-1.1-.9-2-2-2z" />
  </svg>
)

const CustomCheckbox = props => (
  <Checkbox
    className="custom-checkbox"
    checkedIcon={<CheckedIcon />}
    {...props}
  />
);

export default CustomCheckbox;
