import React from 'react';
import { Field } from 'react-final-form';
import { Input } from 'components/Fields';
import { FormattedMessage } from 'react-intl';
import { FormControl } from '@material-ui/core';
import { trimString } from 'helpers';
import * as validators from 'helpers/validators';
import './styles.scss';

const PeriodRSICalculation = () => {
  return (
    <FormControl component="fieldset">
      <div className="form__item">
        <Field
          autoFocus
          withHelper
          component={Input}
          name="rsiPeriodValue"
          validate={value => (
            validators.validateWithRange({
              min_value: 1,
              max_value: 99
            })(value) ||
            validators.validateWithoutSpace(value) ||
            validators.validateIntegerField(value) ||
            trimString(value)
          )}
          label={<FormattedMessage id={'modules.periodRSICalculation'}/>}
        />
      </div>
    </FormControl>
  );
};

export default PeriodRSICalculation;
