import { get, post, put, patch, del } from 'api/utils';
import {
  DUPLICATE_MODULE_API_URL,
  MODULE_API_URL,
  SINGLE_MODULE_API_URL,
  DATA_EDITOR_ARCHIVE_FORMATION,
  MOVE_TO_DEMO_API_URL, MOVE_TO_SAVE_API_URL, MOVE_TO_ACTIVE_API_URL, STOP_MODULE, STOP_SYSTEM
} from './consts'

export default class ModuleService {
  createModule (params) {
    return post('client', MODULE_API_URL, true, params);
  }

  getListModule ({ params, cancelToken }) {
    return get('client', MODULE_API_URL, true, params, false, cancelToken);
  }

  getListModuleForRuntime (params) {
    return get('client', `runtimes/${MODULE_API_URL}`, true, params);
  }

  duplicateModule (id) {
    return post('client', DUPLICATE_MODULE_API_URL(id), true);
  }

  downloadModule (data) {
    return post('finance', DATA_EDITOR_ARCHIVE_FORMATION, true, data, true);
  }

  getModule (id, params) {
    return get('client', SINGLE_MODULE_API_URL(id), true, params);
  }

  patchModule (id, params) {
    return patch('client', SINGLE_MODULE_API_URL(id), true, params);
  }

  putModule (id, params) {
    return put('client', SINGLE_MODULE_API_URL(id), true, params);
  }

  deleteModule (id, params) {
    return del('client', SINGLE_MODULE_API_URL(id), true, params, false);
  }

  stopModule (id, params) {
    return post('finance', STOP_MODULE(id), true);
  }

  stopAllModules () {
    return post('finance', STOP_SYSTEM, true);
  }

  moveToSave (data) {
    return post('finance', MOVE_TO_SAVE_API_URL, true, data);
  }

  moveToDemo (data) {
    return post('finance', MOVE_TO_DEMO_API_URL, true, data);
  }

  moveToActive (data) {
    return post('finance', MOVE_TO_ACTIVE_API_URL, true, data);
  }

  runBacktests (data) {
    return new Promise(data);
  }
}
