import { useCallback, useEffect, useMemo, useState } from 'react';
import { DateTime } from 'luxon';
import _ from 'lodash';

import { useStore } from 'hooks';
import { toJS } from 'mobx';
import { useIntl } from 'react-intl';
import { useGetFilters, MIN_DATE } from 'globalConstants/tradeFilters';
import { useNotification } from 'contexts/notification';
import { globalFormatters } from 'globalConstants/formatters';

const RANGE_FILTER_CONFIG = {
  rangeFrom: 'rangeFrom',
  rangeTo: 'rangeTo'
}

const STATUSES_FILTER = [
  { id: 'active', value: 'Active' },
  { id: 'demo', value: 'Demo' }
];

export const filterConfig = {
  minDate: MIN_DATE,
  initialValues: {
    moduleName: ' ',
    kind: 'active',
    instrument: ' ',
    [RANGE_FILTER_CONFIG.rangeFrom]: MIN_DATE.toMillis(),
    [RANGE_FILTER_CONFIG.rangeTo]: DateTime.now().toMillis()
  },
  rangeFilterConfig: RANGE_FILTER_CONFIG
};

export const useLogs = () => {
  const stores = useStore();

  _useSocket(stores);
  const { allData } = _useGetAllData(stores);
  const { getLogs, lastFilters } = _useGetLogs(stores);
  const { selectFields } = _useGetFields({ data: allData, lastFilters });

  return { allData, selectFields, getLogs };
}

function _useSocket ({ lastItemStore, instrumentsStore }) {
  useEffect(() => {
    for (const { id } of instrumentsStore.instruments) {
      lastItemStore.subscribe(id);
    }
    return () => { lastItemStore.unsubscribe(); }
  }, [instrumentsStore.instruments]);
};

function _useGetAllData ({ tradeOpenOrdersStore, lastItemStore }) {
  const allData = toJS(tradeOpenOrdersStore.tradeOpenOrdersData);
  for (const log of allData.logs) {
    log.lastPrice = lastItemStore.prices.get(log.instrument)
  }

  return { allData };
};

function _useGetFields ({ data, lastFilters }) {
  const { getFilters, instrumentsFilter } = useGetFilters({ data, lastFilters });

  return useMemo(() => {
    const modulesFilter = getFilters('modules');

    const selectFields = [
      {
        label: 'trade.history.filter.module.label',
        name: 'moduleName',
        items: modulesFilter
      },
      {
        label: 'trade.history.filter.status.label',
        name: 'kind',
        items: STATUSES_FILTER
      },
      {
        label: 'trade.history.filter.instrument.label',
        name: 'instrument',
        items: instrumentsFilter
      }
    ];

    return { selectFields };
  }, [data?.filters?.instruments, data?.filters?.kinds, data?.filters?.modules]);
};

function _useGetLogs ({ tradeOpenOrdersStore }) {
  const [lastFilters, setLastFilters] = useState(filterConfig.initialValues);

  const getLogs = useCallback(({ values = {} }) => {
    const filters = _.mapValues(values, value => value !== ' ' ? value : undefined);
    setLastFilters(filters);
    tradeOpenOrdersStore.getOpenOrdersHistory(filters);
  }, []);

  useEffect(() => () => tradeOpenOrdersStore.resetData(), []);

  return { getLogs, lastFilters };
};

export const useOrderNotification = () => {
  const intl = useIntl();
  const { tradeOpenOrdersStore, lastItemStore, instrumentsStore } = useStore();
  const notification = useNotification();
  const { pl } = globalFormatters;

  const closeNotification = _useCloseNotification({ tradeOpenOrdersStore, lastItemStore, instrumentsStore, pl, notification, intl });

  return { closeNotification }
}

function _useCloseNotification ({ tradeOpenOrdersStore, lastItemStore, instrumentsStore, pl, notification, intl }) {
  return (id) => {
    const order = tradeOpenOrdersStore.tradeOpenOrdersData.logs.find((order) => order.id === id);
    const lastPrice = lastItemStore.prices.get(order.instrument);
    const plValue = pl({ row: { ...order, lastPrice }, instruments: instrumentsStore.instruments });

    tradeOpenOrdersStore.updateDataAfterClose(order);
    notification.show(
      intl.formatMessage({ id: 'notification.title.orderClosed' }),
      intl.formatMessage({ id: 'notification.text.orderClosed' }, { plValue })
    );
  }
}
