import React from 'react';

import PasswordProtectedRoute from 'containers/PasswordProtected';
import LogicEditorMenu from 'containers/LogicEditorMenu';

const LogicEditor = () => (
  <PasswordProtectedRoute pageName={'logic-editor'}>
    <LogicEditorMenu />
  </PasswordProtectedRoute>
)

export default LogicEditor;
