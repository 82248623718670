import React, { useState } from 'react';
import propTypes from 'prop-types';
import { Field, useField } from 'react-final-form';
import TripleConditionForm from './TripleConditionForm';
import classNames from 'classnames';
import ConditionRow from '../../BaseIndicator/ConditionList/Condition/ConditionRow';
import { useSwitcher } from 'contexts/pageSwitcher';

import './styles.scss';

const TripleRow = ({ long, short }) => {
  const { input: { value, onChange } } = useField(long);
  const [open, setOpen] = useState(false);
  const switcher = useSwitcher();

  return (
    <div>
      <Field name={`${long}.enabled`}>
        {({ input: { onChange } }) => (
          <ConditionRow
            field={value}
            prefix="entry_system"
            handleClick={() => () => { setOpen(true) }}
            handleChange={field => e => { onChange(e.target.checked) }}
          />
        )}
      </Field>
      <div className={classNames('entry-system-row', { hidden: !value.enabled })}>
        {['candle_pattern', 'candle_close'].map(type => (
          <Field key={type} name={`${long}.value.${type}.enabled`}>
            {({ input: { onChange, value: enabled } }) => (
              <div className={classNames('candlestick-row', { enabled })}>
                <ConditionRow
                  field={value.value[type]}
                  prefix="entry_system"
                  handleChange={field => e => { onChange(e.target.checked) }}
                />
                <div>
                  <button
                    onClick={() => { switcher.moveTo(type === 'candle_pattern' ? 2 : 1, { fieldName: `${long}.value` }) }}
                    className="secondary-btn main-btn"
                  >
                    long
                  </button>
                  <button
                    onClick={() => { switcher.moveTo(type === 'candle_pattern' ? 2 : 1, { fieldName: `${short}.value` }) }}
                    className="secondary-btn main-btn"
                  >
                    short
                  </button>
                </div>
              </div>
            )}
          </Field>
        ))}
      </div>
      <TripleConditionForm
        open={open}
        obj={value}
        prefix="entry_system"
        handleClose={() => setOpen(false)}
        handleSave={onChange}
      />
    </div>
  );
};

TripleRow.propTypes = {
  long: propTypes.object,
  short: propTypes.object,
};

export default TripleRow;
