import { intl } from 'translations/provider';
import _ from 'lodash';

export const INSTRUMENTS = [
  { label: 'currencyPair.gbpUSD', value: 'gbp_usd' },
  { label: 'currencyPair.eurUSD', value: 'eur_usd' },
  { label: 'currencyPair.eurGBP', value: 'eur_gbp' },
  { label: 'currencyPair.eurJPY', value: 'eur_jpy' },
  { label: 'currencyPair.btcUSD', value: 'btc_usd' },
  { label: 'currencyPair.xauUSD', value: 'xau_usd' },
  { label: 'currencyPair.xagUSD', value: 'xag_usd' }
];

export const intlMessage = ({ value }) => intl.formatMessage({
  id: INSTRUMENTS.find(e => e.value === value)?.label || ' ',
  defaultMessage: ' '
});

export const instrumentMessage = ({ instruments, id }) => {
  return id && instruments.length && _(instruments).find({ id })?.label;
}

export const DEFAULT_INSTRUMENT = INSTRUMENTS[0].value;
