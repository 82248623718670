import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Input } from 'components/Fields';
import DialogForm from 'components/DialogForm';
import { Form, Field } from 'react-final-form';
import * as validators from 'helpers/validators';
import '../styles.scss';

export default class TrendConditionForm extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      formError: ''
    }
  }

  handleSubmit = (values) => {
    const { obj, originalObj } = this.props

    const payload = {
      index: values.index,
      rsi_level: parseFloat(values.rsi_level),
      rsi_reset_level1: parseFloat(values.rsi_reset_level1),
      rsi_reset_level2: parseFloat(values.rsi_reset_level2),
    }
    const trends = originalObj.value.map(trend => trend.index === payload.index ? payload : trend)
    const error = validators.validateTrendFieldGroup(trends, obj)

    if (error) {
      this.setState({ formError: error })
    } else {
      this.props.handleSave(this.props.obj, trends);
    }
  };

  validate = (value, values, key) => {
    const { available_range: availableRange } = this.props.obj;

    return (
      validators.validateWithRange({ ...availableRange }, true)(value) ||
      validators.validateTrendField(value, values, key)
    )
  }

  onFieldFocus = () => {
    if (this.state.formError) {
      this.setState({ formError: '' })
    }
  }

  field = (name, sign, values) => {
    const { symbol } = this.props.obj

    return (
      <div className="condition-form-content__field" onFocus={() => this.onFieldFocus()}>
        {sign && (
          <div
            className="condition-form-content__sign bold"
          >
            {sign}
          </div>
        )}
        <Field
          name={name}
          component={Input}
          validate={(val, allValues) => this.validate(val, allValues, name)}
          autoFocus={name === 'rsi_level'}
          withHelper
        />
        <div className="condition-form-content__symbol bold">
          {symbol}
        </div>
      </div>
    )
  }

  content = (values) => (
    <form className="condition-form-content">
      <div className="form">
        {this.title('rsi_level')}
        {this.field('rsi_level', null, values)}
        {this.title('rsi_reset_range')}
        {this.field('rsi_reset_level2', '<', values)}
        {this.field('rsi_reset_level1', '>', values)}
      </div>
    </form>
  );

  title = (postfix) => {
    const { obj, prefix } = this.props;
    const { symbol, available_range: availableRange } = obj;

    return (
      <div className="condition-form-header">
        <div className="title2 bold">
          <FormattedMessage id={`${prefix}.conditions.${postfix}`} />
        </div>

        {availableRange && (
          <span className="condition-form-header__description">
            Allowable range: {availableRange.min_value} —{' '}
            {availableRange.max_value}
            {symbol}
          </span>
        )}
      </div>
    );
  };

  controls = (isDisabled, values) => {
    return (
      <div>
        <p className="error-label">
          {this.state.formError}
        </p>
        <div className="custom-dialog__actions">
          <button
            onClick={this.props.handleClose}
            className="primary-btn outline-btn form-action-btn bold"
          >
            <FormattedMessage id="modules.buttons.cancel" />
          </button>
          <button
            onClick={() => this.handleSubmit(values)}
            disabled={isDisabled}
            className="primary-btn main-btn form-action-btn bold"
          >
            <FormattedMessage id="modules.buttons.save" />
          </button>
        </div>
      </div>
    );
  };

  render () {
    const { open, obj, handleClose } = this.props;
    return (
      <Form
        onSubmit={val => this.handleSubmit(val)}
        initialValues={obj.value}
        render={({ values, invalid }) => {
          return (
            <>
              <DialogForm
                open={open}
                title={<></>}
                content={this.content(values)}
                controls={this.controls(invalid, values)}
                handleClose={handleClose}
                customClass="condition-form-popup"
              />
            </>
          );
        }}
      />
    );
  }
}

TrendConditionForm.propTypes = {
  open: PropTypes.bool,
  obj: PropTypes.object,
  originalObj: PropTypes.object,
  prefix: PropTypes.string,
  handleClose: PropTypes.func,
  handleSave: PropTypes.func
};
