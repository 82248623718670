import React from 'react';

import PasswordProtectedRoute from 'containers/PasswordProtected';
import AuthenticatedRoute from 'containers/App/AuthenticatedRoute';
import ModulesSavedPage from '../ModulesSavedPage';
import ModulesDemoPage from '../ModulesDemoPage';

const ModulesPage = () => (
  <PasswordProtectedRoute pageName={'modules'}>
    <AuthenticatedRoute exact path="/modules/saved" compontent={ModulesSavedPage} />
    <AuthenticatedRoute exact path="/modules/demo" compontent={ModulesDemoPage} />
  </PasswordProtectedRoute>
);

export default ModulesPage;
