import { makeAutoObservable } from 'mobx';

import { represent, toInternal } from 'helpers/utils';
import { States } from './consts'

const response = {
  data: [
    {
      id: 1,
      title: 'Title',
      instruments: ['gbp_usd', 'eur_usd'],
      time_cycle: 'minute',
      timeframe_start: 1,
      timeframe_end: 99,
      start_time: 'time',
      finish_time: 'time',
      frequency_period: 'monthly', // possible values ['daily', 'weekly', 'monthly']
      frequency_day: 'monday', // day for 'weekly' type, available value for 'monthly' type
      frequency_sequence: 'first', // possible values ['first', 'second', 'third', 'fourth', 'last']
      action: 'stop_opening_orders',
      status: 'stop', // status can be 'stop' or 'active'
    },
  ]
}

export default class TradeExclusionStore {
  tradeExclusions = [];
  states = States;
  state = States.Pending;

  constructor(tradeExclusionService) {
    makeAutoObservable(this);
    this.tradeExclusionService = tradeExclusionService;
  }

  * getTradeExclusions() {
    this.state = this.states.Loading;
    try {
      const response = yield this.tradeExclusionService.getTradeExclusions();
      this.tradeExclusions = toInternal(response.data);
      this.state = this.states.Done;
    } catch (e) {
      this.state = this.states.Error;
      throw e;
    }
  }

  * createTradeExclusion(params = {}) {
    this.state = this.states.Loading;
    try {
      const response = yield this.tradeExclusionService.createTradeExclusion(represent(params));
      this.tradeExclusions.unshift(toInternal(response.data))
      this.state = this.states.Done;
    } catch (e) {
      this.state = this.states.Error;
      throw e;
    }
  }

  * updateTradeExclusion(params = {}) {
    this.state = this.states.Loading;
    try {
      const response = yield this.tradeExclusionService.updateTradeExclusion(params?.id, represent(params));
      this.update(response);
      this.state = this.states.Done;
    } catch (e) {
      this.state = this.states.Error;
      throw e;
    }
  }

  * toggleTradeExclusion(id, curStatus) {
    this.state = this.states.Loading;
    try {
      const strategy = {
        active: this.tradeExclusionService.stopTradeExclusion,
        stop: this.tradeExclusionService.launchTradeExclusion
      }
      const response = yield strategy[curStatus](id);

      this.update(response);
      this.state = this.states.Done;
    } catch (e) {
      this.state = this.states.Error;
      throw e;
    }
  }

  * deleteTradeExclusions(id) {
    this.state = this.states.Loading;
    try {
      yield this.tradeExclusionService.deleteTradeExclusion(id);
      this.tradeExclusions = this.tradeExclusions.filter((tradeExclusion) => tradeExclusion.id !== id);
      this.state = this.states.Done;
    } catch (e) {
      this.state = this.states.Error;
      throw e;
    }
  }

  reset() {
    this.tradeExclusions = [];
  }

  update(response) {
    const updated = toInternal(response.data);
    this.tradeExclusions = this.tradeExclusions.map((tradeExclusion) => {
      if (tradeExclusion.id === updated.id) {
        return updated;
      }
      return tradeExclusion;
    });
  }

  get isLoading() {
    return this.state === States.Loading;
  }
}
