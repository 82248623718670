import _ from 'lodash';
import { filters, performers } from './helpers'
import { roundNum } from 'helpers';
import { COMMON_PRECISION } from 'globalConstants';

export const POSITIONS = [
  { label: 'logs.stoch.long', value: 'STOCH_LONG_PREARMING', isEnabled: filters.prearmingStoch, nameSplitter: 'long', kind: 'prearming' },
  { label: 'logs.stoch.short', value: 'STOCH_SHORT_PREARMING', isEnabled: filters.prearmingStoch, nameSplitter: 'short', kind: 'prearming' },
  { label: 'logs.rsi.long', value: 'RSI_LONG', isEnabled: filters.rsi, nameSplitter: 'long' },
  { label: 'logs.rsi.short', value: 'RSI_SHORT', isEnabled: filters.rsi, nameSplitter: 'short' },
  { label: 'logs.candle_pattern.long', value: 'CANDLE_CLOSE_LONG', isEnabled: () => true, nameSplitter: 'long' },
  { label: 'logs.candle_pattern.short', value: 'CANDLE_CLOSE_SHORT', isEnabled: () => true, nameSplitter: 'short' },
  { label: 'logs.whole_sequence.long', value: 'WHOLE_SEQUENCE_LONG', isEnabled: () => true, nameSplitter: 'long' },
  { label: 'logs.whole_sequence.short', value: 'WHOLE_SEQUENCE_SHORT', isEnabled: () => true, nameSplitter: 'short' }
];

const RSI_LONG = [
  { accessor: 'candle_timestamp', isEnabled: () => true, performer: performers.candle_time },
  { accessor: 'actual_timestamp', isEnabled: () => true, performer: performers.actual_time },
  { accessor: 'rsi_arming_closed_below', isEnabled: filters.common, performer: performers.common },
  { accessor: 'rsi_arming_closed_back_above', isEnabled: filters.common, performer: performers.common },
  { accessor: 'rsi_arming_between', isEnabled: filters.common, performer: performers.common },
  { accessor: 'trough_1', isEnabled: filters.angle, performer: performers.angle },
  { accessor: 'trough_2', isEnabled: filters.angle, performer: performers.angle },
  { accessor: 'rsi_trade_arming_line_ascending_angle', isEnabled: filters.common, performer: performers.withDegrees },
  { accessor: 'rsi_trough_1_reset_incline', isEnabled: filters.common, performer: performers.withDegrees },
  { accessor: 'rsi_trade_arming_support_line_extension', isEnabled: filters.common, performer: performers.withSign },
  { accessor: 'rsi_direction_up', isEnabled: filters.common, performer: performers.bool },
  { accessor: 'trough_1_close_below_disarm', isEnabled: filters.common, performer: performers.common },
  { accessor: 'trough_1_push_below_disarm', isEnabled: filters.common, performer: performers.common },
  { accessor: 'trough_2_close_below_disarm', isEnabled: filters.common, performer: performers.common },
  { accessor: 'trough_2_push_below_disarm', isEnabled: filters.common, performer: performers.common },
  { accessor: 'trough_1_rsi_line_closed_above_disarm', isEnabled: filters.common, performer: performers.common },
  { accessor: 'trough_2_rsi_line_closed_above_disarm', isEnabled: filters.common, performer: performers.common },
  { accessor: 'rsi_trough_2_reset_tolerance', isEnabled: filters.common, performer: performers.common },
  { accessor: 'rsi_closed_above_disarm', isEnabled: filters.common, performer: performers.common },
  { accessor: 'rsi_time_out_disarm_trough_1', isEnabled: filters.common, performer: performers.bool },
  { accessor: 'rsi_time_out_disarm_trough_2', isEnabled: filters.common, performer: performers.bool },
  { accessor: 'rsi_trade_arming_support_line_extension_disarm', isEnabled: filters.common, performer: performers.bool }
];

const RSI_SHORT = [
  { accessor: 'candle_timestamp', isEnabled: () => true, performer: performers.candle_time },
  { accessor: 'actual_timestamp', isEnabled: () => true, performer: performers.actual_time },
  { accessor: 'rsi_arming_closed_above', isEnabled: filters.common, performer: performers.common },
  { accessor: 'rsi_arming_closed_back_below', isEnabled: filters.common, performer: performers.common },
  { accessor: 'rsi_arming_between', isEnabled: filters.common, performer: performers.common },
  { accessor: 'peak_1', isEnabled: filters.angle, performer: performers.angle },
  { accessor: 'peak_2', isEnabled: filters.angle, performer: performers.angle },
  { accessor: 'rsi_trade_arming_line_descending_angle', isEnabled: filters.common, performer: performers.withDegrees },
  { accessor: 'rsi_peak_1_reset_decline', isEnabled: filters.common, performer: performers.withDegrees },
  { accessor: 'rsi_trade_arming_resistance_line_extension', isEnabled: filters.common, performer: performers.withSign },
  { accessor: 'rsi_direction_down', isEnabled: filters.common, performer: performers.bool },
  { accessor: 'peak_1_close_above_disarm', isEnabled: filters.common, performer: performers.common },
  { accessor: 'peak_1_push_above_disarm', isEnabled: filters.common, performer: performers.common },
  { accessor: 'peak_2_close_above_disarm', isEnabled: filters.common, performer: performers.common },
  { accessor: 'peak_2_push_above_disarm', isEnabled: filters.common, performer: performers.common },
  { accessor: 'peak_1_rsi_line_closed_below_disarm', isEnabled: filters.common, performer: performers.common },
  { accessor: 'peak_2_rsi_line_closed_below_disarm', isEnabled: filters.common, performer: performers.common },
  { accessor: 'rsi_peak_2_reset_tolerance', isEnabled: filters.common, performer: performers.common },
  { accessor: 'rsi_closed_below_disarm', isEnabled: filters.common, performer: performers.common },
  { accessor: 'rsi_time_out_disarm_peak_1', isEnabled: filters.common, performer: performers.bool },
  { accessor: 'rsi_time_out_disarm_peak_2', isEnabled: filters.common, performer: performers.bool },
  { accessor: 'rsi_trade_arming_resistance_line_extension_disarm', isEnabled: filters.common, performer: performers.bool }
];

const STOCH_LONG = [
  { accessor: 'candle_timestamp', isEnabled: () => true, performer: performers.candle_time },
  { accessor: 'actual_timestamp', isEnabled: () => true, performer: performers.actual_time },
  { accessor: 'k_only_closed_below', isEnabled: filters.common, performer: performers.bool },
  { accessor: 'k_d_closed_below', isEnabled: filters.common, performer: performers.bool },
  { accessor: 'k_d_distance_tolerance', isEnabled: filters.common, performer: performers.bool },
  { accessor: 'k_closed_above_d_below', isEnabled: filters.common, performer: performers.bool },
  { accessor: 'k_d_closed_back_above', isEnabled: filters.common, performer: performers.bool },
  { accessor: 'k_d_between', isEnabled: filters.common, performer: performers.bool },
  { accessor: 'k_d_direction_up', isEnabled: filters.common, performer: performers.bool },
  { accessor: 'd_direction_up', isEnabled: filters.common, performer: performers.bool },
  { accessor: 'k_direction_up', isEnabled: filters.common, performer: performers.bool },
  { accessor: 'k_d_above_level_disarm', isEnabled: filters.common, performer: performers.bool }
];

const STOCH_SHORT = [
  { accessor: 'candle_timestamp', isEnabled: () => true, performer: performers.candle_time },
  { accessor: 'actual_timestamp', isEnabled: () => true, performer: performers.actual_time },
  { accessor: 'k_only_closed_above', isEnabled: filters.common, performer: performers.bool },
  { accessor: 'k_d_closed_above', isEnabled: filters.common, performer: performers.bool },
  { accessor: 'k_d_distance_tolerance', isEnabled: filters.common, performer: performers.bool },
  { accessor: 'k_closed_below_d_above', isEnabled: filters.common, performer: performers.bool },
  { accessor: 'k_d_closed_back_below', isEnabled: filters.common, performer: performers.bool },
  { accessor: 'k_d_between', isEnabled: filters.common, performer: performers.bool },
  { accessor: 'k_d_direction_down', isEnabled: filters.common, performer: performers.bool },
  { accessor: 'd_direction_down', isEnabled: filters.common, performer: performers.bool },
  { accessor: 'k_direction_down', isEnabled: filters.common, performer: performers.bool },
  { accessor: 'k_d_below_level_disarm', isEnabled: filters.common, performer: performers.bool }
];

const CANDLE_CLOSE = [
  { accessor: 'candle_timestamp', isEnabled: () => true, performer: performers.candle_time },
  { accessor: 'actual_timestamp', isEnabled: () => true, performer: performers.actual_time },
  { accessor: 'candle_close', isEnabled: filters.patterns, performer: performers.candlePattern },
  { accessor: 'candle_patterns', isEnabled: filters.patterns, performer: performers.candlePattern }
];

const kdDistance = (k, d) => [roundNum(Math.abs(k - d), COMMON_PRECISION)];
const kd = (k, d) => [roundNum(k, COMMON_PRECISION), roundNum(d, COMMON_PRECISION)];
const k = (k) => [roundNum(k, COMMON_PRECISION)];

const WS_FOR = [
  { accessor: 'rsi_inside_tolerance_range', performer: performers.toleranceRange },

  // rsi long
  { accessor: 'rsi_arming_closed_below', performer: performers.commonWithBool },
  { accessor: 'rsi_arming_closed_back_above', performer: performers.commonWithBool },
  { accessor: 'rsi_arming_between', performer: performers.commonWithBool },
  { accessor: 'rsi_trade_arming_line_ascending_angle', performer: performers.withDegreesAndBoolCondition },
  { accessor: 'rsi_trough_1_reset_incline', performer: performers.withDegreesAndBool },
  { accessor: 'rsi_trade_arming_support_line_extension', performer: performers.withSignAndBool },
  { accessor: 'trough_1_close_below_disarm', performer: performers.commonWithBool },
  { accessor: 'trough_1_push_below_disarm', performer: performers.commonWithBool },
  { accessor: 'trough_2_close_below_disarm', performer: performers.commonWithBool },
  { accessor: 'trough_2_push_below_disarm', performer: performers.commonWithBool },
  { accessor: 'trough_1_rsi_line_closed_above_disarm', performer: performers.commonWithBool },
  { accessor: 'trough_2_rsi_line_closed_above_disarm', performer: performers.commonWithBool },
  { accessor: 'rsi_trough_2_reset_tolerance', performer: performers.commonWithBool },
  { accessor: 'rsi_closed_above_disarm', performer: performers.commonWithBool },
  { accessor: 'rsi_time_out_disarm_trough_1', performer: performers.commonWithBool },
  { accessor: 'rsi_time_out_disarm_trough_2', performer: performers.commonWithBool },

  // rsi short
  { accessor: 'rsi_arming_closed_above', performer: performers.commonWithBool },
  { accessor: 'rsi_arming_closed_back_below', performer: performers.commonWithBool },
  { accessor: 'rsi_arming_between', performer: performers.commonWithBool },
  { accessor: 'rsi_trade_arming_line_descending_angle', performer: performers.withDegreesAndBoolCondition },
  { accessor: 'rsi_peak_1_reset_decline', performer: performers.withDegreesAndBool },
  { accessor: 'rsi_trade_arming_resistance_line_extension', performer: performers.withSignAndBool },
  { accessor: 'peak_1_close_above_disarm', performer: performers.commonWithBool },
  { accessor: 'peak_1_push_above_disarm', performer: performers.commonWithBool },
  { accessor: 'peak_2_close_above_disarm', performer: performers.commonWithBool },
  { accessor: 'peak_2_push_above_disarm', performer: performers.commonWithBool },
  { accessor: 'peak_1_rsi_line_closed_below_disarm', performer: performers.commonWithBool },
  { accessor: 'peak_2_rsi_line_closed_below_disarm', performer: performers.commonWithBool },
  { accessor: 'rsi_peak_2_reset_tolerance', performer: performers.commonWithBool },
  { accessor: 'rsi_closed_below_disarm', performer: performers.commonWithBool },
  { accessor: 'rsi_time_out_disarm_peak_1', performer: performers.commonWithBool },
  { accessor: 'rsi_time_out_disarm_peak_2', performer: performers.commonWithBool },

  // stoch long
  { accessor: 'k_only_closed_below', performer: performers.stoch(k) },
  { accessor: 'k_d_closed_below', performer: performers.stoch(kd) },
  { accessor: 'k_d_distance_tolerance', performer: performers.stoch(kdDistance) },
  { accessor: 'k_closed_above_d_below', performer: performers.stoch(kd) },
  { accessor: 'k_d_closed_back_above', performer: performers.stoch(kd) },
  { accessor: 'k_d_between', performer: performers.stoch(kd) },
  { accessor: 'k_d_above_level_disarm', performer: performers.stoch(kd) },
  { accessor: 'k_d_direction_up', performer: performers.fullBool },
  { accessor: 'd_direction_up', performer: performers.fullBool },
  { accessor: 'k_direction_up', performer: performers.fullBool },

  // stoch short
  { accessor: 'k_only_closed_above', performer: performers.stoch(k) },
  { accessor: 'k_d_closed_above', performer: performers.stoch(kd) },
  { accessor: 'k_closed_below_d_above', performer: performers.stoch(kd) },
  { accessor: 'k_d_closed_back_below', performer: performers.stoch(kd) },
  { accessor: 'k_d_between', performer: performers.stoch(kd) },
  { accessor: 'k_d_below_level_disarm', performer: performers.stoch(kd) },
  { accessor: 'k_d_direction_down', performer: performers.fullBool },
  { accessor: 'd_direction_down', performer: performers.fullBool },
  { accessor: 'k_direction_down', performer: performers.fullBool },
]

const ALL_CONDITIONS = _([STOCH_SHORT, STOCH_LONG, RSI_LONG, RSI_SHORT, WS_FOR])
  .flatten().keyBy('accessor').value();

const WHOLE_SEQUENCE = type => [
  { accessor: 'candle_timestamp', isEnabled: () => true, performer: performers.candle_time },
  { accessor: 'actual_timestamp', isEnabled: () => true, performer: performers.actual_time },
  { accessor: 'timeframe', isEnabled: () => true, performer: performers.timeframe },
  { accessor: 'indicator', isEnabled: () => true, performer: performers.indicator },
  { accessor: 'condition', isEnabled: () => true, performer: performers.wsCondition(type) },
  { accessor: 'value', isEnabled: () => true, performer: performers.wsValue(ALL_CONDITIONS) }
]

export const COLUMNS = {
  STOCH_SHORT_PREARMING: STOCH_SHORT,
  STOCH_LONG_PREARMING: STOCH_LONG,
  RSI_LONG,
  RSI_SHORT,
  CANDLE_CLOSE_SHORT: CANDLE_CLOSE,
  CANDLE_CLOSE_LONG: CANDLE_CLOSE,
  WHOLE_SEQUENCE_LONG: WHOLE_SEQUENCE('long'),
  WHOLE_SEQUENCE_SHORT: WHOLE_SEQUENCE('short')
};
