import { useEffect } from 'react';
import { useChartSync } from 'contexts/chart';
import { useStore } from 'hooks';
import { loadDataByMiddleTimestamp } from 'contexts/chart/helpers';

export function useResize (chart) {
  useEffect(() => {
    if (chart) {
      const onResize = () => { chart.resize(); };
      window.addEventListener('resize', onResize);
      return () => { window.removeEventListener('resize', onResize) };
    }
  }, [chart]);
}

// react on period changes and load new tf at the same ts
export const useLoadChartData = (chart) => {
  [0, 1, 2].forEach(dataZoomIndex => {
    _useLoadChartData(chart, dataZoomIndex)
  });
}

const _useLoadChartData = (chart, dataZoomIndex) => {
  const { chartStore } = useStore();
  const { observers } = useChartSync();
  const index = chartStore.tfGrouper.groupedByPeriod[dataZoomIndex]?.[0];
  const store = chartStore.tfGrouper.groups[dataZoomIndex]?.[0];
  useEffect(() => {
    if (!store?.allData || !store?.params?.period || !chart) return;
    loadDataByMiddleTimestamp(store?.allData, dataZoomIndex, index, chart, observers);
  }, [store?.params?.period]);
}
