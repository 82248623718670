import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { ACTIVE_MODULE_TYPE, ALLOWED_BUTTON_TYPES, DEMO_MODULE_TYPE, SAVED_MODULE_TYPE } from 'containers/ModulesListContainer/consts';

export const useMoveToConfig = ({ handlers, module, activeTab }) => {
  const intl = useIntl();

  const defaultConfig = {
    buttonTitle: intl.formatMessage({ id: 'custom-menu-button-move' }),
    menuItems: [
      {
        menuItemName: intl.formatMessage({ id: 'custom-menu-button-move-action-saved' }),
        action: () => handlers.moveTo(module, SAVED_MODULE_TYPE),
        type: SAVED_MODULE_TYPE
      },
      {
        menuItemName: intl.formatMessage({ id: 'custom-menu-button-move-action-demo' }),
        action: () => handlers.moveTo(module, DEMO_MODULE_TYPE),
        type: DEMO_MODULE_TYPE
      },
      {
        menuItemName: intl.formatMessage({ id: 'custom-menu-button-move-action-active' }),
        action: () => handlers.moveTo(module, ACTIVE_MODULE_TYPE),
        type: ACTIVE_MODULE_TYPE
      },
    ]
  };

  return useMemo(() => {
    const menuItems = defaultConfig.menuItems.filter(({ type }) => {
      return ALLOWED_BUTTON_TYPES[activeTab].some((e) => e === type)
    })
    return {
      ...defaultConfig,
      menuItems
    }
  }, [activeTab]);
};
