import React from 'react';
import PropTypes from 'prop-types';
import { CustomCheckbox } from 'components/Controls';
import classNames from 'classnames';

// Checkbox with symbol
const LabeledCheckbox = ({ onChange, symbol, value, disabled }) => (
  <span className={classNames({ disabled })} key={symbol}>
    <CustomCheckbox
      disabled={disabled}
      onChange={onChange}
      name={symbol}
      checked={value.includes(symbol)}
    />
    <span>{symbol}</span>
  </span>
);

LabeledCheckbox.propTypes = {
  onChange: PropTypes.func,
  symbol: PropTypes.string,
  value: PropTypes.string,
  disabled: PropTypes.bool
};

export default LabeledCheckbox;
