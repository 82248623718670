const createImage = url => new Promise((resolve, reject) => {
  const image = new Image()
  image.addEventListener('load', () => resolve(image))
  image.addEventListener('error', (error) => reject(error))
  image.setAttribute('crossOrigin', 'anonymous')
  image.src = url
});

export const getCroppedImg = async (imageSrc, crop, isBlob = false) => {
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  canvas.width = image.width;
  canvas.height = image.height;

  ctx.drawImage(image, 0, 0);

  const data = ctx.getImageData(crop.x, crop.y, crop.width, crop.height);

  canvas.width = crop.width;
  canvas.height = crop.height;

  ctx.putImageData(data, 0, 0);

  const dCanvas = document.createElement('canvas');
  const dCtx = dCanvas.getContext('2d');
  dCanvas.width = dCanvas.height = 70;
  dCtx.drawImage(canvas, 0, 0, 70, 70);

  return isBlob ? (
    new Promise(resolve => {
      dCanvas.toBlob((file) => {
        resolve(URL.createObjectURL(file));
      }, 'image/jpeg')
    })
  ) : (
    canvas.toDataURL('image/jpeg')
  );
};

export const file2base64 = file => new Promise(resolve => {
  if (file) {
    const reader = new FileReader();
    reader.addEventListener('load', () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  }
});
