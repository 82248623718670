import { DEFAULT_CANDLE_PATTERN } from 'globalConstants/candlePatterns';
import { CUSTOM_PATTERN_KIND_PREFIX, CUSTOM_PATTERN_TITLE_PREFIX_ID } from 'globalConstants/defaultPatterns';
import { intl } from 'translations/provider';

// NOTE: custom patterns are themselves default patterns, but provided by user

export const generateDefaultPattern = (patternArray) => ({
  ...DEFAULT_CANDLE_PATTERN,
  title: getNewPatternTitle(patternArray)
})

// Custom patterns dont have a kind before being submitted
export const isCustomPattern = (kind) => !kind || kind.includes(CUSTOM_PATTERN_KIND_PREFIX)

/**
 * Get custom pattern number from it's title or return null
 * @param {string} prefix title prefix
 * @returns {string|null}
 */
export const getDefaultPatternNumber = (prefix) => (pattern) => {
  const regexp = RegExp(`${prefix}(\\d+).*`)
  const match = pattern.title.match(regexp)
  return match && match[1]
}

/**
 * 1. Filter array to leave only default patterns
 * 2. Extract custom pattern numbers
 * 3. Return max value of resulting array + 1
 * @param {Array<object>} patternArray array consisting of candle pattern objects
 * @returns {number} number for a new custom_pattern
 */
export const getNewPatternNumber = (prefix) => (patternArray) => Math.max(
  ...patternArray.filter(p => isCustomPattern(p.kind))
    .map(getDefaultPatternNumber(prefix)),
  // Add a zero so that if the filter returns empty list we'll still generate 1 as the new number
  // Math.max from -Infinity/0 is 0, as well as it's 0 if there are nulls (in case of title no containing number)
  0
) + 1

/**
 * @param {Array<object>} patternArray array consisting of candle pattern objects
 * @returns {string} incremented title
 */
export const getNewPatternTitle = (patternArray) => {
  const prefix = intl.formatMessage({ id: CUSTOM_PATTERN_TITLE_PREFIX_ID })

  return prefix + getNewPatternNumber(prefix)(patternArray)
}
