import { CANDLE_PATTERNS_LONG, CANDLE_PATTERNS_SHORT } from './candlePatterns';
import { CANDLE_CLOSE_LONG, CANDLE_CLOSE_SHORT } from './candleClose';

const CANDLE_PATTERN_BASE = {
  description: '',
  enabled: false,
  kind: 'candle_pattern'
};

const CANDLE_CLOSE_BASE = {
  description: '',
  enabled: true,
  kind: 'candle_close'
};

export const DEFAULT_PATTERNS_INITIAL_VALUES = {
  triple_close: {
    long: {
      candle_pattern: { ...CANDLE_PATTERN_BASE, value: CANDLE_PATTERNS_LONG },
      candle_close: { ...CANDLE_CLOSE_BASE, value: CANDLE_CLOSE_LONG }
    },
    short: {
      candle_pattern: { ...CANDLE_PATTERN_BASE, value: CANDLE_PATTERNS_SHORT },
      candle_close: { ...CANDLE_CLOSE_BASE, value: CANDLE_CLOSE_SHORT }
    }
  },
  triple_tolerance: {
    long: {
      candle_pattern: { ...CANDLE_PATTERN_BASE, value: CANDLE_PATTERNS_LONG },
      candle_close: { ...CANDLE_CLOSE_BASE, value: CANDLE_CLOSE_LONG }
    },
    short: {
      candle_pattern: { ...CANDLE_PATTERN_BASE, value: CANDLE_PATTERNS_SHORT },
      candle_close: { ...CANDLE_CLOSE_BASE, value: CANDLE_CLOSE_SHORT }
    }
  }
};
