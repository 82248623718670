import React from 'react';
import { createIntl, createIntlCache } from '@formatjs/intl';
import English from './en.json';

export const intl = createIntl({
  // Change on adding new locales
  // locale: navigator.language,
  // messages: English,
  locale: 'en',
  messages: English,
  onError () {},
  defaultRichTextElements: {
    b: c => <b>{c}</b>,
    br: () => <br />,
  }
}, createIntlCache());
