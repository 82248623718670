import React from 'react';
import propTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import { useIntl, FormattedMessage } from 'react-intl';
import cn from 'classnames';
import createDecorator from 'final-form-calculate';
import { TextField } from '@mui/material';
import { DateTime } from 'luxon';
import { CustomRadio } from 'components/Controls';
import { useHistory } from 'react-router-dom';

import DialogForm from 'components/DialogForm';
import SimpleFormControls from 'components/SimpleFormControls';
import CustomDateTimePicker from 'components/Calendar';
import { Input } from 'components/Fields';
import { FormControlLabel, RadioGroup } from '@material-ui/core';
import FilePicker from 'components/FilePicker';
import { DATA_TYPES, ORDER_SIZE_DATA_TYPES } from './consts';
import { trimString } from 'helpers';
import * as validators from 'helpers/validators';

import './styles.scss';

const decorator = createDecorator(
  {
    field: 'order_size_type',
    updates: {
      orderSize: (value, all) => {
        if (value === 'dynamic' && all.orderSize === 1000) {
          return 1;
        }
        if (value === 'static' && all.orderSize === 1) {
          return 1000;
        }

        return all.orderSize;
      }
    }
  }
);

const BackTestModal = ({ open, handlers, copyBacktest, selectedModuleList }) => {
  const intl = useIntl();
  const history = useHistory();
  const minDate = DateTime.fromISO('2019-10-19');
  const maxDate = DateTime.now();

  const onSubmit = (formValue) => {
    if (!selectedModuleList.length) {
      return;
    }

    const data = {
      modulesIds: selectedModuleList.map(({ id }) => id),
      kind: 'simulation',
      setting: {
        kind: 'range',
        ...formValue,
        startPeriod: formValue.startPeriod.toFormat('yyyy-MM-dd'),
        endPeriod: formValue.endPeriod.toFormat('yyyy-MM-dd'),
      }
    };

    if (formValue.dataEditorFile) {
      data.dataEditorFile = formValue.dataEditorFile;
      delete data.setting.dataEditorFile;
    }

    handlers.handleClose();
    handlers.clearSelectedModuleList();
    handlers.create(data)
      .then(() => {
        history.push('/backtest/list');
      })
  };

  const initialValues = {
    name: copyBacktest?.name || 'Backtest',
    totalFund: copyBacktest?.setting.totalFund || 100000,
    order_size_type: copyBacktest?.setting.order_size_type || 'dynamic',
    orderSize: copyBacktest?.setting.orderSize || 1,
    maxAmountOpenOrders: copyBacktest?.setting.maxAmountOpenOrders || 10,
    data_type: 'time_range',
    startPeriod: copyBacktest ? DateTime.fromISO(copyBacktest.setting.startPeriod) : minDate,
    endPeriod: copyBacktest ? DateTime.fromISO(copyBacktest?.setting.endPeriod) : maxDate
  };

  const orderSizeValidation = (value) => {
    if (value.order_size_type === 'dynamic') {
      return (
        validators.validateOrderSizeWithMaxOpenOrders(value.orderSize, value.maxAmountOpenOrders) ||
        validators.validateWithRange({ min_value: 0.01, max_value: 3 }, true)(value.orderSize) ||
        validators.validateWithoutSpace(value.orderSize)
      )
    }
    if (value.order_size_type === 'static') {
      return (
        validators.validateOrderSizeWithTotalFund(value.orderSize, value.totalFund) ||
        validators.validateWithRange({ min_value: 1, max_value: 1000000 }, true)(value.orderSize) ||
        validators.validateWithoutSpace(value.orderSize)
      )
    }
  }

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      decorators={[decorator]}
      validate={(value) => {
        const errors = {};
        errors.startPeriod = validators.validateIsBeforeOrEqualDate(value.startPeriod, value.endPeriod);
        if (value.order_size_type === 'dynamic') {
          errors.maxAmountOpenOrders = validators.validateOrderSizeWithMaxOpenOrders(value.orderSize, value.maxAmountOpenOrders);
        }
        if (value.order_size_type === 'static') {
          errors.totalFund = validators.validateOrderSizeWithTotalFund(value.orderSize, value.totalFund);
        }
        errors.orderSize = orderSizeValidation(value);
        return errors;
      }}

      render={({ handleSubmit, errors, values, form, valid }) => (
        <DialogForm
          open={open}
          handleClose={handlers.handleClose}
          customClass="backtest-modal"
          disableEnforceFocus
          controls={
            <SimpleFormControls
              handleCancel={handlers.handleClose}
              cancelButtonId={'modules.savedModules.back.button'}
              handleSave={handleSubmit}
              saveButtonId={'modules.savedModules.run.button'}
              flexEnd
              disabled={!valid}
            />
          }
          content={
            <form>
              <div className='backtest-form'>
                <Field
                  autoFocus
                  withHelper
                  formatOnBlur
                  name="name"
                  component={Input}
                  parse={value => value || ''}
                  format={v => v.trim()}
                  validate={validators.validateTitle()}
                />

                <div className="backtest-form__row">
                  <div className="backtest-form__label bold title">
                    {intl.formatMessage({ id: 'backtest.modal.form.list-modules' })}
                  </div>
                  <div className="backtest-form__field-wrap">
                    <div className="backtest-form__field">
                      <ul className="backtest-list">
                        {selectedModuleList.map(item => (
                          <li className="backtest-list__item" key={item.id}>{item.name || item.title}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="backtest-form__row">
                  <div className="backtest-form__label bold title">
                    {intl.formatMessage({ id: 'backtest.modal.form.total-fund' })}
                  </div>
                  <div className="backtest-form__field-wrap">
                    <div className="backtest-form__field">
                      <Field
                        withHelper
                        component={Input}
                        parse={value => value !== (+value).toString() ? value : +value}
                        validate={(value) => (
                          validators.validateWithRange({ min_value: 1, max_value: 10000000 })(value) ||
                          validators.validateWithoutSpace(value) ||
                          validators.validateIntegerField(value) ||
                          trimString(value)
                        )}
                        name={'totalFund'}
                        placeholder="100 000"
                      />
                      <div className="backtest-form__symbol">$</div>
                    </div>
                  </div>
                </div>

                <div className="backtest-form__row backtest-form__row--top">
                  <div className="backtest-form__label bold title">
                    {intl.formatMessage({ id: 'backtest.modal.form.order-size' })}
                  </div>

                  <div className="backtest-form__field-wrap">
                    <div className="backtest-form__field">
                      <RadioGroup className="time-frame-form">
                        {ORDER_SIZE_DATA_TYPES.map(env => (
                          <div key={env.value} className="radio-item">
                            <FormControlLabel
                              control={
                                <Field
                                  name="order_size_type"
                                  type="radio"
                                  value={env.value}
                                  component={CustomRadio}
                                />
                              }
                              label={<FormattedMessage id={env.label} />}
                            />
                          </div>
                        ))}
                      </RadioGroup>
                    </div>
                    <div className="backtest-form__field">
                      <Field name="order_size_type" >
                        {({ input }) => {
                          return (
                            <>
                              <Field
                                withHelper
                                component={Input}
                                parse={value => value !== (+value).toString() ? value : +value}
                                name={'orderSize'}
                                placeholder="1"
                              />
                              <div className="backtest-form__symbol">{input.value === 'dynamic' ? '%' : '$'}</div>
                            </>
                          )
                        }
                        }</Field>

                    </div>
                  </div>
                </div>

                <div className="backtest-form__row">
                  <div className="backtest-form__label bold title">
                    {intl.formatMessage({ id: 'backtest.modal.form.max-amount' })}
                  </div>

                  <div className="backtest-form__field-wrap">
                    <div className="backtest-form__field">
                      <Field
                        withHelper
                        component={Input}
                        parse={value => value !== (+value).toString() ? value : +value}
                        validate={(value) => (
                          validators.validateWithRange({ min_value: 1, max_value: 99 })(value) ||
                          validators.validateWithoutSpace(value) ||
                          validators.validateIntegerField(value) ||
                          trimString(value)
                        )}
                        name={'maxAmountOpenOrders'}
                        placeholder="10"
                      />
                    </div>
                  </div>
                </div>
                <RadioGroup className="time-frame-form">
                  {DATA_TYPES.map(env => (
                    <div key={env.value} className="radio-item">
                      <FormControlLabel
                        control={
                          <Field
                            name="data_type"
                            type="radio"
                            value={env.value}
                            component={CustomRadio}
                          />
                        }
                        label={<FormattedMessage id={env.label} />}
                      />
                    </div>
                  ))}
                </RadioGroup>
                <Field name="data_type">{({ input: { value } }) => {
                  if (value === 'time_range') {
                    return (
                      <div className="backtest-form__row">
                        <div className="backtest-form__label bold title">
                          {intl.formatMessage({ id: 'backtest.modal.form.time-range' })}
                        </div>
                        <div className="backtest-form__field-wrap">
                          <div className="backtest-form__field">

                            <Field name="startPeriod">
                              {({ input: { onChange, value } }) => (
                                <CustomDateTimePicker
                                  date={value}
                                  inputFormat="dd/MM/yyyy"
                                  minDate={minDate}
                                  onAccept={onChange}
                                  renderInput={(params) => (
                                    <TextField className={cn('backtest-form__date-field', {
                                      error: errors.startPeriod
                                    })} {...params} />
                                  )}
                                />
                              )}
                            </Field>

                            <div>-</div>

                            <Field name="endPeriod">
                              {({ input: { onChange, value } }) => (
                                <CustomDateTimePicker
                                  date={value}
                                  inputFormat="dd/MM/yyyy"
                                  onAccept={onChange}
                                  renderInput={(params) => (
                                    <TextField className={cn('backtest-form__date-field', {
                                      error: errors.startPeriod
                                    })} {...params} />
                                  )}
                                />
                              )}
                            </Field>
                          </div>
                          <div className="backtest-form__date-range-error">{errors.startPeriod}</div>
                        </div>
                      </div>
                    )
                  } else {
                    return (
                      <Field name="dataEditorFile">
                        {({ input: { onChange } }) => (
                          <FilePicker acceptType="application/zip" onAccept={onChange} />
                        )}
                      </Field>
                    )
                  }
                }
                }</Field>
              </div>

            </form>
          }
        />
      )}
    >
    </Form>
  );
};

BackTestModal.propTypes = {
  open: propTypes.bool,
  handlers: propTypes.objectOf(propTypes.func),
  copyBacktest: propTypes.object,
  selectedModuleList: propTypes.array,
}

export default BackTestModal;
