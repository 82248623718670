import React from 'react';

import PasswordProtectedRoute from 'containers/PasswordProtected';
import CreateModule from 'containers/Modules/CreateModule';
import stores from 'store/rootStore';

const CreateModulePage = () => (
  <PasswordProtectedRoute pageName={'create-module-page'}>
    <CreateModule store={stores.moduleStore} logicEditorStore={stores.logicEditorStore} />;
  </PasswordProtectedRoute>
);

export default CreateModulePage;
