import _ from 'lodash';
import { get, post } from 'api/utils';
import { convertDataToFormData } from 'api/urlHelpers';
import { DEFAULTS_API_URL, DEFAULTS_IMAGES_API_URL } from './consts'

const toBlob = async src => {
  if (src.startsWith('data')) {
    return await (await fetch(src)).blob()
  }

  return await new Promise(resolve => {
    const c = document.createElement('canvas');
    const img = new Image();
    img.src = src;
    img.crossOrigin = '';
    img.onload = function () {
      c.width = this.naturalWidth;
      c.height = this.naturalHeight;
      c.getContext('2d').drawImage(this, 0, 0);
      c.toBlob(resolve, 'image/jpeg', 0.75);
    };
  })
}

export default class LogicEditorService {
  async takeOutImages (params) {
    const copy = _.cloneDeep(params);
    _(copy).values().map(_.values).map('value').flatten().filter('image').each(item => { delete item.image });
    const images = _.fromPairs(await Promise.all(_(params)
      .values().map(system => _(system).entries().map(([type, value]) => value.candle_pattern.value.map(v => ({ ...v, type }))).value())
      .flattenDepth(2).filter('image').map(async ({ image, title, type }) => (
        [`${title}_${type}`, await toBlob(image)]
      )).value()));
    return { images, copy };
  }

  async createPatternDefaults (params) {
    const { images, copy } = await this.takeOutImages(params);
    const result = await post('client', DEFAULTS_API_URL, true, copy);
    if (!_.isEmpty(images)) {
      await post('client', DEFAULTS_IMAGES_API_URL, true, convertDataToFormData(images));
    }
    return result;
  }

  getListPatternDefaults (params) {
    return get('client', DEFAULTS_API_URL, true, params);
  }
}
