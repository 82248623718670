import _ from 'lodash';
import { makeAutoObservable, observable } from 'mobx';

import ArmingLineStorePerPosition from './perPosition';
import { enrichArmingLine } from './calculations';

export class ArmingLineStore {
  value = observable.box(null);
  buttonAvailability = {
    next: false,
    prev: false,
    last: false,
    set (v, name) {
      (name === 'next' || name === undefined) && (this.next = v);
      (name === 'prev' || name === undefined) && (this.prev = v);
      (name === 'last' || name === undefined) && (this.last = v);
    }
  }

  constructor (root) {
    this.long = new ArmingLineStorePerPosition(this, { position: 'long' });
    this.short = new ArmingLineStorePerPosition(this, { position: 'short' });
    this.root = root;
    this.stores = [this.long, this.short];
    makeAutoObservable(this);
  }

  get historyData () {
    return [...this.long.historyData, ...this.short.historyData];
  }

  get lines () {
    if (!this.root.rsi.allData) return [];
    return _(this.historyData)
      .map(enrichArmingLine(this.root.rsi.allData))
      .compact().sortBy('middle.index').value();
  }

  * fetchData () {
    yield Promise.all(this.stores.map(store => store.fetchData()))
  }
}
