import { boundNum } from 'helpers';
import _ from 'lodash';

const calcStepValue = (p1, p2) => (p1.value - p2.value) / (p1.index - p2.index);

export const enrichArmingLine = rsi => line => {
  const points = _.range(1, 4).map(i => {
    const timestamp = line[`point_${i}_timestamp`];
    const value = line[`point_${i}_value`];
    const index = _.sortedIndexBy(rsi, { timestamp }, 'timestamp');
    return { timestamp, value, index };
  });

  if (!rsi.length || points[0].index === rsi.length || points[1].index === rsi.length) {
    return
  }

  const firstStepValue = calcStepValue(points[0], points[1]);
  const secondStepValue = calcStepValue(points[1], points[2]);

  let previous = [rsi[points[0].index].timestamp, points[0].value, line.type];
  const upperBound = boundNum(points[0].index + 1, points[2].index + 1, rsi.length);
  const data = [
    previous,
    ..._.range(points[0].index + 1, upperBound).map(index => {
      const diff = rsi[index].timestamp <= points[1].timestamp ? firstStepValue : secondStepValue;
      return (previous = [rsi[index].timestamp, previous[1] + diff, line.type]);
    })
  ];
  const middle = { index: points[0].index + Math.floor(data.length / 2) };
  return ({ ...line, data, middle });
};
