import React from 'react';
import propTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Field } from 'react-final-form';
import { CustomSwitch } from 'components/Controls';
import { EditIcon } from 'components/Svgs';
import DescriptionRow from '../../Common/DescriptionRow';

import * as images from './images'
import './styles.scss';

// Card with info about candle close pattern with switch & edit button
const CandleCloseRule = ({ rule, index, clickEdit }) => (
  <>
    <div className="candle-close-rule">
      <div className="title">
        <Field
          name={`value[${index}].enabled`}
          type="checkbox"
          component={CustomSwitch}
        />
        <span className="title3 bold">
          <FormattedMessage id={`candle_close.conditions.${rule.kind}`} />
        </span>
        <button
          type="button"
          className="single-icon-btn"
          onClick={clickEdit(rule)}
        >
          <EditIcon />
        </button>
      </div>
      <div className="body">
        <div className="image">
          {images[rule.kind]}
        </div>
        <div className="description">
          {rule.value.map((field, index) => (
            <DescriptionRow
              index={index}
              key={index}
              field={field}
              prefix="candle_close"
            />
          ))}
        </div>
      </div>
    </div>
  </>
);

CandleCloseRule.propTypes = {
  rule: propTypes.object,
  index: propTypes.number,
  clickEdit: propTypes.func
};

export default CandleCloseRule;
