import React, { useState } from 'react';

import { Field } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import { EditIcon } from 'components/Svgs';

import ProfitFrame from 'components/ProfitFrame';

const ProfitTargets = () => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <div className="rs-indicator-list__item">
        <p style={{ maxWidth: 219 }}>
          <FormattedMessage id="entry_system.conditions.profit_targets" />
        </p>
        <div className="rs-indicator-list__interval">
          <div className="rs-indicator-list__interval">
            <Field name="tradeManagement.profit_targets.targets">
              {({ input }) => input.value.join('/')}
            </Field>
          </div>
        </div>
        <button
          type="button"
          className="single-icon-btn"
          onClick={() => setOpen(true)}
        >
          <EditIcon />
        </button>
      </div>

      <Field name="tradeManagement.profit_targets">
        {({ input: { value, onChange } }) => (
          <ProfitFrame
            open={open}
            value={value}
            onClose={() => setOpen(false)}
            onSave={onChange}
          />
        )}
      </Field>
    </>
  );
}

export default ProfitTargets
