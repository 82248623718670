import { makeAutoObservable } from 'mobx';

import { represent, toInternal } from 'helpers/utils';
import { States } from './consts'

export default class RiskManagementStore {
  riskManagementData = null;
  states = States;
  state = States.Pending;

  constructor (riskManagementService) {
    makeAutoObservable(this);
    this.riskManagementService = riskManagementService;
  }

  * getRiskManagement () {
    this.state = this.states.Loading;
    try {
      const response = yield this.riskManagementService.getRiskManagement();
      this.riskManagementData = toInternal(response.data);
      this.state = this.states.Done;
    } catch (e) {
      this.state = this.states.Error;
      throw e;
    }
  }

  * updateRiskManagement (data) {
    this.state = this.states.Loading;
    try {
      const response = yield this.riskManagementService.updateRiskManagement(represent(data));
      this.riskManagementData = toInternal(response.data);
      this.state = this.states.Done;
    } catch (e) {
      this.state = this.states.Error;
      throw e;
    }
  }
}
