import React from 'react';
import propTypes from 'prop-types';
import { inject } from 'mobx-react';
import { useIntl } from 'react-intl';
import { FieldArray } from 'react-final-form-arrays';

import { useNotification } from 'contexts/notification';
import Wizard from 'containers/Wizard';
import ModuleStore from 'store/moduleStore';
import DialogForm from 'components/DialogForm';
import TimeFrameList from 'components/TimeFrame';
import SimpleFormControls from 'components/SimpleFormControls';

import './styles.scss';

const TimeFrameEditor = ({ store, open, type, handleClose, module: { id } }) => {
  const intl = useIntl();
  const notification = useNotification();
  const [module, setModule] = React.useState(null);

  React.useEffect(() => {
    if (!open) {
      setModule(null)
    }
  }, [open]);

  React.useEffect(() => {
    const retriveModule = async () => {
      try {
        const retrievedModule = await store.getModule(id);
        setModule(retrievedModule);
      } catch {
        setModule(null);
      }
    }

    // retrive module if id is provided
    if (open && (id ?? false)) {
      setModule(null);
      retriveModule();
    }
  }, [id, open, store]);

  const onSubmit = async values => {
    handleClose();
    try {
      const response = await store.putModule(id, values);
      const moduleName = response.data.name;
      const title = intl.formatMessage({ id: 'notification.title.moduleUpdated' });
      const text = intl.formatMessage({ id: 'notification.text.moduleUpdated' }, { moduleName });
      notification.show(title, text);
    } catch {}
  };

  const title = intl.formatMessage({ id: `modules.${type}Timeframe` });

  return (
    <DialogForm
      open={open}
      title={title}
      handleClose={handleClose}
      customClass="tf-editor"
      content={module &&
        <Wizard initialValues={module} onSubmit={onSubmit} tfEditorType={type}>
          <FieldArray
            name={type === 'rsi'
              ? 'rsiTimeframes'
              : `stochTimeframes.${type}`}
            component={TimeFrameList}
          />
          <SimpleFormControls handleCancel={handleClose} />
        </Wizard>
      }
    />
  )
};

TimeFrameEditor.propTypes = {
  store: propTypes.instanceOf(ModuleStore),
  module: propTypes.object,
  type: propTypes.string,
  open: propTypes.bool,
  handleClose: propTypes.func
}

const decorator = inject(stores => ({
  store: stores.moduleStore
}))

export default decorator(TimeFrameEditor);
