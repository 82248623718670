import React from 'react';
import propTypes from 'prop-types';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { Tooltip } from '@material-ui/core';
import { DEFAULT_COLUMNS as columns } from '../consts';
import { useIntl, FormattedMessage } from 'react-intl';
import Title from './Title';
import { SAVED_MODULE_TYPE } from 'containers/ModulesListContainer/consts';
import CustomCheckbox from 'components/Controls/CustomCheckbox';
import CustomMenu from 'components/Controls/CustomMenu';
import { useMoveToConfig } from './helpers';

const ModuleRow = ({ module, handlers, activeTab, selectedModuleList }) => {
  const intl = useIntl();
  const isSelected = selectedModuleList.some(({ id }) => module.id === id);

  const config = useMoveToConfig({ handlers, module, activeTab })

  const controls = React.useMemo(() => (
    <div className="app-table__actions">
      <CustomMenu {...config} />
      <button
        disabled={!module.runtimeId}
        onClick={() => handlers.view(module)}
        className="main-btn action-btn bold"
      >
        <FormattedMessage id='modules.savedModules.actions.view' />
      </button>
      {activeTab !== SAVED_MODULE_TYPE && (
        <button
          onClick={() => handlers.stop(module)}
          className="main-btn action-btn bold"
        >
          <FormattedMessage id='modules.savedModules.actions.stop' />
        </button>
      )}

      {activeTab === SAVED_MODULE_TYPE && (
        <React.Fragment>
          <div className="single-icon-btn-wrap">
            {module.hardStopped && (
              <DoNotDisturbOnIcon />
            )}
          </div>
          <Tooltip title={intl.formatMessage({ id: 'modules.savedModules.tooltip.edit' })} placement="top">
            <button
              onClick={() => handlers.edit(module)}
              className="single-icon-btn"
            >
              <EditIcon />
            </button>
          </Tooltip>
          <Tooltip title={intl.formatMessage({ id: 'modules.savedModules.tooltip.delete' })} placement="top">
            <button
              onClick={() => handlers.delete(module)}
              className="single-icon-btn"
            >
              <DeleteIcon />
            </button>
          </Tooltip>
          <Tooltip title={intl.formatMessage({ id: 'modules.savedModules.tooltip.duplicate' })} placement="top">
            <button
              onClick={() => handlers.duplicate(module)}
              className="single-icon-btn"
            >
              <FileCopyIcon />
            </button>
          </Tooltip>
          <CustomCheckbox
            disabled={false}
            onChange={() => handlers.changeSelectedModule(module)}
            name={module.id.toString()}
            checked={isSelected}
          />
        </React.Fragment>
      )}
    </div>
  ), [module, selectedModuleList]);

  const withEditButton = attribute => {
    const callback = (
      ~['rsi', 'prearming', 'trigger'].indexOf(attribute)
        ? () => handlers.editTfs(module, attribute)
        : () => handlers.editInstrument(module, attribute)
    );
    return (
      <div className="edit-button">
        <span>{module[attribute]}</span>
        {module.kind === 'saved' && (
          <button onClick={callback} className="single-icon-btn">
            <EditIcon />
          </button>
        )}
      </div>
    );
  };

  return (
    <tr className={`app-table__row ${module.kind}`} key={module.id}>
      {/* the last of columns is controls so it's skipped & rendered separately */}
      {columns.slice(0, -1).map(({ classes, attribute }) => ( //
        <td key={attribute} className={classes}>
          { // add to timeframe columns edit timeframe button
            ~['rsi', 'prearming', 'trigger', 'instrument'].indexOf(attribute)
              ? withEditButton(attribute)
              : (attribute === 'title'
                ? <Title title={module[attribute]} changeTitle={handlers.changeTitle} id={module.id} isEditingAvailable={module.kind === 'saved'} />
                : module[attribute])
          }
        </td>
      ))}
      <td className="right">
        {controls}
      </td>
    </tr>
  );
}

ModuleRow.propTypes = {
  handlers: propTypes.objectOf(propTypes.func),
  module: propTypes.object,
  activeTab: propTypes.string,
  selectedModuleList: propTypes.array
}

export default ModuleRow;
