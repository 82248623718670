import React from 'react';
import { Link } from 'react-router-dom';
import { NAV_LINKS } from './consts';

import classNames from 'classnames';

import AppBar from '@material-ui/core/AppBar';
import LinkMatch from 'components/LinkMatch';
import TimezonePicker from 'contexts/timezone/picker';

import { SystemLogo, Logo } from 'assets/images';
import './styles.scss';

const NavBar = () => {
  const linkClasses = classNames('main-btn secondary-btn bold');

  return (
    <AppBar className="main-header" position="sticky">
      <div className="main-header__logo-wrapper">
        <div className="image-wrapper">
          <Link to={'/'}>
            <img src={SystemLogo} alt="higher status global logo" />
          </Link>
        </div>
        {/* TO DO: fix path for a dynamic logo */}
        <div className="image-wrapper">
          <Link to={'/'}>
            <img src={Logo} alt="higher status global logo" />
          </Link>
        </div>
      </div>
      <div aria-labelledby="main navigation" className="main-header__links">
        {NAV_LINKS.map(({ title, path, exact }) => (
          <LinkMatch
            key={title}
            to={path}
            label={title}
            className={linkClasses}
            ifMatch={'active'}
            exact={exact}
          />
        ))}
        <TimezonePicker />
      </div>
    </AppBar>
  );
};

export default NavBar;
