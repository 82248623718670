import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import './styles.scss';
import { ArrowIcon } from 'components/Svgs';

const SimpleFormControls = ({ handleSave, handleCancel, customClass, flexEnd, disabled, cancelButtonId, saveButtonId, withCancelIcon }) => {
  const cancel = cancelButtonId || 'modules.buttons.cancel';
  const save = saveButtonId || 'modules.buttons.save';

  return (
    <div className={classNames('simple-form-controls', customClass, { flexEnd })}>
      <button
        className="primary-btn outline-btn form-action-btn bold"
        onClick={handleCancel}
        type={handleCancel ? 'button' : 'reset'}
        style={{ display: 'flex', gap: 5 }}
      >
        {withCancelIcon && (
          <ArrowIcon
            className="btn-icon__icon"
            style={{ width: '14px', height: '14px', transform: 'rotate(180deg)' }}
          />
        )}
        <FormattedMessage id={cancel} />
      </button>
      <button
        className="primary-btn main-btn form-action-btn bold"
        onClick={handleSave}
        disabled={disabled}
        type={handleSave ? 'button' : 'submit'}
      >
        <FormattedMessage id={save} />
      </button>
    </div>
  )
};

SimpleFormControls.propTypes = {
  handleSave: PropTypes.func,
  handleCancel: PropTypes.func,
  customClass: PropTypes.string,
  flexEnd: PropTypes.bool,
  disabled: PropTypes.bool,
  cancelButtonId: PropTypes.string,
  saveButtonId: PropTypes.string,
  withCancelIcon: PropTypes.bool
};

export default SimpleFormControls;
