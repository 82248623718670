import React from 'react';
import classNames from 'classnames';

import LinkMatch from 'components/LinkMatch';

import { INSTRUMENTS_LINKS, SIDE_LINKS } from './contants';

import './styles.scss';

const SideBar = () => {
  const linkClasses = classNames('main-btn main-btn--left bold secondary-btn');

  return (
    <div className="sidebar">
      <div className="sidebar__section">
        <div className="sidebar__title bold">INSTRUMENTS:</div>
        <div className="sidebar__nav">
          {INSTRUMENTS_LINKS.map(({ title, path }) => (
            <LinkMatch
              key={title}
              to={path}
              label={title}
              className={linkClasses}
              ifMatch={'active'}
            />
          ))}
        </div>
      </div>
      <div className="sidebar__section">
        <div className="sidebar__nav">
          {SIDE_LINKS.map(({ title, path }) => (
            <LinkMatch
              key={title}
              to={path}
              label={title}
              className={linkClasses}
              ifMatch={'active'}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default SideBar;
