import * as api from 'api/utils';
import { RUNTIME_URL } from './consts';

export default class RiskManagementService {
  getRiskManagement () {
    return api.get('tradeManagement', RUNTIME_URL, true);
  }

  updateRiskManagement (data) {
    return api.patch('tradeManagement', RUNTIME_URL, true, data);
  }
}
