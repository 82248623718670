import React, { useMemo, createContext, useContext, useEffect } from 'react';
import propTypes from 'prop-types';
import { observer } from 'mobx-react';
import { pubSub } from './pubSub';
import { useUpdateParams } from './hooks';

export const ChartSyncContext = createContext(null);
export const useChartSync = () => useContext(ChartSyncContext);

const ChartSyncProvider = ({ children, module, controls }) => {
  const observers = useMemo(() => ({
    selectedDateForWsLogs: pubSub(),
    selectedDate: pubSub(),
    armingLine: pubSub(),
    toBegin: pubSub(),
    toEnd: pubSub(),
  }), []);

  // Update parameters when changing the timeframe
  useUpdateParams({ module, controls });

  // First load
  useEffect(() => {
    if (controls.timestamp) {
      setTimeout(() => {
        observers.selectedDate.publish(controls.timestamp);
      }, 0);
    } else {
      observers.toEnd.publish();
    }
  }, []);

  return (
    <ChartSyncContext.Provider value={{ observers }}>
      {children}
    </ChartSyncContext.Provider>
  );
}

ChartSyncProvider.propTypes = {
  children: propTypes.node.isRequired,
  module: propTypes.object,
  controls: propTypes.object,
};

export default observer(ChartSyncProvider);
