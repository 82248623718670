import React from 'react';
import PropTypes from 'prop-types';
import ConditionList from './ConditionList';
import StochConditionList from './StochConditionList';

import './styles.scss';

const BaseIndicator = ({ prefix, fieldName }) => prefix === 'stoch' ? (
  <>
    <StochConditionList prefix={prefix} fieldName={fieldName} type="prearming" />
    <StochConditionList prefix={prefix} fieldName={fieldName} type="trigger" />
  </>
) : (
  <ConditionList prefix={prefix} fieldName={fieldName} />
);

BaseIndicator.propTypes = {
  prefix: PropTypes.element,
  fieldName: PropTypes.string
};

export default BaseIndicator;
