import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import './styles.scss';

const CustomMenu = ({ buttonTitle, menuItems }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (fn) => {
    setAnchorEl(null);
  };

  const actionHandler = (action) => {
    if (action) {
      action();
    }

    handleClose();
  };

  return (
    <div className="custom-menu-wrap">
      <button
        className="main-btn action-btn bold custom-menu-button"
        onClick={handleOpen}
      >
        {buttonTitle}
      </button>
      <Menu
        className="custom-menu"
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {menuItems?.map(({ menuItemName, action, type }) => (
          <MenuItem
            className="custom-menu__button"
            onClick={() => actionHandler(action)}
            key={menuItemName}
          >
            {menuItemName}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

CustomMenu.propTypes = {
  buttonTitle: PropTypes.string,
  menuItems: PropTypes.array
};

export default CustomMenu;
