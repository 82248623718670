import React from 'react';

import { HomePageLogo } from 'assets/images';

import './styles.scss';
const HomePage = () => (
  <div className="home-image-wrap">
    <img className="home-image" src={HomePageLogo} alt="higher status global logo" />
  </div>
);

export default HomePage;
