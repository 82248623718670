import React from 'react';
import propTypes from 'prop-types';

import { Field } from 'react-final-form';
import ConditionRow from '../../BaseIndicator/ConditionList/Condition/ConditionRow';

const TradeSizeCalculate = ({ name }) => {
  return (
    <>
      <div className='rs-indicator-list__item'>
        <div>
          <Field name="tradeManagement.trade_size">
            {({ input: { value, onChange } }) => (
              <ConditionRow
                field={{ enabled: value.autocalculate, kind: name }}
                prefix="entry_system"
                handleChange={() => e => { onChange({ ...value, autocalculate: e.target.checked }) }}
              />
            )}
          </Field>
        </div>
      </div>
    </>
  );
}

TradeSizeCalculate.propTypes = {
  name: propTypes.string
};

export default TradeSizeCalculate;
