import * as consts from './consts';
import { DateTime } from 'luxon';
import _ from 'lodash';
import { DATETIME_FORMAT, COMMON_PRECISION } from 'globalConstants';
import { getCandlesRange } from './helpers';
import { performers } from '../LogsPanel/helpers';
import { numberFormatter } from '../../helpers';

// grid lines
const SPLITLINE = {
  splitLine: {
    show: true,
    lineStyle: {
      color: ['#555']
    }
  }
};

const defaultXAxisPointer = () => ({
  snap: false,
  label: {
    show: true,
    formatter: ({ value }) => DateTime.fromSeconds(+value, { zone: 'default' }).toFormat(DATETIME_FORMAT),
  },
  handle: {
    show: false,
    size: 0
  }
})

const onAxis = axis => ({
  xAxisIndex: axis,
  yAxisIndex: axis,
});

// generate options for 30/70 & 20/80 ribbon
const generateRibbon = config => ({
  markLine: {
    symbol: 'none',
    label: { show: false },
    emphasis: { disabled: true },
    silent: true,
    data: [
      {
        yAxis: config.max,
        lineStyle: {
          color: config.maxColor,
          width: 2
        }
      },
      {
        yAxis: config.min,
        lineStyle: {
          color: config.minColor,
          width: 2
        }
      },
    ],
  },
  markArea: {
    emphasis: { disabled: true },
    silent: true,
    itemStyle: {
      color: config.color,
    },
    data: [[
      { yAxis: config.min },
      { yAxis: config.max }
    ]],
  }
});

// generates series for arming lines
const generateArmingLines = lines => lines.map((line, index) => ({
  type: 'line',
  id: `armingLine-${index}`,
  emphasis: {
    focus: 'series',
  },
  blur: {
    lineStyle: {
      opacity: 0.5,
    }
  },
  symbol: 'roundRect',
  symbolSize: 30,
  showAllSymbol: true,
  triggerLineEvent: true,
  itemStyle: {
    color: 'rgba(0, 0, 0, 0)',
  },
  lineStyle: consts.armingLines.lineStyle(line),
  data: line.data,
  ...onAxis(1),
}));

const getPatternName = ({ row }) => {
  return performers.candlePattern({ row }).split(', ').join('\n\n');
}

const getHighlightStyles = ({ totalCandles, color }) => {
  if (totalCandles === 1) {
    return {
      borderColor: color,
      borderWidth: 10,
    }
  }

  return { color };
}

const createPatternsMarkArea = (data) => (row) => {
  const { candle_timestamp: timestamp } = row;
  const totalCandles = 1; // rewrite for CP as max
  const { start, end } = getCandlesRange({ data, timestamp, totalCandles });
  const color = consts.patternsHighlight[row.position === 'long' ? 'green' : 'red'];

  return [
    {
      xAxis: _.nth(data, start + 1).timestamp.toString(),
      name: getPatternName({ row }),
      itemStyle: getHighlightStyles({ totalCandles, color }),
    },
    {
      xAxis: _.nth(data, end).timestamp.toString(),
    }
  ];
};

const getPatternsMarkAreaData = (logs, data) => [...logs.long.allData, ...logs.short.allData]
  .filter(({ candle_timestamp: timestamp }) => timestamp >= data[0]?.timestamp)
  .map(createPatternsMarkArea(data));

const highlightPatterns = (logs, candleData) => {
  return {
    markArea: {
      emphasis: { disabled: true },
      label: {
        show: true,
        position: 'insideTopLeft',
        rotate: 90,
        align: 'right',
        verticalAlign: 'bottom',
      },
      itemStyle: {
        color: consts.rsi.ribbon.color
      },
      data: getPatternsMarkAreaData(logs, candleData)
    }
  }
};

const getAxesOptions = (candleLabelPrecision) => {
  return {
    xAxis: _.range(3).map(gridIndex => ({
      type: 'category',
      gridIndex,
      ...SPLITLINE,
      axisPointer: defaultXAxisPointer(),
      axisLabel: {
        formatter: value => DateTime.fromSeconds(+value, { zone: 'default' }).toFormat(DATETIME_FORMAT),
      },
    })),
    yAxis: _.range(3).map(gridIndex => ({
      gridIndex,
      ...SPLITLINE,
      scale: true,
      position: 'right',
      axisPointer: {
        label: {
          formatter: ({ value }) => value.toFixed(gridIndex === 0 ? candleLabelPrecision : COMMON_PRECISION),
        },
      },
    })),
  }
}

export const candleOptions = ({ chartStore, logs, candleLabelPrecision }) => {
  return {
    ...getAxesOptions(candleLabelPrecision),
    series: {
      id: 'candle',
      data: chartStore.candle.forEChart,
      ...highlightPatterns(logs, chartStore.candle.allData),
    }
  }
}

export const rsiOptions = ({ chartStore, candleLabelPrecision }) => {
  return {
    ...getAxesOptions(candleLabelPrecision),
    series: { id: 'rsi', data: chartStore.rsi.forEChart },
  }
}

export const stochOptions = ({ chartStore, candleLabelPrecision }) => {
  return {
    ...getAxesOptions(candleLabelPrecision),
    series: [
      { id: 'stochK', data: chartStore.stoch.forEChart.kLine },
      { id: 'stochD', data: chartStore.stoch.forEChart.dLine },
    ]
  }
}

export const armingLineOptions = ({ chart, chartStore, candleLabelPrecision }) => {
  const { lines } = chartStore.armingLine;
  const unusedSeries = _.max([0, chart.getOption().series.length - lines.length - 4]);
  return {
    ...getAxesOptions(candleLabelPrecision),
    series: [
      ...generateArmingLines(lines),
      // setOptions updates existing series,
      // so we should hide remains if we'll obtain less TALs after update
      ..._.range(unusedSeries).map(index => ({
        show: false,
        id: `armingLine-${index + lines.length}`,
        // try to change it to random timestamp
        data: _.range(3).map(() => [chartStore.rsi.allData?.[0]?.timestamp, null]),
      })),
    ],
  }
}

export const trendDisarmOptions = ({ chartStore }) => {
  const trendDisarmData = chartStore.trendDisarm.forEChart;
  return {
    series: [{
      type: 'line',
      id: 'trendDisarm',
      symbol: 'none',
      cursor: 'none',
      lineStyle: { color: 'transparent' },
      data: trendDisarmData.map(item => [
        [item.start.toString(), item.rsiResetLevel1],
        [item.end.toString(), item.rsiResetLevel2]
      ]).flat(),
      markArea: {
        emphasis: { disabled: true },
        silent: true,
        itemStyle: consts.trendDisarm.box,
        data: trendDisarmData.map(item => [
          {
            xAxis: item.start.toString(),
            yAxis: item.rsiResetLevel2,
          },
          {
            xAxis: item.end.toString(),
            yAxis: item.rsiResetLevel1,
          }
        ]),
      },
      markLine: {
        emphasis: { disabled: true },
        silent: true,
        symbol: 'none',
        lineStyle: consts.trendDisarm.line,
        data: trendDisarmData.map(item => [
          [ // RSI Level line
            {
              xAxis: item.start.toString(),
              yAxis: item.rsiLevel,
              name: item.rsiLevel,
              label: consts.trendDisarm.label.rsiLevel,
            },
            {
              xAxis: item.end.toString(),
              yAxis: item.rsiLevel,
            }
          ],
          [ // RSI Reset level 2 line
            {
              xAxis: item.start.toString(),
              yAxis: item.rsiResetLevel2,
              name: item.rsiResetLevel2,
              label: { ...consts.trendDisarm.label.rsiResetLevel, position: 'insideEndBottom' },
              lineStyle: { color: 'transparent' },
            },
            {
              xAxis: item.end.toString(),
              yAxis: item.rsiResetLevel2,
            }
          ],
          [ // RSI Reset level 1 line
            {
              xAxis: item.start.toString(),
              yAxis: item.rsiResetLevel1,
              name: item.rsiResetLevel1,
              label: { ...consts.trendDisarm.label.rsiResetLevel, position: 'insideEndTop' },
              lineStyle: { color: 'transparent' },
            },
            {
              xAxis: item.end.toString(),
              yAxis: item.rsiResetLevel1,
            }
          ],
        ]).flat(),
      },
      ...onAxis(1),
    }],
  };
};

const getDataZoomConfig = ({ groupedByPeriod }) => {
  const defaultValue = {
    type: 'inside',
    // rangeMode: ['value', 'value'],
    minValueSpan: 10,
    maxValueSpan: 200,
  }
  return groupedByPeriod.map((xAxisIndex, index) => ({
    id: `dataZoom${index}`,
    xAxisIndex,
    ...defaultValue
  }))
}

export const initOptions = ({
  candleLabelPrecision,
  chartStore: {
    armingLine,
    tfGrouper: { orderedStores, groupedByPeriod }
  },
}) => {
  return {
    tooltip: {
      trigger: 'axis',
      show: true,
      axisPointer: {
        type: 'cross'
      },
      className: 'chart-popup-wrap',
      formatter: popupFormatter(armingLine.value),
    },
    axisPointer: {
      show: true,
      link: [{
        xAxisIndex: [0, 1, 2],
        mapper: (source, sourceInfo, targetInfo) => {
          const sourceValue = orderedStores[sourceInfo.axisIndex].allData[source]?.timestamp;
          return _.sortedIndexBy(orderedStores[targetInfo.axisIndex].allData, { timestamp: sourceValue }, 'timestamp');
        }
      }]
    },
    grid: [
      { left: 0, width: '95%', height: '35%', top: '10%' },
      { left: 0, width: '95%', height: '20%', top: '50%' },
      { left: 0, width: '95%', height: '20%', top: '75%' },
    ],
    dataZoom: getDataZoomConfig({ groupedByPeriod }),
    ...getAxesOptions(candleLabelPrecision),
    series: [
      {
        type: 'candlestick',
        id: 'candle',
        dimensions: ['index', 'date', 'open', 'close', 'highest', 'lowest'],
        encode: {
          x: 'date',
          y: ['open', 'close', 'highest', 'lowest']
        },
        itemStyle: consts.candle,
        ...onAxis(0),
      },
      {
        type: 'line',
        id: 'rsi',
        symbol: 'none',
        lineStyle: consts.rsi.lineStyle,
        blur: {
          lineStyle: {
            opacity: 1,
          }
        },
        ...onAxis(1),
        ...generateRibbon(consts.rsi.ribbon)
      },
      {
        type: 'line',
        id: 'stochK',
        symbol: 'none',
        lineStyle: consts.stoch.kLineStyle,
        ...onAxis(2),
        ...generateRibbon(consts.stoch.ribbon),
      },
      {
        type: 'line',
        id: 'stochD',
        symbol: 'none',
        lineStyle: consts.stoch.dLineStyle,
        ...onAxis(2),
      },
    ],
  };
};

const popupFormatter = (talValue) => (data) => {
  const candleData = _.find(data, { seriesId: 'candle' });
  const rsiData = _.find(data, { seriesId: 'rsi' });
  const stochDataA = _.find(data, { seriesId: 'stochK' });
  const stochDataB = _.find(data, { seriesId: 'stochD' });

  const armingLines = data.filter(curr => curr?.seriesId.match(/armingLine-\d+/));
  const armingLine = talValue.get() ?? _.maxBy(armingLines, 'value[1]');

  const candleElement = getCandleElement({ candleData });
  let rsiElement = getRsiElement({ rsiData });
  const stochElement = getStochElement({ stochDataA, stochDataB });
  const armingLineElement = getTALElement({ armingLine });

  let common = '';
  if (candleElement) {
    common = `${common}${candleElement}`
  }
  if (rsiElement) {
    common = `${common}${rsiElement}`
  }
  if (stochElement) {
    common = `${common}${stochElement}`
  }
  if (armingLineElement) {
    common = `${common}${armingLineElement}`
    rsiElement = `${rsiElement}${armingLineElement}`;
  }

  return `
    <div class="chart-popup">${common}</div>
    ${(rsiData && `<div class="chart-popup-rsi">${rsiElement}</div>`) || ''}
    ${(stochDataA && `<div class="chart-popup-stoch">${stochElement}</div>`) || ''}
  `;
};

const getCandleElement = ({ candleData }) => {
  if (!candleData?.data) return;

  // eslint-disable-next-line no-unused-vars
  const [index, candleDate, open, close, lowest, highest] = candleData?.data;

  const candleColor = open > close ? 'red' : 'green'

  return `
    <div class="chart-popup__element chart-popup__candle">
      O <span class="chart-popup__candle-value chart-popup__candle-value--${candleColor}">${numberFormatter(5).format(open)}</span>
      H <span class="chart-popup__candle-value chart-popup__candle-value--${candleColor}">${numberFormatter(5).format(highest)}</span>
      L <span class="chart-popup__candle-value chart-popup__candle-value--${candleColor}">${numberFormatter(5).format(lowest)}</span>
      C <span class="chart-popup__candle-value chart-popup__candle-value--${candleColor}">${numberFormatter(5).format(close)}</span>
    </div>
  `;
}

const getRsiElement = ({ rsiData }) => {
  if (!rsiData?.data) return;

  // eslint-disable-next-line no-unused-vars
  const [rsiDate, rsiValue] = rsiData?.data;

  return `
    <div class="chart-popup__element chart-popup__rsi">
      RSI
      <span class="chart-popup__rsi-value">${numberFormatter().format(rsiValue)}</span>
    </div>
  `;
}

const getStochElement = ({ stochDataA, stochDataB }) => {
  if (!stochDataA?.data || !stochDataB?.data) return;

  // eslint-disable-next-line no-unused-vars
  const [stochDateA, stochValueA] = stochDataA?.data;
  // eslint-disable-next-line no-unused-vars
  const [stochDateB, stochValueB] = stochDataB?.data;

  return `
    <div class="chart-popup__element chart-popup__stoch">
      Stoch
      <span class="chart-popup__stoch-value chart-popup__stoch-value--kLine">${numberFormatter().format(stochValueA)}</span>
      <span class="chart-popup__stoch-value chart-popup__stoch-value--dLine">${numberFormatter().format(stochValueB)}</span>
    </div>
  `;
}

const getTALElement = ({ armingLine }) => {
  if (!armingLine?.value) return '';

  // eslint-disable-next-line no-unused-vars
  const [data, value, type] = armingLine.value

  return `
    <div class="chart-popup__element chart-popup__tal">
      TAL
      <span class="chart-popup__tal-value chart-popup__tal-value--${type}">${numberFormatter().format(value)}</span>
    </div>
  `;
}
