import ModuleStore from './index';
import { ModuleService } from 'services';

export const moduleStoreBuilder = () => {
  const moduleService = new ModuleService();
  return new ModuleStore(moduleService)
};

export const toModule = values => ({
  moduleName: values.name,
  stochTimeframes: values.stoch_timeframes,
  rsiTimeframes: values.rsi_timeframes,
  rsiPeriodValue: values.rsi_period_value,
  kPeriod: values.k_period,
  dPeriod: values.d_period,
  instrument: values.instrument,
  slowingPeriod: values.slowing_period,
  rsiConditions: values.rsi_conditions,
  stochConditions: values.stoch_conditions,
  entrySystemConditions: values.entry_system_conditions,
  tradeManagement: values.trade_management,
  candlePatternTimeframe: values.candle_pattern_timeframe,
  candlePatterns: values.candle_patterns,
  patternKind: values.pattern_kind,
  stoch_short_params_id: values.stoch_short_fin_params_id,
  stoch_long_params_id: values.stoch_long_fin_params_id,
  rsi_short_params_id: values.rsi_short_fin_params_id,
  rsi_long_params_id: values.rsi_long_fin_params_id,
  candle_close_short_params_id: values.short_candle_patterns_id,
  candle_close_long_params_id: values.long_candle_patterns_id,
  whole_sequence_long_params_id: values.long_whole_sequence_id,
  whole_sequence_short_params_id: values.short_whole_sequence_id
});

export const fromModule = values => ({
  name: values.moduleName,
  stoch_timeframes: values.stochTimeframes,
  rsi_timeframes: values.rsiTimeframes,
  rsi_period_value: values.rsiPeriodValue,
  k_period: values.kPeriod,
  d_period: values.dPeriod,
  instrument: values.instrument,
  slowing_period: values.slowingPeriod,
  rsi_conditions: values.rsiConditions,
  stoch_conditions: values.stochConditions,
  entry_system_conditions: values.entrySystemConditions,
  trade_management: values.tradeManagement,
  candle_pattern_timeframe: values.candlePatternTimeframe,
  candle_patterns: values.candlePatterns
});
