/* eslint-disable camelcase */
import React from 'react';

export const red_bodied = (
  <svg width="200" height="1130" viewBox="0 0 200 1130" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect y="258" width="200" height="614" fill="#FF0000"/>
    <rect x="89" width="22" height="1130" fill="#FF0000"/>
  </svg>
);

export const red_hammer = (
  <svg width="200" height="1130" viewBox="0 0 200 1130" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect y="80" width="200" height="216" fill="#FF0000"/>
    <rect x="89" y="80" width="22" height="970" fill="#FF0000"/>
  </svg>
);

export const red_doji = (
  <svg width="200" height="1130" viewBox="0 0 200 1130" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect y="497" width="200" height="136" fill="#FF0000"/>
    <rect x="89" y="325" width="22" height="480" fill="#FF0000"/>
  </svg>
);

export const green_bodied = (
  <svg width="200" height="1130" viewBox="0 0 200 1130" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect y="258" width="200" height="614" fill="#17C147"/>
    <rect x="89" width="22" height="1130" fill="#17C147"/>
  </svg>
);

export const green_hangman = (
  <svg width="200" height="1130" viewBox="0 0 200 1130" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect y="834" width="200" height="216" fill="#17C147"/>
    <rect x="89" y="80" width="22" height="970" fill="#17C147"/>
  </svg>
);

export const green_doji = (
  <svg width="200" height="1130" viewBox="0 0 200 1130" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect y="497" width="200" height="136" fill="#17C147"/>
    <rect x="89" y="325" width="22" height="480" fill="#17C147"/>
  </svg>
);
