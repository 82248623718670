import React from 'react';

const EmptyIcon = ({ ...props }) => {
  return (
    <svg width="113" height="114" viewBox="0 0 113 114" fill="none" {...props}>
      <path
        d="M22.5862 98.4211H90.3722C98.6844 98.4211 102.924 94.265 102.924 86.0359V62.7618C102.924 58.9382 102.383 57.0679 100.513 54.7821L87.172 38.2824C82.4756 32.547 80.3976 30.7598 73.4153 30.7598H39.5431C32.5193 30.7598 30.4413 32.547 25.7864 38.2824L12.4038 54.7821C10.5751 57.1095 10.0348 58.9382 10.0348 62.7618V86.0359C10.0348 94.265 14.274 98.4211 22.5862 98.4211ZM56.4584 72.5286C51.014 72.5286 47.5228 67.8322 47.5228 63.3852V63.219C47.5228 61.5981 46.5669 60.1019 44.5304 60.1019H18.8042C17.5158 60.1019 17.2664 58.9797 17.9314 58.1485L32.3946 39.9448C34.2649 37.5758 36.3845 36.6199 39.1691 36.6199H73.7478C76.574 36.6199 78.6936 37.5758 80.5638 39.9448L95.027 58.1485C95.692 58.9797 95.4426 60.1019 94.1542 60.1019H68.428C66.3915 60.1019 65.4356 61.5981 65.4356 63.219V63.3852C65.4356 67.8322 61.9445 72.5286 56.4584 72.5286ZM22.794 91.6051C18.9704 91.6051 16.8508 89.5686 16.8508 85.5788V66.2529H41.3302C42.3693 73.4845 48.5203 78.8459 56.4584 78.8459C64.4381 78.8459 70.5892 73.443 71.6282 66.2529H96.1076V85.5788C96.1076 89.5686 93.9464 91.6051 90.1644 91.6051H22.794Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M56.9351 0C58.5561 0 59.8701 1.31407 59.8701 2.93506V15.4091C59.8701 17.0301 58.5561 18.3442 56.9351 18.3442C55.3141 18.3442 54 17.0301 54 15.4091V2.93506C54 1.31407 55.3141 0 56.9351 0Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M87.077 7.2439C88.4808 8.0544 88.9618 9.84945 88.1513 11.2533L81.9143 22.0561C81.1038 23.4599 79.3087 23.9409 77.9049 23.1304C76.5011 22.3199 76.0201 20.5249 76.8306 19.121L83.0676 8.31821C83.8781 6.91439 85.6732 6.4334 87.077 7.2439Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.5418 7.24389C27.9457 6.4334 29.7407 6.91438 30.5512 8.3182L36.7882 19.121C37.5987 20.5248 37.1177 22.3199 35.7139 23.1304C34.3101 23.9409 32.515 23.4599 31.7045 22.0561L25.4675 11.2533C24.657 9.84945 25.138 8.05439 26.5418 7.24389Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default EmptyIcon;
