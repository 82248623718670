import React, { useState, useLayoutEffect, useMemo, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import cn from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import { useConfirmation } from 'contexts/popup';
import { Field, Form, FormSpy } from 'react-final-form';
import { observer } from 'mobx-react';
import { FormControlLabel, RadioGroup } from '@material-ui/core';
import { CustomRadio } from 'components/Controls';
import { ORDER_SIZE_DATA_TYPES } from 'components/BackTestModal/consts';
import { useNotification } from 'contexts/notification';

import { trimString } from 'helpers';
import * as validators from 'helpers/validators';
import { useStore } from 'hooks';
import { Input } from 'components/Fields';
import SimpleFormControls from 'components/SimpleFormControls';
import { TABS, INITIAL_VALUES } from './consts';

import './styles.scss';

const FundManagement = () => {
  const intl = useIntl();
  const [tab, setTab] = useState(TABS.demo);
  const [formState, setFormState] = useState(null);
  const notification = useNotification();
  const { confirm } = useConfirmation();
  const [initialValues, setInitialValues] = useState(INITIAL_VALUES);
  const { demoFundManagementStore, activeFundManagementStore } = useStore();
  const history = useHistory();

  const store = useMemo(() => ({
    [TABS.demo]: demoFundManagementStore,
    [TABS.active]: activeFundManagementStore,
  })[tab], [tab]);

  const onSubmit = formValue => {
    setFormState(null);
    store.patch(formValue);
    notification.show('Success!', 'Fund managment has been\nupdated');
  };

  const orderSizeValidation = (value) => {
    if (value.riskType === 'dynamic') {
      return (
        validators.validateOrderSizeWithMaxOpenOrders(value.risk, value.maxAmount) ||
        validators.validateWithRange({ min_value: 0.01, max_value: 3 }, true)(value.risk) ||
        validators.validateWithoutSpace(value.risk)
      )
    }
    if (value.riskType === 'static') {
      return (
        validators.validateOrderSizeWithTotalFund(value.risk, value.total) ||
        validators.validateWithRange({ min_value: 1, max_value: 1000000 }, true)(value.risk) ||
        validators.validateWithoutSpace(value.risk)
      )
    }
  }

  const validateHandler = (value) => {
    const errors = {};
    if (value.riskType === 'dynamic') {
      errors.maxAmount = validators.validateOrderSizeWithMaxOpenOrders(value.risk, value.maxAmount);
    }
    if (value.riskType === 'static') {
      errors.total = validators.validateOrderSizeWithTotalFund(value.risk, value.total);
    }
    errors.risk = orderSizeValidation(value);
    return errors;
  }

  const changeTab = async tab => {
    if (formState && formState.dirty) {
      const result = await confirm({
        title: intl.formatMessage({ id: 'dialog.title.changeFundManagementTab' }),
        description: intl.formatMessage({ id: 'dialog.description.leaveTab' }),
        error: true
      });
      if (result === 'yes' && formState.valid) {
        onSubmit(formState.values);
      } else if (result === 'cancel') return;
    }
    setFormState(null);
    setTab(tab);
  };

  useEffect(() => {
    const block = history.block(prompt => {
      if (!formState?.dirty) {
        return;
      }

      confirm({
        title: intl.formatMessage({ id: 'dialog.title.changeFundManagementTab' }),
        description: intl.formatMessage({ id: 'dialog.description.leaveFundManagementPage' }),
        error: true
      }).then((result) => {
        if (result === 'yes') {
          onSubmit(formState.values);
        }
        if (['yes', 'no'].includes(result)) {
          block && block();
          history.push(prompt.pathname);
        }
      });
      return false;
    });
    return () => block?.();
  }, [formState]);

  useLayoutEffect(() => {
    async function fetchData () {
      const response = await store.get();
      setInitialValues(response);
    }

    fetchData();
  }, [store]);

  return (
    <div className="form fund-management">
      <div className="form__card">
        <div className="form__card-content">
          <h2 className="title2 bold fund-management__title">
            <FormattedMessage id="fundManagement.title" />
          </h2>
          <div className="modules-tabs">
            {Object.values(TABS).map(label => (
              <button
                key={label}
                type="button"
                onClick={() => { changeTab(TABS[label]) }}
                className={cn('main-btn secondary-btn bold', { active: tab === label })}
              >
                <FormattedMessage id={`fundManagement.button.${label}`} />
              </button>
            ))}
          </div>
          <Form
            onSubmit={onSubmit}
            initialValues={initialValues}
            validate={validateHandler}
            render={({ handleSubmit, valid }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <FormSpy
                    subscription={{ values: true, dirty: true, valid: true }}
                    onChange={({ values, dirty, valid }) => { setFormState({ values, dirty, valid }) }}
                  />
                  <div className='fund-management-form'>
                    <div hidden={tab === 'active'} className="fund-management-form__row">
                      <div className="fund-management-form__label bold title">
                        <FormattedMessage id="fundManagement.form.total" />
                      </div>
                      <div className="fund-management-form__field-wrap">
                        <div className="fund-management-form__field">
                          <Field
                            withHelper
                            component={Input}
                            parse={value => value !== (+value).toString() ? value : +value}
                            validate={(value) => (
                              tab === 'demo' && !store.isLoading && (
                                validators.validateWithRange({ min_value: 1, max_value: 10000000 })(value) ||
                                validators.validateWithoutSpace(value) ||
                                validators.validateIntegerField(value) ||
                                trimString(value)
                              )
                            )}
                            name="total"
                            placeholder="100 000"
                          />
                          <div className="fund-management-form__symbol">$</div>
                        </div>
                      </div>
                    </div>

                    <div className="fund-management-form__row fund-management-form__row--top">
                      <div className="fund-management-form__label bold title">
                        <FormattedMessage id="fundManagement.form.risk" />
                      </div>

                      <div className="fund-management-form__field-wrap">
                        <div className="fund-management-form__field">

                          <RadioGroup className="time-frame-form">
                            {ORDER_SIZE_DATA_TYPES.map(env => (
                              <div key={env.value} className="radio-item">
                                <FormControlLabel
                                  control={
                                    <Field
                                      name={'riskType'}
                                      type="radio"
                                      value={env.value}
                                      component={CustomRadio}
                                    />
                                  }
                                  label={<FormattedMessage id={env.label} />}
                                />
                              </div>
                            ))}
                          </RadioGroup>

                        </div>

                        <div className="fund-management-form__field">
                          <Field name="riskType" >
                            {({ input }) => {
                              return (
                                <>
                                  <Field
                                    withHelper
                                    component={Input}
                                    parse={value => value !== (+value).toString() ? value : +value}
                                    name="risk"
                                    placeholder="10"
                                  />
                                  <div className="fund-management-form__symbol">{input.value === 'dynamic' ? '%' : '$'}</div>
                                </>
                              )
                            }
                            }</Field>
                        </div>
                      </div>
                    </div>

                    <div className="fund-management-form__row">
                      <div className="fund-management-form__label bold title">
                        <FormattedMessage id="fundManagement.form.amount" />
                      </div>

                      <div className="fund-management-form__field-wrap">
                        <div className="fund-management-form__field">
                          <Field
                            withHelper
                            component={Input}
                            parse={value => value !== (+value).toString() ? value : +value}
                            validate={(value) => (
                              validators.validateWithRange({ min_value: 1, max_value: 99 })(value) ||
                              validators.validateWithoutSpace(value) ||
                              validators.validateIntegerField(value) ||
                              trimString(value)
                            )}
                            name="maxAmount"
                            placeholder="10"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <SimpleFormControls
                    customClass={'fund-management-form__buttons'}
                    handleSave={handleSubmit}
                    flexEnd
                    disabled={!valid}
                  />
                </form>
              )
            }}
          >
          </Form>
        </div>
      </div>
    </div>
  )
};

export default observer(FundManagement);
