import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    height: 500,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

export default function Loader () {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Typography>Loading</Typography>
      <CircularProgress />
    </div>
  );
};
