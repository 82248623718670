import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { compose } from 'helpers';
import { HEADER_COLUMNS as headerColumns, ROW_COLUMNS as rowColumns } from './consts';
import TradeHistoryTable from 'components/TradeHistoryTable';
import TradeHistoryFilter from 'components/TradeHistoryFilter';
import { filterConfig, useLogs, useOrderNotification } from './helpers';
import TradeOpenOrdersStore from 'store/tradeOpenOrders';

const TradeOpenOrders = ({ tradeOpenOrdersStore }) => {
  const history = useHistory();
  const { allData, selectFields, getLogs } = useLogs();
  const { closeNotification } = useOrderNotification({})

  const closeHandler = async ({ id }) => {
    try {
      const { status } = await tradeOpenOrdersStore.closeOpenOrder(id);

      if (status === 'close') {
        closeNotification(id)
      }
    } catch {}
  }

  const viewHandler = (order) => {
    if (!order.globalRuntimeId) return;
    history.push(`/module/${order.globalRuntimeId}`, order)
  }

  return (
    <div className="form1 trade-history">
      <div className="form__card1">
        <div className="title2 bold trade-history__title">
          <FormattedMessage id='trade.open-orders.title' />
        </div>
        <TradeHistoryFilter config={{ ...filterConfig, selectFields }} getLogs={getLogs} />
        <TradeHistoryTable headerColumns={headerColumns} rowColumns={rowColumns} isStickyHeader data={allData} handlers={{
          closeHandler: closeHandler,
          viewHandler: viewHandler
        }} />
      </div>
    </div>
  );
};

TradeOpenOrders.propTypes = {
  tradeOpenOrdersStore: PropTypes.instanceOf(TradeOpenOrdersStore),
};

const decorator = compose(
  observer,
  inject(stores => ({
    tradeOpenOrdersStore: stores.tradeOpenOrdersStore,
  })),
);

export default decorator(TradeOpenOrders);
