import { makeAutoObservable, runInAction, toJS } from 'mobx';
import _ from 'lodash';
import { States } from './consts';
import { fromModule, toModule } from './utils';
import { find } from 'lodash/collection';
import { ACTIVE_MODULE_TYPE, DEMO_MODULE_TYPE, SAVED_MODULE_TYPE } from 'containers/ModulesListContainer/consts';
import { getCancelTokenSource } from 'api/utils';

export default class ModuleStore {
  moduleList = [];
  nextCursor = null;
  module = null;
  state = States.Pending;
  selectedModuleList = [];
  cancelTokenSource = null;

  constructor (moduleService) {
    makeAutoObservable(this);
    this.moduleService = moduleService;
  }

  createModule = async params => {
    const data = fromModule(params);
    try {
      this.setState(States.Loading);
      const response = await this.moduleService.createModule(data);
      await this.getModuleList({ kind: 'saved' }, true);
      this.setState(States.Done);
      return response;
    } catch (e) {
      this.setState(States.Error);
    }
  }

  duplicateModule = async id => {
    try {
      this.setState(States.Loading);
      const response = await this.moduleService.duplicateModule(id);
      await this.getModuleList({ kind: 'saved' }, true);
      this.setState(States.Done);
      return response;
    } catch (e) {
      this.setState(States.Error);
    }
  }

  downloadModule = async id => {
    try {
      const module = await this.getModule(id);
      const timeframes = _.intersection(
        ...['rsiTimeframes', 'stochTimeframes.prearming', 'stochTimeframes.trigger']
          .map(key => _(_.get(module, key)).filter('enabled').map('value').value())
      )
      const body = {
        timeframes,
        instrument: module.instrument,
        rsi_period: module.rsiPeriodValue,
        k_period: module.kPeriod,
        d_period: module.dPeriod,
        slowing_period: module.slowingPeriod
      }
      return await this.moduleService.downloadModule(body);
    } catch (e) { }
  }

  getModuleList = async (params, reset = false) => {
    if (this.cancelTokenSource) {
      this.cancelTokenSource.cancel('Request canceled');
    }
    this.cancelTokenSource = getCancelTokenSource();

    if (reset) {
      this.setState(States.Loading);
      this.moduleList = []
    } else if (this.allLoaded) {
      return
    } else {
      params.page_cursor = this.nextCursor
    }

    try {
      const { data } = await this.moduleService.getListModule({ params, cancelToken: this.cancelTokenSource.token });
      this.cancelTokenSource = null;
      runInAction(() => {
        this.moduleList.push(...data.results)
        this.nextCursor = data.next && new URLSearchParams(data.next).get('page_cursor')
      });
      if (reset) {
        this.setState(States.Done);
      }
    } catch (e) {
      this.cancelTokenSource = null;
      this.setState(States.Error);
    }
  }

  getModuleListForRuntime = async params => {
    this.setState(States.Loading);
    try {
      const response = await this.moduleService.getListModuleForRuntime(params);
      runInAction(() => {
        this.moduleList = response.data;
      });
      this.setState(States.Done);
      return await response.data;
    } catch (e) {
      this.setState(States.Error);
    }
  }

  getModule = async (id, isFull = false) => {
    const obj = find(this.currentModuleList, { id: parseInt(id) });
    if (!obj || (isFull && !obj.entry_system_conditions)) {
      const response = await this.moduleService.getModule(id, {});
      return toModule(response.data);
    }
    return toModule(obj);
  }

  putModule = async (id, params) => {
    const formattedParams = fromModule(params);
    try {
      this.setState(States.Loading)
      const response = await this.moduleService.putModule(id, formattedParams);
      await this.getModuleList({ kind: 'saved' }, true);
      // remove old module from selected
      this.selectedModuleList = this.selectedModuleList.filter(item => (item?.id !== id));
      this.setState(States.Done);
      return response;
    } catch (e) {
      this.setState(States.Error);
    }
  }

  patchModule = async (id, params) => {
    const formattedParams = fromModule(params);
    try {
      const response = await this.moduleService.patchModule(id, formattedParams);
      await this.getModuleList({ kind: 'saved' });
      this.setState(States.Done);
      return response;
    } catch (e) {
      this.setState(States.Error);
    }
  }

  deleteModule = async (id, params) => {
    const response = await this.moduleService.deleteModule(id, params);
    this.moduleList = this.moduleList.filter(m => m.id !== id);
    return response;
  }

  stopModule = async (runtimeId, params) => {
    const response = await this.moduleService.stopModule(runtimeId, params);
    await this.getModuleList(params);
    return response;
  }

  stopAllModules = async (params) => {
    const response = await this.moduleService.stopAllModules(params);
    await this.getModuleList(params);
    return response;
  }

  runBacktests = async (data) => {
    try {
      this.setState(States.Loading)
      const response = await this.moduleService.runBacktests(data);
      this.setState(States.Done);
      return response;
    } catch (e) {
      console.error(e);
      this.setState(States.Error);
    }
  }

  moveTo = async (module, type) => {
    try {
      let response;
      this.setState(States.Loading)

      if (type === DEMO_MODULE_TYPE) {
        response = await this.moduleService.moveToDemo({ module_id: module.id });
      }

      if (type === SAVED_MODULE_TYPE) {
        response = await this.moduleService.moveToSave({ runtime_id: module.runtimeId });
      }

      if (type === ACTIVE_MODULE_TYPE) {
        response = await this.moduleService.moveToActive({ runtime_id: module.runtimeId });
      }

      this.setState(States.Done);
      return response;
    } catch (e) {
      console.error(e);
      this.setState(States.Error);
    }
  }

  setState (value) {
    this.state = value;
  }

  setSelectedModule = (module) => {
    const isSelected = this.selectedModuleList.some(({ id }) => id === module.id);

    if (isSelected) {
      this.selectedModuleList = this.selectedModuleList.filter(({ id }) => id !== module.id);

      return;
    }

    this.selectedModuleList = [...this.selectedModuleList, module];
  }

  clearSelectedModuleList = () => {
    this.selectedModuleList = []
  }

  filterSelectedModuleList = (moduleId) => {
    this.selectedModuleList = this.selectedModuleList.filter(({ id }) => id !== moduleId);
  }

  get isLoading () {
    return this.state === States.Loading;
  }

  get currentModuleList () {
    return toJS(this.moduleList);
  }

  get allLoaded () {
    return this.nextCursor === null
  }
}
