import { useCallback, useEffect, useState } from 'react';
import { zoomToBegin, zoomToMiddle, zoomToEnd } from '../helpers';
import _ from 'lodash';
import { useStore } from 'hooks';
import { flowResult } from 'mobx';

export function useSlider (chart) {
  const { chartStore } = useStore();
  const [disabled, setDisabled] = useState(false);
  const [lastPercent, setLastPercent] = useState();

  const onChange = useCallback(async (percent) => {
    setDisabled(true);
    await flowResult(chartStore.goToByPercentage(percent));
    setLastPercent(percent);
  }, []);

  // scroll to start/end in 0/100 cases respectively
  // overwise scroll to middle
  useEffect(() => {
    if (_.isNil(lastPercent) || !chart) return;

    const restoreZoom = {
      0: zoomToBegin,
      100: zoomToEnd
    }[lastPercent] || zoomToMiddle;

    const data = chartStore.tfGrouper.groups.map(group => group[0].allData);
    if (data.some(_.isEmpty)) return;
    data.forEach((data, index) => restoreZoom(chart, data, index, 'slider'));

    setDisabled(false);
    setLastPercent();
  }, [lastPercent, chart, chartStore.tfGrouper.groups]);

  return { onChange, disabled };
}
