import React from 'react';
import PropTypes from 'prop-types';
import { RED, GREEN, NEUTRAL } from 'globalConstants/candlePatterns';
import { CustomCheckbox } from 'components/Controls';
import { FormattedMessage, useIntl } from 'react-intl';
import classNames from 'classnames';

import './styles.scss';
import { useField } from 'react-final-form';
import { useFieldArray } from 'react-final-form-arrays';

const Checkbox = ({ type, disabled, ...checkboxProps }) => (
  <span className={classNames('editor-colors-checkbox', { disabled })}>
    <CustomCheckbox
      inputProps={{ name: type, readOnly: disabled }}
      {...checkboxProps}
    />
    <span>{type}</span>
  </span>
);

Checkbox.propTypes = {
  type: PropTypes.string.isRequired,
  disabled: PropTypes.bool
};

const SameAs = ({ number, ...checkboxProps }) => {
  const intl = useIntl();

  return (
    <Checkbox
      type={intl.formatMessage(
        { id: 'candlePatterns.sameAs' },
        { number: number + 1 }
      )}
      {...checkboxProps}
    />
  );
};

SameAs.propTypes = {
  number: PropTypes.number.isRequired
};

const SameAsArray = ({ currentCandleNumber, candles, sameAs, onChange }) => {
  const isDisabled = React.useCallback((candle) => Number.isSafeInteger(candle.same_as) || !candle.colors.length, []);
  return candles.map((candle, index) => {
    return index !== currentCandleNumber ? (
      <SameAs
        key={`${JSON.stringify(candle)}${index}`}
        number={index}
        disabled={isDisabled(candle)}
        checked={index === sameAs}
        onChange={onChange(index)}
      />
    ) : null;
  });
};

export const EditorColours = ({ index }) => {
  const colourVariants = [RED, GREEN, NEUTRAL];

  const candlesField = useField('value.candles');

  const mustNotBeEmpty = React.useCallback(value => (
    value.colors.length || Number.isSafeInteger(value.same_as)
      ? undefined
      : { colors: <FormattedMessage id="modules.validation.emptyArray" /> }
  ), []);

  const candleField = useField(`value.candles[${index}]`, { validate: mustNotBeEmpty });
  const sameAsField = useField(`value.candles[${index}].same_as`);
  const handleSameAsChange = React.useCallback(
    (number) => (event) => {
      if (event.target?.readOnly) return;
      if (event.target.checked) sameAsField.input.onChange(number);
      else sameAsField.input.onChange('');
    },
    [sameAsField.input]
  );

  const colorField = useFieldArray(`value.candles[${index}].colors`);
  const handleColorChange = React.useCallback((event) => {
    if (event.target?.readOnly) return;

    const { name, checked } = event.target;
    if (checked) colorField.fields.push(name);
    else colorField.fields.remove(colorField.fields.value.indexOf(name));
  }, [colorField.fields]);

  // Can't do 2 input.onChange at the same time in handleColorChange
  React.useEffect(() => {
    let changed = false;
    const newCandlesField = [...candlesField.input.value];
    if (
      // If both colors and sameAs are empty
      (!colorField.fields.length && !Number.isSafeInteger(sameAsField.input.value)) ||
      // Or if sameAs is not empty
      Number.isSafeInteger(candlesField.input.value[index].same_as)
    ) {
      // Search for referenced sameAs' and remove them
      candlesField.input.value.forEach((c, candleIndex) => {
        if (c.same_as === index) {
          newCandlesField[candleIndex].same_as = null;
          changed = true;
        }
      });
    }
    if (changed) candlesField.input.onChange(newCandlesField);
  }, [colorField.input, sameAsField.input]);

  return (
    <div className="editor-colours">
      <div className="title3">
        <FormattedMessage id="candlePatterns.possibleColours" />
      </div>
      <div className="colors indent">
        <div>
          {colourVariants.map((c) => (
            <Checkbox
              key={c}
              type={c}
              disabled={sameAsField.input.value !== ''}
              checked={
                colorField.fields.value.includes(c) && !Number.isSafeInteger(sameAsField.input.value)
              }
              onChange={handleColorChange}
            />
          ))}
        </div>
        <div>
          <SameAsArray
            currentCandleNumber={index}
            sameAs={sameAsField.input.value}
            onChange={handleSameAsChange}
            candles={candlesField.input.value}
          />
        </div>
      </div>
      <p className="error-label">
        {candleField.meta.error?.colors}
      </p>
    </div>
  );
};

EditorColours.propTypes = {
  index: PropTypes.number.isRequired
};
