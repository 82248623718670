import React from 'react';
import propTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { metaKey } from 'globalConstants';
import DialogWindow from 'components/DialogWindow';
import { getValues } from 'helpers';

const ErrorModal = ({ open, handleClose, prefix, field, fields, reason: [reason, collision] }) => {
  const intl = useIntl();

  if (!reason) {
    // do not render without reason
    return null;
  };

  // error message for incosistent toggle dependence
  const errorMessageForToggle = () => {
    const dependencies = field[metaKey]?.dependsOn?.filter?.(dep => fields[dep].enabled);
    if (!dependencies) {
      return null;
    }
    return intl.formatMessage({
      id: 'stoch.error.toggle'
    }, {
      count: dependencies.length,
      conditions: intl.formatList(
        dependencies.map(dep => intl.formatMessage({ id: `${prefix}.conditions.${dep}` }))
      )
    });
  };

  // error message for incosistent toggle inverse dependence
  const errorMessageForToggleInversed = () => {
    const dependencies = field[metaKey]?.dependsOnInversed?.filter?.(dep => !fields[dep].enabled);
    if (!dependencies) {
      return null;
    }
    return intl.formatMessage({
      id: 'stoch.error.toggleInversed'
    }, {
      count: dependencies.length,
      conditions: intl.formatList(
        dependencies.map(dep => intl.formatMessage({ id: `${prefix}.conditions.${dep}` })), { type: 'disjunction' }
      )
    });
  }

  const errorMessageForToggleInversedConcreate = () => {
    const dependencies = field[metaKey]?.dependsOnInversed;
    if (!dependencies) {
      return null;
    }
    return intl.formatMessage({
      id: 'stoch.error.toggleInversedConcreate'
    }, {
      count: dependencies.length,
      cond: intl.formatMessage({ id: `${prefix}.conditions.${field.kind}` }),
      conditions: intl.formatList(
        dependencies.map(dep => intl.formatMessage({ id: `${prefix}.conditions.${dep}` })), { type: 'disjunction' }
      )
    });
  }

  // error message for inconsistent value dependence raised on toggle action
  const errorMessageForToggleOnValue = () => {
    return intl.formatMessage({
      id: 'stoch.error.toggleOnValue'
    }, {
      collision: intl.formatMessage({ id: `${prefix}.conditions.${collision.kind}` }),
      value: intl.formatMessage({
        id: 'description',
        defaultMessage: collision.description
      }, getValues(collision))
    });
  }

  // error message for inconsistent value dependence raised on 'change value' action
  const errorMessageForChangeOnValue = () =>
    intl.formatMessage({
      id: 'stoch.error.changeOnValue'
    }, {
      collision: intl.formatMessage({ id: `${prefix}.conditions.${collision.kind}` }),
      value: intl.formatMessage({
        id: 'description',
        defaultMessage: collision.description
      }, getValues(collision))
    });

  // compose error message by reason type and collision entries
  const errorMessageMap = {
    toggle: errorMessageForToggle,
    toggleInversed: errorMessageForToggleInversed,
    toggleInversedConcreate: errorMessageForToggleInversedConcreate,
    toggleOnValue: errorMessageForToggleOnValue,
    changeOnValue: errorMessageForChangeOnValue
  };
  const errorMessage = errorMessageMap[reason]?.() ??
    intl.formatMessage({ id: reason });

  return (
    <DialogWindow
      error
      open={open}
      title="Incorrect action"
      yesText="OK"
      description={errorMessage}
      onClose={handleClose}
      confirmHandler={handleClose}
    />
  );
};

ErrorModal.propTypes = {
  open: propTypes.bool,
  field: propTypes.object,
  fields: propTypes.object,
  prefix: propTypes.string,
  reason: propTypes.object,
  handleClose: propTypes.func
};

export default ErrorModal;
