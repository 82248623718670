export const DEFAULT_COLUMNS = [
  { classes: 'left', attribute: 'title' },
  {
    classes: 'center',
    attribute: 'prearming',
    filterOptions: [
      { label: 'modules.filters.all', value: 'all' },
      { label: 'modules.filters.prearming.minute', value: 'minutely' },
      { label: 'modules.filters.prearming.hourly', value: 'hourly' },
      { label: 'modules.filters.prearming.daily', value: 'daily' },
    ]
  },
  { classes: 'center', attribute: 'trigger' },
  { classes: 'center', attribute: 'rsi' },
  {
    classes: 'center',
    attribute: 'instrument',
    filterOptions: [
      { label: 'modules.filters.all', value: 'all' },
      { label: 'currencyPair.gbpUSD', value: 'gbp_usd' },
      { label: 'currencyPair.eurUSD', value: 'eur_usd' },
      { label: 'currencyPair.eurGBP', value: 'eur_gbp' },
      { label: 'currencyPair.eurJPY', value: 'eur_jpy' },
      { label: 'currencyPair.btcUSD', value: 'btc_usd' },
      { label: 'currencyPair.xauUSD', value: 'xau_usd' },
      { label: 'currencyPair.xagUSD', value: 'xag_usd' },
    ],
  },
  { classes: 'center', attribute: 'candlePattern' },
  { classes: 'center', attribute: 'lastUpdate' },
  { class: 'classes', attribute: 'controls' }
];
