import React from 'react';
import { SwticherProvider } from 'contexts/pageSwitcher';
import DefaultPatternsMenu from './DefaultPatternsMenu';
import { Form } from 'react-final-form';
import { compose } from 'helpers';
import Loader from 'components/Loader';
import { inject, observer } from 'mobx-react';
import propTypes from 'prop-types';
import { CandlePatterns } from 'containers/Modules/General';
import CandleClose from 'containers/Modules/General/EntrySystem/Patterns/CandleClose';

const LogicEditorMenu = observer(({ logicEditorStore }) => {
  React.useEffect(() => {
    logicEditorStore.getPatternDefaultsList();
  }, []);

  if (!logicEditorStore.isReady || !logicEditorStore.currentPatternDefaultList) {
    return <Loader />;
  };

  return (
    <Form
      onSubmit={() => {}}
      initialValues={logicEditorStore.currentPatternDefaultList}
    >
      {() => (
        <SwticherProvider>
          <DefaultPatternsMenu />
          <CandlePatterns />
          <CandleClose />
        </SwticherProvider>
      )}
    </Form>
  );
});

LogicEditorMenu.propTypes = {
  logicEditorStore: propTypes.object
};

const decorator = compose(
  inject(stores => ({
    logicEditorStore: stores.logicEditorStore
  }))
);

export default decorator(LogicEditorMenu);
