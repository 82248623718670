import * as api from 'api/utils';
import { DETAILED_CLOSE_ORDER_URL, RUNTIME_URL } from './consts';

export default class TradeOpenOrdersService {
  get (params) {
    return api.get('tradeManagement', RUNTIME_URL, true, params);
  }

  closeOrder (orderId) {
    return api.post('tradeManagement', DETAILED_CLOSE_ORDER_URL(orderId), true);
  }

  // download (id, params) {
  //   return api.getBlob('tradeManagement', DETAILED_URL(id), true, params);
  // }
}
