import { makeAutoObservable } from 'mobx';
import { represent, toInternal } from 'helpers/utils';
import { States, initialValue } from './consts'

export default class TradeOpenOrdersStore {
  tradeOpenOrdersData = initialValue;
  states = States;
  state = States.Pending;

  constructor (tradeOpenOrdersService) {
    makeAutoObservable(this);
    this.tradeOpenOrdersService = tradeOpenOrdersService;
  }

  * getOpenOrdersHistory (params = {}) {
    this.state = this.states.Loading;
    try {
      const response = yield this.tradeOpenOrdersService.get(represent(params));
      this.tradeOpenOrdersData = toInternal(response.data);
      this.state = this.states.Done;
    } catch (e) {
      this.state = this.states.Error;
      throw e;
    }
  }

  * closeOpenOrder (orderId) {
    this.state = this.states.Loading;
    try {
      const response = yield this.tradeOpenOrdersService.closeOrder(orderId);
      this.state = this.states.Done;
      return toInternal(response.data);
    } catch (e) {
      this.state = this.states.Error;
      throw e;
    }
  }

  updateDataAfterClose (order) {
    this.tradeOpenOrdersData.logs = this.tradeOpenOrdersData.logs.filter(({ id }) => id !== order.id);
  }

  resetData () {
    this.tradeOpenOrdersData = initialValue;
  }

  get ready () {
    return this.state === this.states.Done;
  }
}
