import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import { intl } from 'translations/provider';

import './styles.scss';
import { useTimezone } from 'hooks';

const TradeExclusionRow = ({ row, rowColumns, handlers, instruments }) => {
  useTimezone();
  return (
    <tr className="app-table__row trade-exclusion-row">
      {rowColumns.map(({ accessor, formatter, className }, index) => {
        const context = {
          accessor,
          value: row[accessor],
          row,
          formattedValue: formatter({ value: row[accessor], row, handlers, instruments })
        };

        return <td key={index} className={className(context)}>{context.formattedValue}</td>;
      })}
      <td className="center trade-exclusion-row__controls-cell">
        <div className="trade-exclusion-row__controls">
          {/* <div className="trade-exclusion-row__control trade-exclusion-row__control--stop">
            {row.status === 'stop' && (
              <Tooltip title={intl.formatMessage({ id: 'trade-exclusion.tooltip.stopped' })} placement="top">
                <DoNotDisturbOnIcon />
              </Tooltip>
            )}
          </div> */}
          <div className="trade-exclusion-row__control trade-exclusion-row__control--button">
            <button disabled={row.status === 'active'} className="main-btn secondary-btn bold" onClick={() => handlers.toggle(row)}>
              <FormattedMessage id={`trade-exclusion.button.active`} />
            </button>
          </div>
          <div className="trade-exclusion-row__control trade-exclusion-row__control--button">
            <button disabled={row.status === 'stop'} className="main-btn secondary-btn bold" onClick={() => handlers.toggle(row)}>
              <FormattedMessage id={`trade-exclusion.button.stop`} />
            </button>
          </div>
          <div className="trade-exclusion-row__control trade-exclusion-row__control--edit">
            <Tooltip title={intl.formatMessage({ id: 'trade-exclusion.tooltip.edit' })} placement="top">
              <button
                onClick={() => handlers.edit(row)}
                className="single-icon-btn"
              >
                <EditIcon />
              </button>
            </Tooltip>
          </div>
          <div className="trade-exclusion-row__control trade-exclusion-row__control--delete">
            <Tooltip title={intl.formatMessage({ id: 'trade-exclusion.tooltip.delete' })} placement="top">
              <button
                onClick={() => handlers.delete(row)}
                className="single-icon-btn"
              >
                <DeleteIcon />
              </button>
            </Tooltip>
          </div>
        </div>
      </td>

    </tr>
  );
};

TradeExclusionRow.propTypes = {
  row: PropTypes.object,
  rowColumns: PropTypes.array,
  instruments: PropTypes.array,
  handlers: PropTypes.object,
};

export default TradeExclusionRow;
