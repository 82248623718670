import React from 'react';
import { Radio } from '@material-ui/core';
import PropTypes from 'prop-types';

import './styles.scss';

const CustomRadio = ({ input, meta, ...other }) => {
  // for final-form native usage
  if (input) {
    const { checked, value, name, onChange, ...otherInput } = input;
    return (
      <Radio
        {...other}
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
        inputProps={otherInput}
        className="custom-radio"
      />
    );
  }
  return <Radio className="custom-radio" {...other} />;
};

CustomRadio.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object
};

export default CustomRadio;
