import React from 'react';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';

const getDuplicateErrorRelations = list => {
  let result = {};

  list.forEach((item) => {
    const firstKey = `${item.candle_from}_${item.from_type}_${item.candle_to}_${item.to_type}`;
    const secondKey = `${item.candle_to}_${item.to_type}_${item.candle_from}_${item.from_type}`;
    const collectionByFirstKey = result[firstKey];
    const collectionBySecondKey = result[secondKey];
    if (collectionByFirstKey) {
      collectionByFirstKey.push(item);
    } else if (collectionBySecondKey) {
      collectionBySecondKey.push(item);
    } else {
      result[firstKey] = [item];
    };
  });

  result = Object.fromEntries(Object.entries(result).filter(([k, v]) => v.length > 1))
  const duplicatesParamsRelations = [];
  Object.values(result).forEach((item) => {
    duplicatesParamsRelations.push(item[item.length - 1])
  });
  return duplicatesParamsRelations;
};

const getCyclicalRelations = relations => {
  const fornatedRelations = {}
  let data = {}

  relations.filter(item => item.from_type === item.to_type).forEach((relation) => {
    const key = `${relation.from_type}_${relation.to_type}`;
    if (fornatedRelations[key]) {
      fornatedRelations[key].push(relation)
    } else {
      fornatedRelations[key] = [relation]
    }
  })

  Object.entries(fornatedRelations).forEach((entry) => {
    const graph = {}
    const [key, item] = entry;

    item.forEach((relation) => {
      const key = `${relation.candle_from}`;
      if (graph[key]) {
        graph[key].push(relation.candle_to)
      } else {
        graph[key] = [relation.candle_to]
      }
    })
    Object.keys(graph).map((vertex) => {
      const result = { result: false, visited: [] }
      const isCyclical = isCyclicalVertex(parseInt(vertex), graph, result).result;
      if (isCyclical) {
        data = { graph: graph, key: key };
      }
    });
  });
  return data;
};

const isCyclicalVertex = (vertex, graph, data) => {
  if (data.visited.includes(vertex) || data.result) {
    data.result = true
    return data
  }
  data.visited.push(vertex);

  (graph[vertex] || []).forEach((points) => {
    data = isCyclicalVertex(points, graph, data)
    if (data.result) {
      return data
    }
  });
  return data;
};

export const validate = values => {
  const duplicatesParamsRelations = getDuplicateErrorRelations(values)

  if (duplicatesParamsRelations.length) {
    return values.map((value, index) => duplicatesParamsRelations.includes(value) ? { symbol: <FormattedMessage id='candlePatterns.error_message.inverse_relations'/> } : undefined)
  };

  const data = getCyclicalRelations(values);

  if (!_.isEmpty(data)) {
    let relation;

    values.forEach((item) => {
      const key = `${item.from_type}_${item.to_type}`;
      if (data.key === key && (data.graph[item.candle_from] || []).includes(item.candle_to)) {
        relation = item;
      }
    });

    return values.map((value, index) => relation === value ? { symbol: <FormattedMessage id='candlePatterns.error_message.cyclical_relations'/> } : undefined)
  }

  return []
};
