import { makeAutoObservable } from 'mobx';

import { signInRequest } from './requests';

class AuthStore {
  storageKey = 'authToken';

  constructor () {
    makeAutoObservable(this);
  }

  * login (body) {
    const response = yield signInRequest(body);
    const token = response.data.auth_token;
    localStorage.setItem(this.storageKey, token);
    this.triggerPostLogin()
    return token;
  }

  logout () {
    localStorage.removeItem(this.storageKey);
  }

  triggerPostLogin () {
    if (this.isLogin) {
      this.root.lastItemStore.firstLoad();
      this.root.instrumentsStore.firstLoad();
    }
  }

  get isLogin () {
    return !!localStorage.getItem(this.storageKey);
  }
}

export default AuthStore;
