import React, { useMemo, useCallback } from 'react';
import propTypes from 'prop-types';

import { FormattedMessage } from 'react-intl';
import { Input } from 'components/Fields';
import { Form, Field } from 'react-final-form';

import DialogForm from 'components/DialogForm';
import SimpleFormControls from 'components/SimpleFormControls';

const ConditionLoss = ({ handleSave, obj, open, handleClose, prefix, symbol, validate }) => {
  const content = useCallback(handleSubmit => (
    <form className="condition-form-content" onSubmit={handleSubmit}>
      <div className="form">
        <div className="condition-form-content__field">
          <Field
            autoFocus
            withHelper
            component={Input}
            name="value"
            validate={validate}
          />
          <div className="condition-form-content__symbol bold">
            {symbol}
          </div>
        </div>
      </div>
    </form>
  ), [validate, symbol]);

  const title = useMemo(() => (
    <div className="condition-form-header">
      <div className="title2 bold">
        <FormattedMessage id={`entry_system.conditions.${obj.kind}`} />
      </div>
    </div>
  ), [prefix, obj.kind]);

  return open && (
    <Form
      onSubmit={values => { handleSave(values); handleClose() }}
      initialValues={obj}
      render={({ invalid, handleSubmit }) => {
        return (
          <DialogForm
            open={open}
            title={title}
            content={content(handleSubmit)}
            controls={
              <SimpleFormControls
                flexEnd
                disabled={invalid}
                handleCancel={handleClose}
                handleSave={handleSubmit}
              />
            }
            handleClose={handleClose}
            customClass="condition-form-popup"
          />
        );
      }}
    />
  );
}

ConditionLoss.propTypes = {
  handleSave: propTypes.func,
  obj: propTypes.object,
  open: propTypes.bool,
  handleClose: propTypes.func,
  prefix: propTypes.string,
  symbol: propTypes.string,
  validate: propTypes.number
};

export default ConditionLoss;
