import React, { useState, useMemo, useCallback, useEffect } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useForm } from 'react-final-form';
import TimeoutConditionForm from './ConditionForm/TimeoutConditionForm';
import TrendConditionForm from './ConditionForm/TrendConditionForm';
import ConditionForm from './ConditionForm';
import ConditionRow from './ConditionRow';
import ErrorModal from './ErrorModal';
import { metaKey, AVAILABILITY, CONDITIONS_DEFAULTS } from 'globalConstants';
import { isToggleInconsensual, isValueInconsensual, checkAvailability } from './helpers';
import { useSwitcher } from 'contexts/pageSwitcher';

/*
To handle custom behavior, conditions have meta information by metaKey accessor.
Currently supported types of meta and it explanation:

  - boolean: bool
    used with boolean conditions w/o values e.g. directions

  - padding: bool
    for padding after condition (for availability)

  - withoutToggle: bool
    whatever conditions is always on and toggle not displayed

  - disabledToggle: bool
    whatever toggle is disabled

  - switchWith: kind
    toggle `kind` condition. Used if two conditions tied and only and only one must be on

  - dependsOn: array of kinds
    tie condition with other conditions on toggle, only one can be activated, on incorrect tries raise error popup
    ( !a & !b & !c ...), where a, b, c are dependent fields

  - dependsOnInversed: array of kinds
    works same as 'dependsOn' above, but value is inversed
    ( a | b | c ...), where a, b, c are dependent fields

  - onValue: { [type]: kinds, ... }
    tie condition with other on value
    currently supported types:
      - lt - less
      - gt - greater
      - le - less or equal
      - ge - greater or equal
    on incorrect actions raise error popup
*/

const Conditions = ({ fields, kind, prefix }) => {
  const switcher = useSwitcher();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isError, setIsError] = useState(false);
  const [field, setField] = useState({});
  const [reason, setReason] = useState([null, null])
  const form = useForm();

  const conditions = useMemo(() => (
    fields.value
      .map((field, index) => ({ index, ...field }))
      .filter(field => field.kind.startsWith(kind) || field.kind.includes('availability'))
  ), [fields.value, kind]);

  // create mapping for effectively searcing
  const mapping = useMemo(() => {
    const map = Object.fromEntries(conditions.map(field => [field.kind, field]))
    // populate fields by meta info
    let defaults = CONDITIONS_DEFAULTS[prefix];
    if (_.isObject(defaults) && !_.isArray(defaults)) {
      const n = fields.name;
      defaults = defaults[n.match(/\.(\w.+)\./)[1]];
    }
    for (const field of defaults ?? []) {
      if (field.kind in map) {
        map[field.kind][metaKey] = field[metaKey];
      }
    };
    return map;
  }, [fields.name, conditions, prefix]);

  useEffect(() => {
    // if rendered from switcher keep modal open
    if (switcher?.context?.kind) {
      const field = mapping[switcher.context.kind]
      if (field) {
        setField(field);
        setIsOpenModal(true);
      }
    }
  }, [switcher.pageIndex]);

  // available conditions
  const available = useMemo(() => ({
    long: mapping[AVAILABILITY.LONG],
    short: mapping[AVAILABILITY.SHORT]
  }), [mapping]);

  const isDisabled = useCallback(field => {
    const isTrend = field?.kind?.includes('trend_disarm')
    const disabled = (available[kind] && available[kind] !== field &&
      !available[kind].enabled) || (isTrend && !available[kind === 'long' ? 'short' : 'long']?.enabled)

    if (isTrend && disabled && field.enabled) {
      form.mutators.updateProps(fields.name, field.index, { enabled: false });
    }

    return disabled
  }, [available, kind]);

  // raise error popup if needed
  const checkError = useCallback((field, newField, isToggle) => {
    let error = isValueInconsensual(newField, isToggle, mapping, kind);
    if (isToggle) {
      error = isToggleInconsensual(newField, mapping) || error;
    }
    error = error || checkAvailability(newField, form, kind);
    if (error) {
      setField(error[1]);
      setReason(error);
      setIsError(true);
      return true;
    }
    return false;
  }, [form, mapping, kind]);

  const handleChange = useCallback(field => e => {
    const { checked: enabled } = e.target;

    // Used to keep one entry system on while editing conditions
    const enabledCount = conditions.filter(field => field.enabled).length;
    if (prefix === 'entry_system' && !enabled && enabledCount <= 1) {
      return;
    }

    if (checkError(field, { ...field, enabled }, true)) {
      return
    }
    form.mutators.updateProps(fields.name, field.index, { enabled });

    const switchWith = mapping[field[metaKey]?.switchWith];
    if (switchWith) {
      form.mutators.updateProps(fields.name, switchWith.index, { enabled: !enabled })
    }
  }, [fields.name, form, checkError, conditions, mapping, prefix]);

  const handleClick = field => () => {
    setIsOpenModal(true);
    setField(field);
  };

  const handleAdd = nestedField => () => {
    if (!_.isNull(nestedField?.nestedIndex)) {
      const originalField = mapping[nestedField.kind]
      const defaults = originalField[metaKey].defaults
      const value = [...originalField.value, defaults[nestedField.nestedIndex + 1]]
      handleSave(originalField, value)
    }
  }

  const handleDelete = nestedField => () => {
    if (nestedField.nestedIndex) {
      const originalField = mapping[nestedField.kind]
      const value = originalField.value.filter((_, i) => i !== nestedField.nestedIndex).map((val, index) => ({ ...val, index }))
      handleSave(originalField, value)
    }
  }

  const handleSave = (field, value) => {
    const newField = { ...field, value };
    if (checkError(field, newField)) {
      return
    }
    form.mutators.updateProps(fields.name, field.index, { value });
    setIsOpenModal(false);
  };

  const Form = React.memo(function Form(props) {
    const kind = props.obj?.kind;
    // eslint-disable-next-line react/prop-types
    if (kind?.includes?.('time_out_disarm')) {
      return <TimeoutConditionForm {...props} />
    } else if (kind?.includes?.('trend_disarm')) {
      const payload = { ...props, originalObj: mapping[props.obj.kind] }
      console.log(payload)
      return <TrendConditionForm {...payload} />
    } else {
      return <ConditionForm {...props} />;
    }
  });

  const flattedConditions = useMemo(() => conditions.map((field) => {
    if (!_.isArray(field?.value)) {
      return [field]
    }
    return field.value.reduce((arr, value, idx) => {

      const child = {
        ...field,
        value,
        // add some additional attrs for condition childs
        nestedIndex: idx,
        isLastNested: idx === field.value.length - 1,
        isMaxNested: idx === mapping[field.kind][metaKey].defaults.length - 1
      }
      if (idx === 0 || (field.enabled && !isDisabled(field))) {
        arr.push(child)
      }
      return arr
    }, [])
  }).flat().filter(field => field.kind.startsWith(kind)), [conditions])

  return (
    <>
      <Form
        open={isOpenModal}
        obj={field}
        prefix={prefix}
        handleClose={() => setIsOpenModal(false)}
        handleSave={handleSave}
      />
      <ErrorModal
        open={isError}
        reason={reason}
        prefix={prefix}
        field={field}
        fields={mapping}
        handleClose={() => { setIsError(false) }}
      />
      {flattedConditions.map((field, index) => {
        return (<>
          {field.isDisabled ? '1' : ''}
          <ConditionRow
            field={field}
            key={index}
            index={index}
            prefix={prefix}
            handleChange={handleChange}
            handleClick={handleClick}
            handleAdd={handleAdd}
            handleDelete={handleDelete}
            isDisabled={isDisabled}
          />
        </>
        )
      }
      )}
    </>
  );
};

Conditions.propTypes = {
  fields: PropTypes.object,
  kind: PropTypes.string,
  prefix: PropTypes.string
};

export default Conditions;
