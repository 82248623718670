import React from 'react';
import ReactDOM from 'react-dom';
import { RawIntlProvider } from 'react-intl';
import { Provider as StoreProvider } from 'mobx-react'

import App from './containers/App';
import * as serviceWorker from './serviceWorker';
import { NotificationProvider } from './contexts/notification';
import Notification from './components/Notification';
import { intl } from 'translations/provider';
import store from 'store/rootStore';

import './styles/_main.scss';
import { NotifyProvider } from 'contexts/popup/notify';
import { ConfirmProvider } from 'contexts/popup/confirm';
import { TimezoneProvider } from 'contexts/timezone/provider';

const MOUNT_NODE = document.getElementById('root');

ReactDOM.render(
  <React.StrictMode>
    <RawIntlProvider value={intl}>
      <TimezoneProvider>
        <NotificationProvider>
          <NotifyProvider>
            <ConfirmProvider>
              <StoreProvider {...store}>
                <App />
                <Notification anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} />
              </StoreProvider>
            </ConfirmProvider>
          </NotifyProvider>
        </NotificationProvider>
      </TimezoneProvider>
    </RawIntlProvider>
  </React.StrictMode>,
  MOUNT_NODE
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
