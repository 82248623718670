export const States = Object.freeze({
  Pending: 0,
  Loading: 1,
  Error: 2,
  Done: 3
});

export const initialValue = {
  filters: {},
  logs: [],
};
