import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Form, useField } from 'react-final-form';
import { useIntl } from 'react-intl';
import DialogForm from 'components/DialogForm';
import Dependency from '../../Common/Dependency';
import Parameter from '../../Common/Parameter';

import './styles.scss';
import SimpleFormControls from 'components/SimpleFormControls';

// Modal dialog for editing one pattern
const RuleEditorForm = ({ rule, type, handleClose }) => {
  const intl = useIntl();
  const field = useField(`value[${rule?.index}]`);

  const title = useMemo(() => (
    rule && intl.formatMessage({
      id: 'candle_close.rule_editor_title'
    }, {
      type, kind: intl.formatMessage({ id: `candle_close.conditions.${rule?.kind}` })
    })
  ), [intl, rule]);

  const handleSave = useCallback(value => {
    field.input.onChange(value);
    handleClose();
  }, [field, handleClose]);

  const content = rule => {
    if (!rule?.value) return;
    const mapping = Object.fromEntries(
      rule.value.map((prop, index) => [prop.kind, { ...prop, index }])
    );
    return (
      <div className="rule-editor">
        <div className="dependencies">
          <div className="title3">Dependencies</div>
          <Dependency prefix={`value[${mapping.dependency.index}]`} />
        </div>
        <div className="parameters">
          <div className="title3">Candle parameters</div>
          <div className="fields">
            <Parameter paramsName="value" param={mapping.high_length} />
            <Parameter paramsName="value" param={mapping.candle_length} />
            <Parameter paramsName="value" param={mapping.low_length} />
          </div>
        </div>
      </div>
    );
  };

  const controls = (handleSubmit, invalid) => (
    <SimpleFormControls
      handleCancel={handleClose}
      handleSave={handleSubmit}
      disabled={invalid}
    />
  );

  return (
    <Form
      initialValues={rule}
      onSubmit={handleSave}
    >
      {({ handleSubmit, values, invalid }) => (
        <DialogForm
          open={!_.isNull(rule)}
          handleClose={handleClose}
          customClass="rule-editor-dialog"
          title={<span>{title}</span>}
          content={content(values)}
          controls={controls(handleSubmit, invalid)}
        />
      )}
    </Form>
  );
};

RuleEditorForm.propTypes = {
  rule: PropTypes.object,
  type: PropTypes.string,
  handleClose: PropTypes.func,
  handleSave: PropTypes.func
};

export default RuleEditorForm;
