export const HEADERS = [null, 'value', 'stop', 'close', 'move', 'send', ''];

export const RISK_MANAGEMENT_INITIAL_DATA = {
  system: [
    {
      type: 'drawdown',
      value: 0,
      stopOpeningNewOrders: false,
      closeOpenOrders: false,
      moveRuntimesToSavedMode: false,
      sendNotifications: false,
    },
    {
      type: 'consecutive_losses',
      value: 0,
      stopOpeningNewOrders: false,
      closeOpenOrders: false,
      moveRuntimesToSavedMode: false,
      sendNotifications: false,
    },
    {
      type: 'daily_loss',
      value: 0,
      stopOpeningNewOrders: false,
      closeOpenOrders: false,
      moveRuntimesToSavedMode: false,
      sendNotifications: false,
    },
    {
      type: 'notification_threshold',
      value: 0,
      stopOpeningNewOrders: false,
      closeOpenOrders: false,
      moveRuntimesToSavedMode: false,
      sendNotifications: false,
    }
  ],
  runtime: [
    {
      type: 'consecutive_losses',
      value: 0,
      stopOpeningNewOrders: false,
      closeOpenOrders: false,
      moveRuntimesToSavedMode: false,
      sendNotifications: false,
    },
    {
      type: 'daily_loss',
      value: 0,
      stopOpeningNewOrders: false,
      closeOpenOrders: false,
      moveRuntimesToSavedMode: false,
      sendNotifications: false,
    },
    {
      type: 'notification_threshold',
      value: 0,
      stopOpeningNewOrders: false,
      closeOpenOrders: false,
      moveRuntimesToSavedMode: false,
      sendNotifications: false,
    }
  ]
}

export const SWITCHER_FIELDS = [
  {
    label: 'risk-management.header.stop',
    fieldName: 'stopOpeningNewOrders',
  },
  {
    label: 'risk-management.header.close',
    fieldName: 'closeOpenOrders',
  },
  {
    label: 'risk-management.header.move',
    fieldName: 'moveRuntimesToSavedMode',
  },
  {
    label: 'risk-management.header.send',
    fieldName: 'sendNotifications',
  },
]

export const VALIDATION_CONFIG = {
  drawdown: {
    available_range: {
      min_value: 1,
      max_value: 1000000
    }
  },
  consecutive_losses: {
    available_range: {
      min_value: 1,
      max_value: 99
    },
  },
  daily_loss: {
    available_range: {
      min_value: 1,
      max_value: 1000000
    },
  },
  notification_threshold: {
    available_range: {
      min_value: 1,
      max_value: 100
    },
  }
}

export const FIELD_SYMBOL = {
  drawdown: '$',
  consecutive_losses: '',
  daily_loss: '$',
  notification_threshold: '%',
}

export const ERRORS = {
  INCORRECT_ACTION: 'dialog.title.incorrectAction',
  STOP_OFF: 'risk-management.error.stop.off',
  CLOSE_OFF: 'risk-management.error.close.off',
  CLOSE_ON: 'risk-management.error.close.on',
  MODULE_ON: 'risk-management.error.module.on',
};
