import React from 'react';

import PasswordProtectedRoute from 'containers/PasswordProtected';
import RiskManagement from 'containers/RiskManagement';

const RiskManagementPage = () => {
  return (
    <PasswordProtectedRoute pageName={'risk-management'}>
      <RiskManagement />
    </PasswordProtectedRoute>
  );
};

export default RiskManagementPage;
