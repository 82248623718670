import { useState } from 'react';
import { useIntl } from 'react-intl';
import { DateTime } from 'luxon';
import { useConfirmation, useNotify } from 'contexts/popup';
import { useNotification } from 'contexts/notification';
import * as validators from 'helpers/validators';
import { toJS } from 'mobx';

export const useControls = ({ tradeExclusionStore }) => {
  const intl = useIntl();
  const { error } = useNotify();
  const { confirm } = useConfirmation();
  const notification = useNotification();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [preFilledData, setPreFilledData] = useState(null);
  const [isEdit, setIsEdit] = useState(null);

  const handlerOpen = (data) => {
    if (data) {
      setIsEdit(true);
      setPreFilledData({
        ...data,
        startTime: DateTime.fromFormat(data.startTime, 'HH:mm:ss').setZone('default'),
        finishTime: DateTime.fromFormat(data.finishTime, 'HH:mm:ss').setZone('default')
      })
    }

    setIsDialogOpen(true);
  }
  const handlerClose = () => {
    setIsDialogOpen(false);
    setIsEdit(false);
    setPreFilledData(null);
  }

  const serverError = detail => error({
    description: intl.formatMessage({ id: `trade-exclusion.modal.validation.${detail}` })
  })

  const handlerApply = async ({ data }) => {
    if (_isSameName(data)) {
      notification.show('Oops', intl.formatMessage({ id: 'trade-exclusion.modal.validation.alreadyExists' }));
      return;
    }

    const sameValuesError = validators.validateSameExclusion(data, toJS(tradeExclusionStore.tradeExclusions))
    if (sameValuesError) {
      error({ description: sameValuesError })
      return;
    }

    try {
      if (isEdit) {
        await tradeExclusionStore.updateTradeExclusion(data);
      } else {
        await tradeExclusionStore.createTradeExclusion(data);
      }
    } catch (e) {
      serverError(e.data?.detail)
      return;
    }

    handlerClose();
    return true
  }
  const handlerDelete = async ({ id, title }) => {
    const result = await confirm({
      title: intl.formatMessage({ id: 'dialog.title.deleteExclusion' }),
      description: intl.formatMessage({ id: 'dialog.description.deleteExclusion' }, { title }),
      error: true
    });

    if (result === 'yes') {
      try {
        await tradeExclusionStore.deleteTradeExclusions(id);
      } catch (e) {
        serverError(e.data?.detail)
      }
    }
  }
  const handlerToggle = async ({ id, status }) => {
    const isActive = status === 'active'

    try {
      await tradeExclusionStore.toggleTradeExclusion(id, status);
    } catch (e) {
      const err = e.data?.detail;
      if (isActive) {
        serverError(err);
      } else {
        serverError(`launch.${err}`);
      }
    }
  };

  const _isSameName = (data) => {
    return tradeExclusionStore.tradeExclusions.some(({ title, id }) => {
      return title.toLowerCase() === data.title.toLowerCase() && id !== data.id;
    });
  }

  const handlers = {
    handlerOpen,
    handlerClose,
    handlerApply,
    handlerDelete,
    handlerToggle,
  };

  const state = {
    isDialogOpen,
    preFilledData,
    isEdit
  };

  return { handlers, state };
};
