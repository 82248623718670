import PropTypes from 'prop-types';
import { metaKey } from 'globalConstants/meta';

const minMaxValue = PropTypes.shape({
  min_value: PropTypes.number.isRequired,
  max_value: PropTypes.number.isRequired
});

const meta = PropTypes.shape({
  withoutToggle: PropTypes.bool
});

export const globalConstValue = PropTypes.shape({
  enabled: PropTypes.bool,
  kind: PropTypes.string,
  color: PropTypes.string,
  colors: PropTypes.arrayOf(PropTypes.string),
  symbol: PropTypes.string,
  description: PropTypes.string,
  available_range: minMaxValue,
  [metaKey]: meta
});

globalConstValue.value = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number,
  PropTypes.bool,
  minMaxValue,
  globalConstValue,
  PropTypes.arrayOf(globalConstValue)
]).isRequired;
