import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import propTypes from 'prop-types';
import DoDisturb from '@mui/icons-material/DoDisturb';
import Check from '@mui/icons-material/Check';
import _ from 'lodash';

import { compose } from 'helpers';
import RiskManagementStore from 'store/riskManagementStore';
import { EditIcon } from 'components/Svgs';
import { HEADERS, RISK_MANAGEMENT_INITIAL_DATA } from './consts';
import RiskManagementForm from './RiskManagementForm';
import Loader from 'components/Loader';
import hasLoader from 'hocs/hasLoader';

import './styles.scss';

const RiskManagement = ({ riskManagementStore, setLoading }) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [obj, setObj] = useState(null);
  const [displayedData, setDisplayedData] = useState(_.entries(RISK_MANAGEMENT_INITIAL_DATA));

  const handleEdit = field => (e) => {
    setObj(field);
    setIsOpenModal(true);
  };

  const handleClose = () => {
    setIsOpenModal(false);
    setObj(null);
  }

  const updateData = (prevValue, newValue) => {
    const { kind, ...value } = newValue;

    return prevValue.map(([prevValueKind, data]) => {
      if (prevValueKind === kind) {
        const newData = data.map((element) => element.type === value.type ? value : element);

        return [kind, newData];
      }

      return [prevValueKind, data];
    });
  }

  const handleSave = async (value) => {
    await riskManagementStore.updateRiskManagement(value);
    setDisplayedData(updateData(displayedData, value));
    handleClose();
  };

  useEffect(() => {
    (async () => {
      await riskManagementStore.getRiskManagement();
      setDisplayedData(_.entries(riskManagementStore.riskManagementData));
      setIsLoading(false)
    })()
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <div className="form risk-management-container">
        <div className="form__card">
          <div className="form__card-content">

            {displayedData.map(([kind, data]) => {
              return (
                <div className="risk-management-block" key={kind}>
                  <div className="risk-management-container__title-wrap">
                    <h2 className="bold risk-management-container__title">
                      <FormattedMessage id={`risk-management.title.${kind}`} />
                    </h2>
                  </div>

                  <div className="risk-management">
                    <div className="risk-management__content">
                      <div className="risk-management__row">
                        {HEADERS.map((value) => (
                          <div className="risk-management__item risk-management__header-cell risk-management__item--title" key={value}>
                            {value && <FormattedMessage id={`risk-management.header.${value}`} />}
                          </div>
                        ))}
                      </div>
                      {data.map((row) => {
                        return (
                          <div className="risk-management__row" key={row.type}>
                            <div className="risk-management__item risk-management__item--title" key={row.type}>
                              <FormattedMessage id={`risk-management.row.${row.type}`} />
                            </div>

                            <div className="risk-management__item">{row.value}</div>
                            <div className="risk-management__item">{row.stopOpeningNewOrders ? <Check /> : <DoDisturb />} </div>
                            <div className="risk-management__item">{row.closeOpenOrders ? <Check /> : <DoDisturb />}</div>
                            <div className="risk-management__item">{row.moveRuntimesToSavedMode ? <Check /> : <DoDisturb />}</div>
                            <div className="risk-management__item">{row.sendNotifications ? <Check /> : <DoDisturb />}</div>
                            <div className="risk-management__item">
                              <button
                                type="button"
                                className="single-icon-btn"
                                onClick={handleEdit?.({ ...row, kind })}
                              >
                                <EditIcon />
                              </button>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>

      {isOpenModal && (
        <RiskManagementForm
          isOpenModal={isOpenModal}
          obj={obj}
          displayedData={_.fromPairs(displayedData)}
          handleSave={handleSave}
          handleClose={handleClose}
        />
      )}
    </>
  );
};

RiskManagement.propTypes = {
  riskManagementStore: propTypes.instanceOf(RiskManagementStore),
};

const decorator = compose(
  observer,
  inject(stores => ({
    riskManagementStore: stores.riskManagementStore
  })),
);

export default decorator(RiskManagement);
