import React from 'react';
import { CustomSelect } from 'components/Controls';
import { MenuItem } from '@mui/material';
import { useTimezone } from './provider';

const TimezonePicker = () => {
  const { tz, setTz, variants } = useTimezone();
  return (
    <CustomSelect
      value={tz}
      items={variants.map(item => (
        <MenuItem
          key={item}
          value={item}
          onClick={() => setTz(item)}
        >{item}</MenuItem>
      ))}
    />
  );
}

export default TimezonePicker;
