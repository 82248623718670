import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { observer, inject as injectStore } from 'mobx-react';

import { Input } from 'components/Fields';

import './styles.scss';

const Login = observer(({ authStore }) => {
  const history = useHistory();
  const intl = useIntl();
  authStore.logout()

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isError, setIsError] = useState(false);

  const onSubmit = async e => {
    e.preventDefault();

    const body = {
      identity: username,
      password: password
    }

    try {
      await authStore.login(body);
      history.push('/');
    } catch {
      setIsError(true);
      setTimeout(() => setIsError(false), 3000);
    }
  }

  return (
    <div className="login">
      <form onSubmit={onSubmit}>
        <h2>{intl.formatMessage({ id: 'login.header' })}</h2>
        <Input
          isSmallField
          label={intl.formatMessage({ id: 'login.identity' })}
          value={username}
          onChange={e => setUsername(e.target.value)}
        />
        <Input
          isSmallField
          label={intl.formatMessage({ id: 'login.password' })}
          value={password}
          onChange={e => setPassword(e.target.value)}
          type="password"
        />
        <input
          type="submit"
          value={intl.formatMessage({ id: 'login.submit' })}
          className="main-btn primary-btn bold"
        />
        <span className="login__error-message" style={{ visibility: isError || 'hidden' }}>
          {intl.formatMessage({ id: 'login.error_message' })}
        </span>
      </form>
    </div>
  )
});

export default injectStore('authStore')(Login);
