import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { Tooltip } from '@material-ui/core';
import ClearIcon from '@mui/icons-material/Clear';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { useIntl } from 'react-intl';
import { useTimezone } from 'hooks';
import { DateTime } from 'luxon';

import './styles.scss';

const BackTestRow = ({ row, handlers }) => {
  useTimezone();
  const intl = useIntl();
  const { name, runtimeItems, status, setting, kind } = row;

  const getRangeValue = (kind, setting) => {
    return kind === 'simulation' ? `${DateTime.fromISO(setting.startPeriod, { zone: 'default' }).toFormat('dd-MM-yyyy')} - ${DateTime.fromISO(setting.endPeriod, { zone: 'default' }).toFormat('dd-MM-yyyy')}` : 'File';
  }

  const getOrderSizeValue = ({ orderSize, orderSizeType }) => {
    if (orderSizeType === 'dynamic') {
      return `${_.round((orderSize), 2)} %`;
    }

    return `$ ${_.round((orderSize), 2)}`;
  }

  return (
    <tr className="app-table__row back-test-row">
      <td className="left">{name}</td>
      <td className="center">
        <ul className="back-test-list__name-list">
          {runtimeItems?.map(({ name, id }) => (
            <li key={id} className="back-test-list__name-list-item">
              <Link to={`/backtest/${row.id}/item/${id}`}>{name}</Link>
            </li>
          ))}
        </ul>
      </td>
      <td className="center">$ {setting.totalFund}</td>
      <td className="center">{getOrderSizeValue(setting)}</td>
      <td className="center">{setting.maxAmountOpenOrders}</td>
      <td className="center">{getRangeValue(kind, setting)}</td>
      <td
        className={cn('center', {
          ongoing: status === 'ongoing',
          finished: status === 'finished'
        })}
      >{status}</td>

      <td>
        <div className="app-table__actions">
          <Tooltip title={intl.formatMessage({ id: 'backtest.modules.list.report.button' })} placement="top">
            <Link
              to={`/trade-report/${row.id}`}
              className="back-test-list__report-btn main-btn btn-icon--right primary-btn bold"
            >
              {intl.formatMessage({ id: 'backtest.modules.list.report.button' })}
            </Link>
          </Tooltip>

          <Tooltip title={intl.formatMessage({ id: 'backtest.modules.list.tooltip.delete' })} placement="top">
            <button
              onClick={() => { handlers.delete(row.id) }}
              className="single-icon-btn"
              disabled={row.status !== 'finished'}
            >
              <ClearIcon />
            </button>
          </Tooltip>

          <Tooltip title={intl.formatMessage({ id: 'backtest.modules.list.tooltip.copy' })} placement="top">
            <button
              onClick={() => handlers.copy(row)}
              className="single-icon-btn"
              disabled={row.status !== 'finished'}
            >
              <FileCopyIcon />
            </button>
          </Tooltip>
        </div>
      </td>
    </tr>
  );
};

BackTestRow.propTypes = {
  row: PropTypes.object,
  handlers: PropTypes.objectOf(PropTypes.func)
};

export default BackTestRow;
