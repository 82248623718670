import React from 'react';
import { FormattedMessage } from 'react-intl';

export const isValid = (value, availableRange) => {
  const regex = /^\d+(\.)?\d*$/;

  value = value.toString()

  if (value === '' || value.indexOf(' ') >= 0) {
    return <FormattedMessage id="modules.validation.empty" />;
  } else if (!regex.test(value)) {
    return <FormattedMessage id="modules.validation.onlyNumeric" />;
  } else if (availableRange) {
    const min = parseFloat(availableRange.min_value);
    const max = parseFloat(availableRange.max_value);
    if (+value < min || +value > max) {
      return <FormattedMessage id="modules.validation.validRange" />;
    }
  }

  // return null;
};
