import React, { useCallback, useEffect, useMemo } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Field, useField } from 'react-final-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { Input } from 'components/Fields';
import { CustomSwitch } from 'components/Controls';

import './styles.scss';

// Length parameter in pattern's editor with validation
const Parameter = ({ param, toggleHidden, paramsName }) => {
  const intl = useIntl();
  const field = useField(`${paramsName}[${param.index}]`);
  const disabled = useMemo(() => !field.input.value.enabled, [field.input.value.enabled]);

  // use only one error from min & max value for space saving
  const error = useMemo(() => (
    field.meta.error?.value?.min_value ||
    field.meta.error?.value?.max_value
  ), [field.meta.error]);

  // revert param values to default on toggle off if there is error on it
  // (except error related to dependency inconsistency)
  useEffect(() => {
    if (disabled && error) {
      field.input.onChange({
        ...field.input.value,
        value: {
          min_value: 0,
          max_value: 7
        }
      });
    }
  }, [disabled, intl]);

  const validate = useCallback(type => (__, state) => {
    const all = _.get(state, paramsName, []);
    const { value, available_range: range, enabled } = all[param.index] ?? {};
    if (!range) {
      return;
    }
    if (!value || (!value[type] && value[type] !== 0)) {
      return intl.formatMessage({ id: 'modules.validation.empty' });
    }
    if (Number.isNaN(+value[type])) {
      return intl.formatMessage({ id: 'modules.validation.onlyNumeric' });
    }
    if (range.min_value > +value[type] || +value[type] > range.max_value) {
      return intl.formatMessage({ id: 'modules.validation.validRangeWithValue' }, range);
    }
    if (+value.max_value < +value.min_value) {
      return intl.formatMessage({ id: 'modules.validation.minGtMax' });
    }
    // const [high,, low, dep] = all;
    const high = all.find(({ kind }) => kind === 'high_length')
    const low = all.find(({ kind }) => kind === 'low_length')
    const dep = all.find(({ kind }) => kind === 'dependency')

    // dependency is unmeet if both low & high enabled and not fulfilled coniditons
    const isDependencyUnmeet = (
      ['low_length', 'high_length'].includes(param.kind) &&
      high.enabled && low.enabled && dep.enabled && enabled
    ) && (
      (dep.value === '>' && +high?.value?.max_value <= +low?.value?.min_value) ||
      (dep.value === '<' && +high?.value?.min_value >= +low?.value?.max_value) ||
      (dep.value === '>=' && +high?.value?.max_value < +low?.value?.min_value) ||
      (dep.value === '<=' && +high?.value?.min_value > +low?.value?.max_value)
    );
    if (isDependencyUnmeet) {
      return intl.formatMessage({ id: 'modules.validation.unmeetDependency' })
    }
  }, [intl, param]);

  return (
    <div className={classNames('parameter', { disabled })}>
      <Field
        name={`${paramsName}[${param.index}].enabled`}
        hidden={toggleHidden}
        type="checkbox"
        validateFields={undefined}
        component={CustomSwitch}
      />
      <FormattedMessage id={`candle_close.conditions.${param.kind}`} />
      <Field
        name={`${paramsName}[${param.index}].value.min_value`}
        validate={validate('min_value')}
        component={Input}
        disabled={disabled}
      />
      <span>-</span>
      <Field
        name={`${paramsName}[${param.index}].value.max_value`}
        validate={validate('max_value')}
        component={Input}
        disabled={disabled}
      />
      <span>{param.symbol}</span>
      <p className="error-label">{error}</p>
      <span />
    </div>
  );
};

Parameter.propTypes = {
  paramsName: PropTypes.string,
  toggleHidden: PropTypes.bool,
  param: PropTypes.shape({
    kind: PropTypes.string,
    enabled: PropTypes.bool,
    symbol: PropTypes.string,
    index: PropTypes.number
  })
};

export default Parameter;
