import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import AuthenticatedRoute from './AuthenticatedRoute';
import NotFoundPage from 'pages/NotFoundPage';
import RunModulePage from 'pages/RunModulePage';
import EditModulePage from 'pages/EditModulePage';
import CreateModulePage from 'pages/CreateModulePage';
import ModulesPage from 'pages/ModulesPage';
import ModulesActivePage from 'pages/ModulesActivePage';
import LoginPage from 'pages/LoginPage';
import LogicEditor from 'pages/LogicEditor';
import BackTestPage from 'pages/BackTestPage';
import TradeHistoryReportPage from 'pages/TradeHistoryReportPage';
import TradeHistoryPage from 'pages/TradeHistoryPage';
import TradeOpenOrdersPage from 'pages/TradeOpenOrdersPage';
import FundManagementPage from 'pages/FundManagement';
import HomePage from 'pages/HomePage';
import TradeExclusionPage from 'pages/TradeExclusionPage';
import { AuthorizedLayout } from 'components/Layouts';
import { LOGIN_URL } from './consts';
import RiskManagementPage from '../../pages/RiskManagementPage';

const App = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Redirect to="/home" />
        </Route>
        <AuthenticatedRoute exact path="/home" compontent={HomePage} layout={AuthorizedLayout} />
        <AuthenticatedRoute exact path="/module/create" compontent={CreateModulePage} layout={AuthorizedLayout} />
        <AuthenticatedRoute exact path="/module/:id/run" compontent={RunModulePage} layout={AuthorizedLayout} />
        <AuthenticatedRoute exact path="/module/:id" compontent={RunModulePage} layout={AuthorizedLayout} />
        <AuthenticatedRoute exact path="/backtest/:id/item/:itemId" compontent={RunModulePage} layout={AuthorizedLayout} />
        <AuthenticatedRoute exact path="/module/:id/edit" compontent={EditModulePage} layout={AuthorizedLayout} />
        <Route exact path="/modules"><Redirect to="/modules/saved" /></Route>
        <AuthenticatedRoute exact path="/modules/:mode(saved|demo)" compontent={ModulesPage} layout={AuthorizedLayout} />
        <AuthenticatedRoute exact path="/modules/active" compontent={ModulesActivePage} layout={AuthorizedLayout} />
        <AuthenticatedRoute exact path="/backtest/list" compontent={BackTestPage} layout={AuthorizedLayout} />
        <AuthenticatedRoute exact path="/logic/editor" compontent={LogicEditor} layout={AuthorizedLayout} />
        <AuthenticatedRoute exact path="/trade-report/:id" compontent={TradeHistoryReportPage} layout={AuthorizedLayout} />
        <AuthenticatedRoute exact path="/trade-history" compontent={TradeHistoryPage} layout={AuthorizedLayout} />
        <AuthenticatedRoute exact path="/fund-management" compontent={FundManagementPage} layout={AuthorizedLayout} />
        <AuthenticatedRoute exact path="/open-orders" compontent={TradeOpenOrdersPage} layout={AuthorizedLayout} />
        <AuthenticatedRoute exact path="/risk-management" compontent={RiskManagementPage} layout={AuthorizedLayout} />
        <AuthenticatedRoute exact path="/trade-exclusion" compontent={TradeExclusionPage} layout={AuthorizedLayout} />
        <Route path={LOGIN_URL}>
          <LoginPage />
        </Route>
        <Route path="*">
          <NotFoundPage />
        </Route>
      </Switch>
    </Router>
  );
};

export default App;
