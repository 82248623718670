import { createTheme } from '@mui/material/styles';

export const theme = (params) => createTheme({
  components: {
    MuiTab: {
      styleOverrides: {
        root: (props) => {
          if (props['aria-label'] === 'pick time') {
            return {
              display: params.isDisplayTime ? '' : 'none'
            }
          }
        }
      },
    },
  },
  palette: {
    primary: {
      main: '#6d3c97',
    },
  },
});
