import React from 'react';
import PropTypes from 'prop-types';
import { ArrowIcon } from 'components/Svgs';
import { FormattedMessage } from 'react-intl';

const FormControls = ({ isFirst = false, isLast = false, onNext, onPrev, disabled }) => {
  const nextButton = isLast
    ? 'modules.buttons.save'
    : 'modules.buttons.continue';

  return (
    <div className='form__btns'>
      {!isFirst && (
        <button
          className="form-action-btn form-action-btn--spaces btn-uppercased outline-btn btn-icon--left primary-btn bold"
          type="button"
          onClick={onPrev}
          disabled={disabled}
        >
          <ArrowIcon
            className="btn-icon__icon"
            style={{ width: '12px', height: '14px' }}
          />
          <FormattedMessage id={'modules.buttons.back'} />
        </button>
      )}

      <button
        className="form-action-btn form-action-btn--spaces btn-uppercased main-btn btn-icon--right primary-btn bold"
        type="button"
        onClick={onNext}
        disabled={disabled}
      >
        <FormattedMessage id={nextButton} />
        {!isLast && (
          <ArrowIcon
            className="btn-icon__icon"
            style={{ width: '12px', height: '14px' }}
          />
        )}
      </button>

    </div>
  );
};

FormControls.propTypes = {
  isLast: PropTypes.bool,
  isFirst: PropTypes.bool,
  onNext: PropTypes.func,
  onPrev: PropTypes.func,
  disabled: PropTypes.bool,
};

export default FormControls;
