import React from 'react';
import propTypes from 'prop-types';
import { inject } from 'mobx-react';
import { useIntl } from 'react-intl';

import Wizard from 'containers/Wizard';
import ModuleStore from 'store/moduleStore';
import { useNotification } from 'contexts/notification';
import InstrumentPickerForm from 'containers/Modules/General/BaseIndicator/ConditionList/Condition/ConditionForm/InstrumentPickerForm';

const InstrumentPicker = ({ store, open, handleClose, module: { id } }) => {
  const intl = useIntl();
  const notification = useNotification();
  const [module, setModule] = React.useState(null);
  const submitRef = React.useRef();

  React.useEffect(() => {
    if (!open) setModule(null);
  }, [open]);

  React.useEffect(() => {
    const retriveModule = async () => {
      try {
        const retrievedModule = await store.getModule(id);
        setModule(retrievedModule);
      } catch {
        setModule(null);
      }
    }

    // retrive module if id is provided
    if (open && (id ?? false)) {
      setModule(null);
      retriveModule();
    }
  }, [id, open, store]);

  const onSubmit = async values => {
    handleClose();
    try {
      const response = await store.putModule(id, values);
      const moduleName = response.data.name;
      const title = intl.formatMessage({ id: 'notification.title.moduleUpdated' });
      const text = intl.formatMessage({ id: 'notification.text.moduleUpdated' }, { moduleName });
      notification.show(title, text);
    } catch {}
  };

  const handleSave = React.useCallback(() => {
    submitRef.current.click();
  }, [submitRef]);

  return (module &&
    <Wizard initialValues={module} onSubmit={onSubmit}>
      <InstrumentPickerForm
        open={open}
        handleClose={handleClose}
        handleSave={handleSave}
        showButton={true}
      />
      <button type="submit" style={{ display: 'none' }} ref={submitRef} />
    </Wizard>
  )
};

InstrumentPicker.propTypes = {
  store: propTypes.instanceOf(ModuleStore),
  module: propTypes.object,
  open: propTypes.bool,
  handleClose: propTypes.func
};

const decorator = inject(stores => ({
  store: stores.moduleStore
}));

export default decorator(InstrumentPicker);
