import React from 'react';

import ModulesListContainer from 'containers/ModulesListContainer';
import { SAVED_MODULE_TYPE } from 'containers/ModulesListContainer/consts';

const ModulesPage = () => {
  return <ModulesListContainer type={SAVED_MODULE_TYPE} />
};

export default ModulesPage;
