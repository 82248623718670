import React from 'react';

import ModulesListContainer from 'containers/ModulesListContainer';
import { ACTIVE_MODULE_TYPE } from 'containers/ModulesListContainer/consts';

const ModulesPage = () => (
  <ModulesListContainer type={ACTIVE_MODULE_TYPE} />
);

export default ModulesPage;
