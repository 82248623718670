import _ from 'lodash';
import { getClosestTimestamp } from 'helpers';

// try to rewrite
export const getCandlesRange = ({ data, timestamp, totalCandles }) => {
  const closestTimestamp = getClosestTimestamp(data, timestamp);
  const timestampPosition = _.sortedIndexBy(data, { timestamp: closestTimestamp }, 'timestamp')

  let start;
  let end;
  const lastDataIndex = data.length - 1;

  if (timestampPosition <= totalCandles) {
    start = 0;
    end = totalCandles;
  }

  if (timestampPosition + totalCandles >= lastDataIndex) {
    start = lastDataIndex - totalCandles;
    end = lastDataIndex;
  } else {
    start = timestampPosition - totalCandles;
    end = timestampPosition;
  }

  return { start, end };
}

export const calculateStart = (data, position) => {
  return position < 0 ? data.length + position : position;
}

export const zoomToEnd = (chart, data, dataZoomIndex, source) => {
  zoomTo(chart, data, dataZoomIndex, source, -100, -1);
}

export const zoomToBegin = (chart, data, dataZoomIndex, source) => {
  zoomTo(chart, data, dataZoomIndex, source, 0, 100);
}

export const zoomToMiddle = (chart, data, dataZoomIndex, source) => {
  const index = Math.round(data.length / 2);
  zoomTo(chart, data, dataZoomIndex, source, index - 50, index + 50);
}

export const zoomTo = (chart, data, dataZoomIndex, source, from, to) => {
  const startValue = calculateStart(data, from);
  const endValue = calculateStart(data, to);
  chart.dispatchAction({
    type: 'dataZoom',
    dataZoomIndex,
    startValue,
    endValue,
    source,
  });
}
