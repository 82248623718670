import React, { createContext, useContext, useState } from 'react';
import { Settings, DateTime, FixedOffsetZone } from 'luxon';
import propTypes from 'prop-types';

const storeKey = 'timezone';
const variants = ['LOCAL', 'UTC', 'NY', 'LONDON'];
const defaultTz = localStorage.getItem(storeKey) ?? 'UTC';
const zoneMapper = {
  UTC: 'utc',
  NY: 'America/New_York',
  LONDON: 'Europe/London',
  LOCAL: 'system',
}

export const TimezoneContext = createContext({ tz: defaultTz, setTz: () => {}, variants });
// call this hook to expose rerendering
export const useTimezone = () => useContext(TimezoneContext);

export const TimezoneProvider = ({ children }) => {
  const [tz, setTz] = useState(defaultTz);
  const timezone = zoneMapper[tz];
  Settings.defaultZone = FixedOffsetZone.instance(DateTime.now().setZone(timezone).offset);
  localStorage.setItem(storeKey, tz);

  return (
    <TimezoneContext.Provider value={{ tz, setTz, variants, timezone }}>
      {children}
    </TimezoneContext.Provider>
  );
};

TimezoneProvider.propTypes = {
  children: propTypes.node.isRequired
};
