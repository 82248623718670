import React from 'react';
import _ from 'lodash';
import { intl } from 'translations/provider';
import { FormattedMessage } from 'react-intl';
import { DateTime } from 'luxon';

function isNumeric(value) {
  return !isNaN(value);
}

function isNumberEmpty(value) {
  return !value && value !== 0
}

export function validateTimeoutField(value) {
  if (!value && value !== 0) {
    return <FormattedMessage id="modules.validation.empty" />;
  } else if (!/^-?[0-9]+$/.test(value)) {
    return <FormattedMessage id="modules.validation.onlyInteger" />;
  } else if (+value < 0) {
    return <FormattedMessage id="modules.validation.graterThenZero" />;
  } else {
    return null;
  }
}


export function validateTrendFieldGroup (trends, field) {
  if (trends.length < 2) {
    return null;
  }

  let idx = null

  const item = trends.find(t => t.index === field.value.index)
  const eq = trends.some(trend => {
    const res = trend.index !== item.index && trendDisarmsIsEquals(trend, item)
    idx = trend.index
    return res
  })

  if (eq) {
    return <FormattedMessage id="modules.validation.hasSameValues" values={{
      target: (
        <>
          <FormattedMessage id={`rsi.conditions.${field.kind}`} />
          {idx !== 0 ? `(${idx})` : ''}
        </>
      )
    }} />
  }
  return null
}

export function trendDisarmsIsEquals (trend1, trend2) {
  return trend1.rsi_level1 === trend2.rsi_level1 &&
         trend1.rsi_reset_level1 === trend2.rsi_reset_level1 &&
         trend1.rsi_reset_level2 === trend2.rsi_reset_level2
}

export function validateTrendField (value, values, fieldName) {
  const rsiLvl = 'rsi_level';
  const rsiResetLvl2 = 'rsi_reset_level2'
  const rsiResetLvl1 = 'rsi_reset_level1'

  const names = {
    [rsiLvl]: 'RSI Level',
    [rsiResetLvl2]: 'RSI Reset Level 2',
    [rsiResetLvl1]: 'RSI Reset Level 1',
  }
  const gt = name => <FormattedMessage id="modules.validation.greaterThanName" values={{ name: names[name] }} />
  const lt = name => <FormattedMessage id="modules.validation.lessThanName" values={{ name: names[name] }} />

  if (fieldName === rsiLvl) {
    if (+value <= +values[rsiResetLvl1]) {
      return gt(rsiResetLvl1)
    }
    if (+value >= +values[rsiResetLvl2]) {
      return lt(rsiResetLvl2)
    }
  }

  if (fieldName === rsiResetLvl2) {
    if (+value <= +values[rsiResetLvl1]) {
      return gt(rsiResetLvl1)
    }
  }

  return null
}

export const validateWithRange = (range, isFloat = false) => function validate (value, all, meta) {
  const enabled = meta?.name && _.get(all, meta.name.replace('value', 'enabled'));

  if (isNumberEmpty(value)) {
    return <FormattedMessage id="modules.validation.empty" />;
  } else if (isNaN(value)) {
    return <FormattedMessage id="modules.validation.onlyNumeric" />;
  } else if (!isFloat && !Number.isSafeInteger(+value)) {
    return <FormattedMessage id="modules.validation.onlyInteger" />;
  } else if (enabled !== false && (+value < range.min_value || +value > range.max_value)) {
    return <FormattedMessage id="modules.validation.validRangeWithValue" values={range} />;
  } else {
    return null;
  }
};

export const validateIntegerField = value => {
  if (/[\\.,:]/.test(value)) return <FormattedMessage id="modules.validation.onlyInteger" />;
  Number.isInteger(value);
};

export function validateMinMax({ min_value: minValue, max_value: maxValue }) {
  if ((+minValue && +maxValue) && (+minValue > +maxValue)) {
    return <FormattedMessage id={'modules.validation.minGtMax'} />;
  }
}

export function validateWithoutSpace(value) {
  value = value.toString();

  if (value === '' || value.indexOf(' ') >= 0) {
    return <FormattedMessage id="modules.validation.empty" />;
  }
}

export function validateTimeframe(timeframe, timeframes, dimension) {
  if (timeframe === '' || timeframe.indexOf(' ') >= 0) {
    return <FormattedMessage id="modules.validation.empty" />;
  } else if (!isNumeric(timeframe)) {
    return <FormattedMessage id="modules.validation.onlyNumeric" />;
  } else if (!/^-?[0-9]+$/.test(timeframe)) {
    return <FormattedMessage id="modules.validation.onlyInteger" />;
  } else if (timeframes.find(item => +item.value === +timeframe * dimension.multiplier)) {
    return <FormattedMessage id="modules.validation.alreadyExist" />;
  } else {
    return validateWithRange({ min_value: 1, max_value: 99 })(+timeframe);
  }
}

export const fixedValidationValue = (num, precision, type) => {
  const regEx = new RegExp(`^[0-9]+(.[0-9]{1,${precision}})?$`);
  const message = <FormattedMessage id={`entry_system.conditions.profit_targets.error.${type}`} />;

  return !regEx.test(num) ? message : null;
};

export const validateTheSmallest = (targets, reassignment) => targets?.some?.(target => +target <= +reassignment)
  ? <FormattedMessage id='entry_system.conditions.profit_targets.error_smallest' />
  : null;

export const validatePrevious = value => {
  value = value.targets?.some?.((f, i, a) => i !== 0 && a[i - 1] > +f)
    ? {
      errorTextPrevious: <FormattedMessage id='entry_system.conditions.profit_targets.error_previous' />,
      targets: _.range(0, 7).map(i => ' ')
    }
    : {};

  return value;
};

export const validateTotal = value => {
  const maxValue = 6;
  const totalValue = _([
    value.pattern_close,
    value.pattern_50_retrace,
    value.pattern_off
  ]).filter('enabled').map('value').map(_.toNumber).sum();

  if (totalValue !== maxValue) {
    return { global: <FormattedMessage id='entry_system.conditions.multiple_trade_execution.error_more' /> };
  }

  return totalValue;
};

/**
 * Given list of existing titles, return function to validate custom pattern title
 * @param {Array<string>} existingTitles
 * @returns {(value: string) => (string | undefined)} validate function which accepts title as its value
 */
export const validatePatternTitle = (existingTitles, maxLength = 50) => function validate(value) {
  if (!value) {
    return intl.formatMessage({ id: 'modules.validation.empty' });
  } else if (value.length > maxLength) {
    return intl.formatMessage({ id: 'logicEditor.validation.tooLong' }, { len: maxLength });
  } else if (existingTitles.map(t => t.toLowerCase()).includes(value.toLowerCase())) {
    return intl.formatMessage({ id: 'logicEditor.validation.alreadyExists' });
  }
};

export const validateTitle = (maxLength = 50) => function validate(value) {
  if (!value) {
    return intl.formatMessage({ id: 'modules.validation.empty' });
  } else if (value.length > maxLength) {
    return intl.formatMessage({ id: 'modules.validation.tooLong' }, { len: maxLength });
  }
};

export const validateIsBeforeDate = (firstDate, secondDate) => {
  if (firstDate >= secondDate) {
    return intl.formatMessage({ id: 'modules.validation.date.is.before' });
  }
};

export const validateIsBeforeOrEqualDate = (firstDate, secondDate) => {
  if (firstDate >= secondDate) {
    return intl.formatMessage({ id: 'modules.validation.date.is.beforeOrEqual' });
  }
};

export const validateOrderSizeWithMaxOpenOrders = (orderSize, maxAmountOpenOrders) => {
  if (orderSize && maxAmountOpenOrders && orderSize * maxAmountOpenOrders > 100) {
    return intl.formatMessage({ id: 'backtest.modal.validation.order_size_max_open_orders_error' });
  }
};

export const validateOrderSizeWithTotalFund = (orderSize, totalFund) => {
  if (orderSize > totalFund) {
    return intl.formatMessage({ id: 'backtest.modal.validation.order_size_total_fund_orders_error' });
  }
};

export const validateTimeFrameRange = (tfStart, tfEnd, timeCycle) => {
  if (!timeCycle || timeCycle === 'all') return undefined;

  const min = 1;
  const max = 99;

  if (!(tfStart && tfEnd)) {
    return intl.formatMessage({ id: 'modules.validation.empty' });
  }

  if (!(_.isNumber(tfStart) && _.isNumber(tfEnd))) {
    return intl.formatMessage({ id: 'modules.validation.onlyNumeric' });
  }

  if ((tfStart < min || tfStart > max) || (tfEnd < min || tfEnd > max)) {
    return intl.formatMessage({ id: 'modules.validation.validRangeWithValue' }, {
      min_value: min,
      max_value: max
    });
  }

  if (tfStart > tfEnd) {
    return intl.formatMessage({ id: 'modules.validation.less' });
  }

  return undefined;
}

export const validateSameExclusion = (targetExcl, exclusions) => {
  const getObj = obj => ({
    ...obj,
    id: 0,
    title: '',
    status: '',
  })
  const target = getObj({
    ...targetExcl,
    startTime: DateTime.fromFormat(targetExcl.startTime, 'HH:mm').toFormat('HH:mm:ss'),
    finishTime: DateTime.fromFormat(targetExcl.finishTime, 'HH:mm').toFormat('HH:mm:ss'),
  });

  const found = exclusions.filter(ex => ex.id !== targetExcl?.id).map(ex => getObj(ex)).some(ex => _.isEqual(ex, target));
  if (found) {
    return intl.formatMessage({ id: 'trade-exclusion.modal.validation.alreadyExistsValue' })
  }
}
