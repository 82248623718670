import React, { useCallback } from 'react';
import _ from 'lodash';
import propTypes from 'prop-types';
import createDecorator from 'final-form-calculate'
import { FormattedMessage } from 'react-intl';
import { Dialog, DialogContent } from '@material-ui/core';
import { CloseIcon } from 'components/Svgs';
import { Input } from 'components/Fields';
import { Field, Form } from 'react-final-form';
import { trimString } from 'helpers';
import * as validators from 'helpers/validators';
import { CustomSwitch } from 'components/Controls';
import { DEFAULT_TRADE_EXECUTION } from './consts';

import SimpleFormControls from 'components/SimpleFormControls';

import './styles.scss'
import classNames from 'classnames';

const decorator = createDecorator(
  {
    field: /\.enabled$/,
    updates: (value, key, all, prev) => {
      return _.isEmpty(prev) ? {} : {
        [key.replace('enabled', 'value')]: +value
      }
    }
  }
)

const MultipleTradeExecutionFrame = ({ open, onClose, onSave, value }) => {
  const handleSubmit = useCallback(value => {
    onSave(value);
    onClose();
  }, [])

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      className="custom-dialog add-frame-form"
    >
      <Form
        decorators={[decorator]}
        onSubmit={handleSubmit}
        validate={validators.validateTotal}
        initialValues={value}
        render={({ errors, invalid, handleSubmit }) => (
          <>
            <DialogContent>
              <button
                className="single-icon-btn single-icon-btn--white cross"
                onClick={onClose}
              >
                <CloseIcon />
              </button>

              <h2 className="title2 bold">
                <FormattedMessage id='entry_system.conditions.multiple_trade_execution' />
              </h2>

              <div className="add-frame-form__fields multiple">
                {DEFAULT_TRADE_EXECUTION.map(pattern => (
                  <Field name={pattern} key={pattern}>
                    {({ input: { value } }) => (
                      <div className={classNames('multiple__container', { disabled: !value.enabled })}>
                        <div className='multiple__block' >
                          <span className='multiple__switch'>
                            <Field
                              type="checkbox"
                              name={`${pattern}.enabled`}
                              component={CustomSwitch}
                            />
                          </span>

                          <div className='title3 bold multiple__title'>
                            <FormattedMessage
                              id={`entry_system.conditions.${pattern}`}
                              tagName="p"
                            />
                          </div>
                        </div>

                        <Field
                          isSmall
                          component={Input}
                          withHelper
                          disabled={!value.enabled}
                          validate={value => (
                            validators.validateWithRange({ min_value: 0, max_value: 6 })(value) ||
                            validators.validateWithoutSpace(value) ||
                            validators.validateIntegerField(value) ||
                            trimString(value)
                          )}
                          name={`${pattern}.value`}
                        />
                      </div>
                    )}
                  </Field>
                ))}
              </div>

              <div className='error-field'>
                {errors.global}
              </div>
            </DialogContent>

            <SimpleFormControls
              flexEnd={true}
              handleCancel={onClose}
              handleSave={handleSubmit}
              disabled={invalid}
            />
          </>
        )}
      />
    </Dialog>
  );
};

MultipleTradeExecutionFrame.propTypes = {
  open: propTypes.bool.isRequired,
  type: propTypes.string.isRequired,
  onSave: propTypes.func.isRequired,
  onClose: propTypes.func.isRequired,
  validate: propTypes.func,
  value: propTypes.number
};

export default MultipleTradeExecutionFrame;
