import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import classNames from 'classnames';

import './styles.scss';

const Filter = ({ type, label, handleChange, items, value }) => {
  const intl = useIntl();

  return (
    <div className="custom-filter">
      {label && <div className="custom-filter__label">{label}</div>}
      <div className="custom-filter__filters">
        {items.map((item) => (
          <button
            className={classNames('custom-filter__button', {
              active: item.value === value
            })}
            key={item.value}
            type="button"
            onClick={() => handleChange(type, item.value)}
            disabled={item.disabled}
          >
            {intl.formatMessage({ id: item.label + (item.kind ? `.${item.kind}` : '') })}
          </button>
        ))}
      </div>
    </div>
  );
};

Filter.propTypes = {
  type: PropTypes.string.isRequired,
  label: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
  value: PropTypes.string.isRequired
};

export default Filter;
