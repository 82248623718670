import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { EmptyIcon } from 'components/Svgs';
import Loader from 'components/Loader';
import hasLoader from 'hocs/hasLoader';
import { DEFAULT_COLUMNS as columns } from './consts';
import BackTestRow from './BackTestRow';

import './styles.scss';

const BackTestList = ({ rows, handlers }) => {
  if (!rows || !rows.length) {
    return (
      <div className="module-empty-view">
        <div className="module-empty-view__icon">
          <EmptyIcon />
        </div>
        <h3 className="bold title3">
          <FormattedMessage id="backtest.emptyList" />
        </h3>
        <div className="module-empty-view__description">
          <FormattedMessage id="backtest.emptyListHint" />
        </div>
      </div>
    );
  }

  return (
    <div className="back-test-list">
      <table className="app-table">
        <thead className="app-table__head back-test-list__head">
          <tr>
            {columns.map(({ attribute, classes }, index) => (
              <th key={index} className={classes}>
                <FormattedMessage id={`backtest.modules.list.${attribute}`} defaultMessage=" " />
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="app-table__content">
          {rows?.map((row) => {
            return <BackTestRow row={row} key={row.id} handlers={handlers} />
          })}
        </tbody>
      </table>
    </div>
  );
};

BackTestList.propTypes = {
  rows: PropTypes.array,
  handlers: PropTypes.objectOf(PropTypes.func),
  isLoading: PropTypes.bool
};

export default hasLoader(BackTestList, Loader);
