import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { ThemeProvider } from '@mui/material/styles';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { MobileDateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { useTimezone } from 'hooks';

import { theme } from './theme';

import './styles.scss'

export default function CustomDateTimePicker ({ open, onClose, onAccept, renderInput, date, minDate, views, inputFormat, isDisplayTime = false }) {
  const { tz } = useTimezone();
  const [value, setValue] = React.useState(date || DateTime.now());
  const maxDate = DateTime.now();

  useEffect(() => { setValue(date) }, [date]);
  useEffect(() => { value && setValue(value.setZone('default')) }, [tz]);

  return (
    <ThemeProvider theme={theme({ isDisplayTime })}>
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <MobileDateTimePicker
          views={views || ['year', 'month', 'day']}
          value={value}
          open={open}
          onAccept={onAccept}
          onClose={onClose}
          onChange={date => { setValue(date.startOf('seconds')) }}
          minDate={minDate || DateTime.fromObject({ year: 2000 })}
          maxDate={maxDate}
          inputFormat={inputFormat || 'yyyy/MM/dd'}
          mask="___/__/__ __:__ _M"
          renderInput={renderInput || (() => null)}
        />
      </LocalizationProvider>
    </ThemeProvider>
  );
}

CustomDateTimePicker.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onAccept: PropTypes.func,
  renderInput: PropTypes.func,
  views: PropTypes.array,
  inputFormat: PropTypes.string,
  isDisplayTime: PropTypes.bool,
  date: PropTypes.instanceOf(DateTime),
  minDate: PropTypes.instanceOf(DateTime),
};
