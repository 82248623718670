import * as api from 'api/utils';
import { DETAILED_URL, POLLING_XLSX_URL, TRADE_HISTORY_URL } from './consts';

export default class TradeHistoryService {
  getTradeHistoryReport (id, params) {
    return api.get('tradeManagement', DETAILED_URL(id), true, params);
  }

  getTradeHistory (params) {
    return api.get('tradeManagement', TRADE_HISTORY_URL, true, params);
  }

  download (id, params) {
    return api.get('tradeManagement', DETAILED_URL(id), true, params);
  }

  pollingXlsx (params) {
    return api.getBlob('tradeManagement', POLLING_XLSX_URL, true, params);
  }

  downloadTradeHistory (params) {
    return api.get('tradeManagement', TRADE_HISTORY_URL, true, params);
  }

  deleteAll () {
    return api.del('tradeManagement', TRADE_HISTORY_URL, true);
  }
}
