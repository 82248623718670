import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Field } from 'react-final-form';
import { EditIcon } from 'components/Svgs';
import InstrumentPickerForm from '../../BaseIndicator/ConditionList/Condition/ConditionForm/InstrumentPickerForm';
import { useStore } from 'hooks';
import { instrumentMessage } from 'globalConstants/tradeManagement';

const InstrumentRow = () => {
  const [isOpenModal, setIsOpenModal] = useState();
  const { instrumentsStore } = useStore();

  return (
    <div className="rs-indicator-list__item">
      <p style={{ maxWidth: 219 }}>
        <FormattedMessage id="entry_system.conditions.instrument" />
      </p>

      <div className="rs-indicator-list__interval">
        <div className="rs-indicator-list__interval">
          <Field name="instrument">
            {({ input }) => instrumentMessage({ instruments: instrumentsStore.instruments, id: input.value })}
          </Field>
        </div>
      </div>

      <button
        type="button"
        className="single-icon-btn"
        onClick={() => setIsOpenModal(true)}
      >
        <EditIcon />
      </button>

      <InstrumentPickerForm
        open={isOpenModal}
        handleClose={() => setIsOpenModal(false)}
      />
    </div>
  );
};

export default InstrumentRow;
