import _ from 'lodash';
import { useChartSync } from 'contexts/chart';
import { useCallback, useEffect, useState } from 'react';
import { filters } from './helpers';

export function useLogsRetrieve (module, controls, logs, positionMap) {
  // RSI & CandleClose logs
  useEffect(() => {
    positionMap.rsi.forEach(position => {
      if (!filters.rsi(module, controls)) return;
      logs[position.value].firstLoad({
        timeframe: controls.rsiPeriod,
        runtimeItemId: module.id,
      });
    });
  }, [controls.rsiPeriod, module.id]);

  useEffect(() => {
    positionMap.candle.forEach(position => {
      logs[position.value].firstLoad({
        timeframe: controls.candlePeriod,
        runtimeItemId: module.id,
      });
    });
  }, [controls.candlePeriod, module.id]);

  // stoch logs
  useEffect(() => {
    if (!controls.stochPeriod) return;
    positionMap.stoch.filter(pos => !pos.disabled).forEach(position => {
      logs[position.value].firstLoad({
        timeframe: controls.stochPeriod,
        runtimeItemId: module.id,
      });
    })
  }, [controls.stochPeriod, module.id]);

  // whole sequence
  useEffect(() => {
    positionMap.whole.forEach(position => {
      logs[position.value].firstLoad({
        runtimeItemId: module.id,
      });
    });
  }, [module.id]);
};

export function useScrolling (position, positions, logs) {
  const { observers } = useChartSync();

  // Store & restore scroll position on each tab
  // number values for absolute value in pixels
  // string values for relatetive index of logs
  const [scrollPositions, setScrollPositions] = useState(
    _.fromPairs(positions.map(p => [p.value, 0]))
  );
  const changeScroll = useCallback(name => value => {
    setScrollPositions(old => ({ ...old, [name]: value }))
  }, [])

  const [prevTs, setPrevTs] = useState(
    _.fromPairs(positions.map(p => [p.value, 0]))
  );

  useEffect(() => observers.selectedDateForWsLogs.subscribe(selectedDate => {
    const wsLogs = ['WHOLE_SEQUENCE_LONG', 'WHOLE_SEQUENCE_SHORT'];
    wsLogs.forEach(type => {
      const index = _.sortedIndexBy(logs[type].allData, { actual_timestamp: selectedDate }, i => -i.actual_timestamp)
      console.log({ index, logs: logs[type].allData, selectedDate });
      setScrollPositions(old => ({ ...old, [type]: `${index}` }));
    });
  }), [logs]);

  // scroll control
  useEffect(() => {
    positions
      .filter(({ value }) => !_.isString(scrollPositions[value]))
      .map(({ value }) => ({ value, ts: logs[value].lastSeriesTimestamp }))
      .filter(({ value, ts }) => value !== position.value && prevTs[value] !== ts)
      .forEach(({ value, ts }) => {
        setScrollPositions(old => ({ ...old, [value]: 0 }));
        setPrevTs(old => ({ ...old, [value]: ts }));
      });
  }, [logs]);

  return { scrollPositions, changeScroll };
}
