import { useChartSync } from 'contexts/chart';
import { useEffect } from 'react';
import { useSlider } from './slider';

export function useControlsButtons (chart) {
  const { observers } = useChartSync();
  const { onChange } = useSlider(chart);

  // buttons to begin & to end
  useEffect(() => observers.toBegin.subscribe(() => onChange(0)), [onChange]);
  useEffect(() => observers.toEnd.subscribe(() => onChange(100)), [onChange]);
}
