import React, { useCallback, useState } from 'react';
import propTypes from 'prop-types';
import { useIntl } from 'react-intl';
import Cropper from 'react-easy-crop';
import { Button, Tooltip } from '@material-ui/core';
import DialogForm from 'components/DialogForm';
import FilePicker from 'components/FilePicker';
import SimpleFormControls from 'components/SimpleFormControls';
import RestoreIcon from '@material-ui/icons/SettingsBackupRestore';

import { getCroppedImg, file2base64 } from './helpers';
import './styles.scss';

const ImagePicker = ({ open, onClose, onChange }) => {
  const intl = useIntl();
  const [acceptedFile, setAcceptedFile] = useState(null);
  const [fullCrop, setFullCrop] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);

  const restoreDefaults = useCallback(() => {
    setAcceptedFile(null);
    setCrop({ x: 0, y: 0 });
    setZoom(1);
  }, []);

  const handleClose = useCallback(() => {
    restoreDefaults();
    onClose();
  }, [restoreDefaults, onClose]);

  const onSave = useCallback(async () => {
    handleClose();
    onChange(acceptedFile
      ? await getCroppedImg(acceptedFile, fullCrop)
      : undefined);
  }, [acceptedFile, fullCrop]);

  return (
    <DialogForm
      open={open}
      title={intl.formatMessage({ id: 'candlePatterns.imagePicker.title' })}
      handleClose={handleClose}
      customClass="image-picker"
      controls={<SimpleFormControls handleCancel={handleClose} handleSave={onSave} />}
      content={acceptedFile ? (
        <>
          <Cropper
            image={acceptedFile}
            crop={crop}
            zoom={zoom}
            maxZoom={10}
            aspect={1}
            zoomSpeed={0.1}
            onCropComplete={(__, crop) => { setFullCrop(crop) }}
            onCropChange={setCrop}
            onZoomChange={setZoom}
          />
          <Tooltip title={intl.formatMessage({ id: 'candlePatterns.imagePicker.restoreTooltip' })}>
            <Button
              variant="outlined"
              className="outlined-borderless-button"
              onClick={restoreDefaults}
            >
              <RestoreIcon />
            </Button>
          </Tooltip>
        </>
      ) : (
        <FilePicker
          onAccept={file => file2base64(file).then(setAcceptedFile)}
          acceptType="image/*"
        />
      )}
    />
  );
};

ImagePicker.propTypes = {
  open: propTypes.bool.isRequired,
  onChange: propTypes.func.isRequired,
  onClose: propTypes.func.isRequired
};

export default ImagePicker;
