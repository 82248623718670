import React from 'react';
import PropTypes from 'prop-types';
import { Field, useField } from 'react-final-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { CustomSwitch } from 'components/Controls';
import { getValues } from 'helpers';
import { EditIcon } from 'components/Svgs';
import DeleteIcon from '@material-ui/icons/Delete';

import { useSwitcher } from 'contexts/pageSwitcher';

import './styles.scss'
import { metaKey } from 'globalConstants';
import { isCustomPattern } from '../utils';

export const CandlePatternTitle = ({ patternName, index, handleDelete, isLogicEditor }) => {
  const switcher = useSwitcher();
  const intl = useIntl();

  const patternField = useField(patternName);
  const pattern = React.useMemo(() => patternField.input.value, [patternField.input.value]);
  const defaultPattern = React.useMemo(() => isCustomPattern(pattern.kind), [pattern.kind]);
  const title = React.useMemo(() => intl.formatMessage({ id: `candlePatterns.${pattern.kind}`, defaultMessage: pattern.title }), [pattern]);

  return (
    <div className="candle-pattern-title">
      { !pattern?.[metaKey]?.withoutToggle
        ? <div className="switch-container">
          <Field
            edge="start"
            type="checkbox"
            name={`${patternName}.enabled`}
            component={CustomSwitch}
          />
        </div> : null
      }
      <h2 className="bold">
        {title}
      </h2>
      <div className="rs-indicator-list__interval">
        <FormattedMessage
          id="description"
          defaultMessage={pattern.description}
          values={getValues(pattern)}
        />
      </div>
      <button
        type="button"
        className="single-icon-btn"
        onClick={() => { switcher.moveTo(1, { pattern, patternIndex: index }) }}
      >
        <EditIcon />
      </button>
      {defaultPattern && isLogicEditor && (
        <button
          type="button"
          className="single-icon-btn"
        >
          <DeleteIcon className='deleteIcon' onClick={handleDelete}/>
        </button>
      )}
    </div>
  );
};

CandlePatternTitle.propTypes = {
  patternName: PropTypes.string,
  isLogicEditor: PropTypes.bool,
  index: PropTypes.number,
  handleDelete: PropTypes.func
};
