import React, { useEffect, useMemo } from 'react';
import { inject, observer } from 'mobx-react';
import propTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';

import TradeHistoryStore from 'store/tradeHistory';
import { compose, downloadToFile } from 'helpers';
import TradeHistoryTable from 'components/TradeHistoryTable';
import TradeHistoryFilter from 'components/TradeHistoryFilter';
import TradeHistorySummary from 'components/TradeHistorySummary';
import { filterConfig, useLogs } from './helpers';
import { HEADER_COLUMNS as headerColumns, ROW_COLUMNS as rowColumns } from './consts';

import './styles.scss';
import { States } from '../../store/tradeHistory/consts';
import TradeHistoryPagination from '../../components/TradeHistoryPagination';

const TradeHistoryReport = ({ tradeStore }) => {
  const { lastFilters, defaultValues, backtest, getLogs, selectFields, paginationState } = useLogs();

  const location = useLocation()

  const reportDownloadEnabled = useMemo(() => (
    !_.isNil(backtest.tradeManagementRuntimeId)
  ), [backtest.tradeManagementRuntimeId])

  const downloadHandler = async () => {
    const id = backtest.tradeManagementRuntimeId
    if (id) {
      const result = await tradeStore.downloadTradeReport(id, lastFilters);
      await filePolling(id, result.taskId)
    }
  };

  const filePolling = async (runtimeId, taskId) => {
    const pkey = tradeStore.getPollingKey(runtimeId) // polling key

    await tradeStore.setPolling(pkey, taskId, async (responseData) => {
      downloadToFile(responseData, `${backtest.name}.xlsx`, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
    })
  }

  const setupPolling = async () => {
    const pkey = tradeStore.getPollingKey(backtest.tradeManagementRuntimeId)
    const taskId = tradeStore.getPolling(pkey)

    if (taskId) {
      await filePolling(backtest.tradeManagementRuntimeId, taskId)
    }
  }

  const filters = React.useMemo(() => {
    const cachedFilters = JSON.parse(localStorage.getItem('trades_filters'.concat(location.pathname)))
    return cachedFilters || defaultValues
  }, []);

  useEffect(() => {
    setupPolling()
    return () => tradeStore.stopPolling()
  }, [backtest])

  useEffect(() => {
    getLogs({ values: filters })

    return () => {
      localStorage.removeItem('trades_filters'.concat(location.pathname))
    }
  }, [])

  return (
    <div className="form1 trade-history">
      <div className="form__card1">
        <div className="title2 bold trade-history__title">
          <FormattedMessage
            id='trade.history.trade-report'
            values={{ name: backtest?.name }}
          />
        </div>
        <TradeHistorySummary tradeHistoryData={tradeStore.tradeHistoryReportData} />
        <TradeHistoryFilter config={{ ...filterConfig, selectFields, initialValues: filters }} getLogs={getLogs} downloading={tradeStore.downloading} reportDownloadEnabled={reportDownloadEnabled} downloadHandler={downloadHandler} />
        <TradeHistoryTable headerColumns={headerColumns} rowColumns={rowColumns} data={tradeStore.tradeHistoryReportData} isStickyHeader />
        <TradeHistoryPagination data={tradeStore.tradeHistoryReportData} paginationState={paginationState} isLoading={tradeStore.state === States.Loading} />
      </div>
    </div>
  );
};

TradeHistoryReport.propTypes = {
  tradeStore: propTypes.instanceOf(TradeHistoryStore),
};

const decorator = compose(
  observer,
  inject(stores => ({
    tradeStore: stores.tradeHistoryStore,
  })),
);

export default decorator(TradeHistoryReport);
