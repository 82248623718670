import _ from 'lodash';
import * as providers from 'providers/apiHistoryProvider';
import { makeAutoObservable } from 'mobx';
import { States } from 'globalConstants';

export default class ArmingLineStorePerPosition {
  historyData = [];
  state = States.Pending;

  constructor (root, params) {
    makeAutoObservable(this);
    this.root = root;
    this.params = params;
  }

  * fetchData () {
    try {
      this.clear();
      this.setParams(this.root.root.rsi.boundaries);

      if (!_.isNil(this.params.begin) && !_.isNil(this.params.end)) {
        this.setState(States.Loading);
        const { data } = yield providers.armingLine(this.params);
        if (data.length) {
          const lines = data.map(line => ({ ...line, ...line.value }));
          this.historyData.replace(this.preProcess(lines));
        }
        this.setState(States.Done);
      }
    } catch (e) {
      console.log(e)
      this.setState(States.Error);
    }
  }

  preProcess (data) {
    return data.map(row => ({
      ...row,
      type: this.params.position,
      position: this.params.position,
      isActualTAL: row.is_order_opened,
      isDashed: !row.is_order_log,
    }))
  }

  clear () {
    this.historyData.clear();
  }

  setState (value) {
    this.state = value;
  }

  setParams (params) {
    Object.assign(this.params, params);
  }
}
