import React, { useMemo, useState } from 'react';
import _ from 'lodash';

import { Field, useField } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { FormattedMessage } from 'react-intl';
import { EditIcon } from 'components/Svgs';

import DialogWindow from 'components/DialogWindow';
import TimeFrameList from 'components/TimeFrame';
import classNames from 'classnames';

const CandlePatternTimeframeRow = () => {
  const [open, setOpen] = useState(false);

  const entrySystem = useField('entrySystemConditions');

  const currentType = useMemo(() => {
    const enabledTolerance = entrySystem.input.value.find(e => e.enabled);

    return ['candle_pattern', 'candle_close'].find(t => enabledTolerance.value[t].enabled)
  }, [entrySystem]);

  const disabled = useMemo(() => currentType === 'candle_close', [currentType])

  return (
    <>
      <div className={classNames('rs-indicator-list__item', { disabled })}>
        <p style={{ maxWidth: 225 }}>
          <FormattedMessage id="entry_system.conditions.candle_pattern_timeframe" />
        </p>
        <div className="rs-indicator-list__interval">
          <div className="rs-indicator-list__interval">
            <Field name="candlePatternTimeframe">
              {({ input }) => _.find(input.value, 'enabled').label}
            </Field>
          </div>
        </div>
        <button
          type="button"
          className="single-icon-btn"
          onClick={() => setOpen(true)}
          disabled={disabled}
        >
          <EditIcon />
        </button>
      </div>

      <DialogWindow
        open={open}
        onClose={() => { setOpen(false) }}
        title={<FormattedMessage id="entry_system.conditions.candle_pattern_timeframe" />}
        description={
          <FieldArray
            isRadio
            component={TimeFrameList}
            name="candlePatternTimeframe"
          />
        }
      />
    </>
  )
};

export default CandlePatternTimeframeRow
