import React, { createContext, useContext, useState, useCallback } from 'react';
import DialogWindow from 'components/DialogWindow';
import propTypes from 'prop-types';

export const ConfirmContext = createContext();
export const useConfirmation = () => useContext(ConfirmContext);

// Provide hook for confirmation context
export const ConfirmProvider = ({ children }) => {
  const [state, setState] = useState({ open: false });
  const [callback, setCallback] = useState(null);

  const confirm = ({ title, description, error = false }) => {
    setState({ title, description, error, open: true });
    return new Promise(resolve => { setCallback(() => resolve) });
  };

  const close = useCallback(result => () => {
    setState(old => ({ ...old, open: false }));
    if (callback) {
      callback(result);
    }
  }, [callback]);

  return (
    <ConfirmContext.Provider value={{ confirm }}>
      <DialogWindow
        error={state.error}
        title={state.title}
        description={state.description}
        open={state.open}
        onClose={close('cancel')}
        confirmHandler={close('yes')}
        handleClose={close('no')}
      />
      {children}
    </ConfirmContext.Provider>
  );
};

ConfirmProvider.propTypes = {
  children: propTypes.node.isRequired
};
