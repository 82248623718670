import { computed, makeObservable } from 'mobx';
import _ from 'lodash';

const positionsMap = {
  long: 'below',
  short: 'above'
};

const hardDisarmConditions = {
  long: [
    'rsi_closed_above_disarm',
    'rsi_time_out_disarm_trough_1',
    'rsi_time_out_disarm_trough_2',
    'trough_2_rsi_line_closed_above_disarm',
    'rsi_trade_arming_support_line_extension_disarm'
  ],
  short: [
    'rsi_closed_below_disarm',
    'rsi_time_out_disarm_peak_1',
    'rsi_time_out_disarm_peak_2',
    'peak_2_rsi_line_closed_below_disarm',
    'rsi_trade_arming_resistance_line_extension_disarm'
  ],
}

export default class TrendDisarmStore {
  logsRsiLongStore = null;
  logsRsiShortStore = null;

  constructor (root) {
    this.chartStore = root;
    makeObservable(this, {
      forEChart: computed,
      logs: computed,
      rsiLevels: computed,
    });
  };

  get forEChart () {
    const logs = this.filterLogsForTrendDisarm(this.logs);
    const rsiBoundaries = this.chartStore.rsi.boundaries;
    if (logs.length === 0) return [];

    let trendDisarmBoxesData = logs.map(logArray => {
      const { timestamp: start, conditionNumber, position } = logArray[0];
      const end = logArray[logArray.length - 1].timestamp;

      if (!rsiBoundaries.begin ||
        !rsiBoundaries.end ||
        start < rsiBoundaries.begin ||
        end > rsiBoundaries.end
      ) return;

      const conditionLevels = this.rsiLevels[position][conditionNumber];
      return {
        start,
        end,
        rsiLevel: conditionLevels.rsi_level,
        rsiResetLevel1: conditionLevels.rsi_reset_level1,
        rsiResetLevel2: conditionLevels.rsi_reset_level2,
      };
    });
    trendDisarmBoxesData = trendDisarmBoxesData.filter(Boolean);
    return trendDisarmBoxesData;
  };

  get logs () {
    // provides logs data, which contains RSI Trend Disarm info
    this.logsRsiLongStore = this.chartStore.root.logsRsiLongStore;
    this.logsRsiShortStore = this.chartStore.root.logsRsiShortStore;

    const formattedLogs = {
      long: this.logsRsiLongStore.allData,
      short: this.logsRsiShortStore.allData
    };
    return formattedLogs;
  }

  get rsiLevels () {
    return {
      long: this.logsRsiLongStore.paramsStates.rsi_trend_disarm_below,
      short: this.logsRsiShortStore.paramsStates.rsi_trend_disarm_above,
    };
  };

  dropIndependentConditionsSteps = independentConditions => {
    Object.keys(independentConditions).forEach(i => {
      independentConditions[i] = [];
    });
  };

  filterLogsForTrendDisarm = logs => {
    const readyToDisplayConditions = [];
    Object.entries(logs).forEach(([position, positionLogs]) => {
      const independentConditions = { 0: [], 1: [], 2: [] };
      [...positionLogs].reverse().forEach((log, logIndex) => {
        if (hardDisarmConditions[position].includes(log.condition)) {
          this.dropIndependentConditionsSteps(independentConditions);
          return;
        }

        const conditionRegex = this.formatCondition(position, log.condition);
        if (!conditionRegex) return;
        const [action, index] = conditionRegex;

        if (!independentConditions[index]) return;

        if (action === 'ws_is_restarted') return;

        if (action === 'tal_went_range') {
          independentConditions[index].length = 0;
          return;
        }

        if (action === 'ws_is_stopped') {
          this.dropIndependentConditionsSteps(independentConditions);
          return;
        }

        const pointFoundNumber = +action[0];
        if (Number.isInteger(pointFoundNumber) &&
            independentConditions[index].length === pointFoundNumber - 1
        ) {
          independentConditions[index].push({
            id: log.id,
            position: log.position,
            timestamp: log.candle_timestamp,
            conditionNumber: index,
          });
          if (independentConditions[index].length === 3) {
            readyToDisplayConditions.push(_.cloneDeep(independentConditions[index]));
          }
        }

        // add remaining not stopped data for displaying
        if (logIndex === positionLogs.length - 1) {
          Object.values(independentConditions).forEach(item => {
            const historyData = this.chartStore.rsi.history;
            const lastChartTimestamp = historyData[historyData.length - 1]?.timestamp;
            if (item.length > 1 && lastChartTimestamp) {
              item[item.length - 1].timestamp = lastChartTimestamp;
              readyToDisplayConditions.push(item);
            }
          });
        }
      });
    });
    return readyToDisplayConditions;
  };

  // The RegExp to define which condition matches our expectations
  formatCondition = (position, condition) => {
    const conditionArr = condition.match(
      new RegExp(`^rsi_trend_disarm_${positionsMap[position]}_(\\w+)_([0-2])$`)
    );
    if (!conditionArr) return null;
    conditionArr.shift();
    return conditionArr; // Example: ['1st_point_found', '0']
  };
}
