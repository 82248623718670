import React, { useState, useEffect, useMemo } from 'react';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import { Collapse, IconButton } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import { Filter } from 'components/Controls';
import { POSITIONS } from './consts';
import LogsTable from './LogsTable';
import TimeframeDropdown from 'containers/Modules/RunModule/Controls/TimeframeDropdown';
import { downloadToFile } from 'helpers';

import { useLogsRetrieve, useScrolling } from './hooks';

import './styles.scss';

const LogsPanel = observer(({ logs, module, controls, setControls }) => {
  const positions = useMemo(() => (
    POSITIONS.map(pos => ({ disabled: !pos.isEnabled(module, controls), ...pos }))
  ), [controls.stochPeriod, controls.rsiPeriod]);

  const positionMap = _.groupBy(positions, ({ value: v }) => v.slice(0, v.indexOf('_')).toLowerCase());
  const [position, setPosition] = useState(positions.find(pos => !pos.disabled));
  const [isCollapsed, setIsCollapsed] = useState(false);

  const downloadHandler = async () => {
    const response = await logs[position.value].downloadLogs(module.id);
    downloadToFile(
      response.data,
      `${module.name} - logs.xlsx`,
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    )
  };

  useLogsRetrieve(module, controls, logs, positionMap);

  useEffect(() => {
    const currentPos = positions.find(pos => pos.value === position.value);
    if (currentPos?.disabled) {
      setPosition(positions.find(pos => !pos.disabled));
    }
  }, [positions, position]);

  const toggleCollapse = () => {
    setIsCollapsed(old => !old);
  };

  const { scrollPositions, changeScroll } = useScrolling(position, positions, logs);

  const timeframeSelectorType = useMemo(() => {
    if (position.value.startsWith('STOCH')) return 'stoch';
    if (/^(RSI|CANDLE)/.test(position.value)) return 'rsi';
  }, [position.value]);

  const tfs = useMemo(() => {
    if (position.value.startsWith('STOCH')) {
      return module.clientModuleParams.stochTimeframes.filter(tf => tf.type.includes(position.kind));
    }
    if (/^(RSI|CANDLE)/.test(position.value)) {
      return module.clientModuleParams.rsiTimeframes;
    }
    if (position.value.startsWith('WHOLE')) {
      return {
        stoch_prearming: _(module.clientModuleParams.stochTimeframes).keyBy('value').mapValues('label').value(),
        rsi_trigger: _(module.clientModuleParams.rsiTimeframes).keyBy('value').mapValues('label').value(),
        stoch_trigger: _(module.clientModuleParams.stochTimeframes).keyBy('value').mapValues('label').value(),
        candle_close: _(module.clientModuleParams.rsiTimeframes).keyBy('value').mapValues('label').value(),
        candle_pattern: _(module.clientModuleParams.rsiTimeframes).keyBy('value').mapValues('label').value(),
        candlestick_chart: _(module.clientModuleParams.candlePatternTimeframe).keyBy('value').mapValues('label').value(),
      }
    }
  }, [position.value]);

  return (
    <div className="logs-panel">
      <div className="logs-panel__top-bar">
        <div className="logs-panel__top-bar__button-container">
          <Filter
            handleChange={(name, value) => {
              if (!isCollapsed || position.value === value) {
                toggleCollapse()
              }
              setPosition(POSITIONS.find(pos => pos.value === value));
            }}
            type="position"
            items={positions}
            value={position.value}
          />
          {_.isArray(tfs) && (
            <TimeframeDropdown
              tfs={tfs}
              label='timeframe'
              controls={controls}
              onChange={(name, val) => { setControls(old => ({ ...old, [name]: val })) }}
              attr={timeframeSelectorType}
            />
          )}
        </div>
        <div className="logs-panel__top-bar__controls">
          <IconButton
            onClick={downloadHandler}
            color="inherit"
            size="medium"
          >
            <GetAppIcon fontSize="inherit" />
          </IconButton>
          <button onClick={toggleCollapse} className="logs-panel__toggle">
            {isCollapsed ? (
              <svg width="22" height="2" viewBox="0 0 22 2" fill="none">
                <path d="M0 1H11H22" stroke="currentColor" strokeWidth="2" />
              </svg>
            ) : (
              <svg width="24" height="11" viewBox="0 0 24 11" fill="none">
                <path
                  d="M23 1.5L12 9L1 1.5"
                  stroke="currentColor"
                  strokeWidth="2"
                />
              </svg>
            )}
          </button>
        </div>
      </div>
      <Collapse in={isCollapsed}>
        <LogsTable
          store={logs[position.value]}
          isLoading={logs[position.value].isLoading}
          scroll={scrollPositions[position.value]}
          changeScroll={changeScroll(position.value)}
          position={position}
          module={module}
          controls={controls}
          tfs={tfs}
        />
      </Collapse>
    </div>
  );
});

const decorator = inject(stores => ({
  logs: {
    STOCH_SHORT_PREARMING: stores.logsStochShortPrearmingStore,
    STOCH_LONG_PREARMING: stores.logsStochLongPrearmingStore,
    RSI_LONG: stores.logsRsiLongStore,
    RSI_SHORT: stores.logsRsiShortStore,
    CANDLE_CLOSE_SHORT: stores.logsCandleCloseShortStore,
    CANDLE_CLOSE_LONG: stores.logsCandleCloseLongStore,
    WHOLE_SEQUENCE_LONG: stores.wholeSequenceLogsStore.long,
    WHOLE_SEQUENCE_SHORT: stores.wholeSequenceLogsStore.short,
  }
}));

export default decorator(LogsPanel);
