import { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { MobXProviderContext } from 'mobx-react';
// eslint-disable-next-line no-unused-vars
import stores from 'store/rootStore';

export { useTimezone } from 'contexts/timezone/provider';

/** @type {() => typeof stores} */
export const useStore = () => useContext(MobXProviderContext);

export function useLayoutMemo (memoFunc, dependencies) {
  const [memo, setMemo] = useState(null);
  useLayoutEffect(() => { setMemo(memoFunc()); }, dependencies);
  return memo;
}

export function useScrollToTop () {
  useEffect(() => {
    document.querySelector('.main-wrapper__content-wrap').scroll(0, 0);
  }, []);
};
