// rigth/left gaps of plot calculated in tf
export const BOUNDARY_GAP = 20;

export const rsi = {
  lineStyle: {
    width: 1,
    color: '#159415'
  },
  ribbon: {
    min: 30,
    max: 70,
    color: '#6d3c9766',
    minColor: '#f006',
    maxColor: '#0f06',
  },
};

export const stoch = {
  kLineStyle: {
    width: 1,
    color: '#318EE8'
  },
  dLineStyle: {
    width: 1,
    color: '#ED8011'
  },
  ribbon: {
    min: 20,
    max: 80,
    color: '#6d3c9766',
    minColor: '#8888',
    maxColor: '#8888',
  }
};

export const armingLines = {
  lineStyle: ({ type, isActualTAL, isDashed }) => {
    const defaultClr = '#2c17b1'
    const activeClr = {
      long: '#ff8a00',
      short: '#faff00'
    }

    return ({
      width: 1,
      color: isActualTAL || isDashed ? activeClr[type] : defaultClr,
      type: isDashed ? 8 : 'solid',
    })
  }
};

export const candles = {
  firstColor: '#eb5454',
  secondColor: '#47b262'
};

export const candle = {
  color: '#47b262',
  color0: '#eb5454',
  borderColor: '#47B262',
  borderColor0: '#eb5454',
};

export const patternsHighlight = {
  red: '#7e3442',
  green: '#1e4432',
};

export const trendDisarm = {
  box: {
    color: 'rgb(84, 26, 40)',
    borderColor: 'rgb(139, 93, 26)',
    borderWidth: 1,
    borderType: 'solid',
    opacity: 0.7,
  },
  line: { color: '#fff', width: 1 },
  label: {
    rsiLevel: {
      show: true,
      position: 'middle',
      fontSize: 10,
      color: '#fff',
    },
    rsiResetLevel: {
      show: true,
      fontSize: 9,
      color: '#fff',
    },
  }
};
