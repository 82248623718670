/**
 * A module to split up bigger globalConstants file
 */

import {
  DEFAULT_MODULE_NAME,
  DEFAULT_K_PERIOD,
  DEFAULT_SLOWING_PERIOD,
  DEFAULT_D_PERIOD,
  DEFAULT_RSI_PERIOD_VALUE,
  DEFAULT_TRADE_SIZE,
  DEFAULT_STOP,
  DEFAULT_TARGETS,
  DEFAULT_POINTS,
  DEFAULT_PROFIT_TARGETS,
  DEFAULT_TRADE_EXECUTION,
  STOCH_TIMEFRAMES,
  RSI_TIMEFRAMES,
  CANDLE_PATTERN_TIMEFRAMES,
  CONDITIONS_DEFAULTS,
  DEFAULT_ENTRY_SYSTEM_CONDITIONS,
} from './globalConstants';
import { DEFAULT_INSTRUMENT } from './tradeManagement';
// A temp stub to retain all previous exports (even if they're not used outside of this module)
export * from './globalConstants';
export * from './meta';

export const INITIAL_VALUES = {
  moduleName: DEFAULT_MODULE_NAME,
  kPeriod: DEFAULT_K_PERIOD,
  slowingPeriod: DEFAULT_SLOWING_PERIOD,
  dPeriod: DEFAULT_D_PERIOD,
  rsiPeriodValue: DEFAULT_RSI_PERIOD_VALUE,
  stochTimeframes: {
    prearming: STOCH_TIMEFRAMES,
    trigger: STOCH_TIMEFRAMES
  },
  rsiTimeframes: RSI_TIMEFRAMES,
  rsiConditions: { tf120: CONDITIONS_DEFAULTS.rsi },
  stochConditions: {
    prearming: { tf600: CONDITIONS_DEFAULTS.stoch.prearming },
    trigger: { tf600: CONDITIONS_DEFAULTS.stoch.trigger }
  },
  entrySystemConditions: DEFAULT_ENTRY_SYSTEM_CONDITIONS,
  instrument: DEFAULT_INSTRUMENT,
  tradeManagement: {
    profit_targets: DEFAULT_PROFIT_TARGETS,
    stop_loss: DEFAULT_STOP,
    to_entry_on_targets: DEFAULT_TARGETS,
    to_entry_on_points: DEFAULT_POINTS,
    multiple_trade_execution: DEFAULT_TRADE_EXECUTION,
    trade_size: DEFAULT_TRADE_SIZE,
  },
  candlePatternTimeframe: CANDLE_PATTERN_TIMEFRAMES,
};

export const States = Object.freeze({
  Pending: 0,
  LoadingNewPage: 1,
  Loading: 2,
  Error: 3,
  Done: 4,
  Refresh: 5,
  WaitingReady: 6
});

export const DATETIME_FORMAT = 'dd.MM.yyyy HH:mm';
export const DATE_HISTORY_FORMAT = 'dd.MM.yyyy';
export const ANGLE_PRECISION = 2;
export const COMMON_PRECISION = 2;
export const PRICE_OF_POINTS = 0.00001;

export const priceOfPoints = (instrument) => {
  return {
    eur_jpy: 0.001
  }[instrument] || PRICE_OF_POINTS;
}

export const DEFAULT_COUNT = 2000;
