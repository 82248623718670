import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import DialogForm from 'components/DialogForm';
import { Field } from 'react-final-form';
import { FormControlLabel, RadioGroup } from '@material-ui/core';
import { CustomRadio } from 'components/Controls';

import '../styles.scss';
import SimpleFormControls from 'components/SimpleFormControls';
import { useStore } from 'hooks';

const InstrumentPickerForm = ({ open, handleClose, showButton, handleSave }) => {
  const { instrumentsStore } = useStore();
  const content = useMemo(() => (
    <form className="condition-form-content">
      <div className="form">
        <RadioGroup className="time-frame-form">
          {instrumentsStore.instruments.map(instrument => (
            <div key={instrument.id} className="radio-item">
              <FormControlLabel
                control={
                  <Field
                    name="instrument"
                    type="radio"
                    value={instrument.id}
                    component={CustomRadio}
                  />
                }
                label={<FormattedMessage id={instrument.label} />}
              />
            </div>
          ))}
        </RadioGroup>
      </div>

      {
        showButton
          ? <SimpleFormControls showButton={true} handleCancel={handleClose} handleSave={handleSave} />
          : null
      }
    </form>
  ), [open]);

  return (
    <DialogForm
      open={open}
      title={<FormattedMessage id="entry_system.conditions.instrument" />}
      content={content}
      handleClose={handleClose}
      customClass="condition-form-popup"
    />
  );
}

InstrumentPickerForm.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  showButton: PropTypes.func,
  handleSave: PropTypes.func
};

export default InstrumentPickerForm;
