import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import TextField from '@material-ui/core/TextField';

import './styles.scss';

const Input = ({ isSmallField, input, meta, withHelper, ...props }) => {
  const customProps = Object.assign({
    error: meta?.invalid
  }, props, input);
  if (withHelper) customProps.helperText = meta?.error;

  return (
    <TextField
      className={classNames('custom-input', {
        'custom-input--small': isSmallField
      })}
      {...customProps}
    />
  );
};

Input.propTypes = {
  isSmallField: PropTypes.bool,
  withHelper: PropTypes.bool,
  input: PropTypes.object,
  meta: PropTypes.shape({
    invalid: PropTypes.bool,
    error: PropTypes.node
  })
};

export default Input;
