import React from 'react';
import propTypes from 'prop-types';
import { Field, Form, FormSpy } from 'react-final-form';

import { CustomSelect } from 'components/Fields';

import './styles.scss';

const ModulesFilters = ({ getModules, isLoading, config: { initialValues, selectFields } }) => {
  return (
    <div className="modules-filter">
      <Form
        onSubmit={() => { }}
        initialValues={initialValues}
        render={() => (
          <form className="modules-filter-form">

            {selectFields?.map(({ label, name, items, disabled }) => {
              return (
                <div className="modules-filter__block" key={name}>
                  <div className="modules-filter__label bold">{label}</div>

                  <Field
                    disabled={isLoading || disabled}
                    name={name}
                    component={CustomSelect}
                    items={items}
                  />
                </div>
              )
            })}
            <FormSpy
              subscription={{ values: true }}
              onChange={getModules}
            />
          </form>
        )}
      >
      </Form>
    </div>
  );
};

ModulesFilters.propTypes = {
  config: propTypes.object,
  getModules: propTypes.func,
  isLoading: propTypes.bool
};

export default ModulesFilters;
