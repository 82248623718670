import _ from 'lodash';
import { numberFormatter } from 'helpers';

const checkIsValidValue = ({ value }) => {
  if (!value && typeof value !== 'number') {
    return '-';
  }
}

const formatters = {
  currency: ({ value }) => checkIsValidValue({ value }) || `$ ${numberFormatter(2).format(value)}`,
  percent: ({ value }) => checkIsValidValue({ value }) || `${_.round((value), 2)} %`,
  simple: ({ value }) => checkIsValidValue({ value }) || value,
}

export const SUMMARY_ROWS = [
  { attribute: 'returnRate', formatter: formatters.percent },
  { attribute: 'drawdown', formatter: formatters.percent },
  { attribute: 'grossProfit', formatter: formatters.currency },
  { attribute: 'grossLoss', formatter: formatters.currency },
  { attribute: 'netProfit', formatter: formatters.currency },
  { attribute: 'netLoss', formatter: formatters.currency },
  { attribute: 'fundAmount', formatter: formatters.currency },
  {
    attribute: 'totalTrades',
    formatter: formatters.simple,
    additionalParams: [
      { attribute: 'percentageOfWins', fieldName: 'won', formatter: formatters.percent },
      { attribute: 'percentageOfLosses', fieldName: 'lost', formatter: formatters.percent },
      { attribute: 'percentageOfBreakEvens', fieldName: 'breakEven', formatter: formatters.percent },
    ]
  },
  { attribute: 'totalWins', formatter: formatters.simple },
  { attribute: 'totalLosses', formatter: formatters.simple },
  { attribute: 'totalBreakEvens', formatter: formatters.simple },
  {
    attribute: 'totalShortPositions',
    formatter: formatters.simple,
    additionalParams: [
      { attribute: 'percentageOfShortWins', fieldName: 'won', formatter: formatters.percent },
      { attribute: 'percentageOfShortLosses', fieldName: 'lost', formatter: formatters.percent },
      { attribute: 'percentageOfShortBreakEvens', fieldName: 'breakEven', formatter: formatters.percent },
    ]
  },
  {
    attribute: 'totalLongPositions',
    formatter: formatters.simple,
    additionalParams: [
      { attribute: 'percentageOfLongWins', fieldName: 'won', formatter: formatters.percent },
      { attribute: 'percentageOfLongLosses', fieldName: 'lost', formatter: formatters.percent },
      { attribute: 'percentageOfLongBreakEvens', fieldName: 'breakEven', formatter: formatters.percent },
    ]
  },
  { attribute: 'largestProfitTrade', formatter: formatters.currency },
  { attribute: 'largestLossTrade', formatter: formatters.currency },
  {
    attribute: 'maxConsecutiveWins',
    formatter: formatters.simple,
    additionalParams: [
      { attribute: 'consecutiveProfitTotalAmout', fieldName: 'total', formatter: formatters.currency },
    ]
  },
  {
    attribute: 'maxConsecutiveLosses',
    formatter: formatters.simple,
    additionalParams: [
      { attribute: 'consecutiveLossTotalAmout', fieldName: 'total', formatter: formatters.currency },
    ]
  },
];
