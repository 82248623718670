import { defaultHeaderColumnClasses, globalClassNames, globalFormatters } from 'globalConstants/formatters';

const {
  identity,
  datetime,
  instrument,
  fixedCurrency,
  fixed,
  fixedPercent,
  intl,
  pattern,
  notionalValue,
} = globalFormatters;

const {
  center,
  pl,
  position,
  breakSpaces,
} = globalClassNames;

export const ROW_COLUMNS = [
  { accessor: 'index', formatter: identity, className: center },
  { accessor: 'candleOpenDatetime', formatter: datetime, className: center },
  // { accessor: 'candleCloseDatetime', formatter: datetime, className: center },
  { accessor: 'actualOpenDatetime', formatter: datetime, className: center },
  { accessor: 'actualCloseDatetime', formatter: datetime, className: center },
  { accessor: 'instrument', formatter: instrument, className: center },
  { accessor: 'size', formatter: fixedCurrency(), className: center },
  { accessor: 'notionalValue', formatter: notionalValue, className: center },
  { accessor: 'openPrice', formatter: fixed(6), className: center },
  { accessor: 'exitPrice', formatter: fixed(6), className: center },
  { accessor: 'pl', formatter: fixedCurrency(), className: pl },
  { accessor: 'fundAmount', formatter: fixedCurrency(), className: center },
  { accessor: 'returnRate', formatter: fixedPercent(), className: center },
  { accessor: 'position', formatter: identity, className: position },
  { accessor: 'moduleName', formatter: identity, className: center },
  { accessor: 'entrySystem', formatter: intl, className: center },
  { accessor: 'triggerSystem', formatter: intl, className: center },
  { accessor: 'triggerPatterns', formatter: pattern, className: breakSpaces },
];

export const HEADER_COLUMNS = [
  { classes: defaultHeaderColumnClasses, attribute: 'index' },
  { classes: defaultHeaderColumnClasses, attribute: 'candle-open-datetime' },
  // { classes: defaultHeaderColumnClasses, attribute: 'candle-close-datetime' },
  { classes: defaultHeaderColumnClasses, attribute: 'actual-open-datetime' },
  { classes: defaultHeaderColumnClasses, attribute: 'actual-close-datetime' },
  { classes: defaultHeaderColumnClasses, attribute: 'instrument' },
  { classes: defaultHeaderColumnClasses, attribute: 'size' },
  { classes: defaultHeaderColumnClasses, attribute: 'notional-value' },
  { classes: defaultHeaderColumnClasses, attribute: 'open-price' },
  { classes: defaultHeaderColumnClasses, attribute: 'exit-price' },
  { classes: defaultHeaderColumnClasses, attribute: 'p&l' },
  { classes: defaultHeaderColumnClasses, attribute: 'fund-amount' },
  { classes: defaultHeaderColumnClasses, attribute: 'return' },
  { classes: defaultHeaderColumnClasses, attribute: 'position' },
  { classes: defaultHeaderColumnClasses, attribute: 'module' },
  { classes: defaultHeaderColumnClasses, attribute: 'entry-system' },
  { classes: defaultHeaderColumnClasses, attribute: 'trigger-system' },
  { classes: defaultHeaderColumnClasses, attribute: 'pattern' }
];
