import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Wizard from 'containers/Wizard';
import { useHistory, useParams } from 'react-router-dom';
import { NotificationContext } from 'contexts/notification';
import Loader from 'components/Loader';
import { useIntl } from 'react-intl';
import { observer, inject } from 'mobx-react';
import { putNewCustomPatterns } from './utils';
import { syncEntrySystemTripleLines } from '../helpers';

const EditModule = observer(({ store, logicEditorStore }) => {
  const { id } = useParams();
  const intl = useIntl();
  const history = useHistory();
  const notification = React.useContext(NotificationContext);
  const [module, setModule] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    const getModule = async () => {
      const module = await store.getModule(id, true);

      await logicEditorStore.getPatternDefaultsList();
      const defaultPatterns = logicEditorStore.currentPatternDefaultList;
      const moduleES = _.keyBy(module.entrySystemConditions, 'kind');

      // find not included custom pattern and append as disabled
      for (const entrySystem of ['triple_close', 'triple_tolerance']) {
        for (const position of ['long', 'short']) {
          putNewCustomPatterns(
            defaultPatterns[entrySystem][position].candle_pattern.value,
            moduleES[`${position}_${entrySystem}`].value.candle_pattern.value
          );
        }
      }

      setModule(module);
    };
    getModule();
  }, [id, store]);

  const onSubmit = async values => {
    try {
      setIsLoading(true);
      const payload = syncEntrySystemTripleLines(values)
      const response = await store.putModule(id, payload);
      const moduleName = response.data.name;
      const title = intl.formatMessage({
        id: 'notification.title.moduleUpdated'
      });
      const text = intl.formatMessage({
        id: 'notification.text.moduleUpdated'
      }, { moduleName });
      notification.show(title, text);
    } catch {}
    history.push('/modules/saved');
  };

  if (isLoading || _.isEmpty(module) || store.isLoading) {
    return <Loader />;
  };

  return (
    <Wizard initialValues={module} onSubmit={onSubmit} isEdit />
  );
});

EditModule.propTypes = {
  store: PropTypes.object
};

const decorator = inject(stores => ({
  store: stores.moduleStore,
  logicEditorStore: stores.logicEditorStore
}));

export default decorator(EditModule);
