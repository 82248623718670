import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { DEFAULT_COLUMNS as columns } from 'components/ModulesList/consts';
import CustomCheckbox from 'components/Controls/CustomCheckbox';

import './styles.css';

const BackTestCopyModulesList = ({ rows, onSelectModule, selectedModuleList }) => {
  const isSelected = (id) => selectedModuleList.some((module) => module.id === id);

  return (
    <div className="back-test-copy-modules-list">
      <table className="app-table">
        <thead className="app-table__head">
          <tr>
            {columns.map(({ attribute, classes }, index) => (
              <th key={index} className={classes}>
                <FormattedMessage id={`modules.savedModules.${attribute}`} defaultMessage=" " />
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="app-table__content">
          {rows.map((module) => (
            <tr className="app-table__row" key={module.id}>
              {columns.slice(0, -1).map(({ classes, attribute }) => ( //
                <td key={attribute} className={classes}>{module[attribute]}</td>
              ))}

              <td className="right">
                <div className="app-table__actions">
                  <CustomCheckbox
                    disabled={false}
                    onChange={() => onSelectModule(module)}
                    name={module.id}
                    checked={isSelected(module.id)}
                  />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

BackTestCopyModulesList.propTypes = {
  rows: PropTypes.array,
  onSelectModule: PropTypes.func,
  selectedModuleList: PropTypes.array,
};

export default BackTestCopyModulesList;
