import React, { useMemo, useCallback, useState } from 'react';
import _ from 'lodash';
import { useSwitcher } from 'contexts/pageSwitcher';
import { Field, Form, useField } from 'react-final-form';
import { FormattedMessage, useIntl } from 'react-intl';
import SimpleFormControls from 'components/SimpleFormControls';
import { CandlePatternSettings } from '../CandlePatternSettings';
import { CandlePatternRelations } from '../CandlePatternRelations';
import { Delete } from '@material-ui/icons';
import Dependency from 'containers/Modules/General/EntrySystem/Patterns/Common/Dependency';
import PropTypes from 'prop-types';

import './styles.scss';
import { EditorColours } from './EditorColours';
import Parameter from '../../EntrySystem/Patterns/Common/Parameter';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import {
  maxCandleRuleCount,
  DEFAULT_CANDLE_PATTERN_RULE,
  minCandleRuleCount
} from 'globalConstants/candlePatterns';
import { Button, Icon } from '@material-ui/core';
import createDecorator from 'final-form-calculate';
import { Input } from 'components/Fields';
import { getListOfTitles } from 'helpers';
import { validatePatternTitle } from 'helpers/validators';
import { isCustomPattern } from '../utils';
import ImagePicker from './ImagePicker';
import ImageTwoToneIcon from '@material-ui/icons/ImageTwoTone';

const CandleEditor = ({ name, index, candlesField }) => {
  const candleField = useField(name);
  const relationsField = useField('value.relations');
  const mapping = useMemo(() => (
    Object.fromEntries(
      candleField.input.value.value
        .map((prop, index) => [prop.kind, { ...prop, index }])
    )
  ));

  const deleteDisabled = React.useMemo(() => (
    candlesField.length <= minCandleRuleCount || // leave at least `minCandleRuleCount`
    candlesField.value.some(c => c.same_as === index) || // not referenced by candles
    relationsField.input.value.some(r => ( // not referenced by relations
      [r.candle_from, r.candle_to].includes(index + 1)
    ))
  ), [candlesField, relationsField.input, index]);

  return (
    <div className="candle-editor">
      <div className="title2 bold heading">
        <FormattedMessage
          id="module.candlePatterns.candleTitle"
          values={{ number: index + 1 }}
        />
        <button
          className="single-icon-btn single-icon-btn"
          disabled={deleteDisabled}
          onClick={() => candlesField.remove(index)}
          type="button"
        >
          <Delete fontSize="large" />
        </button>
      </div>
      <EditorColours index={index} />
      <div className="title3">
        <FormattedMessage id="candlePatterns.dependenciesInsideTheCandle" />
      </div>
      <Dependency prefix={`${name}.value[${mapping.dependency.index}]`} />
      <div className="parameters">
        <div className="title3">
          <FormattedMessage id="candlePatterns.candleParameters" />
        </div>
        <div className="fields">
          <Parameter paramsName={`${name}.value`} param={mapping.high_length} />
          <Parameter paramsName={`${name}.value`} param={mapping.candle_length} />
          <Parameter paramsName={`${name}.value`} param={mapping.low_length} />
        </div>
      </div>
    </div>
  );
};

CandleEditor.propTypes = {
  name: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  candlesField: PropTypes.object
};

const decorator = createDecorator({
  // follow candles index number in relations & candles on candle deletion
  field: /^value\.candles$/,
  isEqual: (a, b) => a?.length === b?.length,
  updates: (value, name, allValues, prevValues) => {
    const currCandles = allValues?.value?.candles;
    const prevCandles = prevValues?.value?.candles;
    if (!currCandles || !prevCandles || currCandles.length >= prevCandles.length) return {};
    const deletedIndex = _.zip(currCandles, prevCandles).findIndex(([c1, c2]) => c1 !== c2);
    return {
      'value.relations': allValues.value.relations.map(relation => ({
        ...relation,
        candle_from: relation.candle_from - (relation.candle_from - 1 > deletedIndex),
        candle_to: relation.candle_to - (relation.candle_to - 1 > deletedIndex)
      })),
      'value.candles': value.map(candle => candle.same_as
        ? { ...candle, same_as: candle.same_as - (candle.same_as > deletedIndex) }
        : candle
      )
    };
  }
})

const CandlePatternEditor = ({ isLogicEditor, prefix }) => {
  const [pickerOpen, setPickerOpen] = useState(false);
  const switcher = useSwitcher();
  const intl = useIntl();

  const pattern = useMemo(() => switcher.context.pattern, [switcher]);
  const type = useMemo(() => (
    pattern.kind && pattern.kind
      .match(/[^_]+$/)[0]
      .replace(/\b\w/, c => c.toUpperCase())
  ), [pattern]);
  const customPattern = useMemo(() => isCustomPattern(pattern.kind), [pattern.kind]);

  const title = useMemo(() => {
    if (customPattern) {
      return intl.formatMessage(
        { id: 'logicEditor.customPatterns.editorTitle' },
        { title: pattern.title }
      )
    }
    return intl.formatMessage(
      { id: 'candlePatterns.candlePatternEditorTitle' },
      { type, kind: intl.formatMessage({ id: `candlePatterns.${pattern?.kind}` }) }
    )
  }, [pattern])

  const patternField = useField(`value[${switcher.context.patternIndex}]`);

  const handleSubmit = useCallback(value => {
    patternField.input.onChange(value);
    switcher.moveTo(0, undefined, true);
  }, [patternField, switcher]);

  const allPatterns = useField('value');
  const existingTitles = useMemo(() => customPattern && getListOfTitles(
    allPatterns.input.value.filter((_, index) => index !== switcher.context.patternIndex)
  ), [switcher.context]);
  const validate = useMemo(() => validatePatternTitle(existingTitles), [existingTitles]);

  return (
    <Form
      initialValues={pattern}
      onSubmit={handleSubmit}
      mutators={{ ...arrayMutators }}
      decorators={[decorator]}
    >
      {({ handleSubmit, invalid, values }) => (
        <div className="rs-indicator-from create-module-form form">
          <div className="form__card">
            <div className="form__card-content">
              <h2 className="title2 bold">{title}</h2>
              {isLogicEditor && customPattern && (
                <div className="title-editor">
                  <div className="image">
                    {values.image
                      ? <img src={values.image} />
                      : <Icon><ImageTwoToneIcon /></Icon>
                    }
                    <Button
                      variant="outlined"
                      className="outlined-borderless-button"
                      type="button"
                      onClick={() => { setPickerOpen(true) }}
                    >
                      <FormattedMessage id="candlePatterns.setImage" />
                    </Button>
                  </div>
                  <Field
                    withHelper
                    formatOnBlur
                    name="title"
                    component={Input}
                    isSmallField={true}
                    validate={validate}
                    format={value => value.trim()}
                  />
                </div>
              )}
              <FieldArray
                name="value.candles"
                render={({ fields }) => (
                  <div className="candle-rules">
                    <div className="candle-rules-list">
                      {fields.map((name, index) => (
                        <CandleEditor
                          key={index}
                          name={name}
                          index={index}
                          candlesField={fields}
                        />
                      ))}
                    </div>
                    <Button
                      variant="outlined"
                      className="outlined-borderless-button"
                      disabled={fields.length >= maxCandleRuleCount}
                      onClick={() => fields.push(DEFAULT_CANDLE_PATTERN_RULE)}
                    >
                      <FormattedMessage id="module.candlePatterns.addCandle" />
                    </Button>
                  </div>
                )}
              />
              <CandlePatternSettings />
              <CandlePatternRelations />
              <SimpleFormControls
                disabled={invalid}
                handleSave={handleSubmit}
                handleCancel={() => { switcher.moveTo(0, undefined, true); }}
              />
              <Field name="image"
                render={({ input: { onChange } }) => (
                  <ImagePicker
                    open={pickerOpen}
                    onChange={onChange}
                    onClose={() => setPickerOpen(false)}
                  />
                )}
              />
            </div>
          </div>
        </div>
      )}
    </Form>
  );
};

CandlePatternEditor.propTypes = {
  isLogicEditor: PropTypes.bool,
  prefix: PropTypes.string
}

export default CandlePatternEditor;
