import { makeAutoObservable, toJS } from 'mobx';
import _ from 'lodash';

const FI_PROVIDER = process.env.REACT_APP_FI_PROVIDER || 'ew';

export default class InstrumentsStore {
  instruments = [];
  defaultInstrument = {};

  constructor (provider) {
    makeAutoObservable(this);
    this.provider = provider;
  }

  * firstLoad () {
    const { data } = yield this.provider();
    this.instruments = _.filter(data, { provider: FI_PROVIDER });
    this.defaultInstrument = this.instruments[0];
  }

  get instrumentsData () {
    return toJS(this.instruments);
  }

  get (id) {
    return _.find(this.instruments, { id });
  }
}
