import React, { useState } from 'react';
import propTypes from 'prop-types';
import { Field, Form, FormSpy } from 'react-final-form';
import { FormattedMessage } from 'react-intl';

import { intl } from 'translations/provider';
import { validateIntegerField } from 'helpers/validators';
import { useNotify } from 'contexts/popup';
import { VALIDATION_CONFIG, ERRORS, SWITCHER_FIELDS, FIELD_SYMBOL } from '../consts';
import { isValid } from './heplers';
import DialogForm from 'components/DialogForm';
import { Input } from 'components/Fields';
import { CustomSwitch } from 'components/Controls';
import { isValidConsecutiveLosses } from './validators';

import './styles.scss';

const RiskManagementForm = ({ obj, displayedData, isOpenModal, handleSave, handleClose }) => {
  const [prevSwitchersState, setPrevSwitchersState] = useState({
    stopOpeningNewOrders: obj.stopOpeningNewOrders,
    closeOpenOrders: obj.closeOpenOrders,
    moveRuntimesToSavedMode: obj.moveRuntimesToSavedMode,
    sendNotifications: obj.sendNotifications,
  });
  const { error } = useNotify();
  const { available_range: availableRange } = VALIDATION_CONFIG[obj.type];
  const initialValues = obj;

  const validate = (values) => {
    const errors = {};
    errors.value =
      isValid(values?.value || '', availableRange) ||
      validateIntegerField(values?.value || '') ||
      isValidConsecutiveLosses({ value: values?.value, obj, displayedData });

    return errors;
  }

  const showError = ({ title = ERRORS.INCORRECT_ACTION, description }) => {
    error({
      title: intl.formatMessage({ id: title }),
      description: intl.formatMessage({ id: description }),
    });
  };

  const resetFormField = ({ form, changedField, fieldValue }) => {
    form.change(changedField, !fieldValue);
  }

  const handleError = ({ form, changedField, fieldValue, description }) => {
    showError({ description });
    resetFormField({ form, changedField, fieldValue });
  }

  const handleSwitcher = (form) => ({ values }) => {
    const formValue = values;
    const changedField = Object.keys(prevSwitchersState).find((fieldName) => formValue[fieldName] !== prevSwitchersState[fieldName]) || '';

    if (!changedField) {
      return;
    }

    const fieldValue = formValue[changedField];
    const { stopOpeningNewOrders, closeOpenOrders, moveRuntimesToSavedMode } = formValue;

    if (changedField === 'stopOpeningNewOrders' && !fieldValue && (moveRuntimesToSavedMode || closeOpenOrders)) {
      return handleError({ form, changedField, fieldValue, description: ERRORS.STOP_OFF })
    }

    if (changedField === 'closeOpenOrders') {
      if (!fieldValue && moveRuntimesToSavedMode) {
        return handleError({ form, changedField, fieldValue, description: ERRORS.CLOSE_OFF })
      }

      if (fieldValue && !stopOpeningNewOrders) {
        return handleError({ form, changedField, fieldValue, description: ERRORS.CLOSE_ON })
      }
    }

    if (changedField === 'moveRuntimesToSavedMode' && fieldValue && !(stopOpeningNewOrders && closeOpenOrders)) {
      return handleError({ form, changedField, fieldValue, description: ERRORS.MODULE_ON })
    }

    setPrevSwitchersState((prev) => {
      return { ...prev, ...formValue };
    });
  }

  const title = () => (
    <div className="risk-management-popup__title-wrap">
      <div className="risk-management-popup__title">
        <FormattedMessage id={`risk-management.title.${obj.kind}`} />
      </div>
      <div className="risk-management-popup__sub-title">
        <FormattedMessage id={`risk-management.title.${obj.type}`} />
      </div>

      {availableRange && (
        <span className="risk-management-popup__description">
            Allowable range: {availableRange.min_value} —{' '}
          {availableRange.max_value}
        </span>
      )}
    </div>
  )

  const content = (form) => (
    <>
      <div className="risk-management-popup__content">
        <div className="risk-management-popup__field">
          <Field
            withHelper
            name={'value'}
            component={Input}
          />
          <div className="risk-management-popup__symbol bold">
            {FIELD_SYMBOL[obj.type]}
          </div>
        </div>

        {SWITCHER_FIELDS.map(({ label, fieldName }) => (
          <div className="risk-management-popup__field" key={label}>
            <div className="risk-management-popup__field-name">
              <FormattedMessage id={label} />
            </div>
            <Field
              type="checkbox"
              name={fieldName}
              render={({ input, ...rest }) => {
                return (
                  <CustomSwitch
                    input={input}
                    {...rest}
                  />
                )
              }}
            />
          </div>
        ))}
      </div>

      <FormSpy
        onChange={handleSwitcher(form)}
      />
    </>
  )

  const controls = (handleSubmit, invalid) => (
    <div className="risk-management-popup__actions">
      <button
        type="button"
        onClick={handleClose}
        className="primary-btn outline-btn form-action-btn bold"
      >
        <FormattedMessage id="modules.buttons.cancel" />
      </button>
      <button
        type="button"
        onClick={handleSubmit}
        className="primary-btn main-btn form-action-btn bold"
        disabled={invalid}
      >
        <FormattedMessage id="modules.buttons.save" />
      </button>
    </div>
  )

  return (
    <Form
      onSubmit={handleSave}
      initialValues={initialValues}
      validate={validate}
      render={({ handleSubmit, invalid, form }) => (
        <DialogForm
          open={isOpenModal}
          title={title()}
          content={content(form)}
          controls={controls(handleSubmit, invalid)}
          handleClose={handleClose}
          customClass="risk-management-popup" />
      )}
    />
  );
};

RiskManagementForm.propTypes = {
  obj: propTypes.object,
  displayedData: propTypes.object,
  isOpenModal: propTypes.bool,
  handleSave: propTypes.func,
  handleClose: propTypes.func,
}

export default RiskManagementForm;
