import { intl } from 'translations/provider';

export const DATA_TYPES = [
  { label: intl.formatMessage({ id: 'backtest.modal.form.time-range' }), value: 'time_range' },
  { label: intl.formatMessage({ id: 'backtest.modal.form.file' }), value: 'file' }
];
export const ORDER_SIZE_DATA_TYPES = [
  { label: intl.formatMessage({ id: 'backtest.modal.form.dynamic' }), value: 'dynamic' },
  { label: intl.formatMessage({ id: 'backtest.modal.form.static' }), value: 'static' }
];
