import _ from 'lodash';
import { instrumentMessage } from 'globalConstants/tradeManagement';
import { DateTime } from 'luxon';

const performTfs = tfs => {
  return _.isArray(tfs)
    ? tfs
      .filter(tf => tf.enabled)
      .map(tf => tf.label)
      .join(', ')
    : null
}

export const toRow = (instruments) => ({
  id,
  instrument,
  candle_pattern_timeframe: candlePatternTimeframe,
  pattern_kind: patternKind,
  name: title,
  updated_at: lastUpdate,
  rsi_timeframes: rsi,
  stoch_timeframes: { prearming, trigger },
  kind,
  hard_stopped: hardStopped,
  runtime_id: runtimeId
}) => ({
  id,
  title,
  runtimeId,
  prearming: performTfs(prearming),
  trigger: performTfs(trigger),
  rsi: performTfs(rsi),
  instrument: instrumentMessage({ instruments, id: instrument }),
  lastUpdate: DateTime.fromISO(lastUpdate, { zone: 'default' }).toFormat('dd.MM.yy'),
  candlePattern: patternKind === 'candle_pattern'
    ? performTfs(candlePatternTimeframe)
    : '-',
  kind,
  hardStopped
});
