import React, { useEffect, useMemo } from 'react';
import { Pagination } from '@mui/material';
import PropTypes from 'prop-types';
import { MenuItem, Select } from '@material-ui/core';

import './styles.scss';

const TradeHistoryPagination = ({ data, paginationState, isLoading, isPageSizeOptions }) => {
  const { pageNumber, setPageNumber, pageCount, setPageCount, pageSizeOptions, getLogsByPagination } = paginationState;

  useEffect(() => {
    getLogsByPagination();
  }, [pageNumber, pageCount]);

  const handleChangePage = (event, newPage) => {
    setPageNumber(newPage);
  }

  const handleChangePageSize = (event) => {
    setPageCount(event.target.value);
    setPageNumber(1);
  };

  const count = useMemo(() => Math.ceil(data?.count / pageCount) || 1, [data?.count])

  if (!data?.logs?.length) {
    return null;
  }

  return (
    <div className="trade-history-pagination">
      <Pagination
        onChange={handleChangePage}
        shape="rounded"
        count={count}
        disabled={isLoading}
        siblingCount={4}
        page={pageNumber}
        classes={{
          root: 'table-pagination',
          ul: 'pagination-list',
        }}
      />

      {/*{!isPageSizeOptions && (*/}
      {/*  <div className="trade-history-pagination__select-wrap">*/}
      {/*    <span className="trade-history-pagination__select-label">*/}
      {/*      /!* TODO: move to en.json *!/*/}
      {/*      Rows per page:*/}
      {/*    </span>*/}
      {/*    <Select*/}
      {/*      className="custom-select trade-history-pagination__select"*/}
      {/*      defaultValue={pageCount}*/}
      {/*      onChange={handleChangePageSize}*/}
      {/*    >*/}
      {/*      {pageSizeOptions?.map((value) => (*/}
      {/*        <MenuItem value={value} key={value}>{value}</MenuItem>*/}
      {/*      ))}*/}
      {/*    </Select>*/}
      {/*  </div>*/}
      {/*)}*/}
    </div>
  );
};

TradeHistoryPagination.propTypes = {
  data: PropTypes.object,
  paginationState: PropTypes.object,
  isPageSizeOptions: PropTypes.bool,
  isLoading: PropTypes.bool,
};

export default TradeHistoryPagination;
