import React from 'react';
import _ from 'lodash';
import { DateTime } from 'luxon';
import { DATETIME_FORMAT } from 'globalConstants';
import { FormattedMessage } from 'react-intl';
import { intl } from 'translations/provider';

export function roundNum(num, precision) {
  const pow = Math.pow(10, precision);
  return Math.round(num * pow) / pow;
}

export function downloadToFile(content, filename, contentType = 'text/plain') {
  const a = document.createElement('a');
  const file = new Blob([content], { type: contentType });

  a.href = URL.createObjectURL(file);
  a.download = filename;
  a.click();

  URL.revokeObjectURL(a.href);
}

export function createSingleton(createInstance) {
  let instance = null;
  return (...args) => instance || (instance = createInstance(...args));
}

export function compose (...functions) {
  return obj => functions.reduce((acc, f) => f(acc), obj);
}

export function trimString(value) {
  value = value.toString()

  if (value.substr(0, 1) === '0' && value.length > 1) {
    return <FormattedMessage id="modules.validation.onlyInteger" />;
  }
}

/**
 * Get list of titles as strings from candle pattern object array
 * @param {Array<{kind: string, title: string}>} patternArray
 * @returns {Array<string>}
 */
export function getListOfTitles(patternArray) {
  return patternArray.map(pattern => intl.formatMessage({ id: 'candlePatterns.' + pattern.kind, defaultMessage: pattern.title }))
}

export function toPrecision(value, precision) {
  return roundNum(+value, precision).toFixed(precision);
}

const formatter = new Intl.NumberFormat('en-US', {
  signDisplay: 'exceptZero'
})

export const numberFormatter = (precision = 2) => {
  return new Intl.NumberFormat('en-US', {
    maximumFractionDigits: precision
  })
};

export function getValues(obj, withSigns = false) {
  const { symbol, value } = obj;
  if (typeof value === 'object') {
    if (!['\u00B0', 'Pts', '%'].includes(symbol?.trim())) {
      return { ...value, symbol }
    }
    const res = { symbol }
    for (const key of Object.keys(value)) {
      const val = value[key]
      const k = { [key]: key, min_value: 'min', max_value: 'max' }[key]
      if (_.isNumber(val)) {
        res[k] = withSigns ? formatter.format(toPrecision(+val, 2)) : toPrecision(+val, 2)
      }
    }
    return { ...value, ...res }
  }
  return { symbol, value: toPrecision(+value, 2) };
}

export function formatUnixTimestamp (timestamp) {
  return timestamp && DateTime.fromSeconds(timestamp, { zone: 'default' }).toFormat(DATETIME_FORMAT)
}

const compareClosestTimestamp = (selectedTimestamp, ATimestamp, BTimestamp) => {
  return (Math.abs(BTimestamp - selectedTimestamp) < Math.abs(ATimestamp - selectedTimestamp) ? BTimestamp : ATimestamp)
}

export const getClosestTimestamp = (data, selectedTimestamp) => {
  return _.reduce(data, (prev, curr) => {
    const currUTCTimestamp = curr.timestamp ?? curr;
    const prevUTCTimestamp = prev.timestamp ?? prev;

    return compareClosestTimestamp(selectedTimestamp, prevUTCTimestamp, currUTCTimestamp);
  });
};

export function boundNum (min, value, max) {
  !_.isNaN(min) && (value = _.max([min, value]));
  !_.isNaN(max) && (value = _.min([max, value]));
  return value;
}
