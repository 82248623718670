import React from 'react';
import propTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { MenuItem, Select } from '@material-ui/core';
import './styles.scss';

const TimeframeDropdown = ({ tfs, controls, labelType, attr, onChange, label, isShowDescription }) => {
  const periodAttr = `${attr}Period`;

  const shorttenDescription = type => {
    const replacer = [
      { from: 'rsi', to: 'cc' },
      { from: 'candle', to: 'cp' },
      { from: 'prearming', to: 'p' },
      { from: 'trigger', to: 't' },
    ];

    return replacer
      .filter(({ from }) => type.includes(from))
      .map(({ to }) => to)
      .join('/')
      .toUpperCase();
  };

  return (
    <div className="timeframe-dropdown">
      <span className="label">
        {label || <FormattedMessage id={`modules.runModule.tf.${labelType}`} />}:
      </span>
      <Select
        value={controls[periodAttr] + ''}
        onChange={e => { onChange(periodAttr, parseInt(e.target.value)) }}
        IconComponent={() => null}
      >
        {tfs.map(tf => (
          <MenuItem className="dropdown-item" value={tf.value} key={tf.id}>
            <span>{tf.label}</span>
            {isShowDescription && <span className='separate'>{shorttenDescription(tf.type)}</span>}
          </MenuItem>
        )
        )}
      </Select>
    </div>
  );
};

TimeframeDropdown.propTypes = {
  tfs: propTypes.array,
  controls: propTypes.object,
  labelType: propTypes.string,
  attr: propTypes.string,
  onChange: propTypes.func,
  label: propTypes.string,
  isShowDescription: propTypes.bool,
};

export default TimeframeDropdown;
