import React from 'react';
import propTypes from 'prop-types';
import Chart from 'components/EChart';
import { observer } from 'mobx-react';
import LogsPanel from 'components/LogsPanel';

const ChartSync = ({ module, controls, setControls }) => {
  return (
    <div className="run-module-charts">
      <Chart controls={controls} />
      <LogsPanel
        module={module}
        controls={controls}
        setControls={setControls}
      />
    </div>
  );
};

ChartSync.propTypes = {
  module: propTypes.object,
  controls: propTypes.object,
  setControls: propTypes.func,
}

export default observer(ChartSync);
