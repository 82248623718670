import { useIntl } from 'react-intl';

export const filterConfig = {
  initialValues: {
    prearming: ' ',
    instrument: ' ',
  }
};

export const useModules = () => {
  const { selectFields } = _useGetFields()

  return { selectFields }
}

function _useGetFields () {
  const intl = useIntl();

  const selectFields = [
    {
      label: intl.formatMessage({ id: 'modules.filters.prearming.tf' }),
      disabled: true,
      name: 'prearming',
      items: [
        { value: intl.formatMessage({ id: 'modules.filters.all' }), id: ' ' },
        { value: intl.formatMessage({ id: 'modules.filters.prearming.minute' }), id: 'minutely' },
        { value: intl.formatMessage({ id: 'modules.filters.prearming.hourly' }), id: 'hourly' },
        { value: intl.formatMessage({ id: 'modules.filters.prearming.daily' }), id: 'daily' },
      ]
    },
    {
      label: intl.formatMessage({ id: 'modules.filters.instrument' }),
      name: 'instrument',
      items: [
        { value: intl.formatMessage({ id: 'modules.filters.all' }), id: ' ' },
        { value: intl.formatMessage({ id: 'currencyPair.gbpUSD' }), id: 'gbp_usd' },
        { value: intl.formatMessage({ id: 'currencyPair.eurUSD' }), id: 'eur_usd' },
        { value: intl.formatMessage({ id: 'currencyPair.eurGBP' }), id: 'eur_gbp' },
        { value: intl.formatMessage({ id: 'currencyPair.eurJPY' }), id: 'eur_jpy' },
        { value: intl.formatMessage({ id: 'currencyPair.btcUSD' }), id: 'btc_usd' },
        { value: intl.formatMessage({ id: 'currencyPair.xauUSD' }), id: 'xau_usd' },
        { value: intl.formatMessage({ id: 'currencyPair.xagUSD' }), id: 'xag_usd' },
      ]
    }
  ];

  return { selectFields };
};
