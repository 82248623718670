import _ from 'lodash';
import { computed, makeObservable } from 'mobx';

// There is need to group indicators by timeframe and work with them in particular manner
export class TimeframeGrouper {
  constructor (stores) {
    makeObservable(this, {
      groupedByPeriod: computed,
      groups: computed,
    });
    this.orderedStores = stores;
  }

  get groupedByPeriod () {
    return _([0, 1, 2]).groupBy(e => this.orderedStores[e].params.period).values().value();
  }

  get groups () {
    return this.groupedByPeriod.map(group => group.map((e) => this.orderedStores[e]));
  }

  getIndexDataZoom (targetValue) {
    return _.findIndex(this.groupedByPeriod, group => group.includes(targetValue));
  };
}
