import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent } from '@material-ui/core';
import { CloseIcon } from 'components/Svgs';

const DialogForm = ({ open, title, content, controls, handleClose, customClass, disableEnforceFocus }) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      className={`custom-dialog add-frame-form ${customClass}`}
      disableEnforceFocus={disableEnforceFocus}
    >
      <DialogContent>
        <button
          type="button"
          className="single-icon-btn single-icon-btn--white cross"
          onClick={handleClose}
        >
          <CloseIcon />
        </button>
        <h2 className="title2 bold">
          {title}
        </h2>
        <div className="add-frame-form__fields">
          {content}
        </div>
      </DialogContent>
      {controls}
    </Dialog>
  );
};

DialogForm.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.node,
  content: PropTypes.node,
  controls: PropTypes.node,
  handleClose: PropTypes.func,
  customClass: PropTypes.string,
  disableEnforceFocus: PropTypes.bool
};

export default DialogForm;
