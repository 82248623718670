import React, { createContext, useContext, useState } from 'react';
import DialogWindow from 'components/DialogWindow';
import propTypes from 'prop-types';

export const NotifyContext = createContext();
export const useNotify = () => useContext(NotifyContext);

export const NotifyProvider = ({ children }) => {
  const [state, setState] = useState({ open: false });
  const show = ({ title, description, error }) => {
    setState({ title, description, error, open: true });
  };

  const error = props => { show({ ...props, error: true }) };
  const close = () => { setState(old => ({ ...old, open: false })) };

  return (
    <NotifyContext.Provider value={{ show, error }}>
      <DialogWindow
        yesText="OK"
        error={state.error}
        title={state.title}
        description={state.description}
        open={state.open}
        onClose={close}
        confirmHandler={close}
      />
      {children}
    </NotifyContext.Provider>
  );
};

NotifyProvider.propTypes = {
  children: propTypes.node.isRequired
};
