import _ from 'lodash';
import { DateTime } from 'luxon';
import { makeAutoObservable, toJS } from 'mobx';
import { States } from 'globalConstants';

const OFFSET_FIELDS = ['actual_timestamp', 'sort_index', 'id'];
const pickOffset = log => _.pick(log, OFFSET_FIELDS);

export default class LogsStore {
  historyData = [];
  state = States.Done;
  params = {}
  topReached = false
  bottomReached = false
  paramsStates = {}

  constructor (provider, params) {
    this.params = params;
    makeAutoObservable(this);
    this.provider = provider;
  }

  clear () {
    this.historyData.clear();
  }

  * followRSI () {
    if (this.state === States.Done) {
      this.state = States.Loading;
      const { data: { logs = [] } } = yield this.provider({
        ...this.params,
        from: _.nth(this.root.chartStore.rsi.allData, 0)?.timestamp,
        to: _.nth(this.root.chartStore.rsi.allData, -1)?.timestamp,
      });
      this.historyData.replace(logs);
      this.topReached = false;
      this.bottomReached = false;
      this.state = States.Done;
    }
  }

  * firstLoad (param) {
    if (this.state === States.Done) {
      this.clear();
      this.setParams(param);
      const { data: { logs, params } } = yield this.provider(this.params);
      this.paramsStates = params[this.params.position]?.[this.params.indicator]?.[this.params.timeframe];
      this.historyData = logs;
      this.state = States.Done;
      this.topReached = true;
      this.bottomReached = false;
    }
  }

  * nextPage () {
    if (this.state === States.Done && !this.bottomReached) {
      this.state = States.LoadingNewPage;
      const lastLog = _.nth(this.allData, -1);
      const { data: { logs } } = yield this.provider({
        ...this.params,
        ...pickOffset(lastLog),
      });
      if (logs.length) {
        this.historyData.push(...logs);
      } else {
        this.bottomReachedReached = true;
      }
      this.state = States.Done;
    }
  };

  * prevPage () {
    if (this.state === States.Done && !this.topReached) {
      this.state = States.LoadingNewPage;
      const firstLog = _.nth(this.allData, 0);
      const { data: { logs } } = yield this.provider({
        ...this.params,
        ...pickOffset(firstLog),
        reverse: false,
      });
      if (logs.length) {
        logs.reverse();
        this.historyData.unshift(...logs);
      } else {
        this.topReached = true;
      }
      this.state = States.Done;
    }
  }

  * goTo (timestamp) {
    if (this.state === States.Done) {
      this.state = States.Loading;
      const { data: { logs = [] } } = yield this.provider({
        ...this.params,
        nearTimestamp: timestamp,
      });
      this.historyData.replace(logs);
      this.topReached = false;
      this.bottomReached = false;
      this.state = States.Done;
    }
  }

  * downloadLogs (runtimeItemId) {
    return yield this.provider({
      runtimeItemId,
      format: 'xlsx',
      tz_offset: DateTime.now().offset,
    });
  }

  setParams (params) {
    this.params = Object.assign(this.params || {}, params);
  }

  get history () {
    return toJS(this.historyData);
  }

  get isLoading () {
    const hasNoData = this.state === States.Pending || this.state === States.Error;
    return !hasNoData && (this.state === States.Loading);
  }

  get allData () {
    return this.history;
  }
}
