export const URLS = {
  client: {
    baseURL: process.env.REACT_APP_CLIENT_API_URL
  },
  finance: {
    baseURL: process.env.REACT_APP_API_URL
  },
  marketData: {
    baseURL: process.env.REACT_APP_MARKET_DATA_API_URL
  },
  tradeManagement: {
    baseURL: process.env.REACT_APP_TRADE_MANAGEMENT_API_URL
  },
  instruments: {
    baseURL: process.env.REACT_APP_FI_URL
  },
  '3s': {
    baseURL: process.env.REACT_APP_3S_API_URL
  },
};
