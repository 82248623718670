import React from 'react';
import PropTypes from 'prop-types';
import { Select } from '@material-ui/core';

import './styles.scss';

const CustomSelect = ({ children, input, items, ...props }) => {
  const customProps = Object.assign({ children: children ?? items }, input, props);
  return (
    <Select variant="outlined" className="custom-select" {...customProps} />
  );
};

CustomSelect.propTypes = {
  children: PropTypes.any,
  input: PropTypes.any,
  items: PropTypes.any,
};

export default CustomSelect;
