import { useCallback, useMemo, useState } from 'react';
import { DateTime } from 'luxon';
import _ from 'lodash';

import { useStore } from 'hooks';
import { States } from 'store/tradeHistory/consts';
import { useGetFilters, MIN_DATE } from 'globalConstants/tradeFilters';

const RANGE_FILTER_CONFIG = {
  rangeFrom: 'closeOrderRangeFrom',
  rangeTo: 'closeOrderRangeTo'
}

const STATUSES_FILTER = [
  { id: 'active', value: 'Active' },
  { id: 'demo', value: 'Demo' }
];

const PAGE_SIZE_OPTIONS = [10, 25, 50, 100]

export const filterConfig = {
  minDate: MIN_DATE,
  initialValues: {
    moduleName: ' ',
    instrument: ' ',
    timeCycle: ' ',
    version: ' ',
    kind: 'active',
    [RANGE_FILTER_CONFIG.rangeFrom]: MIN_DATE.toMillis(),
    [RANGE_FILTER_CONFIG.rangeTo]: DateTime.now().toMillis()
  },
  rangeFilterConfig: RANGE_FILTER_CONFIG
};

export const useLogs = () => {
  const { tradeHistoryStore } = useStore();
  const stores = { tradeHistoryStore };

  const { lastFilters, getLogs, paginationState } = _useGetLogs({ ...stores });
  const { selectFields } = _useGetFields({ data: stores.tradeHistoryStore?.tradeHistoryData, lastFilters });

  const defaultValues = { ...filterConfig.initialValues }

  return { lastFilters, defaultValues, getLogs, selectFields, paginationState };
}

function _useGetFields ({ data, lastFilters }) {
  const { getFilters, instrumentsFilter } = useGetFilters({ data, lastFilters });

  return useMemo(() => {
    const timeCycleFilter = getFilters('timeCycles');

    const versionsFilter = getFilters('versions');

    const modulesFilter = getFilters('modules');

    const selectFields = [
      {
        label: 'trade.history.filter.time-cycle.label',
        name: 'timeCycle',
        items: timeCycleFilter,
        idMultiple: true
      },
      {
        label: 'trade.history.filter.module.label',
        name: 'moduleName',
        items: modulesFilter,
        idMultiple: true
      },
      {
        label: 'trade.history.filter.version.label',
        name: 'version',
        items: versionsFilter,
        isDisabled: ({ values }) => {
          if (!values) {
            return false;
          }

          const dependentFields = ['moduleName'];
          return dependentFields.some(fieldName => !values[fieldName] || !values[fieldName]?.trim() || !values[fieldName]?.length)
        },
        checkAndReset: (resetFn, prevValue) => {
          const isReset = (
            prevValue.version !== ' ' &&
            prevValue.moduleName === ' '
          );
          if (isReset) {
            resetFn({ ...prevValue, version: ' ' });
          }
        },
        idMultiple: true
      },
      {
        label: 'trade.history.filter.status.label',
        name: 'kind',
        items: STATUSES_FILTER,
        idMultiple: true
      },
      {
        label: 'trade.history.filter.instrument.label',
        name: 'instrument',
        items: instrumentsFilter,
        idMultiple: true
      },
    ];

    return { selectFields };
  }, [data?.filters?.instruments, data?.filters?.modules, data?.filters?.timeCycles, data?.filters?.versions, data?.filters?.statuses]);
};

function _useGetLogs ({ tradeHistoryStore }) {
  const [lastFilters, setLastFilters] = useState(filterConfig.initialValues);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageCount, setPageCount] = useState(20);

  const getLogs = useCallback(({ values }) => {
    const filters = _.mapValues(values ?? lastFilters, (value) => {
      return (value !== ' ' && !_.isNil(value)) ? value : undefined
    })
    if (!filters?.version || (filters?.version?.trim && !filters?.version?.trim())) {
      delete filters.version;
    }
    setLastFilters(filters);

    tradeHistoryStore.getTradeHistory({ ...filters, pageNumber: pageNumber - 1, pageCount });
  }, []);

  const getLogsByPagination = () => {
    if (tradeHistoryStore.state === States.Loading) {
      return;
    }

    if (!lastFilters?.version || (lastFilters?.version?.trim && !lastFilters?.version?.trim())) {
      delete lastFilters.version;
    }
    tradeHistoryStore.getTradeHistory({ ...lastFilters, pageNumber: pageNumber - 1, pageCount });
  }

  const paginationState = {
    pageNumber,
    setPageNumber,
    pageCount,
    setPageCount,
    getLogsByPagination,
    getLogs,
    pageSizeOptions: PAGE_SIZE_OPTIONS
  }

  return { lastFilters, getLogs, paginationState };
}
