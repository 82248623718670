import React from 'react';

const CirclePlus = ({ ...props }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <rect width="20" height="20" rx="10" fill="currentColor" />
      <path
        d="M15.9025 10.9966L15.9025 9.00258H10.997L10.997 4.09712H9.00298L9.00298 9.00258H4.09753L4.09753 10.9966H9.00298L9.00298 15.9021H10.997L10.997 10.9966H15.9025Z"
        fill="black"
      />
    </svg>
  );
};

export default CirclePlus;
