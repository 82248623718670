import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { DateTime } from 'luxon';
import _ from 'lodash';

import { useGetFilters, MIN_DATE } from 'globalConstants/tradeFilters';
import { useStore } from 'hooks';
import { States } from '../../store/tradeHistory/consts';

const RANGE_FILTER_CONFIG = {
  rangeFrom: 'closeOrderRangeFrom',
  rangeTo: 'closeOrderRangeTo'
}

const PAGE_SIZE_OPTIONS = [10, 25, 50, 100]

export const filterConfig = {
  minDate: MIN_DATE,
  initialValues: {
    moduleName: ' ',
    instrument: ' ',
    [RANGE_FILTER_CONFIG.rangeFrom]: MIN_DATE.toMillis(),
    [RANGE_FILTER_CONFIG.rangeTo]: DateTime.now().toMillis()
  },
  rangeFilterConfig: RANGE_FILTER_CONFIG
};

export const useLogs = () => {
  const { tradeHistoryStore, backtestStore } = useStore();
  const { id: runtimeId } = useParams();

  const { backtest } = _useGetBacktest({ tradeHistoryStore, backtestStore, runtimeId });
  const { lastFilters, getLogs, paginationState } = _useGetLogs({ tradeHistoryStore, backtest });
  const { selectFields } = _useGetFields({ data: tradeHistoryStore?.tradeHistoryReportData, lastFilters });

  const defaultValues = { ...filterConfig.initialValues }

  return { backtest, defaultValues, lastFilters, getLogs, selectFields, paginationState };
}

function _useGetFields ({ data, lastFilters }) {
  const { getFilters, instrumentsFilter } = useGetFilters({ data, lastFilters });

  return useMemo(() => {
    const modulesFilter = getFilters('modules');

    const selectFields = [
      {
        label: 'trade.history.filter.module.label',
        name: 'moduleName',
        items: modulesFilter
      },
      {
        label: 'trade.history.filter.instrument.label',
        name: 'instrument',
        items: instrumentsFilter
      }
    ];

    return { selectFields };
  }, [data?.filters?.instruments, data?.filters?.modules]);
};

function _useGetLogs ({ tradeHistoryStore, backtest }) {
  const [lastFilters, setLastFilters] = useState(filterConfig.initialValues);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageCount, setPageCount] = useState(100);

  const getLogs = useCallback(({ values, prepend = false }) => {
    const filters = _.mapValues(values ?? lastFilters, (value) => {
      return (value !== ' ' && !_.isNil(value)) ? value : undefined
    })

    setLastFilters(filters);
    if (backtest.tradeManagementRuntimeId) {
      tradeHistoryStore.getTradeHistoryReport(
        backtest.tradeManagementRuntimeId,
        { ...filters, pageNumber: pageNumber - 1, pageCount },
        prepend
      );
    }
  }, [backtest.tradeManagementRuntimeId]);

  const getLogsByPagination = () => {
    if (tradeHistoryStore.state === States.Loading) {
      return;
    }

    if (backtest.tradeManagementRuntimeId) {
      tradeHistoryStore.getTradeHistoryReport(
        backtest.tradeManagementRuntimeId,
        { ...lastFilters, pageNumber: pageNumber - 1, pageCount }
      );
    }
  }

  const paginationState = {
    pageNumber,
    setPageNumber,
    pageCount,
    setPageCount,
    getLogsByPagination,
    getLogs,
    pageSizeOptions: PAGE_SIZE_OPTIONS
  }

  useEffect(() => { getLogs({ valid: true }) }, [getLogs]);

  return { lastFilters, getLogs, paginationState };
}

function _useGetBacktest ({ backtestStore, tradeHistoryStore, runtimeId }) {
  const [backtest, setBacktest] = useState({});

  useEffect(() => {
    backtestStore.get(runtimeId).then(setBacktest)
    return () => { tradeHistoryStore.tradeHistoryData = []; }
  }, [runtimeId]);

  return { backtest };
}
