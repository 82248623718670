import { computed, makeObservable } from 'mobx';
import IndicatorStore from './baseIndicatorStore';

export default class StochStore extends IndicatorStore {
  constructor () {
    super(...arguments);
    makeObservable(this, {
      forEChart: computed,
    });
  }

  get forEChart () {
    return {
      kLine: this.allData.map(e => [e.timestamp, e.k_value]),
      dLine: this.allData.map(e => [e.timestamp, e.d_value]),
    };
  }
}
