import _ from 'lodash';

const retrieveCustomPatternsNames = entrySystemConditions =>
  _.fromPairs(
    _.flatten(
      entrySystemConditions.map(_.property('value.candlePattern.value'))
    )?.filter(({ title }) => title)?.map(({ kind, title }) => [kind, title])
  )

const formatTimeframes = data =>
  _.entries(_.groupBy(data, 'value'))
    .map(([_, [tf1, tf2]]) => ({ ...tf1, type: `${tf1?.type ?? ''}|${tf2?.type ?? ''}` }));

const produceSettingsValues = (module) => {
  const conditions = {
    rsi_trigger: module.clientModuleParams.rsiConditions,
    stoch_trigger: module.clientModuleParams.stochConditions.trigger,
    stoch_prearming: module.clientModuleParams.stochConditions.prearming,
  };

  const simplified = _(conditions).mapValues(condition => (
    _(condition).mapKeys((v, k) => +k.slice(2))
      .mapValues(conditions => _.fromPairs(conditions.map(c => [c.kind, c.value])))
      .value()
  )).value();

  _.forIn(simplified, condition => _.forIn(condition, conditions => {
    _(conditions).toPairs().forEach(([kind, value]) => {
      if (/(long|short)_(trough|peak)_(1|2)_(angle|left_depth|right_depth)/.test(kind)) {
        const [, key, property] = kind.match(/([a-z]+_[a-z]+_\d)_(\w+)$/);
        (conditions[key] = conditions[key] || {})[property] = value;
      }
    });
  }));

  const esc = _(module.clientModuleParams.entrySystemConditions).filter('enabled').value();
  simplified.escType = esc[0].kind.match(/(long|short)_(\w+)$/)[2];
  simplified.entrySystem = _.fromPairs(esc.map(c => [c.kind.match(/(long|short)_(\w+)$/)[1], c.value]));

  return simplified;
};

export const preformatModule = original => {
  const module = _.cloneDeep(original);
  module.clientModuleParams.stochTimeframes = _.flatten(
    _.entries(module.clientModuleParams.stochTimeframes)
      .map(([type, tfs]) => tfs.map(tf => ({ type, ...tf }))))

  module.clientModuleParams.rsiTimeframes = module.clientModuleParams.rsiTimeframes.map(e => ({ ...e, type: 'rsi' }))

  for (const tfs of ['stochTimeframes', 'rsiTimeframes', 'candlePatternTimeframe']) {
    module.clientModuleParams[tfs] = module.clientModuleParams[tfs]
      .filter(tf => tf.enabled)
      .sort((a, b) => a.value - b.value)
  }

  module.clientModuleParams.stochTimeframes = formatTimeframes(module.clientModuleParams.stochTimeframes)
  module.clientModuleParams.rsiTimeframes = formatTimeframes(module.clientModuleParams.rsiTimeframes)

  module.isPatternKind = module.clientModuleParams.patternKind === 'candle_pattern';
  module.isCloseKind = module.clientModuleParams.patternKind === 'candle_close';

  module.patternTitles = retrieveCustomPatternsNames(module.clientModuleParams?.entrySystemConditions);
  module.isRealtime = module.runtime?.kind === 'demo' || module.runtime?.kind === 'active'
  module.settingsValues = produceSettingsValues(module);

  return module;
};
