import React, { useCallback, useMemo, useState } from 'react';
import propTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Dialog, DialogContent, MenuItem } from '@material-ui/core';
import { CustomSelect } from 'components/Controls';
import { CloseIcon } from 'components/Svgs';
import { Input } from 'components/Fields';
import { TRADE_OPTIONS } from './consts';
import SimpleFormControls from 'components/SimpleFormControls';
import * as validators from 'helpers/validators';

const SizeFrame = ({ open, onClose, onSave, value }) => {
  const [values, setValue] = useState(value.value);
  const [unit, setUnit] = useState(value.unit);

  const error = useMemo(() => (
    validators.validateWithRange(TRADE_OPTIONS[unit], true)(values) || validators.fixedValidationValue(values, TRADE_OPTIONS[unit].precision, TRADE_OPTIONS[unit].option)
  ), [values, unit, setValue, TRADE_OPTIONS]);

  const handleSave = useCallback(() => (onSave({
    ...value,
    value: values,
    unit,
  })), [values, unit]);

  const handleChangeOption = option => () => {
    setUnit(prevUnit => {
      setValue(prevValue => +(prevValue / TRADE_OPTIONS[prevUnit].multiplier * TRADE_OPTIONS[option].multiplier).toFixed(5))
      return option;
    })
  }

  const handleClose = () => {
    setValue(value.value)
    setUnit(value.unit)
    onClose();
  }

  const getAllowableRange = () => `${TRADE_OPTIONS[unit].min_value} — ${TRADE_OPTIONS[unit].max_value}`;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      className="custom-dialog add-frame-form"
    >
      <DialogContent>
        <button
          className="single-icon-btn single-icon-btn--white cross"
          onClick={handleClose}
        >
          <CloseIcon />
        </button>
        <div className="dialog-form-header">
          <h2 className="title2 bold">
            <FormattedMessage id='entry_system.conditions.trade_size' />
          </h2>
          <span className="dialog-form-header__description">
            Allowable range: {getAllowableRange()} Lot
          </span>
        </div>
        <div className="add-frame-form__fields">
          <Input
            value={values}
            autoFocus
            onChange={e => setValue(e.target.value)}
            error={error}
            helperText={error}
          />

          <CustomSelect
            name="valueUnit"
            value={unit}
          >
            {Object.values(TRADE_OPTIONS).map(item => (
              <MenuItem
                className="custom-select__option"
                key={item.option}
                value={item.option}
                onClick={handleChangeOption(item.option)}
              >
                <FormattedMessage id={`entry_system.trade_options.${item.option}`} />
              </MenuItem>
            ))}
          </CustomSelect>
        </div>
      </DialogContent>

      <SimpleFormControls
        flexEnd={true}
        handleCancel={handleClose}
        handleSave={() => { handleSave(); onClose() }}
        disabled={error}
      />
    </Dialog>
  );
};

SizeFrame.propTypes = {
  open: propTypes.bool.isRequired,
  option: propTypes.string.isRequired,
  onSave: propTypes.func.isRequired,
  onClose: propTypes.func.isRequired,
  defaultLabel: propTypes.string.isRequired,
  validate: propTypes.func,
  value: propTypes.number
};

export default SizeFrame;
