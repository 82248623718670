import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Input } from 'components/Fields';
import { trimString } from 'helpers';
import * as validators from 'helpers/validators';
import { FormattedMessage } from 'react-intl';
import ClearIcon from '@material-ui/icons/Clear';
import { Grid, MenuItem } from '@material-ui/core';
import { Field, useField } from 'react-final-form';
import { CustomSelect } from 'components/Controls';
import { relationTypeVariants, symbolVariants } from 'globalConstants/candlePatterns';

import './styles.scss';

const defaultWidth = 100;
const typeWidth = 250;

export const CandlePatternRelation = ({ name, handleDelete, candleCount }) => {
  const { meta } = useField(name, {
    validate: ({ candle_from: cf, candle_to: ct }) => (
      cf === ct
        ? <FormattedMessage id="candlePatterns.error_message.same_candle" />
        : undefined
    )
  });
  const range = { min_value: 1, max_value: 100 };

  return (
    <div className="candle-pattern-relation">
      <Grid container wrap="nowrap">
        <Grid item>
          <Field
            name={`${name}.candle_from`}
            style={{ width: defaultWidth }}
            component={CustomSelect}
            items={_.range(1, candleCount + 1).map(v => (
              <MenuItem key={v} value={v}>
                <FormattedMessage id="candlePatterns.relationNumber" values={{ number: v }} />
              </MenuItem>
            ))
            }
          />
        </Grid>
        <Grid item>
          <Field
            name={`${name}.from_type`}
            style={{ width: typeWidth }}
            component={CustomSelect}
            items={relationTypeVariants.slice(0, 4).map(v => (
              <MenuItem key={v} value={v}>
                <FormattedMessage id={`candlePatterns.relations.${v}`} />
              </MenuItem>
            ))}
          />
        </Grid>
        <Grid item>
          <Field
            name={`${name}.symbol`}
            style={{ width: defaultWidth }}
            component={CustomSelect}
            items={symbolVariants.map(s => (
              <MenuItem key={s} value={s}>{s}</MenuItem>
            ))
            }
          />
        </Grid>
        <Grid item className="input">
          <div style={{ width: defaultWidth, paddingTop: '5px' }}>
            <Field
              isSmallField
              component={Input}
              name={`${name}.percent`}
              validate={(value) => (
                validators.validateWithRange(range)(value) ||
                validators.validateWithoutSpace(value) ||
                validators.validateIntegerField(value) ||
                trimString(value)
              )}
            />
          </div>
          <p>%</p>
        </Grid>
        <Grid item>
          <Field
            name={`${name}.candle_to`}
            component={CustomSelect}
            style={{ width: defaultWidth }}
            items={_.range(1, candleCount + 1).map(v => (
              <MenuItem key={v} value={v}>
                <FormattedMessage id="candlePatterns.relationNumber" values={{ number: v }} />
              </MenuItem>
            ))
            }
          />
        </Grid>
        <Grid item>
          <Field
            name={`${name}.to_type`}
            style={{ width: typeWidth }}
            component={CustomSelect}
            items={relationTypeVariants.map(v => (
              <MenuItem key={v} value={v}>
                <FormattedMessage id={`candlePatterns.relations.${v}`} />
              </MenuItem>
            ))
            }
          />
        </Grid>
        <Grid item className="delete-container">
          {handleDelete && (
            <button onClick={handleDelete} className="single-icon-btn" type="button">
              <ClearIcon />
            </button>
          )}
        </Grid>
      </Grid>
      <div className="error-message">
        {meta.error?.percent ?? meta.error?.symbol ?? meta.error}
      </div>
    </div>
  );
};

CandlePatternRelation.propTypes = {
  name: PropTypes.string.isRequired,
  candleCount: PropTypes.number.isRequired,
  handleDelete: PropTypes.func
};
