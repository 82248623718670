import React, { useMemo, useState } from 'react';

import { FormattedMessage } from 'react-intl';
import { Field, useField } from 'react-final-form';
import { EditIcon } from 'components/Svgs';

import MultipleTradeExecutionFrame from 'components/MultipleTradeFrame';
import classNames from 'classnames';

const MultipleTradeExecution = () => {
  const [open, setOpen] = useState(false);

  const entrySystem = useField('entrySystemConditions');

  const currentType = useMemo(() => {
    const enabledTolerance = entrySystem.input.value.find(e => e.enabled);

    return ['candle_pattern', 'candle_close'].find(t => enabledTolerance.value[t].enabled)
  }, [entrySystem]);

  const disabled = useMemo(() => currentType === 'candle_close', [currentType])

  return (
    <>
      <div className={classNames('rs-indicator-list__item', { disabled })}>
        <p style={{ maxWidth: 219 }}>
          <FormattedMessage id="entry_system.conditions.multiple_trade_execution" />
        </p>
        <div className="rs-indicator-list__interval">
          <div className="rs-indicator-list__interval"></div>
        </div>
        <button
          type="button"
          className="single-icon-btn"
          onClick={() => setOpen(true)}
          disabled={disabled}
        >
          <EditIcon />
        </button>

        <Field name="tradeManagement.multiple_trade_execution">
          {({ input: { value, onChange } }) => (
            <MultipleTradeExecutionFrame
              open={open}
              value={value}
              onClose={() => setOpen(false)}
              onSave={onChange}
            />
          )}
        </Field>
      </div>
    </>
  );
}

export default MultipleTradeExecution
