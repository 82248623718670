import React from 'react';
import RunModule from 'containers/Modules/RunModule';

const RunModulePage = () => {
  return (
    <RunModule />
  );
};

export default RunModulePage;
