import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';

import { NAV_LINKS } from './consts';
import { ACTIVE_MODULE_TYPE } from 'containers/ModulesListContainer/consts';

import './styles.scss';

const ModulesTypeTabs = ({ activeTab, handlers }) => {
  return (
    <div className="modules-tabs">
      <div className="modules-tabs-inner">
        {NAV_LINKS.map(({ title, tab }) => (
          <button
            key={title}
            onClick={() => handlers.onChange(tab)}
            className={cn('main-btn secondary-btn bold', { active: activeTab === tab })}
            disabled={tab === ACTIVE_MODULE_TYPE}
          >
            <FormattedMessage id={title} />
          </button>
        ))}
      </div>
    </div>
  );
};

ModulesTypeTabs.propTypes = {
  activeTab: PropTypes.string,
  handlers: PropTypes.arrayOf(PropTypes.func),
};

export default ModulesTypeTabs;
