import urlcat from 'urlcat';
import { get, getBlob } from 'api/utils';
import * as consts from './consts';

const logsApiBuilder = (baseUrl, additionalFilters = {}) => params => {
  const uri = urlcat('', baseUrl, { ...additionalFilters, ...params });
  return get('3s', uri, true, null, params.format === 'xlsx');
};

export const armingLine = params => {
  params = { ...params, from: params.begin, to: params.end };
  delete params.begin;
  delete params.end;
  const uri = urlcat('', consts.ARMING_LINES_URL, params);
  return get('3s', uri, true);
};

const getDataSource = (kind, runtimeId) => {
  if (kind === 'simulation_with_file') {
    return (process.env.REACT_APP_DATA_SOURCE_FILE || consts.DATA_SOURCE_FILE) + `_${runtimeId}`;
  }
  return process.env.REACT_APP_DATA_SOURCE || consts.DATA_SOURCE;
}
const chartApiBuilder = template => params => {
  const { runtimeId, setId, count, to, from, percent } = params;
  const isAllowedKind = consts.ALLOWED_KIND_TYPES.some(kindType => kindType === params.kind);
  const kind = ['demo', 'active'].includes(params.kind) ? 'trading' : params.kind;
  const url = isAllowedKind ? `${kind}/${template}` : template;
  const dataSource = getDataSource(kind, runtimeId);
  const uri = urlcat('', url, { dataSource, runtimeId, setId, count, to, from, percent });
  return get('marketData', uri, true);
};

export const candle = chartApiBuilder(consts.CANDLESTICK_API_URL);
export const rsi = chartApiBuilder(consts.RSI_API_URL);
export const stoch = chartApiBuilder(consts.STOCH_API_URL)

const chartCSVApiBuilder = baseUrl => params => {
  const { candleSetId: candle, rsiSetId: rsi, stochSetId: stoch, kind, runtimeId } = params;

  const dataSource = getDataSource(kind, runtimeId);
  const uri = urlcat('', baseUrl, { runtimeId, candle, rsi, stoch, dataSource });
  return getBlob('marketData', uri, true);
}

export const logsProvider = logsApiBuilder(consts.LOGS_3S);

export const chartCSVApiProvider = chartCSVApiBuilder(consts.CHART_CSV_API_URL)
export const currentPrices = () => get('marketData', consts.CURRENT_PRICE_URL, true)
export const instruments = () => get('instruments', consts.INSTUMENTS_URL, true)
