import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Select, MenuItem } from '@material-ui/core';

import './styles.scss';

const useStyles = makeStyles({
  select: {
    '&.MuiPaper-root': {
      width: 'auto',
    }
  },
});
const CustomSelect = ({ input, meta, withHelper, items, ...props }) => {
  const classes = useStyles();

  const customProps = Object.assign({
    error: meta?.invalid
  }, props, input);
  if (withHelper) customProps.helperText = meta?.error;
  return (
    <Select
      className="custom-select"
      label="Age"
      MenuProps={{ classes: { paper: classes.select } }}
      {...customProps}
    >
      {items?.map((item) => (
        <MenuItem value={item.id} key={item.id}>{item.value}</MenuItem>
      ))}
    </Select>
  );
};

CustomSelect.propTypes = {
  withHelper: PropTypes.bool,
  input: PropTypes.object,
  items: PropTypes.array,
  meta: PropTypes.shape({
    invalid: PropTypes.bool,
    error: PropTypes.node
  })
};

export default CustomSelect;
