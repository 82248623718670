import React from 'react';
import _ from 'lodash';
import { globalConstValue } from '../propTypes';
import DescriptionRow from '../../EntrySystem/Patterns/Common/DescriptionRow';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import './styles.scss';

export const CandlePatternRule = React.memo(({ rule, index }) => {
  const intl = useIntl();
  const values = React.useMemo(() => {
    const v = Object.entries(rule.value);
    return v.filter(([name]) => !['relations'].includes(name))
  }, [rule.value]);

  return (
    <div>
      <h3 className="candle-pattern-rule__title bold">
        {intl.formatMessage(
          { id: 'module.candlePatterns.candleTitle' },
          { number: index + 1 }
        )}
      </h3>
      <div className={classNames('description-row')} data-kind="color">
        <div>Colour: {!_.isNil(rule.same_as)
          ? intl.formatMessage(
            { id: 'module.candlePatterns.sameAs' },
            { number: rule.same_as + 1 }
          ) : rule.colors.join(' / ')
        }</div>
      </div>
      {values.map(([_, rule], ruleIndex) => (
        <DescriptionRow
          index={ruleIndex}
          key={ruleIndex}
          field={rule}
          prefix="candlePatterns"
        />
      ))}
    </div>
  );
});

CandlePatternRule.displayName = 'CandlePatternRule';

CandlePatternRule.propTypes = {
  rule: globalConstValue.isRequired,
  index: PropTypes.number.isRequired
};
