import React from 'react';
import PropTypes from 'prop-types';
import { FieldArray } from 'react-final-form-arrays';

import TimeFrameList from 'components/TimeFrame';

import './styles.scss';

const CommonTimeFrame = ({ timeframesField }) => (
  <FieldArray
    name={timeframesField}
    component={TimeFrameList}
  />
);

CommonTimeFrame.propTypes = {
  timeframesField: PropTypes.string
};

export default CommonTimeFrame;
