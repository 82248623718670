import React from 'react';
import _ from 'lodash';
import cn from 'classnames';
import { DateTime } from 'luxon';

import { numberFormatter } from '../helpers';
import { intl } from 'translations/provider';
import { instrumentMessage } from './tradeManagement';
import { DATE_HISTORY_FORMAT } from './index';

export const performers = {
  candlePattern: ({ patterns, patternTitles }) => {
    return (
      patterns.map(pattern => (
        intl.formatMessage({
          id: `candle_close.conditions.${pattern}`,
          // defaultMessage: patternTitles[pattern]
        })
      )).join('\n\n')
    )
  }
}

const multiplyPosition = position => position === 'long' ? 1 : -1;

export const globalFormatters = {
  empty: () => '-',
  identity: ({ value }) => value,
  version: ({ row: { moduleCreatedAt, version } }) => (
    `${version} (${DateTime.fromISO(moduleCreatedAt, { zone: 'default' }).toFormat(DATE_HISTORY_FORMAT)})`
  ),
  intl: ({ value }) => value && intl.formatMessage({ id: `trade.history.formatter.${value}` }),
  fixed: precision => ({ value }) => !_.isNil(value) ? numberFormatter(precision).format(value) : null,
  fixedCurrency: precision => ({ value }) => !_.isNil(value) ? `$ ${numberFormatter(precision).format(value)}` : null,
  fixedPercent: precision => ({ value }) => !_.isNil(value) ? `${numberFormatter(precision).format(value)} %` : null,
  time: ({ value }) => value && DateTime.fromISO(value, { zone: 'utc' }).setZone('default').toFormat('HH:mm'),
  datetime: ({ value }) => value && DateTime.fromISO(value, { zone: 'default' }).toFormat('dd.MM.yyyy HH:mm:ss'),
  instrument: ({ value, instruments }) => instrumentMessage({ instruments, id: value }) || null,
  totalLabel: () => intl.formatMessage({ id: 'trade.history.total' }),
  pattern: ({ value }) => value.length && performers.candlePattern({ patterns: value }),

  stopLoss: ({ row: { position, openPrice, stopLoss } }) => {
    if (_.isNil(openPrice) || _.isNil(stopLoss)) {
      return null;
    }

    const value = openPrice - multiplyPosition(position) * stopLoss;

    return numberFormatter(6).format(value);
  },
  profitTarget: ({ row: { position, openPrice, profitTargetPts } }) => {
    if (_.isNil(openPrice) || _.isNil(profitTargetPts)) {
      return null;
    }

    const value = openPrice + multiplyPosition(position) * profitTargetPts;

    return numberFormatter(6).format(value);
  },
  pl: ({ row: { position, openPrice, lastPrice, stopLossPts, size, instrument }, instruments }) => {
    const coefficient = _(instruments).find({ id: instrument })?.coefficient;

    if (!coefficient) {
      return;
    }

    const value =
      multiplyPosition(position) * (lastPrice - openPrice) *
      coefficient * size / stopLossPts;

    return !_.isNaN(value) ? `$ ${numberFormatter().format(value)}` : '';
  },
  viewButton: ({ handlers, row }) => (
    <button className="main-btn secondary-btn bold" onClick={() => handlers.viewHandler(row)}>
      {intl.formatMessage({ id: 'modules.buttons.view' })}
    </button>
  ),
  closeButton: ({ handlers, row }) => (
    <button className="main-btn secondary-btn bold" onClick={() => handlers.closeHandler(row)}>
      {intl.formatMessage({ id: 'modules.buttons.close' })}
    </button>
  ),
  returnRate: (plFn, fixedPercentFn) => ({ row }) => {
    const pl = plFn({ row }).replace(/,/g, '');
    return fixedPercentFn({ value: pl / row.size });
  },
  notionalValue: ({ row: { notionalValue } }) => {
    return !_.isNaN(notionalValue) ? `$ ${numberFormatter(10).format(notionalValue)}` : '';
  },
};

export const globalClassNames = {
  center: () => 'center',
  left: () => 'left',
  bold: () => cn('center', 'bold'),
  pl: ({ value, formattedValue }) => {
    const targetValue = ((value && `${value}`) || formattedValue);
    const plValue = targetValue?.replace(/[$,]/g, '');
    return cn('center', { red: plValue <= 0, green: plValue > 0 });
  },
  position: ({ value }) => cn('center', { 'red-text': value === 'short', 'green-text': value === 'long' }),
  breakSpaces: () => 'center break-spaces',
  inline: () => 'center nowrap',
};

export const defaultHeaderColumnClasses = 'center bold';
