import React, { useState, useMemo, useEffect } from 'react';
import propTypes from 'prop-types';
import ReactCodeInput from 'react-code-input';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { Dialog } from '@material-ui/core';
import { CloseIcon } from 'components/Svgs';
import './styles.scss';
import { useNotify } from '../../contexts/popup';

const tinyHash = s => {
  for (var i = 0, h = 9; i < s.length;) {
    h = Math.imul(h ^ s.charCodeAt(i++), 9 ** 9);
  }
  return h ^ h >>> 9
}

const CORRECT_CODE_HASH = 0x57b359d4;

const PasswordProtected = ({ children, pageName, ...rest }) => {
  const history = useHistory();
  const intl = useIntl();
  const { error } = useNotify();

  const [code, setCode] = useState('');
  const isCodeCorrect = pageName !== 'fundManagement' || (code.length === 6 && tinyHash(code) === CORRECT_CODE_HASH);
  const isCodeIncorrect = code.length === 6 && tinyHash(code) !== CORRECT_CODE_HASH;
  const isCodeStored = useMemo(() => sessionStorage.getItem(pageName) === 'allowed', [pageName]);

  const handleClose = () => {
    if (history.action === 'POP') {
      history.push('/');
    } else {
      history.goBack();
    }
  }

  useEffect(() => {
    if (isCodeCorrect) {
      sessionStorage.setItem(pageName, 'allowed');
    }
  }, [isCodeCorrect]);

  useEffect(() => {
    if (isCodeIncorrect) {
      error({
        title: intl.formatMessage({ id: 'fundManagement.error.title' }),
        description: intl.formatMessage({ id: 'fundManagement.error.description' }),
      });
    }
  }, [isCodeIncorrect]);

  useEffect(() => {
    return () => {
      sessionStorage.removeItem(pageName);
    };
  }, []);

  return <>
    <Dialog
      open={!(isCodeCorrect || isCodeStored)}
      aria-labelledby="form-dialog-title"
      className="custom-dialog add-frame-form password-protected-dialog"
    >
      <div className="password-protected-dialog__title">
        <FormattedMessage id="fundManagement.password" />
      </div>

      <ReactCodeInput
        fields={6}
        name="code"
        type="password"
        inputMode="numeric"
        value={code}
        onChange={setCode}
        isValid={!isCodeIncorrect}
      />

      <button
        type="button"
        className="single-icon-btn single-icon-btn--white fund-management-dialog__close-icon cross"
        onClick={handleClose}
      >
        <CloseIcon />
      </button>
    </Dialog>
    {(isCodeCorrect || isCodeStored) && children}
  </>;
}

PasswordProtected.propTypes = {
  children: propTypes.node,
  pageName: propTypes.string
};

export default PasswordProtected;
